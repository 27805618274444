import { useCallback, useEffect, useState } from "react";
import { IBaseList } from "../../core/entities/BaseList";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { getEmptyGUID } from "../../core/utilities/String";
import { defaultUserMessageAlert, defaultUserMessageAlerts, IUserMessageAlert } from "../entities/UserMessageAlert";

export const useGetAlerts = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IUserMessageAlert>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, getUrl('/api/app/alert-messages-user', { maxResultCount: 1000 }));
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetUserMessageAlertHistory = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IUserMessageAlert>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, getUrl('/api/app/alert-messages-user/history', { maxResultCount: 1000 }));
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePostAlertMarkAsRead = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [alert, setAlert] = useState<IUserMessageAlert>(defaultUserMessageAlert);
    const [alerts, setAlerts] = useState<IUserMessageAlert[]>(defaultUserMessageAlerts);
    const [totalItemCount, setTotalItemCount] = useState<number>(0);

    const updateAlert = useCallback((alert: IUserMessageAlert) => {
        alerts.map((item) => {
            if (item.id === alert.id) {
                item.isRead = true;
                return item;
            }
            else
                return item;
        });

        return alerts;
    }, [alerts]);

    useEffect(() => {
        if (alert.id === getEmptyGUID()) { return; }
        processPostAsync(setService, `/api/app/alert-messages-user/mark-read?messageId=${alert.id}`);
        updateAlert(alert);
        setTotalItemCount(alerts.filter(e => !e.isRead).length);
        setAlert(defaultUserMessageAlert);
        setAlerts(defaultUserMessageAlerts);
    }, [alert, alerts, updateAlert]);

    return { service, totalItemCount, updateAlert, setAlert, setAlerts, setTotalItemCount };
};

const getUrl = (url: string, parameters: Record<string, any>): string => { //TODO: move to core
    if (!parameters) { return url; }

    const params = Object.keys(parameters)
        .filter(key => parameters[key])
        .map(key => `${key}=${parameters[key]}`)
        .join('&');

    return `${url}?${params}&MaxResultCount=1000`;
}