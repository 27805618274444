import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { IUser } from '../../entities/User';
import { usePutUser } from '../../services/UserService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IUser> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            <OEFormInput
                label="UserName" name="userName" value={values.userName}
                disabled={true} errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="First Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Last Name" name="surname" value={values.surname}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Email" name="email" value={values.email}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Phone Number" name="phoneNumber" value={values.phoneNumber}
                errors={errors} touched={touched} columns={labelColumns}
                required={false} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IUser>().shape({
    name: Yup.string().required('First Name is required').min(2).max(250).nullable(),
    email: Yup.string().required('Email is required').min(2).max(250).nullable(),
    surname: Yup.string().required('Last Name is required').min(2).max(250).nullable(),
    //    externalUrl: Yup.string()
    //        .when('routeId', {
    //            is: 0,
    //            then: Yup.string().required('External Url is required when no route is chosen.').min(1).nullable()
    //        })
});

interface IFormikInfo {
    item: IUser;
    onCancel: () => void;
    onSave: () => void;
}

const UserFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {

    const { service, setUser } = usePutUser();

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            title={`Edit User: ${item.name}`}
            progressMessage={ProgressMessages.User}
            successMessage={SuccessMessages.User}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={setUser}
            service={service}
        />
    );
};

export default UserFormik;
