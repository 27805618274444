import React, { useEffect, useState } from 'react';
import OEBreadcrumb from '../../core/components/breadcrumbs/OEBreadcrumb';
import { IBreadcrumb } from '../../core/components/breadcrumbs/entities/Breadcrumb';
import OEFilter from '../../core/components/filter/OEFilter';
import { IFilter } from '../../core/components/filter/entities/Filter';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import { IConfirmationMessage, MessageSubstitions, defaultConfirmationMessage, getConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { OrganizationUnitDeleteConfirm, OrganizationUnitDeleteError } from '../../core/components/messaging/enums/ConfirmationMessages';
import OETable from '../../core/components/table/OETable';
import { ColumnType, IColumn, ModifiedColumns } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import { ITableAction } from '../../core/components/table/entities/TableAction';
import { Icon } from '../../core/entities/Icon';
import { parseQueryString, updateURLParameter } from '../../core/utilities/URL';
import { IOrganizationType, defaultOrganizationType, getChildren, getOrganizationType } from '../entities/OrganizationType';
import { IOrganizationUnit, defaultOrganizationUnit, getOrganizationUnitList } from '../entities/OrganizationUnit';
import { useDeleteOrganizationUnit, useGetOrganizationUnits } from '../services/OrganizationUnitService';
import OrganizationUnitFormik from './OrganizationUnitFormik';

enum ModalTypes {
    None = 1,
    Edit = 2,
}

interface IComponentInfo {
    types: IOrganizationType[];
}

const OrganizationUnits: React.FunctionComponent<IComponentInfo> = ({ types }) => {
    const params: any = parseQueryString();

    const { service, doRefresh } = useGetOrganizationUnits();
    const { service: deleteService, setOrganizationUnitId: setDeleteId } = useDeleteOrganizationUnit();

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [items, setItems] = useState<IOrganizationUnit[]>([]);
    const [filterTypeList, setFilterTypeList] = useState<IOrganizationUnit[]>([]);
    const [filterList, setFilterList] = useState<IOrganizationUnit[]>([]);
    const [item, setItem] = useState<IOrganizationUnit>(defaultOrganizationUnit);
    const [orgType, setOrgType] = useState<IOrganizationType>({ ...defaultOrganizationType, id: 0 });
    const [parentId, setParentId] = useState<string>(params.orgid || '');
    const [parentName, setParentName] = useState<string>('Top Level');
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: '', name: 'Top Level' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [filterTypes, setFilterTypes] = useState<IOrganizationType[]>([]);
    const [addActions, setAddActions] = useState<ITableAction[]>([]);
    const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (types.length > 0) {
            doRefresh()
        }
        // eslint-disable-next-line
    }, [types]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            onSave();
        }
        else if (deleteService.isFinished) {
            setConfirmation({ ...OrganizationUnitDeleteError, setConfirmation, message: deleteService.response.message, onOk: onCancel });
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        if (service.result) {
            setItems(getOrganizationUnitList(service.result, types));
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateBreadcrumbs(parentId);
        }
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateURLParameter('orgid', parentId);
            updateBreadcrumbs(parentId);
        }
        else {
            setFilterList([]);
        }
        // eslint-disable-next-line
    }, [parentId]);

    useEffect(() => {
        const t: IOrganizationType[] = getChildren(types, orgType.id);
        const a: ITableAction[] = [];
        setFilterTypes(t);
        if (parentId !== '') {
            a.push({ icon: Icon.Add, text: `Add New Unit`, action: onAdd });
        }
        setAddActions(a);
        // eslint-disable-next-line
    }, [orgType]);

    useEffect(() => {
        if (breadcrumbs.length > 0) {
            setParentName(breadcrumbs[breadcrumbs.length - 1].name);
        }
    }, [breadcrumbs]);

    const updateBreadcrumbs = (pid: string, breadcumbs?: IBreadcrumb[]) => {
        let b: IBreadcrumb[] = breadcumbs || [];
        if (pid !== '') {
            let i: IOrganizationUnit = items.filter(q => q.id === pid)[0];
            b = [{ id: i.id, name: i.displayName }].concat(b);
            updateBreadcrumbs(i.parentId || '', b);
        }
        else {
            b = rootBreadcrumbs.concat(b);
            if (parentId !== '') {
                setOrgType(getOrganizationType(types, items.filter(q => q.id === parentId)[0].typeId));
            }
            else {
                setOrgType(getOrganizationType(types, 0));
            }
            setBreadcrumbs(b);
        }
    }

    const onFilter = (s: IOrganizationUnit[]) => {
        if (s.filter(q => q.parentId === parentId).length > 0) {
            setFilterTypeList(s.filter(q => q.parentId === parentId));
        }
        else {
            setFilterTypeList([]);
            setFilterList([]);
        }
    }

    const onSave = () => {
        setModalType(ModalTypes.None);
        doRefresh();
    };

    const onCancel = () => {
        setModalType(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultOrganizationUnit, parentId });
        setModalType(ModalTypes.Edit);
    };

    const onEdit = (i: IOrganizationUnit) => {
        setItem(i);
        setModalType(ModalTypes.Edit);
    };

    const onNavigate = (i: IOrganizationUnit) => {
        setParentId(i.id);
    };

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        setParentId(i.id);
    };

    const onConfirmDelete = (i: IOrganizationUnit) => {
        const m: IConfirmationMessage = getConfirmationMessage(OrganizationUnitDeleteConfirm, [{ name: MessageSubstitions.Name, value: i.displayName }]);
        setConfirmation({ ...m, setConfirmation, item: i.id, onOk: setDeleteId, onCancel: onCancel });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit Unit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, helpText: 'Delete Unit', condition: 'childCount', notCondition: true }
    ];

    const childactions: IColumnAction[] = [
        { icon: Icon.ChildrenNavigate, onClick: onNavigate, helpText: 'View Child Menus', condition: 'canAddChild' }
    ];

    const columns: IColumn[] = [
        { actions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { actions: childactions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'displayName', name: 'Name', sort: true, type: ColumnType.Link, onClick: onEdit },
        { id: 'type', name: 'Type', sort: true, type: ColumnType.String },
        { id: 'importId', name: 'Import Id', sort: true, type: ColumnType.String },
        { id: 'childCount', width: "20px", name: 'Children', sort: true, type: ColumnType.Integer, className: 'text-center' },
        ...ModifiedColumns,
    ];

    const filter: IFilter = {
        name: 'orgUnits',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['displayName'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by name, type' },
        ],
    };

    return (
        <>
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />
            <OEFilter
                singleLine={true} refresh={doRefresh} className="m-t-0" items={filterTypeList} filter={filter}
                setFilterList={setFilterList} />
            <OETable
                loading={service.isInProgress}
                loadingMessage="Loading Organizations"
                data={filterList}
                columns={columns}
                showPagination={filterList.length > 10}
                defaultSort="textCol"
                defaultPageSize={25}
                noDataMessage={`No units found for your search criteria ${parentId !== '' ? ` under ${parentName}` : ' at the Top Level'}, click <b>Add New Unit</b> to add.`}
                actions={addActions}
            />

            {modalType === ModalTypes.Edit && (
                <OrganizationUnitFormik types={filterTypes} onCancel={onCancel} onSave={onSave} item={item} />
            )}
            <OEConfirmation {...confirmation} />
        </>
    );
};

export default OrganizationUnits;