import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";

export enum ProfilePictureType {
    None = 0,
    Gravatar = 1,
    Image = 2
}

export interface IProfileImage extends IBaseAuditedEntity {
    type: number;
    source: string;
    fileContent: string,
    imageContent: string;
    newType: number;
}

export const defaultProfileImage: IProfileImage = {
    type: -1, source: '', imageContent: '', fileContent: '', newType: -1
};

export const getImageSource = (p: IProfileImage): string => {
    return `${p.type === ProfilePictureType.Gravatar ? p.source : `data:image/png;base64,${p.fileContent}`}`
}

export const getByteValue = (p: string): string => {
    const index: number = p.indexOf(',');
    return p.substring(index+1);
}
