import React, { useEffect, useState } from 'react';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import { IConfirmationMessage, defaultConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { Icon } from '../../../core/entities/Icon';
import { IReportPage, ReportPageType, defaultReportPage, getReportPageList } from '../../entities/ReportPage';
import { useDeleteReportPage, useGetReportPages } from '../../services/ReportPageService';
import ReportPageFormik from './ReportPageFormik';

enum ModalTypes {
    None = 1,
    Edit,
}

interface IPageList {
    setPage: (i: IReportPage) => void;
    pageType: ReportPageType;
    defaultConfiguration: string;
}

const ReportPages: React.FunctionComponent<IPageList> = ({ setPage, pageType, defaultConfiguration }) => {

    const { service, setItemId } = useGetReportPages();
    const { service: deleteService, setItemId: setDeleteId } = useDeleteReportPage();

    const [items, setItems] = useState<IReportPage[]>([]);
    const [item, setItem] = useState<IReportPage>(defaultReportPage);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);

    useEffect(() => {
        refreshItems();
        // eslint-disable-next-line
    }, [pageType]);

    useEffect(() => {
        if (service.result) {
            setItems(getReportPageList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            refreshItems();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    const refreshItems = () => {
        setItemId(pageType);
    }

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onViewGroup = (i: IReportPage) => {
        setPage(i);
    };

    const onAdd = () => {
        setItem({
            ...defaultReportPage,
            pageType, configuration: defaultConfiguration
        });
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IReportPage) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onSuccess = () => {
        setShowModal(ModalTypes.None);
        refreshItems();
    };


    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IReportPage) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Report Page?",
            message: `Are you sure you want to delete the report page <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "groupCount", notCondition: true, helpText: 'Delete' },
    ];

    const childactions: IColumnAction[] = [
        { icon: Icon.ChildrenNavigate, onClick: onViewGroup, helpText: 'View Report Groups' }
    ];

    const columns: IColumn[] = [
        { actions: childactions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'name', name: 'Name / Id', idNewLine: 'id', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Report Page' },
        { id: 'description', name: 'Description', sort: true, type: ColumnType.String },
        { id: 'path', name: 'Path', sort: true, type: ColumnType.NavigationLink, target: '_blank' },
        { id: 'groupCount', name: 'Children', sort: true, type: ColumnType.Integer, className: 'text-center' },
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
    ];

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OETable
                loading={service.isInProgress}
                loadingMessage="Loading Report Pages"
                data={items}
                noDataMessage="There are no report pages"
                columns={columns}
                showPagination={true}
                defaultSort="name"
                defaultPageSize={10}
                actions={[
                    { icon: Icon.Add, text: 'Add New Page', action: onAdd },
                ]}
            />
            {showModal === ModalTypes.Edit && (
                <ReportPageFormik onCancel={onCancel} onSuccess={onSuccess} item={item} />
            )}

        </>
    );
};


export default ReportPages;