import { IReportColumn } from "./ReportColumm";

export interface ISiteReportResults {
    header: string;
    data: any[];
    columns: IReportColumn[];
}

export const defaultSiteReportResults: ISiteReportResults = {
    header: '', data: [], columns: []
};
