import React, { useEffect, useState } from 'react';
import { parseQueryString } from '../../../../core/utilities/URL';
import { ReportConfigurationGroupStyles, ReportConfigurationType, ReportConfiugurationGroupOrganizationLevel, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup } from '../../../../reporting/entities/ReportGroup';
import { IMicroStrategyFilter } from '../../../entities/MicroStrategyFilters';
import SectionGroupLink from './SectionGroupLink';
import SectionGroupTab from './SectionGroupTab';


interface IComponentInfo {
    section: IReportGroup;
    activeGroup: IReportGroup;
    setActiveGroup: (i: IReportGroup) => void;
    pageFilters: IMicroStrategyFilter[];
}

const SectionGroups: React.FunctionComponent<IComponentInfo> = ({ section, setActiveGroup, activeGroup, pageFilters }) => {
    const params: any = parseQueryString();
    const [type, setType] = useState<string>(ReportConfigurationGroupStyles.Links);
    const [localGroups, setLocalGroups] = useState<IReportGroup[]>([]);
    const [organizational, setOrganizational] = useState<boolean>(false);

    const [schoolIndex] = useState<number>(2);
    const [districtIndex] = useState<number>(1);

    const setGroupByID = (id: string): boolean => {
        for (const g of section.groups) {
            if (g.id === id) {
                setActiveGroup(g);
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        setType(getReportConfigurationValue(ReportConfigurationType.GroupStyle, section.configuration));
        if (getReportConfigurationValue(ReportConfigurationType.OrganizationLevelSection, section.configuration)) {
            setInitialGroup(true);
        }
        else {
            setInitialGroup(false);
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (pageFilters.length > 0 && organizational) {
            setOrganizationalGroups();
        }
        // eslint-disable-next-line
    }, [pageFilters]);

    const setOrganizationalGroups = () => {
        let level: ReportConfiugurationGroupOrganizationLevel = ReportConfiugurationGroupOrganizationLevel.State;
        const schoolSuffix: string = getReportConfigurationValue(ReportConfigurationType.OrganizationLevelSuffixSchool, section.configuration);
        const districtSuffix: string = getReportConfigurationValue(ReportConfigurationType.OrganizationLevelSuffixDistrict, section.configuration);

        if (pageFilters.length > schoolIndex && !pageFilters[schoolIndex].value.endsWith(schoolSuffix)) {
            level = ReportConfiugurationGroupOrganizationLevel.School;
        }

        else if (pageFilters.length > districtIndex && !pageFilters[districtIndex].value.endsWith(districtSuffix)) {
            level = ReportConfiugurationGroupOrganizationLevel.District;
        }

        const l: IReportGroup[] = [];
        for (const g of section.groups) {
            if (getReportConfigurationValue(ReportConfigurationType.OrganizationLevelGroup, g.configuration) === level) {
                l.push(g);
            }
        }
        setLocalGroups(l);
        if (l.length > 0) {
            setActiveGroup(l[0]);
        }
    }

    const setInitialGroup = (o: boolean) => {
        setOrganizational(o);
        if (o) {
            setOrganizationalGroups();
        }
        else {
            if (section.groups.length > 1) {
                setLocalGroups(section.groups);
            } else {
                setLocalGroups([]);
            }
            if (!setGroupByID(params.gid)) {
                setActiveGroup(section.groups.filter(q => q.isActive)[0]);
            }
        }
    }

    return (
        <>
            {(!organizational || pageFilters.length > 0) && (
                <>
                    {type === ReportConfigurationGroupStyles.Links && (
                        <>
                            <div className="group-links">
                                {localGroups.length > 1 && localGroups.filter(q => q.isActive).map((i, index3) =>
                                    <SectionGroupLink onClick={setActiveGroup} className={`${i.id === activeGroup.id ? 'active' : ''}`} key={index3} group={i} />
                                )}
                            </div>
                            <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                            <p className="description m-t-10 m-l-10">{`${section?.description ?? ''}`} </p>

                        </>
                    )}
                    {type === ReportConfigurationGroupStyles.Tabs && (
                        <>
                            <p className="subtitle m-t-10 m-l-10">{`${section.title}`} </p>
                            <p className="description m-t-10 m-l-10">{`${section?.description ?? ''}`} </p>
                            <nav className="group-tabs fst-italic small m-t-10 m-b-0 nav nav-tabs mb-3 nav nav-tabs" role="tablist">
                                {localGroups.length > 1 && localGroups.filter(q => q.isActive).map((g, index) =>
                                    <SectionGroupTab key={index} index={index} group={g} selected={activeGroup.id === g.id} onClick={setActiveGroup} />
                                )}
                            </nav>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default SectionGroups;
