import React, { CSSProperties } from 'react';
import { Icon } from '../../entities/Icon';

const errorDiv: CSSProperties = {
    color: 'darkred'
    , fontStyle: 'italic'
};

interface IComponentInfo {
    error?: string;
    touched?: string;
}

const OEFormError: React.FunctionComponent<IComponentInfo> = ({ error, touched }) => {

    return (
        <>
            {error && touched && (
                <div style={errorDiv} className="invalid-feedback"><i className={Icon.Error} /> {error}</div>
            )}
        </>
    );
};

export default OEFormError;
