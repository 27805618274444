import React, { useEffect, useState } from 'react';
import { Icon } from '../../entities/Icon';
import OEButton, { ButtonStyle } from '../form/OEButton';
import OEForm from '../form/OEForm';
import OEWell, { WellStyle } from '../general/OEWell';
import FilterItem from './FilterItem';
import { IFilter, IFilterValue } from './entities/Filter';
import { IFilterAction } from './entities/FilterAction';
import {
    getDefaultValues,
    getFilterList,
    getSavedFilterValues,
    saveFilterValues,
} from './entities/FilterSave';

interface IComponentInfo {
    items?: any[];
    filter: IFilter;
    setFilterList?: (i: any) => void;
    refresh?: () => void;
    onSubmit?: (i: IFilterValue[]) => void;
    submitOnReset?: boolean;
    className?: string;
    actionList?: IFilterAction[];
    singleLine?: boolean;
    buttonsClassName?: string;
    actionsNewLine?: boolean;
    isUser?: boolean;
    onFilter?: (i: string) => void;
}

const OEFilter: React.FunctionComponent<IComponentInfo> = ({
    items, filter, setFilterList, refresh, className, actionsNewLine,
    actionList, singleLine, onSubmit, buttonsClassName, submitOnReset, isUser, onFilter
}) => {
    const [values, setValues] = useState<IFilterValue[]>(
        getSavedFilterValues(filter)
    );

    useEffect(() => {
        if (isUser && onFilter) {
            onFilter(values[0].value);
        } else {
            if (items && items.length > 0) {
                setFilterList?.(getFilterList(filter, values, items));
            }
            saveFilterValues(filter.name, values);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (items) {
            if (items.length > 0) {
                setFilterList?.(getFilterList(filter, values, items));
            }
        }
        // eslint-disable-next-line
    }, [items]);

    const onChangeValue = (d: IFilterValue) => {
        const f: IFilterValue[] = values;
        for (const i of f) {
            if (i.name === d.name) {
                i.value = d.value;
                if (onSubmit && filter.filters.filter(q => q.name === i.name)[0].autoSearch) {
                    onSubmit(f);
                }
                break;
            }
        }
        setValues([...f]);
    };

    const onReset = () => {
        setValues(getDefaultValues(filter));
        submitOnReset && onSubmit?.(getDefaultValues(filter));
    };

    const onRefresh = () => {
        refresh && refresh();
        onSubmit?.(values);
    };

    return (
        <>
            <OEWell oeStyle={WellStyle.Bordered} className={`${className || ''}`} >
                <OEForm className={`${className || ''} row align-items-end small`}>
                    {values.length > 0 && filter.filters.map((item, index) =>
                        <FilterItem
                            key={index}
                            item={values.filter(q => q.name === item.name)[0]}
                            filter={item}
                            onChangeValue={onChangeValue}
                            singleLine={singleLine}
                        />
                    )}
                    {actionsNewLine && (
                        <div />     
                    )}
                    <div className={`${buttonsClassName ? buttonsClassName : 'col'}`}>
                        <div>
                            <OEButton bStyle={ButtonStyle.Cancel} className="m-t-20 btn-sm" onClick={onReset} icon={Icon.Reset} text="Reset Filters" />
                            {(refresh || onSubmit) &&
                                <OEButton className="m-t-20 btn-sm" onClick={onRefresh} icon={Icon.Refresh} text="Refresh" />
                            }
                            {actionList && actionList.map((action, index) =>
                                <OEButton
                                    key={index}
                                    hidden={action.hide}
                                    icon={action.icon}
                                    bStyle={action.bStyle}
                                    className={`m-t-20 btn-sm ${action.className ? action.className : ''}`}
                                    onClick={action.action}
                                    text={action.text}
                                />
                            )}
                        </div>
                    </div>
                </OEForm>
            </OEWell>
        </>
    );
};

export default OEFilter;
