import React, { useEffect, useState } from 'react';
import OEHeading from '../core/components/general/OEHeading';
import { Icon } from '../core/entities/Icon';
import { defaultAboutVersion, IAboutVersion } from './entities/Profile';
import { useGetAboutVersion } from './services/ProfileService';

const About: React.FunctionComponent = () => {
    
    const { service } = useGetAboutVersion();

    const [about, setAbout] = useState<IAboutVersion[]>([defaultAboutVersion]);

    useEffect(() => {
        if (service.result) {
            setAbout(service.result);
        }
    }, [service]);

    const generateRows = () => {
        var list: JSX.Element[] = [];
        about.map((item, index) => {
            list.push(<tr key={index}>
                <td>{item.name}</td>
                <td>{item.version}</td>
            </tr >);
            return item;
        });
        return list;
    }

    return <div className="container">
        <OEHeading className="m-b-20 m-t-10" text="About" icon={Icon.UserManagement} />
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Version</th>
                </tr>
            </thead>
            <tbody>
                {generateRows()}
            </tbody>
        </table>
    </div>;
};

export default About;