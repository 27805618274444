import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import {INotification }  from './entities/Notification'

export interface IOENotificationInfo {
    notification: INotification;
    setNotification: (v: INotification) => void;
}

const OENotification: React.FunctionComponent<IOENotificationInfo> = ({ notification, setNotification }) => {
    const { addToast } = useToasts()

    useEffect(() => {
        if (notification && notification.message && notification.message.length > 0) {
            addToast(notification.message, {
                appearance: notification.type,
                autoDismiss: true,
                autoDismissTimeout: notification.duration || 5000
            })
            setNotification({ ...notification, message: '' });
        }
        // eslint-disable-next-line
    }, [notification]);

    return (<></>);
};

export default OENotification;