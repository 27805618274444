import React, { useEffect, useState } from 'react';
import { parseQueryString } from '../../../../core/utilities/URL';
import { defaultReportPage, IReportPage } from '../../../../reporting/entities/ReportPage';
import { useGetReportPageDataDownload } from '../../../../reporting/services/ReportPageService';
import GroupList from './GroupList';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSDataDownload: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const { service: pageService, setPageId } = useGetReportPageDataDownload('');

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageParameterId] = useState(params['pid'] || '');
    const [openSection, setOpenSection] = useState<any>([]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        setPageId(pageParameterId);
        // eslint-disable-next-line
    }, [pageParameterId]);

    return (
        <div className='container'>
            <h2>{"Downloads"}</h2>
            <ul>
                {page.groups.map((sl) =>
                    <GroupList key={sl.id} group={sl} pageConfigruation={page.configuration} openSection={openSection} setOpenSection={(id) => {
                        if (openSection.includes(id)) {
                            let filteredSection = openSection.filter((section: string) => section !== id);
                            setOpenSection(filteredSection);
                        } else setOpenSection([...openSection, id])
                    }} />
                )}
            </ul>
            {/* {pageParameterId} */}
        </div>
    );
};

export default MSDataDownload;
