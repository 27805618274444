import React from 'react';

export interface IQPageTitle {
    text: string;
    size?: number;
    hidden?: boolean;
    icon?: string;
    className?: string;
}

const OEDisplay: React.FunctionComponent<IQPageTitle> = ({ text, size, hidden, icon, className }) => {
    return (
        <>
            {!hidden && (
                <p className={`display-${size || 4}${className || ''}`}>
                    {icon && (
                        <i className={icon} />
                    )}
                    {text}
                </p>
            )}
        </>
    );
};

export default OEDisplay;
