import React from 'react';
import OELink from '../general/OELink';
import { IBreadcrumb } from './entities/Breadcrumb';

export interface IOEBreadcrumbItemInfo {
    breadcrumb: IBreadcrumb;
    length: number;
    index: number;
    onNavigate: (b: IBreadcrumb, i: number) => void;
}

const OEBreadcrumbItem: React.FunctionComponent<IOEBreadcrumbItemInfo> = ({ breadcrumb, length, index, onNavigate }: IOEBreadcrumbItemInfo) => {

    const onCrumbClick = () => {
        onNavigate(breadcrumb, index);
    };

    return (
        <li className={`breadcrumb-item${index === length - 1 ? ' active' : ''}`}>
            {breadcrumb.icon && (
                <i className={breadcrumb.icon} />
            )}
            {index === length - 1 && (
                <span onClick={onCrumbClick} >{breadcrumb.name}
                </span>
            )}
            {index < length - 1 && (
                <OELink onClick={onCrumbClick} >{breadcrumb.name}
                </OELink>
            )}
        </li>
    );
};

export interface IOEBreadcrumbInfo {
    breadcrumbs: IBreadcrumb[];
    navigateBreadcrumb?: (crumb: IBreadcrumb) => void;
    setBreadcrumbs?: (crumnbs: IBreadcrumb[]) => void;
    noLinks?: boolean;
    hide?: boolean;
    className?: string;
}


export default OEBreadcrumbItem;
