import { useEffect, useState } from "react";
import { IBaseList } from "../../core/entities/BaseList";
import { DeleteRequest, processDeleteAsync } from "../../core/services/DeleteRequest";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { processPutAsync, PutRequest } from "../../core/services/PutRequest";
import { defaultArtifact, getMicroStrategyArtifactForSave, getSsrsArtifactForSave, getUrlArtifactForSave, IArtifact } from "../entities/Artifact";
import { IArtifactTag } from "../entities/ArtifactTag";
import { ArtifactType } from "../entities/ArtifactType";
import { GetArtifactsInput } from "../entities/GetArtifactsInput";

export const useGetArtifacts = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IArtifact>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);
    const [filters, setFilters] = useState<GetArtifactsInput>({});

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, getUrl('/api/app/artifacts', filters));
        setRefresh(false);
    }, [filters, filters.application, filters.artifactType, filters.description, filters.displayName, filters.filterText, filters.isPublished, filters.tags, refresh]);

    return { service, doRefresh, setFilters };
};

export const useDeleteArtifact = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() => {
        if (deleteId < 1) { return; }
        processDeleteAsync(setService, `/api/app/artifacts/${deleteId}`);
    }, [deleteId]);

    return { service, setDeleteId };
};

export const usePostMicroStrategyArtifact = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.MicroStrategy) { return; }
        processPostAsync(setService, `/api/app/artifacts/microstrategy`, getMicroStrategyArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePostSsrsArtifact = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Ssrs) { return; }
        processPostAsync(setService, `/api/app/artifacts/ssrs`, getSsrsArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePostUrlArtifact = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Url) { return; }
        processPostAsync(setService, `/api/app/artifacts/url`, getUrlArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePutMicroStrategyArtifact = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.MicroStrategy) { return; }
        processPutAsync(setService, `/api/app/artifacts/microstrategy/${artifact.id}`, getMicroStrategyArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePutSsrsArtifact = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Ssrs) { return; }
        processPutAsync(setService, `/api/app/artifacts/ssrs/${artifact.id}`, getSsrsArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePutUrlArtifact = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Url) { return; }
        processPutAsync(setService, `/api/app/artifacts/url/${artifact.id}`, getUrlArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const useGetArtifactDomainTags = () => {
    const [service, setService] = useState(GetRequest.empty<IArtifactTag>());
    const [refresh, setRefresh] = useState(true);
    const refreshDomainTags = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/artifacts/domainTags`);
        setRefresh(false);
    }, [refresh]);

    return { service, refreshDomainTags };
};

export const useGetArtifactSubjectTags = () => {
    const [service, setService] = useState(GetRequest.empty<IArtifactTag>());
    const [refresh, setRefresh] = useState(true);
    const refreshDomainTags = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/artifacts/subjectTags`);
        setRefresh(false);
    }, [refresh]);

    return { service, refreshDomainTags };
};

const getUrl = (url: string, parameters: Record<string, any>): string => { //TODO: move to core
    if (!parameters) { return url; }

    const params = Object.keys(parameters)
        .filter(key => parameters[key])
        .map(key => `${key}=${parameters[key]}`)
        .join('&');

    return `${url}?${params}&MaxResultCount=1000`;
}

export const useCompileArtifacts = () => {
    const [service, setService] = useState(GetRequest.empty<any>());
    const [compile, setCompile] = useState(false);

    useEffect(() => {
        if (!compile) { return; }
        processGetAsync(setService, `/api/app/artifacts/compile`);
        setCompile(false);
    }, [compile]);

    return { service, setCompile };
};
