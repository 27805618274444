import React from 'react';
import { Icon } from '../../entities/Icon';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';

interface IComponentInfo {
    className?: string;
    errors?: string[];
    icon?: string;
}

const OEErrorList: React.FunctionComponent<IComponentInfo> = ({ errors, className, icon }) => {
    return (
        <>
            {errors && errors.length > 0 && (
                <OERow>
                    <OECol sm={12} className="m-l-10">
                        <div className={`${className || ''} alert alert-danger`}>
                            <i className={icon || Icon.Error} aria-hidden="true" />
                            The following errors occurred: {errors.length }
                            <ul>
                                {errors.map((item, index) =>
                                    <li key={index}>{item}</li>
                                )}
                            </ul>
                        </div>
                    </OECol>
                </OERow>
            )}
        </>
    );
};

export default OEErrorList;