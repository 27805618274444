import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEForm from '../../../core/components/formik/OEForm';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEHeading from '../../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import { MicroStrategyDossierFilterType, MicroStrategyDossierFilterTypeDropdownValues } from '../../../report-microstrategy/entities/MicrostrategyDossierFilter';
import { getReportFilterList, IReportBaseFilter, IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';
import { useGetEmbeddedReportFilters } from '../../services/ReportFilterService';

const labelColumns: number = 3;

interface IFormInfo {
    lookupTables: IReportFilter[];
    isEditing: boolean;
}

const Form: React.FunctionComponent<FormikProps<IReportBaseFilter> & IFormInfo> = ({ values, errors, touched, setFieldValue, isEditing, lookupTables }) => {

    const setFilterLabel = (i: any) => {
        if (i !== '') {
            const s: IReportFilter = lookupTables.filter(q => q.name === i)[0];
            setTimeout(function () {
                setFieldValue('name', s.name);
                setFieldValue('label', s.label);
                setFieldValue('externalId', s.externalId);
            }, 100)
        }
    }

    return (
        <>
            <OEFormDropdown
                label="Filter" name="name" value={values.name}
                fields={{ id: 'name', name: 'label' }} disabled={isEditing}
                onChange={setFilterLabel} alwaysShowDefault={false} defaultSelect={defaultDropdownSelect}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables}
            />
            <OEFormInput
                label="Label" name="label" value={values.label} required={true}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
            />
            <OEFormInput
                label="Source Filter Names" name="externalId" value={values.externalId} required={true}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
            />

            <OEFormDropdown
                label="Filter Type" name="filterType" value={values.filterType} autoFocus={true}
                errors={errors} touched={touched} columns={labelColumns} setFieldValue={setFieldValue}
                values={MicroStrategyDossierFilterTypeDropdownValues}
            />

            {values.filterType === MicroStrategyDossierFilterType.Range && (
                <OEFormInput
                    label="Range Increments" name="rangeIncrements" value={values.rangeIncrements}
                    errors={errors} touched={touched} columns={labelColumns}
                    instructions='For range filters how many increments to go back to'
                    setFieldValue={setFieldValue} inputType={FormInputType.Number} type={OEInputType.Number}
                />
            )}

            {values.filterType === MicroStrategyDossierFilterType.SingleSelectWithAll && (
                <OEFormInput
                    label="All Select Label" name="selectAllLabel" value={values.selectAllLabel}
                    errors={errors} touched={touched} columns={labelColumns}
                    instructions='Label to put in dropdown for selecting all items.'
                    setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                />
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IReportBaseFilter>().shape({
    name: Yup.string().required('Filter is required').nullable(),
    label: Yup.string().required('Label is required').nullable(),
});

interface IFormikInfo {
    item: IReportBaseFilter;
    isEditing: boolean;
    onCancel: () => void;
    onSubmit: (i: IReportBaseFilter) => void;
}

const PageFilterFormik: React.FunctionComponent<IFormikInfo> = ({ item, isEditing, onCancel, onSubmit }) => {

    const { service: filterService } = useGetEmbeddedReportFilters(true);

    const [embeddedFilters, setEmbeddedFilters] = useState<IReportFilter[]>([]);
    const [localValues] = useState(item);

    useEffect(() => {
        if (filterService.result) {
            setEmbeddedFilters(getReportFilterList(filterService.result));
        }
    }, [filterService]);

    const getForm = (props: FormikProps<any>, p: boolean) => (
        <OEForm {...props}
            onCancel={onCancel}
            component={Form}
            submitText="Submit"
            cancelText="Cancel"
            lookupTables={embeddedFilters}
            labelColumns={2}
            isEditing={isEditing}
        />
    );

    return (
        <OEModal show={true} oeSize={ModalSize.Medium} onHide={onCancel}>
            <OEModalBody className="alert-light">
                <OEHeading size={3} text={`${isEditing ? 'Edit' : 'Add'} Report ${localValues.dossier ? 'Dossier' : ''} Filter`} />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={localValues}
                    enableReinitialize={true}
                    validationSchema={ValidationScheme}
                >
                    {(props) => getForm(props, isEditing)}
                </Formik>

            </OEModalBody>
        </OEModal >
    );
};

export default PageFilterFormik;
