import { FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormBoolean from '../../core/components/formik/OEFormBoolean';
import OEFormEditor from '../../core/components/formik/OEFormEditor';
import OEFormik from '../../core/components/formik/OEFormik';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { emptyGUID } from '../../core/utilities/String';
import { IWidget } from '../entities/Widget';
import { WidgetTypeCode } from '../entities/WidgetType';
import { useCreateWidget, usePutWidget } from '../services/WidgetService';

const labelColumns: number = 12;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IWidget> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    const [htmlEditor, setHtmlEditor] = useState<boolean>(false);
    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Code" name="code" value={values.code}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            {values.widgetType.code === WidgetTypeCode.Text && (
                <OEFormInput
                    label="Content" name="content" value={values.content}
                    errors={errors} touched={touched} columns={labelColumns}
                    required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
                />
            )}
            {values.widgetType.code === WidgetTypeCode.HTMLContent && (
                <>
                    <OEFormBoolean label="Use HTML Editor" columns={labelColumns} name="editor" value={htmlEditor} onChange={setHtmlEditor} />
                    {htmlEditor && (
                        <OEFormEditor
                            label="" name="content" value={values.content}
                            errors={errors} touched={touched}
                            columns={labelColumns} setFieldValue={setFieldValue}
                        />
                    )}
                    {!htmlEditor && (
                        <OEFormInput
                            label="Content" name="content" value={values.content}
                            errors={errors} touched={touched} columns={labelColumns}
                            rows={10} cols={100}
                            required={true} setFieldValue={setFieldValue} inputType={FormInputType.LargeText}
                        />
                    )}
                </>
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IWidget>().shape({
});

interface IFormikInfo {
    item: IWidget;
    onCancel: () => void;
    onSave: () => void;
}

const WidgetFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {

    const { service, setItem: setSaveItem } = usePutWidget();
    const { service: createService, setItem: setCreateItem } = useCreateWidget();

    return (
        <OEFormik
            item={item}
            labelColumns={labelColumns}
            progressMessage={`${ProgressMessages.DefaultProgress} ${item.name}`}
            successMessage={` ${item.name} ${SuccessMessages.DefaultSuccess}`}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={!emptyGUID(item.id) ? setSaveItem : setCreateItem}
            service={!emptyGUID(item.id) ? service : createService}
        />
    );
};

export default WidgetFormik;
