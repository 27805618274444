import datasetSourceTypes from '../lookups/dataset-source-types.json';
import { GetDatasetConnectionsInput } from '../entities/GetDatasetConnectionsInput';
import {
    FilterType,
    IFilter,
    IFilterValue,
} from '../../core/components/filter/entities/Filter';
import { useEffect } from 'react';
import {
    getFilterValue,
    getSavedFilterValues,
} from '../../core/components/filter/entities/FilterSave';

import { Icon } from '../../core/entities/Icon';
import OEFilter from '../../core/components/filter/OEFilter';

type Props = {
    onFilter: (i: GetDatasetConnectionsInput) => void;
    onAdd: () => void;
};

export const DatasetConnectionFilters: React.FunctionComponent<Props> = ({
    onFilter,
    onAdd,
}) => {
    const filter: IFilter = {
        name: 'artifactFilter',
        autoSearch: true,
        filters: [
            {
                name: 'displayName',
                label: 'Display Name',
                width: 220,
                placeholder: 'Search by display Name',
            },
            {
                name: 'datasetSourceType',
                label: 'Dataset Source Type',
                autoSearch: true,
                width: 220,
                type: FilterType.Dropdown,
                values: datasetSourceTypes,
            },
            {
                name: 'databaseSchema',
                label: 'Database Schema',
                width: 240,
                placeholder: 'Search by Database Schema',
            },
        ],
    };

    useEffect(() => {
        handleFilter(getSavedFilterValues(filter));
        // eslint-disable-next-line
    }, []);

    const handleFilter = (i: IFilterValue[]) => {
        onFilter({
            displayName: getFilterValue(filter, i, 'displayName'),
            datasetSourceType: getFilterValue(filter, i, 'datasetSourceType'),
            databaseSchema: getFilterValue(filter, i, 'databaseSchema'),
        });
    };

    return (
        <>
            <OEFilter
                singleLine={true}
                onSubmit={handleFilter}
                submitOnReset={true}
                buttonsClassName="h6 col-xl-4 col-lg-12"
                className="m-t-0"
                filter={filter}
                actionList={[{ action: onAdd, text: 'Add New', icon: Icon.Add }]}
            />
        </>
    );
};
