import React from 'react';
import OEHeading from '../core/components/general/OEHeading';
import OETabs, { OETab } from '../core/components/tabs/OETabs';
import { Icon } from '../core/entities/Icon';
import Form from './Form';
import General from './General';
import Messaging from './Messaging';
import Styles from './Styles';
import Table from './Table';

const StyleGuide: React.FunctionComponent = () => {
    return (
        <>
            <OEHeading icon={Icon.Example} text="Style Guide" size={3} />
            <OETabs>
                <OETab key="styles" title="Reference" eventKey="styles">
                    <Styles />
                </OETab>
                <OETab key="messaging" title="Messaging" eventKey="messaging">
                    <Messaging />
                </OETab>
                <OETab key="table" title="Table" eventKey="table">
                    <Table />
                </OETab>
                <OETab key="general" title="General" eventKey="general">
                    <General />
                </OETab>
                <OETab key="form" title="Form" eventKey="form">
                    <Form />
                </OETab>
            </OETabs>

        </>
    );
};

export default StyleGuide;