import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';


export interface IArtifactDatasetViewColumns extends IBaseAuditedEntity {
    id: string;
    displayName: string;
}

export const defaultIArtifactDatasetViewColumns: IArtifactDatasetViewColumns = {
    id: '', displayName: ''
};

export interface IArtifactDatasetViewColumnSearch {
    datasetConnectionId: string;
    view: string;
}

export const defaultArtifactDatasetViewColumnSearch: IArtifactDatasetViewColumnSearch = {
    datasetConnectionId: '', view: ''
};
