import { defaultDropdownValues, IDropdownValues } from '../../core/components/form/OEDropdown';
import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';

export interface IReportConfigurations {
    pageConfiguration: string;
    sectionConfiguration: string;
    sectionTitleConfiguration: string;
    groupConfiguration: string;
    reportConfiguration: string;
}

export const defaultReportConfigurations: IReportConfigurations = {
    pageConfiguration: '', sectionConfiguration: '', groupConfiguration: '',
    reportConfiguration: '', sectionTitleConfiguration: ''
};

export enum ReportConfigurationType {
    None = '',
    CollapsibleSections = 'CS',
    CubeID = 'CI',
    ChapterList = 'CL',
    MinReportHeight = 'DH',
    MaxReportHeight = 'DM',
    MinReportWidth = 'DW',
    MaxReportWidth = 'D2',
    DefaultSection = 'DS',
    DataDownload = 'DDL',
    DataDownloadOff = 'DDO',
    DataDownloadPrefix = 'DDP',
    DataDownloadDirectory = 'DDD',
    ExportReportFilename = 'EF',
    ExportReport = 'ER',
    ExportPDFilename = 'EPF',
    ExportPDF = 'EPR',
    FilterAlignment = 'FA',
    GroupStyle = 'GS',
    HideApply = 'HA',
    HideFilterSummary = 'HFS',
    ReportHeightPercentage = 'HP',
    Icon = 'IC',
    IconOff = 'ICO',
    MultipleReports = 'MR',
    NoDataMessage = 'ND',
    PaginationStyle = 'PS',
    PublicPage = 'PP',
    PageType = 'PT',
    ReportFlagging = 'RFL',
    ReportFoooter = 'RF',
    ReportType = 'RT',
    OrganizationLevelSection = 'SOL',
    OrganizationLevelGroup = 'GOL',
    OrganizationLevelSuffixSchool = 'SSCHOL',
    OrganizationLevelSuffixDistrict = 'SDISOL',
    ShowFilters = 'SF',
    ShowNavgiationBar = 'SN',
    ShowTitle = 'ST',
    Suppression = 'SUPPR'
}

export enum ReportConfigurationDataType {
    Boolean = 'BO',
    Dropdown = 'DD',
    Integer = 'IN',
    None = 'NO',
    String = 'ST',
    Html = 'HT',
    Icon = 'IC'
}


export enum ReportConfigurationGroupStyles {
    Links = 'LK',
    Tabs = 'TB',
}

export enum ReportConfigurationPaginationStyles {
    Links = 'LK',
    Tabs = 'TB',
}
export enum ReportConfigurationFilterAlignments {
    Left = 'LF',
    Right = 'RT',
}

export enum ReportConfiugurationGroupOrganizationLevel {
    State = 'ST',
    District = 'DI',
    School = 'SC',
    None = 'N'
}
export enum ReportConfigurationSuppression {
    AdminOn = 'AON',
    AdminOff = 'AOFF',
    UserOn = 'UON',
    UserOff = 'UOFF'
}

export enum ReportDataDownloadType {
    DownloadOff = 'DO',
    DownloadPageFilter = 'DPF',
    DownloadFile = 'DF',
}

interface IReportConfigurationType {
    id: string;
    name: string;
    values?: IDropdownValues[];
    dataType: string;
    defaultValue: any;
    disableDelete?: boolean;
}

export interface IReportConfigurationItemForSave extends IBaseAuditedEntity {
    id: string;
    value: any;
}

export interface IReportConfigurationItem extends IReportConfigurationType, IBaseAuditedEntity {
    displayValue: string;
    value: any;
    isSet: boolean;
}

const configurationTypes: IReportConfigurationType[] =
    [
        { id: ReportConfigurationType.DataDownloadDirectory, name: 'Data Download Directory', dataType: ReportConfigurationDataType.String, defaultValue: '' },
        { id: ReportConfigurationType.DataDownloadPrefix, name: 'Data Download Prefix', dataType: ReportConfigurationDataType.String, defaultValue: '' },
        { id: ReportConfigurationType.DataDownloadOff, name: 'Data Download Off', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        {
            id: ReportConfigurationType.DataDownload, name: 'Data Download Type', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportDataDownloadType.DownloadOff,
            values: [
                { ...defaultDropdownValues, id: ReportDataDownloadType.DownloadOff, name: 'Download Off' },
                { ...defaultDropdownValues, id: ReportDataDownloadType.DownloadPageFilter, name: 'Download with Page Filters' },
                { ...defaultDropdownValues, id: ReportDataDownloadType.DownloadFile, name: 'Download File' },
            ]
        },
        { id: ReportConfigurationType.MinReportHeight, name: 'Min Report Height', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.MaxReportHeight, name: 'Max Report Height', dataType: ReportConfigurationDataType.Integer, defaultValue: 20000 },
        { id: ReportConfigurationType.MinReportWidth, name: 'Min Report Width', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.MaxReportWidth, name: 'Max Report Width', dataType: ReportConfigurationDataType.Integer, defaultValue: 20000 },
        { id: ReportConfigurationType.ReportHeightPercentage, name: 'Height Percentage', dataType: ReportConfigurationDataType.Integer, defaultValue: 100 },
        {
            id: ReportConfigurationType.PageType, name: 'Reports Type', dataType: ReportConfigurationDataType.Dropdown, defaultValue: 'RE', disableDelete: true,
            values: [
                { ...defaultDropdownValues, id: 'RE', name: 'Embedded Reports' },
                { ...defaultDropdownValues, id: 'RF', name: 'iFrame Reports' },
                { ...defaultDropdownValues, id: 'RS', name: 'Site Reports' },
            ]
        },
        {
            id: ReportConfigurationType.ReportType, name: 'Report Type', dataType: ReportConfigurationDataType.Dropdown, defaultValue: 'RE',
            values: [
                { ...defaultDropdownValues, id: 'RE', name: 'Dossier' },
                { ...defaultDropdownValues, id: 'RP', name: 'Document' },
            ]
        },
        {
            id: ReportConfigurationType.PaginationStyle, name: 'Pagination Style', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationPaginationStyles.Tabs,
            values: [
                { ...defaultDropdownValues, id: ReportConfigurationPaginationStyles.Links, name: 'Links' },
                { ...defaultDropdownValues, id: ReportConfigurationPaginationStyles.Tabs, name: 'Tabs' },
            ]
        },
        {
            id: ReportConfigurationType.GroupStyle, name: 'Group Style', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationGroupStyles.Links,
            values: [
                { ...defaultDropdownValues, id: ReportConfigurationGroupStyles.Links, name: 'Links' },
                { ...defaultDropdownValues, id: ReportConfigurationGroupStyles.Tabs, name: 'Tabs' },
            ]
        },
        {
            id: ReportConfigurationType.FilterAlignment, name: 'Filter Alignmment', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationFilterAlignments.Left,
            values: [
                { ...defaultDropdownValues, id: ReportConfigurationFilterAlignments.Left, name: 'Left' },
                { ...defaultDropdownValues, id: ReportConfigurationFilterAlignments.Right, name: 'Right' },
            ]
        },
        {
            id: ReportConfigurationType.Suppression, name: 'Suppression', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationSuppression.UserOff,
            values: [
                { ...defaultDropdownValues, id: ReportConfigurationSuppression.AdminOn, name: 'Admin On' },
                { ...defaultDropdownValues, id: ReportConfigurationSuppression.AdminOff, name: 'Admin Off' },
                { ...defaultDropdownValues, id: ReportConfigurationSuppression.UserOn, name: 'User On' },
                { ...defaultDropdownValues, id: ReportConfigurationSuppression.UserOff, name: 'User Off' },
            ]
        },
        { id: ReportConfigurationType.ReportFlagging, name: 'Report Flags', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.DefaultSection, name: 'Default Section', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.CollapsibleSections, name: 'Collapsible Sections', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.PublicPage, name: 'Public', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.NoDataMessage, name: 'No Data Message', dataType: ReportConfigurationDataType.Html, defaultValue: 'No Data was found for selected filters.' },
        { id: ReportConfigurationType.HideApply, name: 'Hide Apply Button', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.HideFilterSummary, name: 'Hide Dossier Filters Summary', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.MultipleReports, name: 'Allow Multiple Reports', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ShowFilters, name: 'Show Dossier Filters', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ShowNavgiationBar, name: 'Show Dossier Navigation Bar', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.Icon, name: 'Icon', dataType: ReportConfigurationDataType.Icon, defaultValue: '' },
        { id: ReportConfigurationType.IconOff, name: 'Icon Inactive', dataType: ReportConfigurationDataType.Icon, defaultValue: '' },
        { id: ReportConfigurationType.ShowTitle, name: 'Show Report Title', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.ReportFoooter, name: 'Report Footer', dataType: ReportConfigurationDataType.Html, defaultValue: '' },
        { id: ReportConfigurationType.ExportReport, name: 'Export Enabled', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ExportReportFilename, name: 'Export Filename', dataType: ReportConfigurationDataType.String, defaultValue: 'excelExport.xlsx' },
        { id: ReportConfigurationType.ExportPDF, name: 'PDF Enabled', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ExportPDFilename, name: 'PDF Filename', dataType: ReportConfigurationDataType.String, defaultValue: 'Report.pdf' },
        { id: ReportConfigurationType.CubeID, name: 'Cube Id', dataType: ReportConfigurationDataType.String, defaultValue: '' },
        { id: ReportConfigurationType.ChapterList, name: 'Chapter List', dataType: ReportConfigurationDataType.String, defaultValue: '' },
        { id: ReportConfigurationType.OrganizationLevelSection, name: 'Section By Organization Level', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.OrganizationLevelSuffixDistrict, name: 'Group Organization Level Suffix (District)', dataType: ReportConfigurationDataType.String, defaultValue: '999' },
        { id: ReportConfigurationType.OrganizationLevelSuffixSchool, name: 'Group Organization Level Suffix (School)', dataType: ReportConfigurationDataType.String, defaultValue: '000' },
        {
            id: ReportConfigurationType.OrganizationLevelGroup, name: 'Group Organization Level', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationFilterAlignments.Left,
            values: [
                { ...defaultDropdownValues, id: ReportConfiugurationGroupOrganizationLevel.None, name: 'None' },
                { ...defaultDropdownValues, id: ReportConfiugurationGroupOrganizationLevel.State, name: 'State' },
                { ...defaultDropdownValues, id: ReportConfiugurationGroupOrganizationLevel.District, name: 'District' },
                { ...defaultDropdownValues, id: ReportConfiugurationGroupOrganizationLevel.School, name: 'School' },
            ]
        },
    ];

export const getConfigurationType = (id: string): IReportConfigurationType => {
    return configurationTypes.filter(q => q.id === id)[0];
}

export const defaultReportConfigurationItem: IReportConfigurationItem = {
    id: ReportConfigurationType.None, name: '', dataType: ReportConfigurationDataType.None,
    values: [], value: '', displayValue: '', isSet: false, defaultValue: ''
};

export const getReportConfigurationValues = (id: string): IDropdownValues[] => {
    if (configurationTypes.filter(q => q.id === id).length > 0)
        return configurationTypes.filter(q => q.id === id)[0].values || [];
    return [];
};

export const getReportConfigurationDisplayValue = (i: IReportConfigurationItem): string => {
    switch (i.dataType) {
        case ReportConfigurationDataType.Dropdown:
            const v: IDropdownValues[] = getReportConfigurationValues(i.id);
            if (v.length > 0 && v.filter(q => q.id.toString() === i.value.toString()).length > 0) {
                return v.filter(q => q.id.toString() === i.value.toString())[0].name;
            }
            return '';

        case ReportConfigurationDataType.Boolean:
            return i.value ? 'Yes' : 'No'
                ;
        default:
            return i.value;
    }
};

export const getReportConfigurationDefaultValue = (id: string): any => {
    try {
        return configurationTypes.filter(q => q.id === id)[0].defaultValue;
    }
    catch {
        return '';
    }
};

export const getReportConfigurationValue = (id: string, ...config: any): any => {
    for (const c of config) {
        const v: IReportConfigurationItemForSave[] = getJSONFromReportConfiguration(c);
        if (v && v.length > 0 && v.filter(q => q.id === id).length > 0) {
            const i: IReportConfigurationItemForSave = v.filter(q => q.id === id)[0];

            return i.value;
        }
    }
    return getReportConfigurationDefaultValue(id);
};

export const getJSONForReportConfigurationSave = (i: IReportConfigurationItem[]): string => {
    const j: IReportConfigurationItemForSave[] = [];
    for (const p of i) {
        j.push({ id: p.id, value: p.value });
    }
    return JSON.stringify(j);
}

export const getJSONFromReportConfiguration = (p: string): IReportConfigurationItemForSave[] => {
    try {
        return p === null ? [] : JSON.parse(p);
    }
    catch {
        return [];
    }
}

export const getReportPageConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.DataDownload) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.DataDownloadPrefix) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.DataDownloadDirectory) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.Suppression) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ChapterList) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportFlagging) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.GroupStyle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PaginationStyle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.NoDataMessage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PageType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideApply) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.CollapsibleSections) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportSectionConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.Suppression) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.Icon) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.IconOff) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportSectionItemConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.DefaultSection) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.GroupStyle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PaginationStyle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.Icon) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.IconOff) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.OrganizationLevelSection) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.OrganizationLevelSuffixDistrict) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.OrganizationLevelSuffixSchool) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportGroupConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MultipleReports) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.OrganizationLevelGroup) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportListConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowNavgiationBar) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowFilters) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportHeightPercentage) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.DataDownloadOff) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.FilterAlignment) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ChapterList) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideApply) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowNavgiationBar) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowFilters) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.NoDataMessage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportFoooter) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportReport) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportReportFilename) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportPDF) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportPDFilename) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.CubeID) },
    ];
    return [...sortConfigruationArray(d)];
}

const sortConfigruationArray = (d: IReportConfigurationItem[]): IReportConfigurationItem[] => {
    return d.sort((n1, n2) => {
        if (n1.name > n2.name) {
            return 1;
        }
        if (n1.name < n2.name) {
            return -1;
        }
        return 0;
    });
};