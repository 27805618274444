import axios from 'axios';
import { defaultRequestResponse, IRequestResponse } from '../entities/RequestResponse';
import { getAuthorizationToken } from './Authentication';
import { getAPIError } from '../utilities/APIError';

const config = {
    headers: {
        'RequestVerificationToken': `${getAuthorizationToken()}`
    }
};

export interface IPutResult {
    success: boolean;
    data: any;
    response: IRequestResponse;
}

export class PutRequest {
    public static empty(): PutRequest {
        return new PutRequest(
            false,
            false,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static inProgress(): PutRequest {
        return new PutRequest(
            true,
            false,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static success(data: any): PutRequest {
        return new PutRequest(
            false,
            true,
            true,
            data,
            defaultRequestResponse
        );
    }

    public static error(error: string): PutRequest {
        return new PutRequest(
            false,
            false,
            true,
            undefined,
            { ...defaultRequestResponse, message: error }
        );
    }

    constructor(
        public isInProgress: boolean,
        public isSuccess: boolean,
        public isFinished: boolean,
        public data: any,
        public response: IRequestResponse
    ) { }
}

export const processPutAsync = (setService: (p: PutRequest) => void, url: string, postData?: any) => {
    // tslint:disable-next-line:no-floating-promises
    putAsync(setService, url, postData);
};

const putAsync = async (setService: (p: PutRequest) => void, url: string, postData?: any) => {
    try {
        setService(PutRequest.inProgress());

        const result = await axios.put<IPutResult>(`${url}`, postData, config);
        setService(PutRequest.success(result));
    } catch (e: any) {
        setService(PutRequest.error(getAPIError(e)));
    }
};
