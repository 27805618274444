import React, { useEffect, useState } from 'react';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { ProcessingToken } from '../../../reporting/entities/ReportProcessing';
import { validateToken } from '../../services/MicrostrategyDossierService';
declare global { var msLogin: any; }
declare global { var publicLogin: any; }
interface IComponentInfo {
    validToken: boolean;
    setValidToken: (b: boolean) => void;
    isPublic?: boolean;
}


export const TokenValidation: React.FunctionComponent<IComponentInfo> = ({ validToken, setValidToken, isPublic }) => {
    const defaultWaitMessage: string = 'One moment please...'

    const [waitMessage, setWaitMessage] = useState<string>(defaultWaitMessage);
    const [processingStep, setProcessingStep] = useState<ProcessingToken>(ProcessingToken.Idle);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingToken.TokenCheck:
                setProcessingStep(ProcessingToken.TokenValidating);
                break;

            case ProcessingToken.TokenValidating:
                validateToken(isTokenValid);
                break;

            case ProcessingToken.TokenValid:
                setValidToken(true);
                setProcessingStep(ProcessingToken.Idle);
                break;

        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (!validToken) {
            setProcessingStep(ProcessingToken.TokenCheck);
        }
        // eslint-disable-next-line
    }, [validToken]);

    const isTokenValid = (e: boolean) => {
        if (e) {
            setWaitMessage(defaultWaitMessage);
            setProcessingStep(ProcessingToken.TokenValid);;
        }
        else {
            setWaitMessage(`${waitMessage}.`);
            if (isPublic) {
                publicLogin();
            }
            else {
                msLogin(`${localStorage.getItem('msPath')}/auth/login-dialog.jsp?loginMode=4194304`);
            }

            window.setTimeout(() => {
                setProcessingStep(ProcessingToken.TokenCheck);;
            }, 5000); // this setTimeout is a workaround for invalid sessions
        }
    }

    return (
        <>
            <OESpinner
                message={waitMessage}
                hidden={processingStep !== ProcessingToken.TokenCheck && processingStep !== ProcessingToken.TokenValidating}
            />
        </>
    );
};


export default TokenValidation;
