import React, { useEffect, useState } from 'react';
import PermissionRestrictions from '../../security/components/PermissionRestrictions';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IRoute } from '../entities/Route';
import { getSecurityRouteList } from '../entities/SecurityRoute';
import { useGetSecurityRoute, usePutRolePermissions } from '../services/SecurityRouteService';

interface IComponentInfo {
    item: IRoute;
    onCancel: () => void;
}

const RoutePermissions: React.FunctionComponent<IComponentInfo> = ({ item, onCancel }) => {
    const { service, setRouteId } = useGetSecurityRoute();
    const { service: saveService, setSecurity } = usePutRolePermissions();

    const [currentSecurity, setCurrentSecurity] = useState<ISecurityRole[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service.result) {
            setCurrentSecurity(getSecurityRouteList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (saveService.isFinished) {
            if (saveService.isSuccess) {
                onCancel();
            } else {
                setErrors([saveService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [saveService]);

    const refreshSecruity = () => {
        setRouteId(item.id);
    };

    const updateSecruity = (i: ISecurityRole[]) => {
        setSecurity({ id: item.id, permissions: i });
    };

    return (
        <>
            <PermissionRestrictions
                currentSecurity={currentSecurity}
                setSecurity={updateSecruity}
                refreshSecurity={refreshSecruity}
                isInProgress={saveService.isInProgress}
                onCancel={onCancel}
                title={`Edit Restrictions for Route: ${item.name}`}
                info="Select any roles from the Available column to restrict access to this route, if no restrictions are selected all users will have access. Changes are not complete until you hit Submit. "
                errors={errors}
            />
        </>
    );
};

export default RoutePermissions;

