import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultOrganizationUnit, getOrganizationUnitForAdd, getOrganizationUnitForSave, IOrganizationUnit } from '../entities/OrganizationUnit';

export const useGetOrganizationUnits = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IOrganizationUnit>>());
    const [refresh, setRefresh] = useState(false);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/identity/organization-units/all`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutOrganizationUnit = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [organizationUnit, setOrganizationUnit] = useState<IOrganizationUnit>(defaultOrganizationUnit);

    useEffect(() => {
        if (organizationUnit.displayName === '') { return; }
        processPutAsync(setService, `/api/identity/organization-units/${organizationUnit.id}`, getOrganizationUnitForSave(organizationUnit));
        setOrganizationUnit(defaultOrganizationUnit);
    }, [organizationUnit]);

    return { service, setOrganizationUnit };
};

export const useCreateOrganizationUnit = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [organizationUnit, setOrganizationUnit] = useState<IOrganizationUnit>(defaultOrganizationUnit);

    useEffect(() => {
        if (organizationUnit.displayName === '') { return; }
        processPostAsync(setService, `/api/identity/organization-units`, getOrganizationUnitForAdd(organizationUnit));
        setOrganizationUnit(defaultOrganizationUnit);
    }, [organizationUnit]);

    return { service, setOrganizationUnit };
};

export const useDeleteOrganizationUnit = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [organizationUnitId, setOrganizationUnitId] = useState<string>('');

    useEffect(() => {
        if (organizationUnitId === '') { return; }
        processDeleteAsync(setService, `/api/identity/organization-units?id=${organizationUnitId}`);
        setOrganizationUnitId('');
    }, [organizationUnitId]);

    return { service, setOrganizationUnitId };
};

