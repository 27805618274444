import { useEffect, useState } from 'react';
import OEFormItem from '../../core/components/formik/OEFormItem';
import OEWell, { WellStyle } from '../../core/components/general/OEWell';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { IArtifactDatasetViewColumns } from '../entities/ArtifactDatasetViewColumns';
import { defaultDatasetFilter, IDatasetFilter } from '../entities/DatasetFilter';
import { DatasetFilterSelectorSelect } from '../entities/DatasetFilterSelector';
import FilterFormik from './FilterFormik';

enum ViewTypes {
    Default = 1,
    Edit
}


interface IComponentInfo {
    filters: IDatasetFilter[];
    labelColumns: number;
    columnData: IArtifactDatasetViewColumns[];
    setFilters: (i: IDatasetFilter[]) => void;
}


const Filters: React.FunctionComponent<IComponentInfo> = ({
    filters,
    labelColumns,
    setFilters,
    columnData

}) => {

    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);
    const [item, setItem] = useState<IDatasetFilter>(defaultDatasetFilter);
    const [localValues, setLocalValues] = useState<IDatasetFilter[]>([]);


    useEffect(() => {
        setLocalValues(filters);
        // eslint-disable-next-line
    }, [filters]);

    const onDelete = (i: IDatasetFilter) => {
        setLocalValues([...localValues.filter(q => q.categoryName !== i.categoryName)]);
    }

    const onAdd = () => {
        setShowView(ViewTypes.Edit);
        setItem(defaultDatasetFilter);
    }

    const onCancel = () => {
        setShowView(ViewTypes.Default);
    }

    const onSave = (i: IDatasetFilter) => {
        setShowView(ViewTypes.Default);
        if (localValues.filter(q => q.filterName === i.filterName).length > 0) {
            localValues.filter(q => q.filterName === i.filterName)[0].filterSelector = i.filterSelector;;
        }
        else {
            localValues.push(i);
        }
        setFilters([...localValues]);
    }

    const onEdit = (i: IDatasetFilter) => {
        setShowView(ViewTypes.Edit);
        setItem(i);
    }


    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onDelete, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'filterName', name: 'Name', sort: true, type: ColumnType.String },
        { id: 'filterSelector', name: 'Columns', sort: true, type: ColumnType.StringSelector, values: DatasetFilterSelectorSelect },
    ];


    return (
        <>
            <OEFormItem name="filters" columns={labelColumns} label="Filters">
                <OEWell oeStyle={WellStyle.BorderedClear} >
                    <OETable
                        columns={columns}
                        data={filters}
                        bordered={true}
                        className="table-bordered"
                        noDataMessage="No Filters have been added"
                        actions={[
                            { icon: Icon.Add, text: 'Add Filter', action: onAdd },
                        ]}
                    />
                </OEWell>
            </OEFormItem>

            {showView === ViewTypes.Edit && (
                <FilterFormik item={item} onCancel={onCancel} onSave={onSave} existing={filters} columnData={columnData} />
            )}
        </>
    );
};

export default Filters;