export interface IDcFileRequestUploadResultDto {
    fileName: string;
    fileRows: number;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
    uploadedOn: Date
    uploadedBy: string
}

export const defaultDcFileRequestUploadResultDto: IDcFileRequestUploadResultDto = {
    fileName: '',
    fileRows: 0,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    uploadedOn: new Date(),
    uploadedBy: ''
}