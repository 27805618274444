export interface ISiteSettings {
    logoutRedirect: string;
    selfUrl: string;
    sessionTimeout: string;
    browserCloseDelay: string;
    debugUsers: string;
    serverTimeZone: string;
    msPath: string;
    msAuthenticationURL: string;
    msPublicPath: string;
    msPublicMenuId: string;
    reportShowDelay: string;
    chatbotEnabled: boolean;
    showSessionTimeout: boolean;
    canDebug: boolean;
    maintenance: boolean;
    homePageLogoLink: string;
}

export const defaultSiteSettings: ISiteSettings = {
    logoutRedirect: '', selfUrl: '', sessionTimeout: '0', browserCloseDelay: '10', debugUsers: '',
    serverTimeZone: '', msPath: '', msPublicPath: '', msPublicMenuId: '0', reportShowDelay: '0',
    chatbotEnabled: false, showSessionTimeout: false, canDebug: false, maintenance: false, msAuthenticationURL: '',
    homePageLogoLink: ''
};
