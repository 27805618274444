import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export interface IOrganizationType extends IBaseAuditedEntity {
    id: number;
    code: string;
    name: string;
    parentId?: number;
    childCount: number;
}

export const defaultOrganizationType: IOrganizationType = {
    id: -1, code: '', name: '', parentId: 0, childCount: 0
};

export const getOrganizationTypeList = (i: IBaseList<IOrganizationType>): IOrganizationType[] => {
    const d: IOrganizationType[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultOrganizationType,
            ...v,
            parentId: v.parentId || 0
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getOrganizationTypeForSave = (i: IOrganizationType): IOrganizationType => {
    i.extraProperties = [];
    if (i.parentId === 0) {
        i.parentId = undefined;
    }
    i.extraProperties.push({ id: 'TypeId', value: i.typeId });
    return i;
};

export const getOrganizationType = (i: IOrganizationType[], id: number): IOrganizationType => {
    let t: IOrganizationType = { ...defaultOrganizationType, id: 0 };
    if (i.length > 0 && i.filter(q => q.id === id).length > 0) {
        return i.filter(q => q.id === id)[0];
    }
    return t;
};

export const getChildren = (i: IOrganizationType[], id: number): IOrganizationType[] => {
    let t: IOrganizationType[] = [];
    if (i.length > 0 && i.filter(q => q.parentId === id).length > 0) {
        return i.filter(q => q.parentId === id);
    }
    return t;
};


