import React, { useEffect, useState } from 'react';
import { changeDateFormat, formatDateTime } from '../../utilities/Date';
import { OEFormControl } from './OEForm';

interface IComponentInfo {
    value: Date;
    name: string;
    onChange?: (content: Date) => void;
    setFieldValue?: (id: string, data: any) => void;
    disabled?: boolean;
    formatString?: string;
    width?: string;
    placeholder?: string;
}


const OEDate: React.FunctionComponent<IComponentInfo> = ({
    value, name, setFieldValue, onChange
}) => {
    const [localvalue, setLocalValue] = useState<string>("");

    useEffect(() => {
        setLocalValue(formatDateTime(value.toLocaleString(), "yyyy-mm-dd"));
    }, [value]);

    const handleChange = (event: any) => {
        setLocalValue(event.target.value);
        const s: string = changeDateFormat(event.target.value);
        const d: Date = new Date(`${s} 12:00`);
        name && setFieldValue?.(name, d);
        onChange?.(d);
    };

    return (
        <div>
            <OEFormControl
                value={localvalue}
                type="date"
                id={name}
                onChange={handleChange}
                placeholder="MM/DD/YYYY"
            />
        </div>
    );
};


export default OEDate;

