import React from 'react';
import OEIcon from '../../core/components/general/OEIcon';
import { Icon } from '../../core/entities/Icon';
import { IRole } from '../entities/Role';

interface IComponentInfo {
    role: IRole;
    onSelect: (i: IRole) => void;
}

const RolePermission: React.FunctionComponent<IComponentInfo> = ({ role, onSelect }) => {

    const onClick = () => {
        onSelect(role);
    }

    return (
        <>
            <div onClick={onClick} className={`m-b-5 ${role.selected ? 'permissionsDivSelectedStyle' : 'permissionsDivStyle'}`}>
                {role.selected &&
                    <OEIcon icon={Icon.ArrowRight} className="m-t-5 pull-right" />
                }
                {role.name}
                {!role.selected &&
                    <OEIcon icon={Icon.ArrowLeft} className="m-t-5 pull-left" />
                }
            </div>
        </>
    );
};


export default RolePermission;
