import React, { useEffect, useState } from 'react';
import OEHeading from '../core/components/general/OEHeading';

const Oops: React.FunctionComponent = () => {

    const [message, setMessage] = useState<any>()

    useEffect(() => {
        if (localStorage.getItem('error') != null) {
            setMessage(localStorage.getItem('error'));
        }
        localStorage.removeItem('error');
        // eslint-disable-next-line
    }, []);


    return (
        <div className="container m-t-40">
            <OEHeading size={1} text="Oops!!" />
            {message && (
                <>
                    <p>
                        The following error occured for the page you requested:
                    </p>
                    <div>
                        {message}
                    </div>
                </>
            )}
            {!message && (
                <>
                    <p>
                      An error occured for the page you requested.
                    </p>
                </>
            )}
        </div>
    );
};

export default Oops;