import { IBaseList } from '../../core/entities/BaseList';
import { ISecurityRole } from '../../security/entities/SecurityRole';

export interface IRoute extends Record<any, any> {
    id: number;
    name: string;
    path: string;
    component: string;
    isPublic: boolean;
    isActive: boolean;
}

export const defaultRoute: IRoute = {
    id: -1, name: '', path: '', component: '', isPublic: false, isActive: true
};

export const getRouteList = (i: IBaseList<IRoute>): IRoute[] => {
    return i.items;
};

export interface IRoutePermissionUpdate extends Record<any, any> {
    id: number;
    permissions: ISecurityRole[];
}

export const defaultRoutePermissionUpdate: IRoutePermissionUpdate = {
    id: -1, permissions: []
};
