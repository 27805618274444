import React from 'react';
import { IReportFilter, IReportFilterSelectionValues } from '../../../report-microstrategy/entities/ReportFilter';
import SiteReportFilter from './SiteReportFilter';

interface IFilterInfo {
    filters: IReportFilter[];
    filterValues: IReportFilterSelectionValues[];
    updateFilter: (i: number, v: string) => void;
}

const SiteReportFilters: React.FunctionComponent<IFilterInfo> = ({ filters, filterValues, updateFilter }) => {

    return (
        <>
            {filters.map((filter) =>
                <div className="pull-left m-r-10" key={filter.sortOrder} >
                    <SiteReportFilter filter={filter} values={filterValues.filter(q => q.name === filter.name)} onChange={updateFilter} />
                </div>
            )}
        </>
    );
};

export default SiteReportFilters;
