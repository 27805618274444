import React, { CSSProperties, useEffect, useState } from 'react';
import { displayHTMLContent } from '../../utilities/Miscellaneous';
import { getInformationMessage, IInformationMessage, InformationMessage } from './enums/InformationMessages';

interface IComponentInfo {
    message?: string;
    style?: CSSProperties;
    hidden?: boolean;
    informationMessage?: InformationMessage;
    icon?: string;
    className?: string;
}

const OEInformation: React.FunctionComponent<IComponentInfo> = ({ message, style, hidden, informationMessage, icon, className }) => {

    const [localMessage, setLocalMessage] = useState<string>('');

    useEffect(() => {
        message && setLocalMessage(message);
    }, [message]);

    useEffect(() => {
        message && setLocalMessage(message);
        if (informationMessage) {
            const i: IInformationMessage = getInformationMessage(informationMessage);
            setLocalMessage(i.message);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {!hidden && localMessage && localMessage.length > 0 && (
                <div style={style} className={className}>
                    {icon && (
                        <i className={`${icon} m-t-0`} aria-hidden="true" />
                    )}
                    <span dangerouslySetInnerHTML={displayHTMLContent(localMessage)} />

                </div>
            )}
        </>
    );
};

export default OEInformation;