import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import { getReportList, IReport } from '../../entities/Report';
import { getReportListConfigurations } from '../../entities/ReportConfiguration';
import { defaultReportList, IReportList } from '../../entities/ReportList';
import { defaultReportLookupTables, IReportLookupTables } from '../../entities/ReportLookupTables';
import { getReportTypeList } from '../../entities/ReportType';
import { useCreateReportList, useGetReportList, usePutReportList } from '../../services/ReportListService';
import { useGetAllReports } from '../../services/ReportService';
import { useGetReportTypes } from '../../services/ReportTypeService';
import Configuration from '../report-configruation/Configuration';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
    lookupTables: IReportLookupTables;
}

const Form: React.FunctionComponent<FormikProps<IReportList> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    const { service: reportService } = useGetAllReports();
    const [reports, setReports] = useState<IReport[]>([]);

    useEffect(() => {
        if (reportService.result) {
            setReports(getReportList(reportService.result));
        }
    }, [reportService]);

    const onChangeReport = (i: string) => {
        if (reports.filter(q => q.id === i).length > 0) {
            const p: IReport = reports.filter(q => q.id === i)[0];
            setTimeout(function () {
                setFieldValue('title', p.title);
                setFieldValue('description', p.description);
                setFieldValue('configuration', p.configuration || '');
                setFieldValue('filters', p.filters || '');
            }, 100)
        } else {
            setTimeout(function () {
                setFieldValue('reportId', '');
            }, 100)
        }
    };

    return (
        <>
            <OEFormDropdown
                label="Report Type" name="reportTypeId" value={values.reportTypeId}
                defaultSelect={defaultDropdownSelect} alwaysShowDefault={false} onChange={onChangeReport}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables.types}
                setFieldValue={setFieldValue} required={true}
            />

            {values.reportTypeId !== '' && (
                <OEFormDropdown
                    label="Report" name="reportId" value={values.reportId}
                    defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                    fields={{ id: 'id', name: 'title' }} onChange={onChangeReport}
                    errors={errors} touched={touched} columns={labelColumns}
                    values={reports.filter(q => q.reportTypeId === values.reportTypeId && (q.isActive || q.id === values.reportId))}
                    setFieldValue={setFieldValue} required={true}
                />
            )}

            {values.reportId !== '' && (
                <>
                    <OEFormInput
                        label="Title" name="title" value={values.title}
                        errors={errors} touched={touched} columns={labelColumns}
                        required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
                    />

                    <OEFormInput
                        label="Description" name="description" value={values.description}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
                    />

                    <Configuration
                        values={values}
                        configurations={getReportListConfigurations(values.configuration)}
                        setFieldValue={setFieldValue}
                    />

                    <OEFormBoolean
                        label="Active" name="isActive" value={values.isActive}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue}
                    />
                </>
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IReportList>().shape({
    reportTypeId: Yup.string().required('Report Type is required').min(2, 'Mininum Not Met').max(250).nullable(),
    reportId: Yup.string().required('Report is required').min(2).max(250).nullable(),
    title: Yup.string().required('Title is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    id: string;
    sortOrder: number;
    reportGroupId: string;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportListFormik: React.FunctionComponent<IFormikInfo> = ({ id, reportGroupId, onCancel, onSuccess, sortOrder }) => {

    const { service, setItemId } = useGetReportList();
    const { service: updateService, setItem: setUpdateItem } = usePutReportList();
    const { service: createService, setItem: setCreateReport } = useCreateReportList();
    const { service: typeService } = useGetReportTypes();


    const [lookups, setLookups] = useState<IReportLookupTables>(defaultReportLookupTables);
    const [item, setItem] = useState<IReportList>({ ...defaultReportList, sortOrder, reportGroupId });

    useEffect(() => {
        if (service.result) {
            setItem({ ...service.result.reportList, reportTypeId: service.result.report ? service.result.report.reportTypeId : '' });
        }
    }, [service]);

    useEffect(() => {
        if (typeService.result) {
            setLookups({ ...defaultReportLookupTables, types: getReportTypeList(typeService.result) });
        }
    }, [typeService]);

    useEffect(() => {
        setItemId(id);
        // eslint-disable-next-line
    }, [id]);

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            lookupTables={lookups}
            title={`${item.id === '' ? `Add New Report` : `Edit Report: ${item.title}`}`}
            progressMessage={ProgressMessages.Report}
            successMessage={SuccessMessages.Report}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReport : setUpdateItem}
            service={item.id === '' ? createService : updateService}
        />
    );
};

export default ReportListFormik;
