import React, { useEffect, useState } from 'react';
import { OEFormLabel } from '../../../core/components/form/OEForm';
import OEInput, { OEInputType } from '../../../core/components/form/OEInput';
import OEFormSubmit from '../../../core/components/formik/OEFormSubmit';
import OEModal, { ModalSize, OEModalBody, OEModalFooter, OEModalHeader, OEModalTitle } from '../../../core/components/general/OEModal';
import OEWell, { WellStyle } from '../../../core/components/general/OEWell';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import { Icon } from '../../../core/entities/Icon';
import { IReportFlag } from '../../entities/ReportFlag';
import { useCreateReportFlag, usePutReportFlag } from '../../services/ReportFlagService';

interface IDetailInfo {
    label: string;
    value: string;
}

const Detail: React.FunctionComponent<IDetailInfo> = ({ label, value }) => {
    return (
        <>
            <OECol sm={2}><i className="pull-right">{label}:</i></OECol>
            <OECol sm={10}><b>{value}</b></OECol>
        </>
    );
};

interface IFormikInfo {
    item: IReportFlag;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportFlagFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem: setSaveFlag } = usePutReportFlag();
    const { service: createService, setItem: setCreateFlag } = useCreateReportFlag();
    const [comments, setComments] = useState<string>(item.comments);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service && service.isFinished) {
            if (service.isSuccess) {
                onSuccess();
            } else {
                setErrors(
                    [service.response.message] || [
                        'An error occurred saving feedback',
                    ]
                );
            }
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (createService && createService.isFinished) {
            if (createService.isSuccess) {
                onSuccess();
            } else {
                setErrors(
                    [service.response.message] || [
                        'An error occurred saving feedback',
                    ]
                );
            }
        }
        // eslint-disable-next-line
    }, [createService]);

    const onClear = () => {
        setComments('');
    }

    const onSubmit = () => {
        setErrors([]);
        if (item.id) {
            setSaveFlag({ ...item, comments });
        } else {
            setCreateFlag({ ...item, comments });
        }
    }

    return (
        <OEModal oeSize={ModalSize.Medium} onHide={onCancel} show={true} >
            <OEModalHeader onHide={onCancel}>
                <OEModalTitle
                    title={`${item.report}`}
                    icon={Icon.FlagOff}>
                </OEModalTitle>
            </OEModalHeader>

            <OEModalBody>
                <OEWell oeStyle={WellStyle.Bordered} className="m-t-0">
                    <OERow className="m-b-0 m-t-0">
                        <Detail label="Report" value={item.report} />
                        <Detail label="Year" value={item.academicYearLabel} />
                        <Detail label="District" value={item.district} />
                        <Detail label="School" value={item.school} />
                    </OERow>

                </OEWell>
                <OEFormLabel>Feedback/Comments</OEFormLabel>
                <OEInput value={comments} onChange={setComments} placeholder="Enter your feedback/comments here" type={OEInputType.LargeText} rows={8} />
                <OEErrorList errors={errors} />
            </OEModalBody>
            <OEModalFooter>
                <OEFormSubmit
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    altActionText="Clear Feedback"
                    onAltAction={onClear}
                    altIcon={Icon.Erase}
                    disabled={service && service.isInProgress}
                />
            </OEModalFooter>
        </OEModal>
    );
};

export default ReportFlagFormik;