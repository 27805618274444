import React from 'react';
import { IReportGroup } from '../../../../reporting/entities/ReportGroup';

interface IComponentInfo {
    group: IReportGroup;
    onClick: (i: IReportGroup) => void;
    className?: string;
}

const SectionGroupLink: React.FunctionComponent<IComponentInfo> = ({ group, onClick, className }) => {
    const onSelect = () => {
        onClick(group);
    }

    return (
        <span className={className || ''} onClick={onSelect} >
            {group.title}
        </span>
    );
};

export default SectionGroupLink;
