import React, { Fragment, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Icon } from '../../entities/Icon';
import OEButton, { ButtonStyle } from '../form/OEButton';
import OEModal, { ModalSize, OEModalBody, OEModalFooter, OEModalHeader, OEModalTitle } from '../general/OEModal';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';
import OEErrorList from '../messaging/OEErrorList';
import { ISortData } from './entities/SortData';

interface IComponentInfo {
    title: string;
    help?: string;
    items: ISortData[];
    onOk: (items: ISortData[]) => void;
    onCancel: () => void;
    errors?: string[];
    show: boolean;
}

const OESort: React.FunctionComponent<IComponentInfo> = ({ title, items, help, onOk, onCancel, errors, show }) => {
    const [sortList, setSortList] = useState<ISortData[]>([]);
    const [colSize, setColSize] = useState<number>(12);

    useEffect(() => {
        if (items.length > 0) {
            sortItems(items);
        }
    }, [items]);

    const sortItems = (list: ISortData[]) => {
        let index: number = 1;
        const newList = [...list];
        for (const item of list) {
            item.sortOrder = index;
            index = index + 1;
        }
        if (newList.length > 10) {
            setColSize(6);
        }
        if (newList.length > 20) {
            setColSize(4);
        }
        setSortList([...newList]);
    };

    const submit = () => {
        onOk(sortList);
    };


    return (
        <Fragment>
            <OEModal oeSize={ModalSize.Large} show={show}>
                <OEModalHeader onHide={onCancel}>
                    <OEModalTitle icon={Icon.Sort}>
                        {title}
                        <span style={{ fontSize: '14px', padding: '0px', margin: '0px' }}> <br /><i className={Icon.Info} aria-hidden="true" />
                            {`${help ? help : 'drag items below to change sort order'}`}
                        </span>
                    </OEModalTitle>
                </OEModalHeader>

                <OEModalBody>
                    <OERow>
                        <ReactSortable setList={sortItems} list={sortList} className="sortable">
                            {sortList.map((item, index) =>
                                <OECol sm={colSize} key={index} id={`${item.id}`} >
                                    <div className="truncate sortSeector">
                                        {`${item.sortOrder}. ${item.name}`}
                                    </div>
                                </OECol>
                            )}
                        </ReactSortable>
                    </OERow>
                    <OEErrorList errors={errors} />
                </OEModalBody>
                <OEModalFooter>
                    <OEButton onClick={submit} text="Save" />
                    <OEButton bStyle={ButtonStyle.Cancel} onClick={onCancel} text="Cancel" />
                </OEModalFooter>
            </OEModal>
        </Fragment >
    );
};

export default OESort;
