import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import {
    ISiteReport,
    defaultSiteReport,
} from '../../reporting/entities/SiteReport';
import { IDcFormListItem } from '../entities/DcFormList';
import { PutRequest, processPutAsync } from '../../core/services/PutRequest';

export type IDcFormFilter = { term: string; orgUnitId: string } | null;

export const useGetDcFormsLists = () => {
    const [service, setService] = useState(
        GetRequest.empty<IBaseList<IDcFormListItem>>()
    );
    const [dcFormFilter, setDcFormFilter] = useState<IDcFormFilter>(null);

    useEffect(() => {
        if (dcFormFilter == null) {
            return;
        }
        processGetAsync(
            setService,
            getUrl(`api/app/dc-forms`, {
                term: dcFormFilter.term,
                orgUnitId: dcFormFilter.orgUnitId,
            })
        );
        setDcFormFilter(null);
    }, [dcFormFilter]);

    return { service, setDcFormFilter };
};

export const useGetDcForm = () => {
    const [service, setService] = useState(GetRequest.empty<IDcFormListItem>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') {
            return;
        }
        processGetAsync(setService, `/api/app/dc-forms/${itemId}/form`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const useGetFormFilters = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<ISiteReport>(defaultSiteReport);

    useEffect(() => {
        if (item.reportFilters.length <= 0) {
            return;
        }
        processPostAsync(
            setService,
            `/api/app/site-reports/form-filters`,
            item
        );
        setItem(defaultSiteReport);
    }, [item]);

    return { service, setItem };
};

export const usePutDcFormFinalize = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [finalizeId, setFinalizeId] = useState<string>();

    useEffect(() => {
        if (!finalizeId) {
            return;
        }
        processPutAsync(setService, `/api/app/dc-forms/${finalizeId}/finalize`);
        setFinalizeId(undefined);
    }, [finalizeId]);

    return { service, setFinalizeId };
};

export const usePutDcFormComplete = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [completeId, setCompleteId] = useState<string>();

    useEffect(() => {
        if (!completeId) {
            return;
        }
        processPutAsync(setService, `/api/app/dc-forms/${completeId}/complete`);
        setCompleteId(undefined);
    }, [completeId]);

    return { service, setCompleteId };
};

const getUrl = (url: string, parameters: Record<string, any>): string => {
    if (!parameters) {
        return url;
    }

    const params = Object.keys(parameters)
        .filter((key) => parameters[key])
        .map((key) => `${key}=${parameters[key]}`)
        .join('&');

    return `${url}?${params}&MaxResultCount=1000`;
};
