import { getUrlParameters } from '../../core/components/parameters/entities/OEParameter';
import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IRoute } from './Route';
import { ISiteMenu } from './SiteMenu';

export enum MenuTarget {
    NewWindow = '_blank',
    Self = '_self'
}

export interface IMenu extends IBaseAuditedEntity {
    id: number;
    name: string;
    icon: string;
    externalUrl: string;
    sortOrder: number;
    path: string;
    parentId: number;
    route: string;
    routeId?: number;
    childCount: number;
    parameters: string;
    isActive: boolean;
    rootId: number;
    target: string;
    parentMenu: boolean;
    onClick?: (i: ISiteMenu) => void;
}

export const defaultMenu: IMenu = {
    id: -1, name: '', icon: '', externalUrl: '', parentId: -1, route: '',
    path: '', sortOrder: 0, childCount: 0, routeId: 0, parameters: '',
    rootId: -1, isActive: true, target: MenuTarget.Self, parentMenu: false
};

export interface IMenuListItem extends IBaseAuditedEntity {
    menu: IMenu;
    route: IRoute;
}

export interface IMenuLookupTables {
    routes: IRoute[];
}

export const defaultMenuLookupTables: IMenuLookupTables = {
    routes: []
};

export const getMenuList = (i: IBaseList<IMenuListItem>): IMenu[] => {
    const d: IMenu[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultMenu,
            ...v.menu,
            path: (v.route ? v.route.path : v.menu.externalUrl) + getUrlParameters(v.menu.parameters),
            parentId: v.menu.parentId > 0 ? v.menu.parentId : 0,
            routeId: v.route ? v.route.id : 0,
            route: v.route ? v.route.name : '',
            parentMenu: v.menu.externalUrl === '#'
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getMenuForSave = (i: IMenu): IMenu => {
    if (i.routeId === 0) {
        i.routeId = undefined;
    }
    return i;
};

export interface IMenuPermissionUpdate extends Record<any, any> {
    id: number;
    permissions: ISecurityRole[];

}

export const defaultMenuPermissionUpdate: IMenuPermissionUpdate = {
    id: -1, permissions: []
};
