import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReport, IReport, IReportListItem } from '../entities/Report';
import { defaultReportAccessLog, IReportAccessLog } from '../entities/ReportAccessLog';

export const useGetReports = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportListItem>>());
    const [reportTypeId, setReportTypeId] = useState<string>('');

    useEffect(() => {
        if (reportTypeId === '') { return; }
        processGetAsync(setService, `/api/app/reports?MaxResultCount=1000&ReportTypeId=${reportTypeId}`);
        setReportTypeId('');
    }, [reportTypeId]);

    return { service, setReportTypeId };
};

export const useGetAllReports = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportListItem>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/reports?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetReport = () => {
    const [service, setService] = useState(GetRequest.empty<IReportListItem>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/reports/${itemId}/with-navigation-properties`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const useGetReportById = () => {
    const [service, setService] = useState(GetRequest.empty<IReport>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/reports/${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReport = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReport>(defaultReport);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/reports/${item.id}`, item);
        setItem(defaultReport);
    }, [item]);

    return { service, setItem };
};

export const useCreateReport = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReport>(defaultReport);

    useEffect(() => {
        if (item.title === '') { return; }
        processPostAsync(setService, `/api/app/reports`, item);
        setItem(defaultReport);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReport = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [reportId, setReportId] = useState<string>('');

    useEffect(() => {
        if (reportId === '') { return; }
        processDeleteAsync(setService, `/api/app/reports/${reportId}`);
        setReportId('');
    }, [reportId]);

    return { service, setReportId };
};

export const useLogAccess = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportAccessLog>(defaultReportAccessLog);

    useEffect(() => {
        if (item.reportId === '') { return; }
        processPostAsync(setService, `/api/app/report-access-logs`, item);
        setItem(defaultReportAccessLog);
    }, [item]);

    return { service, setItem };
};
