import React, { useEffect, useState } from 'react';
import { IReportFilter } from '../../report-microstrategy/entities/ReportFilter';
import { ISiteReport, defaultSiteReportFormFilter } from '../../reporting/entities/SiteReport';
import { IDcFormFilter, useGetFormFilters } from '../services/DcFormListService';
import Filter from './Filter';

interface IComponentInfo {
    setDcFormFilter: (dcFormFilter: IDcFormFilter) => void;
}

const Filters: React.FunctionComponent<IComponentInfo> = ({ setDcFormFilter }) => {
    const { service, setItem } = useGetFormFilters(); // Step 4 - get filters for page
    const [filters, setFilters] = useState<IReportFilter[]>([]);

    useEffect(() => {
        const i: ISiteReport = { ...defaultSiteReportFormFilter };
        for (const f of i.reportFilters) {
            const c: string = localStorage.getItem(`FormFilter-${f.name}`) || '';
            if (c) {
                f.value = c;
            }
        }
        setItem(i);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (service.isFinished && service.data) {
            setFilters(service.data.data);
            // setFilterValues(siteReportFilterService.data.data);
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (filters.length > 0 && filters.filter(q => q.name === 'School')) {
            try {
                setDcFormFilter({
                    term: filters.filter(q => q.name === 'AcademicYear').length > 0 ? filters.filter(q => q.name === 'AcademicYear')[0].selectedValue : '',
                    orgUnitId: filters.filter(q => q.name === 'School')[0].selectedValue
                });
            }
            catch { }
        }
        // eslint-disable-next-line
    }, [filters]);

    const updateFilters = (f: IReportFilter) => {
        const i: IReportFilter = filters.filter(q => q.name === f.name)[0];
        i.value = f.value;
        setItem({ ...defaultSiteReportFormFilter, reportFilters: filters });
        setFilters([...filters]);
    }

    return (
        <>
            {filters.length > 0 && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr)', gap: '0.5em' }}>
                    {filters.map((item, index) =>
                        <Filter
                            filter={item}
                            key={index}
                            setValue={updateFilters}
                        />
                    )}
                </div>
            )}
            <div />
        </>
    );
};

export default Filters;
