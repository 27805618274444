import React from 'react';
import MSEmbeddedTierOneReport from './MSEmbeddedTierOneReport';

const MSEmbeddedTierOne: React.FunctionComponent = () => {
    return (
        <MSEmbeddedTierOneReport />
    );
};

export default MSEmbeddedTierOne;
