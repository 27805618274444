import { ModalSize } from "../../general/OEModal";

export interface IFormInfo {
    component: any;
    progressMessage?: string;
    successMessage?: string;
    submitText?: string;
    cancelText?: string;
    onCancel: () => void;
    errorList?: string[];
    lookupTables?: any;
    labelColumns: number;
    parameter?: any;
    isEditing?: boolean;
    altActionText?: string;
    onAltAction?: (values: any) => void;
    oeSize?: ModalSize;
}

export interface IFormikInfo extends IFormInfo {
    item: any;
    onSave?: () => void;
    validationSchema?: any;
    setItem: (i: any) => void;
    service?: any;
}

export interface IFormikModalInfo extends IFormikInfo {
    title?: string;
    icon?: string;
}

export enum FormInputType {
    Label,
    Number,
    String,
    LargeText
}

