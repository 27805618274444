import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { IWidgetGroup } from '../entities/WidgetGroup';


export const useGetWidgetGroups = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetGroup>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/widget-groups?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetWidgetGroupByCode = (s: string) => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetGroup>>());
    const [code, setCode] = useState<string>(s);

    useEffect(() => {
        if (code === '') { return; }
        processGetAsync(setService, `api/app/widget-groups?Code=${code}`);
        setCode('')
    }, [code]);

    return { service, setCode };
};

export const useGetWidgetByID = (s: string) => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetGroup>>());
    const [code, setCode] = useState<string>(s);

    useEffect(() => {
        if (code === '') { return; }
        processGetAsync(setService, `api/app/widget-groups?Code=${code}`);
        setCode('')
    }, [code]);

    return { service, setCode };
};


