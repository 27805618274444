import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import OEParameters from '../../../core/components/parameters/OEParameters';
import { IOEParameter, OEParameterType, getParameters } from '../../../core/components/parameters/entities/OEParameter';
import { Icon } from '../../../core/entities/Icon';
import { IReport } from '../../entities/Report';
import { getReportConfigurations } from '../../entities/ReportConfiguration';
import { IReportLookupTables, defaultReportLookupTables } from '../../entities/ReportLookupTables';
import { IReportType, ReportType, defaultReportType, getReportTypeList, isPowerBIReport, isSiteReport } from '../../entities/ReportType';
import { useCreateReport, usePutReport } from '../../services/ReportService';
import { useGetReportType, useGetReportTypes } from '../../services/ReportTypeService';
import Configuration from '../report-configruation/Configuration';
import Filters from '../report-configruation/Filters';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
    lookupTables: IReportLookupTables;
}

const Form: React.FunctionComponent<FormikProps<IReport> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    const [url, setUrl] = useState<string>('');

    const [parameters, setParameters] = useState<IOEParameter[]>([]);
    const { service: typeService, setItemId: setReportTypeId } = useGetReportType();
    const [reportType, setReportType] = useState<IReportType>(defaultReportType);

    useEffect(() => {
        if (typeService.result) {
            setReportType({ ...typeService.result.reportType, server: typeService.result.reportServer });
        }
    }, [typeService]);

    useEffect(() => {
        setParameters([
            ...getParameters(values.parameters || ''),
            ...getParameters(reportType.parameters || ''),
            ...getParameters((reportType.server && reportType.server.parameters) || '')
        ]);
    }, [values.parameters, reportType]);

    useEffect(() => {
        setReportTypeId(values.reportTypeId);
        // eslint-disable-next-line
    }, [values.reportTypeId]);

    useEffect(() => {
        if (reportType && reportType.server) {
            let url: string = '';
            for (const p of parameters) {
                url = `${url}${url.length > 0 ? '&' : '?'}${p.key}=${p.value}`;
            }
            if (values.externalId !== '') {
                url = `${url}/${values.externalId}`;
            }
            setUrl(`${reportType.server.path}${url}`);
        }
    }, [parameters, reportType, values.externalId]);


    return (
        <>
            <OEFormDropdown
                label="Report Type" name="reportTypeId" value={values.reportTypeId}
                defaultSelect={defaultDropdownSelect} alwaysShowDefault={false}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables.types}
                setFieldValue={setFieldValue} required={true}
            />

            <OEFormInput
                label="Title" name="title" value={values.title} errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Description" name="description" value={values.description}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />

            <OEFormBoolean
                label="Active" name="isActive" value={values.isActive}
                errors={errors} touched={touched} columns={labelColumns} setFieldValue={setFieldValue}
            />
            {isPowerBIReport(values.reportTypeId) && (
                <>
                    <OEFormInput
                        label="Width" name="width" value={values.width}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.Number}
                        instructions="Leave blank for max-width in browser"
                    />

                    <OEFormInput
                        label="Height" name="height" value={values.height}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.Number}
                        instructions="Leave blank for max-hieght in browser"
                    />

                    <OEParameters values={getParameters(values.parameters).filter(q => q.type === OEParameterType.URL)}
                        setFieldValue={setFieldValue} type={OEParameterType.URL} />

                    <Configuration
                        values={values} configurations={getReportConfigurations(values.configuration)}
                        setFieldValue={setFieldValue}
                    />

                </>
            )}

            {!isSiteReport(values.reportTypeId) && !isPowerBIReport(values.reportTypeId) && (
                <>
                    <OEFormInput
                        label="Width" name="width" value={values.width}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.Number}
                    />

                    <OEFormInput
                        label="Height" name="height" value={values.height}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.Number}
                    />

                    {values.reportTypeId && values.reportTypeId === ReportType.EmbeddedFilters && (
                        <OEParameters values={getParameters(values.parameters).filter(q => q.type === OEParameterType.URL)} setFieldValue={setFieldValue} type={OEParameterType.URL} />
                    )}

                    <Configuration
                        values={values} configurations={getReportConfigurations(values.configuration)}
                        setFieldValue={setFieldValue}
                    />

                    <Filters isReport={true} values={values} filters={[]} setFieldValue={setFieldValue} />

                    <OEFormInput
                        label="URL Extension" name="externalId" value={values.externalId}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.String}
                        type={OEInputType.LargeText} rows={3}
                    />

                    <OEFormInput
                        label="URL Preview" name="urlpreview" value={url}
                        errors={errors} touched={touched} columns={labelColumns} disabled={true}
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
                    />

                </>
            )}

        </>
    );
};

const ValidationScheme = Yup.object<IReport>().shape({
    reportTypeId: Yup.string().required('Report Type is required').min(2, 'Mininum Not Met').max(250).nullable(),
    title: Yup.string().required('Title is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IReport;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReport();
    const { service: createService, setItem: setCreateReport } = useCreateReport();
    const { service: typeService } = useGetReportTypes();

    const [lookups, setLookups] = useState<IReportLookupTables>(defaultReportLookupTables);

    useEffect(() => {
        if (typeService.result) {
            setLookups({ ...defaultReportLookupTables, types: getReportTypeList(typeService.result) });
        }
    }, [typeService]);

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            lookupTables={lookups}
            title={`${item.id === '' ? `Add New Report` : `Edit Report: ${item.title}`}`}
            progressMessage={ProgressMessages.Report}
            successMessage={SuccessMessages.Report}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReport : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportFormik;