import React, { Children, CSSProperties } from 'react';
import Col from 'react-bootstrap/Col';

interface IComponentInfo {
    className?: string;
    id?: string;
    onClick?: () => void;
    style?: CSSProperties;
    xs?: number;
    xsOffset?: number;
    sm?: number;
    smOffset?: number;
    md?: number;
    mdOffset?: number;
    lg?: number;
    lgOffset?: number;
    children?: any;
}

export const OECol: React.FunctionComponent<IComponentInfo> = ({ onClick, style, id, className, xs, xsOffset, sm, smOffset, md, mdOffset, lg, lgOffset, children }) => {
    return (
        <Col
            id={id}
            className={className}
            xs={{ span: xs || sm, offset: xsOffset! }}
            sm={{ span: sm, offset: smOffset! }}
            md={{ span: md || sm, offset: mdOffset! }}
            lg={{ span: lg || sm, offset: lgOffset! }}
            onClick={onClick}
            style={style}
        >
            {Children.map(children, i => i)}
        </Col>
    );
};

