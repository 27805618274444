import { getEmptyGUID } from "../../core/utilities/String";

export interface IAlertSubscription {
    id: string;
    eventName?: string;
    isSubscribed: boolean
}

export const defaultAlertSubscription: IAlertSubscription = {
    id: getEmptyGUID(),
    eventName: "",
    isSubscribed: false,
};

export const defaultAlertSubscriptions: IAlertSubscription[] = [];