import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { IOrganizationUnit } from '../../organization/entities/OrganizationUnit';
import { IRole } from '../../organization/entities/Role';

export interface ISecurityUser {
    id: number;
    userId: string;
    organizationUnitId: string;
    organizationUnit: string;
    roleId: string;
    role: string;
    delete: boolean;
    add: boolean;
    isDefault: boolean;
    dirty: boolean;
    importID: string;
}

export const defaultSecurityUser: ISecurityUser = {
    id: -1, userId: '', organizationUnit: '', organizationUnitId: '',
    role: '', roleId: '', delete: false, isDefault: false, add: false, dirty: false, importID: ''
};

export interface ISecurityUserListItem extends IBaseAuditedEntity {
    securityUser: ISecurityUser;
}

export const getSecurityUserList = (i: IBaseList<ISecurityUserListItem>, r: IRole[], o: IOrganizationUnit[]): ISecurityUser[] => {
    const d: ISecurityUser[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultSecurityUser,
            ...v,
            organizationUnit: o.filter(q => q.id === v.organizationUnitId).length > 0 ? o.filter(q => q.id === v.organizationUnitId)[0].displayName : 'Organization Not Loaded',
            importID: o.filter(q => q.id === v.organizationUnitId).length > 0 ? o.filter(q => q.id === v.organizationUnitId)[0].importId : 'Organization Not Loaded',
            role: r.filter(q => q.id === v.roleId).length > 0 ? r.filter(q => q.id === v.roleId)[0].name : 'Role Not Loaded',

        });
    }
    return d;
};

export const getSecurityUserUniqueId = (i: ISecurityUser): string => {
    return `${i.organizationUnitId}-${i.roleId}`;
};


