import React from 'react';
import AlertSubscriptionsList from '../../alert-subsciptions/components/AlertSubscriptionsList';
import ArtifactList from '../../artifacts/components/ArtifactList';
import { DatasetConnectionList } from '../../dataset-connections/components/DatasetConnectionList';
import DcFileUploadList from '../../dc-file-upload/components/DcFileUploadList';
import DcFormsList from '../../dc-forms/components/DcFormsList';
import { DcFormsView } from '../../dc-forms/components/DcFormsView';
import { AnalystDictionary } from '../../dictionaries/components/AnalystDictionary';
import { ElementDictionary } from '../../dictionaries/components/ElementDictionary';
import HelpPage from '../../help/HelpPage';
import OrganizationAdmin from '../../organization/OrganizationAdmin';
import MSEmbeddedPublic from '../../public/report-microstrategy/MSEmbeddedPublic';
import MSDataDownload from '../../report-microstrategy/components/templates/data-download/MSDataDownload';
import MSEmbeddedPage from '../../report-microstrategy/components/templates/report-only/MSEmbeddedPage';
import MSEmbeddedTierOne from '../../report-microstrategy/components/templates/single-tier/MSEmbeddedTierOne';
import MSEmbeddedTierThree from '../../report-microstrategy/components/templates/three-tier/MSEmbeddedTierThree';
import BIEmbeddedPage from '../../report-powerbi/components/BIEmbeddedPage';
import BIEmbeddedTierOne from '../../report-powerbi/components/BIEmbeddedTierOne';
import ReportAdmin from '../../reporting/components/admin-reports/ReportAdmin';
import ReportList from '../../reporting/components/report-list/ReportList';
import SiteReports from '../../reporting/components/site-reports/SiteReports';
import StyleGuide from '../../style-guide/StyleGuide';
import About from '../../user/About';
import Glossary from '../../user/components/glossary/Glossary';
import GlossaryTable from '../../user/components/glossary/GlossaryTable';
import Reference from '../../user/components/reference/Reference';
import ReferenceTable from '../../user/components/reference/ReferenceTable';
import UserAdmin from '../../user/UserAdmin';
import UserProfile from '../../user/UserProfile';
import Widget from '../../widgets/Widget';
import WidgetAdmin from '../../widgets/WidgetAdmin';
import Dashboard from '../custom/pages/Dashboard';
import Maintenance from '../custom/pages/Maintenance';
import Oops from '../Oops';
import PageNotFound from '../PageNotFound';
import SiteAdmin from '../SiteAdmin';
import { parseQueryString } from '../../core/utilities/URL';

interface ICoreComponentInfo {
    name: string;
}

export const OECoreComponent: React.FunctionComponent<ICoreComponentInfo> = ({ name }) => {
    const params: any = parseQueryString();
    switch (name.toLowerCase()) {
        case 'about':
            return (<About />);

        case 'alertsubscriptionslist':
            return (<AlertSubscriptionsList />);

        case 'analystdictionary':
            return (<AnalystDictionary />);

        case 'artifactlist':
            return (<ArtifactList />);

        case 'biembeddedpage':
            return (<BIEmbeddedPage />);

        case 'biembeddedpagetierone':
            return (<BIEmbeddedTierOne />);

        case 'dashboard':
            return (<Dashboard />);

        case 'datasetconnectionlist':
            return (<DatasetConnectionList />);

        case 'dcformslist':
            return (<DcFormsList />);

        case 'dcformsview':
            return (<DcFormsView />);

        case 'dcfileuploadlist':
            return (<DcFileUploadList />);

        case 'elementdictionary':
            return (<ElementDictionary />);

        case 'help':
            return (<HelpPage />);

        case 'glossary':
            return (<Glossary />);

        case 'glossarytable':
            return (<GlossaryTable />);

        case 'maintenance':
            return (<Maintenance />);

        case 'msembeddedtierthree':
            return (<MSEmbeddedTierThree />);

        case 'msembeddedpage':
            return (<MSEmbeddedPage />);

        case 'msembeddedtierone':
            return (<MSEmbeddedTierOne />);

        case 'msembeddedpublic':
            return (<MSEmbeddedPublic pageId={params['pid'] || ''} />);

        case 'msdatadownload':
            return (<MSDataDownload />);

        case 'oops':
            return (<Oops />);

        case 'organizationadmin':
            return (<OrganizationAdmin />);

        case 'reference':
            return (<Reference />);

        case 'referencetable':
            return (<ReferenceTable />);

        case 'reportadmin':
            return (<ReportAdmin />);

        case 'reportlist':
            return (<ReportList />);

        case 'styleguide':
            return (<StyleGuide />);

        case 'siteadmin':
            return (<SiteAdmin />);

        case 'sitereports':
            return (<SiteReports />);

        case 'useradmin':
            return (<UserAdmin />);

        case 'userprofile':
            return (<UserProfile />);

        case 'widget':
            return (<Widget />);

        case 'widgetadmin':
            return (<WidgetAdmin />);

        default:
            return (<PageNotFound />);
    }
};