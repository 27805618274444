import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IRoute } from './Route';

interface ISecurityRoute extends ISecurityRole {
    id: number;
    routeId: number;
}

export interface ISecurityRouteListItem extends IBaseAuditedEntity {
    securityRoute: ISecurityRoute;
}

export interface IRouteLookupTables {
    routes: IRoute[];
}

export const defaultRouteLookupTables: IRouteLookupTables = {
    routes: []
};

export const getSecurityRouteList = (i: IBaseList<ISecurityRouteListItem>): ISecurityRole[] => {
    const d: ISecurityRole[] = [];
    for (const v of i.items) {
        d.push({ roleId: v.securityRoute.roleId });
    }
    return d;
};


