import React, { Children, CSSProperties } from 'react';
import OEHeading from './OEHeading';
import OELink from './OELink';

export enum WellStyle {
    General = 'general-well',
    Clear = 'clear-well',
    Bordered = 'bordered-well',
    BorderedShaded = 'bordered-well-shaded',
    BorderedClear = 'clear-well bordered-well',
}

export interface IWellActions {
    onClick?: () => void;
    icon?: string;
    text?: string;
    hidden?: boolean;
}

interface IComponentInfo {
    id?: string;
    title?: string;
    icon?: string;
    className?: string;
    oeStyle?: string;
    style?: CSSProperties;
    headingSize?: number;
    actions?: IWellActions[];
    children?: any;
}

const OEWell: React.FunctionComponent<IComponentInfo> = ({
    className, title, icon, id, style, children, oeStyle, actions, headingSize
}) => {

    return (
        <>
            <div
                className={` container well ${className || ''} ${oeStyle || WellStyle.General}`}
                id={id}
                style={{ ...style, maxWidth: '100%' }}
            >
                {actions && actions.filter(q => !q.hidden).map((action, index) =>
                    <OELink text={action.text} className="pull-right" icon={action.icon} onClick={action.onClick} />
                )}
                {title && (
                    <OEHeading className="m-t-0 m-l=0 m-b-10" icon={icon} text={title} size={headingSize || 4} />
                )}
                {Children.map(children, i => i)}
            </div>
            <div className="cleardiv" />
        </>
    );

};

export default OEWell;