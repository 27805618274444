import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReportServer, IReportServer } from '../entities/ReportServer';
import { defaultSession, ISession } from '../entities/Session';

export const useGetReportServers = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportServer>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/report-servers?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetReportServer = () => {
    const [service, setService] = useState(GetRequest.empty<IReportServer>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-servers/${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReportServer = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportServer>(defaultReportServer);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/report-servers/${item.id}`, item);
        setItem(defaultReportServer);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportServer = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportServer>(defaultReportServer);

    useEffect(() => {
        if (item.name === '') { return; }
        processPostAsync(setService, `/api/app/report-servers`, item);
        setItem(defaultReportServer);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportServer = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-servers/${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const useCreateSession = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<ISession>(defaultSession);

    useEffect(() => {
        if (item.server === '') { return; }
        processPostAsync(setService, `/api/app/site-reports/create-session`, item);
        setItem(defaultSession);
    }, [item]);

    return { service, setItem };
};
