import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { IPowerBIReport } from '../entities/PowerBIReport';

export const useGetPowerBIReport = () => {
    const [service, setService] = useState(GetRequest.empty<IPowerBIReport>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/powerbi/${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

