export const localStoragePersistentKey: string = 'NIMPER:';

export const reactLogout = (url: string): any => {
    updateCookie('XSRF-TOKEN', '');

    //clear out non persistent localStorage items
    for (const key in localStorage) {
        if (!key.startsWith(localStoragePersistentKey)) {
            localStorage.removeItem(key);
        }
    }
    setTimeout(function () {
        redirectLogin(url || '/Account/Login?returnUrl=/LoginRedirect');
    }, 500)
};

export const getAuthorizationToken = (): any => {
    return getCookie('XSRF-TOKEN');
};

export const setAuthorizationToken = (expirationSeconds: number): any => {
    var expires = (new Date(Date.now() + (expirationSeconds * 1000))).toUTCString();
    document.cookie = `XSRF-TOKEN=${getAuthorizationToken}; expires=${expires}; path=/;`
};

export const getCookie = (name: string) => {
    let cookieValue: string = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const updateCookie = (name: string, value: string) => {
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookie = value;
                break;
            }
        }
    }
}

export const redirectLogin = (url?: string): any => {
    console.log('********************* UNAUTHORIZED *******************************');
    window.location.href = url ? url : '/Account/Login?returnUrl=/LoginRedirect';
};

