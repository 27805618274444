import React from 'react';
import MSEmbeddedPageReport from './MSEmbeddedPageReport';

const MSEmbeddedPage: React.FunctionComponent = () => {
    return (
        <MSEmbeddedPageReport />
    );
};

export default MSEmbeddedPage;
