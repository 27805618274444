import { FormikProps } from 'formik';
import React from 'react';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';
import OEErrorList from '../messaging/OEErrorList';
import OESpinner from '../messaging/OESpinner';
import { IFormInfo } from './entities/Form';
import OEFormSubmit from './OEFormSubmit';

interface IQFormComponentInfo {
    values: any;
    errors: any;
    touched: any;
    component?: any;
    lookupTables: any;
    setFieldValue: any;
    isEditing?: boolean;
}

const OEFormComponent: React.FunctionComponent<IQFormComponentInfo> = ({
    component,
    setFieldValue,
    values,
    errors,
    touched,
    lookupTables,
    isEditing,
}) => {
    const Component: React.FunctionComponent<IQFormComponentInfo> = component;
    return (
        <Component
            values={values}
            errors={errors}
            touched={touched}
            lookupTables={lookupTables}
            setFieldValue={setFieldValue}
            isEditing={isEditing}
        />
    );
};

const OEForm: React.FunctionComponent<FormikProps<any> & IFormInfo> = ({
    isSubmitting,
    labelColumns,
    component,
    lookupTables,
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    onCancel,
    errorList,
    submitText,
    cancelText,
    progressMessage,
    isEditing,
    altActionText,
    onAltAction,
}) => {
    const lblColSpan: number = labelColumns === 12 ? 0 : labelColumns;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        submitForm();
    };

    const handleCancel = (e: any) => {
        e.preventDefault();
        onCancel();
    };

    const handleAltAction = (e: any) => {
        e.preventDefault();
        onAltAction && onAltAction(values);
    };
    return (
        <>
            <form noValidate={true}>
                <OEFormComponent
                    component={component}
                    values={values}
                    errors={errors}
                    lookupTables={lookupTables}
                    touched={touched}
                    isEditing={isEditing}
                    setFieldValue={setFieldValue}
                />

                <OEErrorList errors={errorList} />
                {isSubmitting && (
                    <OERow>
                        <OECol sm={12 - lblColSpan} smOffset={lblColSpan}>
                            <OESpinner message={progressMessage} />
                        </OECol>
                    </OERow>
                )}

                {!isSubmitting && (
                    <OEFormSubmit
                        sm={12 - lblColSpan}
                        smOffset={lblColSpan}
                        disabled={isSubmitting}
                        onCancel={handleCancel}
                        onSubmit={handleSubmit}
                        submitText={submitText}
                        cancelText={cancelText}
                        altActionText={altActionText}
                        onAltAction={handleAltAction}
                    />
                )}
            </form>
        </>
    );
};

export default OEForm;
