import { useEffect, useState } from "react";
import { parseQueryString } from "../../core/utilities/URL";
import { IReport, defaultReport } from "../../reporting/entities/Report";
import { useGetReportById } from "../../reporting/services/ReportService";
import BIEmbeddedReport from "./BIEmbeddedReport";

const BIEmbeddedPage: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const { service: reportService, setItemId: setReportId } = useGetReportById();

    const [item, setItem] = useState<IReport>(defaultReport);

    useEffect(() => {
        setReportId(params.id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (reportService.result) {
            setItem(reportService.result);
        }
        // eslint-disable-next-line
    }, [reportService]);

    return (
        <>
            <h2>{item.title}</h2>
            {item.id !== '' && (
                <BIEmbeddedReport configuration={item.configuration} report={item} />
            )}
        </>
    );
};

export default BIEmbeddedPage;