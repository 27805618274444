import React from 'react';
import { OECoreComponent } from '../../components/OECoreComponent';
interface ICoreComponentInfo {
    name: string;
}

export const OECustomComponent: React.FunctionComponent<ICoreComponentInfo> = ({ name }) => {
    switch (name.toLowerCase()) {
        default:
            return (<OECoreComponent name={name} />);
    }
};
