import React, { useEffect, useState } from 'react';
import OEDropdown, { defaultDropdownIntegerSelectAll, defaultDropdownSelectAll, DropdownType } from '../form/OEDropdown';
import { OEFormGroup, OEFormLabel } from '../form/OEForm';
import OEInput from '../form/OEInput';
import { FilterDataType, FilterType, IFilterItem, IFilterValue } from './entities/Filter';
import yesno from './lookups/yesno-types.json';

interface IComponentInfo {
    item: IFilterValue;
    filter: IFilterItem;
    singleLine?: boolean;
    onChangeValue: (v: IFilterValue, preformSearch?: boolean) => void;
}

const FilterItem: React.FunctionComponent<IComponentInfo> = ({ item, filter, onChangeValue, singleLine }) => {

    const [value, setValue] = useState<any>(item.value || '');

    useEffect(() => {
        setValue(item.value);
    }, [item.value]);


    const onChange = (data: any) => {
        const performSearch: boolean = filter.autoSearchLength ? data.length >= filter.autoSearchLength : (filter.autoSearch || false);
        onChangeValue({ ...item, value: data }, performSearch);
        setValue(data);
    };

    return (
        <>
            {!filter.hide && (
                <OEFormGroup inline={true} className="m-r-5">
                    <OEFormLabel htmlFor={filter.name}>{filter.label}</OEFormLabel>
                    {!singleLine && (
                        <br />
                    )}
                    {(!filter.type || filter.type === FilterType.Input) && (
                        <OEInput
                            width={`${filter.width ? filter.width : 100}px`}
                            onChange={onChange}
                            maxLength={255}
                            placeholder={filter.placeholder}
                            name={filter.name}
                            value={value} />
                    )}
                    {(filter.type === FilterType.Dropdown || filter.type === FilterType.YesNo) && (
                        <OEDropdown
                            width={`${filter.width ? filter.width : 100}px`}
                            onChange={onChange}
                            values={filter.type === FilterType.YesNo ? yesno : (filter.values || [])}
                            alwaysShowDefault={!filter.hideDefault && true}
                            defaultSelect={filter.defaultSelect || (filter.dataType === FilterDataType.Number ? defaultDropdownIntegerSelectAll : defaultDropdownSelectAll)}
                            type={filter.dataType === FilterDataType.Number ? DropdownType.Integer : DropdownType.String}
                            name={filter.name}
                            fields={filter.fields}
                            value={value} />
                    )}
                </OEFormGroup>
            )}
        </>
    );
};

export default FilterItem;

