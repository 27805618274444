import { useState } from 'react';
import { OEFormLabel } from '../../core/components/form/OEForm';
import OEInput from '../../core/components/form/OEInput';
import OEFormSubmit from '../../core/components/formik/OEFormSubmit';
import OEModal, { ModalSize, OEModalBody } from '../../core/components/general/OEModal';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import OEMessage from '../../core/components/messaging/OEMessage';
import { ICSVSelectItem } from '../../core/entities/CSVSelectItem';
import { Icon } from '../../core/entities/Icon';

interface IArtifactTagEditInfo {
    item: string;
    items: ICSVSelectItem[];
    onCancel: () => void;
    onSave: (i: string) => void;
}

const ArtifactTagEdit: React.FunctionComponent<IArtifactTagEditInfo> = ({ item, items, onCancel, onSave }) => {
    const [newValue, setNewValue] = useState<string>(item);
    const [message, setMessage] = useState<string>('');


    const onChange = (e: string) => {
        setNewValue(e);
        setMessage('');
    };
    const handleCancel = (e: any) => {
        e.preventDefault();
        onCancel();
    };

    const handleSubmit = () => {
        if (items.filter(q => q.value === newValue).length > 0) {
            setMessage('Tag already exists');
        }
        else {
            onSave(newValue);
        }
    };

    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onCancel} title="Edit Tag">
            <OEModalBody>
                <OERow>
                    <OECol sm={2}  >
                        <OEFormLabel>New Value</OEFormLabel>
                        <br />
                    </OECol>

                    <OECol sm={10}>
                        <OEInput value={newValue} onChange={onChange} />
                        <OEFormSubmit
                            sm={10}
                            smOffset={2}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                        <div>
                            <OEMessage className="small" type={MessageType.Error} message={message} icon={Icon.Error} />
                        </div>
                    </OECol>
                </OERow>
            </OEModalBody>
        </OEModal >
    );
};

export default ArtifactTagEdit;