import React from 'react';
import OEDropdown, { DropdownType, IDropdownFields } from '../form/OEDropdown';
import OEFormItem, { IOEFormItemInfo } from './OEFormItem';
import {
    getFormControlTouched,
    getFormValue,
} from '../../utilities/FormHelper';

interface IComponentInfo {
    value: any;
    values: any[];
    defaultSelect?: any;
    fields?: IDropdownFields;
    alwaysShowDefault?: boolean;
    type?: DropdownType;
}

const OEFormDropdown: React.FunctionComponent<
    IComponentInfo & IOEFormItemInfo
> = ({
    columns,
    name,
    label,
    errors,
    touched,
    setFieldValue,
    required,
    disabled,
    hidden,
    autoFocus,
    onChange,
    values,
    defaultSelect,
    alwaysShowDefault,
    type,
    value,
    fields,
}) => {
    return (
        <OEFormItem
            columns={columns}
            name={name}
            label={label}
            errors={errors}
            touched={touched}
            required={required}
            hidden={hidden}
        >
            <OEDropdown
                className={`${
                    getFormValue(errors, name) &&
                    getFormControlTouched(touched, name)
                        ? 'is-invalid'
                        : ''
                } `}
                fields={fields}
                autoFocus={autoFocus}
                disabled={disabled}
                type={type}
                values={values || []}
                defaultSelect={defaultSelect}
                name={name}
                value={value}
                alwaysShowDefault={alwaysShowDefault}
                onChange={onChange}
                setFieldValue={setFieldValue}
            />
        </OEFormItem>
    );
};

export default OEFormDropdown;
