import { useEffect, useState } from 'react';
import OEFormItem from '../../core/components/formik/OEFormItem';
import OEWell, { WellStyle } from '../../core/components/general/OEWell';
import { INotification } from '../../core/components/messaging/entities/Notification';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { IArtifactDatasetViewColumns } from '../entities/ArtifactDatasetViewColumns';
import { defaultDatasetCategory, IDatasetCategory } from '../entities/DatasetCategory';
import CategoryFormik from './CategoryFormik';

enum ViewTypes {
    Default = 1,
    Edit
}

interface IComponentInfo {
    categories: IDatasetCategory[];
    labelColumns: number;
    columnData: IArtifactDatasetViewColumns[];
    setNotification: (n: INotification) => void;
    setCategories: (i: IDatasetCategory[]) => void;
}


const Categories: React.FunctionComponent<IComponentInfo> = ({ categories, labelColumns, columnData, setNotification, setCategories }) => {

    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);
    const [item, setItem] = useState<IDatasetCategory>(defaultDatasetCategory);
    const [localValues, setLocalValues] = useState<IDatasetCategory[]>([]);

    useEffect(() => {
        setLocalValues(categories);
        // eslint-disable-next-line
    }, [categories]);


    const onDelete = (i: IDatasetCategory) => {
        setLocalValues([...localValues.filter(q => q.categoryName !== i.categoryName)]);
    }

    const onAdd = () => {
        setShowView(ViewTypes.Edit);
        setItem(defaultDatasetCategory);
    }

    const onCancel = () => {
        setShowView(ViewTypes.Default);
    }

    const onSave = (i: IDatasetCategory) => {
        setShowView(ViewTypes.Default);
        if (localValues.filter(q => q.categoryName === i.categoryName).length > 0) {
            localValues.filter(q => q.categoryName === i.categoryName)[0].categoryColumns = i.categoryColumns;;
        }
        else {
            localValues.push(i);
        }
        setCategories([...localValues]);
    }

    const onEdit = (i: IDatasetCategory) => {
        setShowView(ViewTypes.Edit);
        setItem({ ...i, adding: false });
    }

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onDelete, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { id: 'categoryName', name: 'Name', sort: false, type: ColumnType.String },
        { id: 'categoryColumns', name: 'Columns', sort: false, type: ColumnType.StringArray },
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
    ];

    return (
        <>
            <OEFormItem name="categories" columns={labelColumns} label="Categories">
                <OEWell oeStyle={WellStyle.BorderedClear} >
                    <OETable
                        columns={columns}
                        data={localValues}
                        bordered={true}
                        className="table-bordered"
                        noDataMessage="No Categories have been added"
                        actions={[
                            { icon: Icon.Add, text: 'Add Category', action: onAdd },
                        ]}
                    />
                </OEWell>
            </OEFormItem>

            {showView === ViewTypes.Edit && (
                <CategoryFormik item={item} onCancel={onCancel} onSave={onSave} setNotification={setNotification} existing={categories} columnData={columnData} />
            )}
        </>

    );
};

export default Categories;