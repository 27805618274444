import { IBaseList } from "../../core/entities/BaseList";

export interface IReportServer extends Record<any, any> {
    id: string;
    name: string;
    path: string;
    parameters: string;
    canDelete: boolean;
}

export const defaultReportServer: IReportServer = {
    id: '', name: '', path: '', canDelete: false, parameters: ''
};


export const getReportServerList = (i: IBaseList<IReportServer>): IReportServer[] => {
    return i.items;
};

