
export enum OEParameterType {
    URL = 'url',
    Global = 'global',
}
export interface IOEParameter extends Record<any, any> {
    key: string;
    value: string;
    type: OEParameterType;
}

export const defaultOEParameter: IOEParameter = {
    key: '', value: '', type: OEParameterType.URL
};

export const getParameters = (p: string): IOEParameter[] => {
    try {
        if (p.trim() === '{}') return [];

        return JSON.parse(p);
    }
    catch {
        return [];
    }
}

export const getUrlParameters = (parameters: string): string => {
    let url: string = '';
    try {
        for (const p of getParameters(parameters).filter(q => !q.global)) {
            url = `${url}${url.length > 0 ? '&' : '?'}${p.key}=${p.value}`;
        }
    }
    catch { }
    return url;
}

