import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../../core/components/form/OEButton';
import OENotification from '../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../core/components/messaging/entities/Notification';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { Icon } from '../../../core/entities/Icon';
import { formatDateTime } from '../../../core/utilities/Date';
import { defaultReportAccessLog } from '../../entities/ReportAccessLog';
import { IReportFilter, IReportFilterSelectionValues, ReportFilterType } from '../../../report-microstrategy/entities/ReportFilter';
import { ISiteReport, defaultSiteReport } from '../../entities/SiteReport';
import { ISiteReportResults, defaultSiteReportResults } from '../../entities/SiteReportResults';
import { defaultViewReport } from '../../entities/ViewReport';
import { useLogAccess } from '../../services/ReportService';
import { useGetSiteReport, useGetSiteReportFilters, useViewSiteReport } from '../../services/SiteReportService';
import SiteReportFilters from './SiteReportFilters';

interface IComponentInfo {
    item: ISiteReport;
}

const SiteReport: React.FunctionComponent<IComponentInfo> = ({ item }) => {
    const { service: reportService, setId } = useGetSiteReport('');
    const { service: viewService, setReport: setViewReport } = useViewSiteReport();
    const { service: filterService, setReport: setFilterReport } = useGetSiteReportFilters();

    const { setItem: logReport } = useLogAccess();
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [results, setResults] = useState<ISiteReportResults>(defaultSiteReportResults);
    const [report, setReport] = useState<ISiteReport>(defaultSiteReport);
    const [header, setHeader] = useState<string>('');
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);
    const [filterValues, setFilterValues] = useState<IReportFilterSelectionValues[]>([]);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result);
        }
    }, [reportService]);

    useEffect(() => {
        if (viewService.isFinished) {
            if (viewService.data.data.columns.length > 0) {
                setReport({ ...report, columns: viewService.data.data.columns });
            }
            setResults(viewService.data.data);
        }
        // eslint-disable-next-line
    }, [viewService]);

    useEffect(() => {
        if (filterService.isFinished) {
            if (filterService.data) {
                setFilterValues(filterService.data.data);
            }
            else {
                setNotification({ message: `Error occurred loading filters for report.`, type: 'error' })
            }
        }
    }, [filterService]);

    useEffect(() => {
        if (report.filterProcedure && report.filterProcedure !== '' && filterValues.length === 0) {
            setFilterReport(report);
        }
        const c: IColumn[] = [];
        for (const i of report.columns) {
            if (i.reportColumnTypeId === ColumnType.Date) {
                c.push({
                    id: i.name, name: i.header, type: ColumnType.String, sort: true,
                });

                for (const d of results.data) {
                    d[i.name] = formatDateTime(d[i.name], 'mm/dd/yyyy st');
                }
            }

            else {
                if (i.properties && i.properties.length > 0) {
                    const props: IColumn = JSON.parse(i.properties.replace(/'/g, '"'));
                    c.push({
                        ...props, id: i.name, name: i.header, type: i.reportColumnTypeId, sort: true,
                    });
                }
                else {
                    c.push({
                        id: i.name, name: i.header, type: i.reportColumnTypeId, sort: true,
                    });
                }
            }
        }
        setColumns(c);

        setReportFilters([...report.reportFilters]);
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (item.id !== '') {
            setId(item.id);
        }
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        let h: string = results.header;
        if (results.data.length > 0) {
            for (const c of reportFilters) {
                if (c.reportFilterTypeId === ReportFilterType.Dropdown) {
                    h = h.replace(`{${c.name}:value}`,
                        filterValues.filter(q => q.name === c.name)[0].values.filter(q => q.id === c.value)[0].name);
                }
            }
        }
        else {
            h = '';
        }
        setHeader(h);
        // eslint-disable-next-line
    }, [results]);

    useEffect(() => {
        // if filters have cascading filters we need to make sure all filter values are valid
        if (report.reportFilters.filter(q => q.cascading).length > 0) {
            for (const v of filterValues) {
                for (const x of report.reportFilters) {
                    if (x.name === v.name) {
                        if (v.values.filter(q => q.id === x.value).length === 0) {
                            x.value = '';
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [filterValues]);

    const updateFilter = (i: number, v: string) => {
        report.reportFilters[i - 1].value = v;
        if (report.reportFilters[i - 1].cascading) setFilterReport(report);
    };

    const onReportRun = () => {
        setShowReport(true);
        logReport({ ...defaultReportAccessLog, reportId: report.id, parameters: JSON.stringify(report.reportFilters) });
        setViewReport({ ...defaultViewReport, id: report.id, reportFilters: report.reportFilters });
    };

    const onResetFilters = () => {
        const filters: IReportFilter[] = report.reportFilters;
        for (const i of filters) {
            i.value = i.defaultValue;
        }
        setReportFilters([...filters]);
        setViewReport({ ...defaultViewReport, id: report.id, reportFilters: filters });
    };


    return (
        <>
            <OENotification setNotification={setNotification} notification={notification} />
            <div>
                <h5>{report.title}</h5>
                <div className="site-report-filter-section">
                    <div className="site-report-filters">
                        <SiteReportFilters filters={reportFilters} filterValues={filterValues} updateFilter={updateFilter} />
                        <div className="m-l-40" style={{ marginTop: '25px' }} >
                            <OEButton onClick={onReportRun} icon={Icon.Run} text="View Report" />
                            <OEButton onClick={onResetFilters} hidden={report.reportFilters.length === 0} bStyle={ButtonStyle.Cancel} icon={Icon.Reset} text="Reset Filters" />
                        </div>
                    </div>
                </div>
                {showReport && (
                    <div className="site-report-results">
                        <div className="site-report-totals">
                            {header}
                        </div>
                        <OETable
                            loading={viewService.isInProgress}
                            loadingMessage="Loading Report"
                            data={results.data}
                            columns={columns}
                            exportFilename={report.title}
                            showPagination={true}
                            showExport={true}
                            defaultSort="name"
                            defaultPageSize={25}
                            noDataMessage={`No data found`}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default SiteReport;