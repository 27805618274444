import axios from 'axios';
import { INotification } from '../components/messaging/entities/Notification';
import { defaultRequestResponse, IRequestResponse } from '../entities/RequestResponse';
import { getAPIError } from '../utilities/APIError';
import { scrollToTop } from '../utilities/URL';
import { getAuthorizationToken } from './Authentication';

const config = {
    headers: {
        'RequestVerificationToken': `${getAuthorizationToken()}`
    }
};

export interface IPostRequest {
    success: boolean;
    data: any;
    response: IRequestResponse;
}

export class PostRequest {
    public static empty(): PostRequest {
        return new PostRequest(
            false,
            false,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static inProgress(): PostRequest {
        return new PostRequest(
            true,
            true,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static success(data: any): PostRequest {
        return new PostRequest(
            false,
            true,
            true,
            data,
            defaultRequestResponse
        );
    }

    public static error(error: string): PostRequest {
        return new PostRequest(
            false,
            false,
            true,
            undefined,
            { ...defaultRequestResponse, message: error }
        );
    }

    constructor(
        public isInProgress: boolean,
        public isSuccess: boolean,
        public isFinished: boolean,
        public data: any,
        public response: IRequestResponse
    ) { }
}

export const processPostAsync = (setService: (p: PostRequest) => void, url: string, postData?: any) => {
    // tslint:disable-next-line:no-floating-promises
    postAsync(setService, url, postData);
};

const postAsync = async (setService: (p: PostRequest) => void, url: string, postData?: any) => {
    try {
        setService(PostRequest.inProgress());

        const result = await axios.post<IPostRequest>(`${url}`, postData, config);
        setService(PostRequest.success(result));
    } catch (e: any) {
        setService(PostRequest.error(getAPIError(e)));
    }
};

interface IProcessPostRequest {
    service: any;
    setNotification?: (i: INotification) => void;
    successMessage?: string;
    successAction?: any;
    scroll?: boolean;
    errorMessage?: string;
}

export const processPostRequest = (item: IProcessPostRequest) => {
    // tslint:disable-next-line:no-floating-promises
    if (item.service.isFinished) {
        if (item.service.isSuccess) {
            item.scroll && scrollToTop();
            item.successMessage && item.setNotification?.({ type: 'success', message: item.successMessage });
            item.successAction && item.successAction();
        }
        else {
            item.errorMessage && item.setNotification?.({ message: item.errorMessage, type: 'error', });
        }
    }
};
