import React, { Fragment, useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import OELink from '../../../core/components/general/OELink';
import OEWell, { WellStyle } from '../../../core/components/general/OEWell';
import { OECol } from '../../../core/components/grid/OECol';
import { Icon } from '../../../core/entities/Icon';
import { IReportGroup } from '../../entities/ReportGroup';
import { getReportList, IReportList } from '../../entities/ReportList';
import { useGetReportLists } from '../../services/ReportListService';

interface IComponentInfo {
    item: IReportGroup;
    index: number;
}

const Group: React.FunctionComponent<IComponentInfo> = ({ item }) => {
    const { service, setGroupId } = useGetReportLists();

    const [items, setItems] = useState<IReportList[]>([]);

    useEffect(() => {
        if (service.result) {
            setItems(getReportList(service.result));
        }
    }, [service]);

    useEffect(() => {
        setGroupId(item.id);
        // eslint-disable-next-line
    }, [item]);


    return (
        <OECol sm={4} >
            <OEWell oeStyle={WellStyle.BorderedShaded} className="report-header" >
                <OEHeading className="p-t-10" text={item.title} size={4} icon={Icon.ReportGroup} />
            </OEWell>
            <div className="m-l-10">
                {items.filter(q => q.isActive && q.reportActive).map((report, index) =>
                    <Fragment key={index}>
                        <OELink
                            title={report.description}
                            href={report.path}
                            className="m-t-10"
                            tooltipClass="large-tooltip"
                            tooltipPlacement="right"
                            text={report.title}
                        />
                        <br />
                    </Fragment>
                )}
                {items.filter(q => q.isActive && q.reportActive).length === 0 && (
                    <div className="no-reports">
                        There are no active reports for this group.
                    </div>
                )}
            </div>
        </OECol>
    );
};


export default Group;