import React from 'react';
import OEIcon from '../../core/components/general/OEIcon';
import OELink from '../../core/components/general/OELink';
import { Icon } from '../../core/entities/Icon';
import { ISecurityUser } from '../entities/SecurityUser';

interface IComponentInfo {
    item: ISecurityUser;
    onDefault?: (i: ISecurityUser) => void;
    onDelete?: (i: ISecurityUser) => void;
}

const UserPermission: React.FunctionComponent<IComponentInfo> = ({ item, onDefault, onDelete }) => {
    const onDefaultClick = () => {
        onDefault && onDefault(item);
    }

    const onDeleteClick = () => {
        onDelete && onDelete(item);
    }

    return (
        <>
            <div className={`${item.isDefault ? 'permissionsDivSelectedStyle' : 'permissionsDivStyle'}`}>
                {onDelete && (
                    <OELink hidden={item.isDefault} onClick={onDeleteClick} title="Remove Permission" className="m-t-5 pull-right" >
                        <OEIcon icon={Icon.Delete} />
                    </OELink>
                )}
                {!item.isDefault && onDefault && (
                    <OELink onClick={onDefaultClick} title="Set As Default" className="m-t-5 pull-right" >
                        <OEIcon icon={Icon.Checked} />
                    </OELink>
                )}
                <div style={{ fontSize: '14px', margin: '5px' }} >
                    <b>{`${item.organizationUnit} `}</b>
                    <span style={{ fontSize: '11px' }} >{`- ${item.importID}`}</span><br />
                    <span style={{ fontSize: '13px' }} ><i>{`${item.role} ${item.isDefault ? ' - default' : ''}`}</i></span>
                </div>
            </div>
        </>
    );
};

export default UserPermission;
