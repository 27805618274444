import { useEffect, useState } from 'react';
import { IUserMessageAlert } from '../entities/UserMessageAlert';
import { useGetUserMessageAlertHistory } from '../services/UserMessageAlertService';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import OETable from '../../core/components/table/OETable';
import OEHeading from '../../core/components/general/OEHeading';

const AlertUserMessageHistoryList: React.FunctionComponent = () => {
    const { service: getAlertService } = useGetUserMessageAlertHistory();

    const [alerts, setAlerts] = useState<IUserMessageAlert[]>([]);

    useEffect(() => {
        if (getAlertService.result) {
            setAlerts(getAlertService.result.items);
        }
    }, [getAlertService]);

    const columns: IColumn[] = [
        { id: 'subject', name: 'Subject', sort: true, type: ColumnType.String },
        { id: 'body', name: 'Body', sort: true, type: ColumnType.String, },
        { id: 'creationTime', name: 'Date', sort: true, type: ColumnType.Date, },
        { id: 'creationTime', name: 'Time', sort: true, type: ColumnType.Time, },
    ];

    return <div className="container">
        <OEHeading className="m-b-20 m-t-10" text="Message History" />
        <OETable
            loading={getAlertService.isInProgress}
            loadingMessage="Loading Messages"
            data={alerts}
            columns={columns}
            showPagination={(alerts?.length || 0) > 10}
            defaultSort="name"
            noDataMessage="No Messages exist"
            defaultPageSize={10}
        />
    </div>
};

export default AlertUserMessageHistoryList;