import { IReportFilter } from '../../report-microstrategy/entities/ReportFilter';

export interface IViewReport {
    id: string;
    reportFilters: IReportFilter[];
}

export const defaultViewReport: IViewReport = {
    id: '', reportFilters: []
};
