import BasePagedAndSortedResultRequestEntity from "../../core/entities/BasePagedAndSortedResultRequestEntity";
import { getEmptyGUID } from "../../core/utilities/String";

export interface IUserMessageAlert extends BasePagedAndSortedResultRequestEntity {
    id: string;
    filterText?: string;
    subject?: string;
    body?: string;
    sorting?: string;
    skipCount?: string;
    creationTime: string;
    isRead: boolean
}

export const defaultUserMessageAlert: IUserMessageAlert = {
    id: getEmptyGUID(),
    filterText: undefined,
    subject: undefined,
    body: undefined,
    sorting: undefined,
    skipCount: undefined,
    maxResultCount: 1000,
    creationTime: "",
    isRead: false
};

export const defaultUserMessageAlerts: IUserMessageAlert[] = [];