
export const ReportIconBaseURL = "/images/reporting/";

export interface IReportIcon {
    filename: string;
}

export const ReportIcons: IReportIcon[] =
    [
        { filename: 'Accountability/icons/1-alternate-test-rule.png' },
        { filename: 'Accountability/icons/95-testing-participation.png' },
        { filename: 'Accountability/icons/AbouttheSchool.png' },
        { filename: 'Accountability/icons/AppropriateBehaviors.png' },
        { filename: 'Accountability/icons/AttendenceRate.png' },
        { filename: 'Accountability/icons/ChoiceReady.png' },
        { filename: 'Accountability/icons/ChronicAbsenteeismrate.png' },
        { filename: 'Accountability/icons/CompletionRate.png' },
        { filename: 'Accountability/icons/Compliance.png' },
        { filename: 'Accountability/icons/Demographics.png' },
        { filename: 'Accountability/icons/DisproportionateRepresentation.png' },
        { filename: 'Accountability/icons/DropoutRate.png' },
        { filename: 'Accountability/icons/EducatorQualifications.png' },
        { filename: 'Accountability/icons/el-student-performance.png' },
        { filename: 'Accountability/icons/EnglishLearnerProgress.png' },
        { filename: 'Accountability/icons/ESSACostPerPupil.png' },
        { filename: 'Accountability/icons/Evaluation&Transition.png' },
        { filename: 'Accountability/icons/FederalProgramsIcon15.png' },
        { filename: 'Accountability/icons/GradDropoutSSIP.png' },
        { filename: 'Accountability/icons/GraduationRate.png' },
        { filename: 'Accountability/icons/iep-student-performance.png' },
        { filename: 'Accountability/icons/KeyPerformanceIndicators.png' },
        { filename: 'Accountability/icons/KnowledgeandSkills.png' },
        { filename: 'Accountability/icons/LeastRestrictiveEnvironment.png' },
        { filename: 'Accountability/icons/LongTermGoals1.png' },
        { filename: 'Accountability/icons/LongTermGoals2.png' },
        { filename: 'Accountability/icons/OverallScore.png' },
        { filename: 'Accountability/icons/PostSecondaryOutcomes.png' },
        { filename: 'Accountability/icons/SchoolPerformanceIndex.png' },
        { filename: 'Accountability/icons/SocialEmotionalSkills.png' },
        { filename: 'Accountability/icons/StudentAchievement.png' },
        { filename: 'Accountability/icons/StudentEngagement.png' },
        { filename: 'Accountability/icons/StudentGrowth.png' },
        { filename: 'Accountability/icons/SuspensionExpulsion.png' },
        { filename: 'Accountability/icons/TestingAccommodations.png' },
        { filename: 'Financial/icons/CapitalProjectsPerPupil.png' },
        { filename: 'Financial/icons/CostofEducationPerPupil.png' },
        { filename: 'Financial/icons/debtperpupil.png' },
        { filename: 'Financial/icons/ExpendituresandOutcomesAnalysis.png' },
        { filename: 'Financial/icons/ExtracurricularCostPerPupil.png' },
        { filename: 'Financial/icons/FederalPrograms.png' },
        { filename: 'Financial/icons/foodservicebalancesheet.png' },
        { filename: 'Financial/icons/foodserviceexpenditure.png' },
        { filename: 'Financial/icons/foodservicerevenue.png' },
        { filename: 'Financial/icons/FoundationAidPerWSU.png' },
        { filename: 'Financial/icons/FoundationAidPerWSU_old.png' },
        { filename: 'Financial/icons/fundgroup1costperpupil.png' },
        { filename: 'Financial/icons/RevenuePerPupil.png' },
        { filename: 'Financial/icons/TaxableValuationPerPupil.png' },
        { filename: 'Financial/icons/transportationcostperpupil.png' },
        { filename: 'academic_performance_icon.svg' },
        { filename: 'academic_performance_icon_g.svg' },
        { filename: 'home_icon.svg' },
        { filename: 'home_icon_g.svg' },
        { filename: 'cte_icon.svg' },
        { filename: 'cte_icon_g.svg' },
        { filename: 'educational_opportunity_icon.svg' },
        { filename: 'educational_opportunity_icon_g.svg' },
        { filename: 'financial_transparency_icon.svg' },
        { filename: 'financial_transparency_icon_g.svg' },
        { filename: 'school_accountability_icon.svg' },
        { filename: 'school_accountability_icon_g.svg' },
        { filename: 'school_overview_icon.svg' },
        { filename: 'school_overview_icon_g.svg' },
        { filename: 'school_safety_icon.svg' },
        { filename: 'school_safety_icon_g.svg' },
        { filename: 'transition_readiness_icon.svg' },
        { filename: 'transition_readiness_icon_g.svg' },
    ];

