import React, { Children } from 'react';

interface IComponentInfo {
    debug: boolean;
    small?: boolean;
    children?: any;
}

const DebugMessage: React.FunctionComponent<IComponentInfo> = ({ children, debug, small }) => {
    return (
        <>
            {debug && !small && (
                <h5 className="text-success" style={{ background: '#eee', border: '1px solid', borderRadius: '15px', padding: '6px', position: 'absolute', top: '26px', left: '340px' }} >
                    {Children.map(children, (i) => i)}
                </h5>
            )}
            {debug && small && (
                <div style={{ fontSize: '10px', background: '#eee', border: '1px solid', borderRadius: '15px', padding: '6px' }} className="text-success m-l-10">
                    {Children.map(children, (i) => i)}
                </div>
            )}
        </>
    );
};

export default DebugMessage;
