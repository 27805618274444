import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormInputType, IFormInfo } from '../../core/components/formik/entities/Form';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import OEHeading from '../../core/components/general/OEHeading';
import OEIcon from '../../core/components/general/OEIcon';
import { ModalSize } from '../../core/components/general/OEModal';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { INotification } from '../../core/components/messaging/entities/Notification';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import OEMessage from '../../core/components/messaging/OEMessage';
import { ICSVSelectItem } from '../../core/entities/CSVSelectItem';
import { Icon } from '../../core/entities/Icon';
import { IArtifactDatasetViewColumns } from '../entities/ArtifactDatasetViewColumns';
import { IDatasetCategory } from '../entities/DatasetCategory';

interface ISelectorChoseItem {
    item: ICSVSelectItem;
    onSelect: (i: ICSVSelectItem) => void;
}

const SelectorChooseItem: React.FunctionComponent<ISelectorChoseItem> = ({ item, onSelect }) => {

    const onClick = () => {
        onSelect(item);
    }

    return (
        <span style={{ fontSize: '11px' }}>
            <div onClick={onClick} className={`${item.isSelected ? 'permissionsDivSelectedStyle' : 'permissionsDivStyle'}`}>
                {item.isSelected &&
                    <OEIcon icon={Icon.ArrowRight} className="m-t-5 pull-right" />
                }
                {item.value}
                {!item.isSelected &&
                    <OEIcon icon={Icon.ArrowLeft} className="m-t-5 pull-left" />
                }
            </div>
        </span>
    );
};


const Form: React.FunctionComponent<FormikProps<IDatasetCategory> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    const [localValues, setLocalValues] = useState<ICSVSelectItem[]>([]);

    useEffect(() => {
        const i: ICSVSelectItem[] = [];
        for (const v of lookupTables) {
            i.push({ value: v.displayName, isSelected: values.categoryColumns.filter((q: any) => q === v.displayName).length > 0 });
        }
        setLocalValues(i);
        // eslint-disable-next-line
    }, [values]);

    const onUpdateSelection = (i: ICSVSelectItem) => {
        const t: ICSVSelectItem[] = [...localValues];
        const s: string[] = [];
        for (const v of t) {
            if (i.value === v.value) {
                v.isSelected = !v.isSelected;
            }
            if (v.isSelected) {
                s.push(v.value);
            }
        }
        setFieldValue('categoryColumns', [...s]);
        setLocalValues([...t]);
    }
    return (
        <>
            <OEFormInput
                label="Name"
                name="categoryName"
                value={values.categoryName}
                errors={errors}
                touched={touched}
                columns={12}
                required={true}
                setFieldValue={setFieldValue}
                inputType={FormInputType.String}
            />

            <OERow className="m-t-20">
                <OECol sm={6}>
                    <OEHeading size={5} underline={true} text="Selected Columns:" />
                    <OERow>
                        {localValues.filter(q => q.isSelected === true).map((item: any, index: any) =>
                            <SelectorChooseItem onSelect={onUpdateSelection} item={item} key={index} />
                        )}
                        {localValues.filter(q => q.isSelected).length === 0 && (
                            <OEMessage type={MessageType.Success} hideDismissable={true} message="No columns are selected" />
                        )}
                    </OERow>
                </OECol>
                <OECol sm={6}>
                    <OEHeading underline={true} size={5} text="Available Columns" />
                    <OERow>
                        {localValues.filter(q => !q.isSelected).map((item, index) =>
                            <OECol sm={6}>
                                <SelectorChooseItem onSelect={onUpdateSelection} item={item} key={index} />
                            </OECol>
                        )}
                        {localValues.filter(q => !q.isSelected).length === 0 && localValues.length > 0 && (
                            <OECol sm={6}>
                                <OEMessage type={MessageType.Information} hideDismissable={true} message="All columns have been selected" />
                            </OECol>
                        )}
                        {localValues.length === 0 && (
                            <OECol sm={6}>
                                <OEMessage type={MessageType.Information} hideDismissable={true} message="No columns have been added." />
                            </OECol>
                        )}
                    </OERow>
                </OECol>
            </OERow>

        </>
    );
};

const ValidationScheme = Yup.object<IDatasetCategory>().shape({
    categoryName: Yup.string().required('Name is required').nullable()
});

interface IFormikInfo {
    item: IDatasetCategory;
    existing: IDatasetCategory[];
    columnData: IArtifactDatasetViewColumns[];
    setNotification: (n: INotification) => void;
    onCancel: () => void;
    onSave: (item: IDatasetCategory) => void;
}

const CategoryFormik: React.FunctionComponent<IFormikInfo> = ({
    item, onCancel, onSave, existing, setNotification, columnData
}) => {

    const setArtifact = (i: IDatasetCategory) => {
        if (i.adding && existing.filter(q => q.categoryName === i.categoryName).length > 0) {
            setNotification({ message: `Category Name already exists`, type: 'error' })
        }
        else {
            onSave(i);
        }
    };


    return (
        <OEFormikModal
            oeSize={ModalSize.Medium}
            item={item}
            labelColumns={12}
            title={`${!item.adding ? `Edit Category: ${item.categoryName}` : `Add New Category`}`}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            lookupTables={columnData}
            onCancel={onCancel}
            setItem={setArtifact}
        />
    );
};

export default CategoryFormik;