import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';

export interface IDcFileRequest extends IBaseAuditedEntity {
    agencyId: string;
    fileTypeId: string;
    term: string;
    name: string;
    description: string;
    requestStatus: number;
}

export interface IDcAgency extends IBaseAuditedEntity {
    code: string;
    name: string;
    description: string;
    agencyStatus: number;
    azureSasUrl: string;
    azureFolder: string;
    azureDataSource: string;
    agencyTypeId: number;
    organizationUnitId: string;
}

export interface IDcFileType extends IBaseAuditedEntity {
    code: string;
    name: string;
    description: string;
    customProperty: string;
    fileTypeCategoryId: number;
    fileTypeGroupId: number;
}

export interface IDcFileTypeCategory {
    id: string;
    name: string;
    description: string;
}

export interface IDcFileTypeGroup {
    id: string;
    name: string;
    description: string;
}

export interface IDcFileRequestWithNavigationProperties {
    dcFileRequest: IDcFileRequest;
    dcAgency: IDcAgency;
    dcFileType: IDcFileType;
    dcFileTypeCategory?: IDcFileTypeCategory;
    dcFileTypeGroup?: IDcFileTypeGroup;
}

export interface IDcFileUpload {
    id: string;
    agencyCode: string;
    agencyAzureDataSource: string;
    agencyAzureFolder: string;
    azureFileUrl?: string;
    agencyName: string;
    fileTypeCode: string;
    fileTypeName: string;
    fileTypeCategoryName?: string;
    fileTypeGroupName?: string;
    uploadedFileName?: string;
    uploadedFile?: string;
    term?: string;
    uploadedRows?: number;
    uploadedOn?: string;
    updatedBy?: string;
    hasHeader: boolean;
}

export const defaultDcFileUpload: IDcFileUpload = {
    id: '',
    agencyCode: '',
    agencyAzureDataSource: '',
    agencyAzureFolder: '',
    azureFileUrl: '',
    agencyName: '',
    fileTypeCode: '',
    fileTypeName: '',
    uploadedFileName: '',
    uploadedFile: '',
    term: '',
    uploadedRows: 0,
    uploadedOn: '',
    updatedBy: '',
    hasHeader: false,
};
