export interface IValidationErrors {
    message: string;
    members: string[];
}

export interface IRequestResponse {
    status: number;
    message: string;
    details: string;
    data: any;
    validationErrors: IValidationErrors[];
}

export const defaultRequestResponse: IRequestResponse = {
    status: 0, message: '', details: '', data: null, validationErrors: []
};

