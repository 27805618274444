import React, { useEffect, useState } from 'react';
import OEFilter from '../../core/components/filter/OEFilter';
import { FilterDataType, FilterType, IFilter, IFilterValue } from '../../core/components/filter/entities/Filter';
import { getFilterValue, getSavedFilterValues } from '../../core/components/filter/entities/FilterSave';
import { ButtonStyle } from '../../core/components/form/OEButton';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { IConfirmationMessage, defaultConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { Icon } from '../../core/entities/Icon';
import { GetArtifactsInput } from '../entities/GetArtifactsInput';
import artifactLookup from '../lookups/artifact-types.json';
import { useCompileArtifacts } from '../services/ArtifactService';

type Props = {
    onFilter: (i: GetArtifactsInput) => void;
    onAdd: () => void;
};

const ArtifactFilters: React.FunctionComponent<Props> = ({ onFilter, onAdd }) => {
    const { service, setCompile } = useCompileArtifacts();

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (service.result) {
            if (service.result === "Success") {
                setNotification({ message: `Compile Process Started Successfully`, type: 'success' })
            }
            else {
                setNotification({ message: service.result, type: 'warning' })
            }
        }
    }, [service]);

    const filter: IFilter = {
        name: 'artifactFilter',
        autoSearch: true,
        filters: [
            { name: 'displayName', label: 'Display Name', width: 200, placeholder: 'Search by display Name' },
            { name: 'application', label: 'Application', width: 200, placeholder: 'Search by application' },
            { name: 'artifactType', autoSearch: true, width: 200, type: FilterType.Dropdown, values: artifactLookup, label: 'Artifact Type' },
            { name: 'isPublished', autoSearch: true, dataType: FilterDataType.Boolean, width: 100, type: FilterType.YesNo, label: 'Is Published' },
        ],
    };

    useEffect(() => {
        handleFilter(getSavedFilterValues(filter));
        // eslint-disable-next-line
    }, []);


    const handleFilter = (i: IFilterValue[]) => {
        onFilter({
            displayName: getFilterValue(filter, i, 'displayName'),
            application: getFilterValue(filter, i, 'application'),
            artifactType: getFilterValue(filter, i, 'artifactType'),
            isPublished: getFilterValue(filter, i, 'isPublished'),
        });
    };

    const onConfirmCompile = () => {
        setConfirmation({
            setConfirmation,
            show: true,
            title: 'Compile Changes?',
            message: `Are you sure you want to start the Artifact Compile Process?`,
            onCancel: onCancel,
            onOk: onCompile,
        });
    };

    const onCancel = () => {
    };

    const onCompile = () => {
        setCompile(true);
    };

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OENotification setNotification={setNotification} notification={notification} />
            <OEFilter
                singleLine={true}
                onSubmit={handleFilter}
                submitOnReset={true}
                className="m-t-0"
                filter={filter}
                actionsNewLine={true}
                actionList={[
                    { action: onAdd, text: 'Add New', icon: Icon.Add },
                    { action: onConfirmCompile, text: 'Compile', icon: Icon.Compile, bStyle: ButtonStyle.Secondary },
                ]}
            />
        </>
    );
};

export default ArtifactFilters;