export const getFormValue = (obj: Record<string, any>, name: string): any => {
    if (!name) return name;

    const parts = name.split('.');

    for (var i = 0, iLen = parts.length - 1; i < iLen; i++) {
        const part = parts[i];
        var test = obj[part] as Object;

        if (test !== undefined) {
            obj = test;
        } else {
            break;
        }
    }

    return obj[parts[i]] ?? '';
};

export const getFormControlTouched = (obj: Record<string, any>, name: string): string => {
    const isTouched =  getFormValue(obj, name);
    return isTouched === true ? 'true' : '';
}