import { useEffect, useState } from "react";
import { IBaseList } from "../../core/entities/BaseList";
import { GetRequest, processGet, processGetAsync } from "../../core/services/GetRequest";
import { IDatasetConnection, defaultDatasetConnection, getDatasetConnectionForSave } from "../entities/DatasetConnection";
import { GetDatasetConnectionsInput } from "../../dataset-connections/entities/GetDatasetConnectionsInput";
import { DeleteRequest, processDeleteAsync } from "../../core/services/DeleteRequest";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { PutRequest, processPutAsync } from "../../core/services/PutRequest";
import { DatasetSourceType } from "../entities/DatasetSourceType";

export const useGetDatasetConnections = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IDatasetConnection>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);
    const [filters, setFilters] = useState<GetDatasetConnectionsInput>({});

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, getUrl('/api/app/dataset-connections', filters));
        setRefresh(false);
    }, [filters, filters.displayName, filters.datasetSourceType, filters.databaseSchema, refresh]);

    return { service, doRefresh, setFilters };
};

export const useDeleteDatasetConnection = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() => {
        if (deleteId < 1) { return; }
        processDeleteAsync(setService, `/api/app/dataset-connections/${deleteId}`);
    }, [deleteId]);

    return { service, setDeleteId };
};

export const usePostDatasetConnection = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [datasetConnection, setDatasetConnection] = useState<IDatasetConnection>(defaultDatasetConnection);

    useEffect(() => {
        if (!datasetConnection.displayName) { return; }
        processPostAsync(setService, `/api/app/dataset-connections`, getDatasetConnectionForSave(datasetConnection));
        setDatasetConnection(defaultDatasetConnection);
    }, [datasetConnection]);

    return { service, setDatasetConnection };
};

export const usePutDatasetConnection = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [datasetConnection, setDatasetConnection] = useState<IDatasetConnection>(defaultDatasetConnection);

    useEffect(() => {
        if (!datasetConnection.displayName) { return; }
        processPutAsync(setService, `/api/app/dataset-connections/${datasetConnection.id}`, getDatasetConnectionForSave(datasetConnection));
        setDatasetConnection(defaultDatasetConnection);
    }, [datasetConnection]);

    return { service, setDatasetConnection };
};

export const useTestDatasetConnection = () => {
    const [datasetConnectionTest, setDatasetConnectionTest] = useState<IDatasetConnection>(defaultDatasetConnection);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [doTest, setDoTest] = useState(false);
    const testConnection = () => setDoTest(true);

    useEffect(() => {
        (async () => {
            if (!doTest) { return; }
            const url = datasetConnectionTest.datasetSourceType === DatasetSourceType.SqlServer ? '/api/app/dataset-connections/test-sql-connection' : '/api/app/dataset-connections/test-snowflake-connection';
            const isValid = await processGet(getUrl(url, { id: datasetConnectionTest.id, connectionString: datasetConnectionTest.connectionString }));
            setIsValid(isValid);
            setDoTest(false);
            setIsValid(null);
        })();
    }, [datasetConnectionTest.id, datasetConnectionTest.connectionString, datasetConnectionTest.datasetSourceType, doTest]);

    return { testConnection, setDatasetConnectionTest, doTest, isValid };
}

const getUrl = (url: string, parameters: Record<string, any>): string => { //TODO: move to core
    if (!parameters) { return url; }

    const params = Object.keys(parameters)
        .filter(key => parameters[key])
        .map(key => `${key}=${parameters[key]}`)
        .join('&');

    return `${url}?${params}&MaxResultCount=1000`;
}

