import React from 'react';
import { DictionaryIframe } from './DictionaryIframe';

export const AnalystDictionary: React.FunctionComponent = () => {
    return (
        <>
            <DictionaryIframe
                title="Analyst Dictionary"
                url="https://dictionary.synergyz.otised.net/dictionaries/17/remote/analyst-view?tenantId=ad053008-0328-41fa-89a3-53a0135f220a"
            />
        </>
    );
};
