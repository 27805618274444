import React from 'react';

interface IComponentInfo {
    name: string;
    className?: string;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
    value?: any;
    selectedValue: any;
    text: string;
}

const OECheckbox: React.FunctionComponent<IComponentInfo> = ({
    name, className, onChange,
    disabled, value, text, selectedValue
}) => {


    const handleChange = (event: any) => {
        onChange?.(value);
    };

    return (
        <>
            <div className={`mb-3 form-check ${className || ''}`}>
                <input
                    disabled={disabled}
                    value={value}
                    type="checkbox"
                    onChange={handleChange}
                    className="form-check-input"
                    checked={selectedValue === value}
                    id={name}
                />
                <label className="form-check-label" htmlFor={name}>{text}</label>
            </div>
        </>
    );
};

export default OECheckbox;