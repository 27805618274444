import React, { useEffect, useState } from 'react';
import { ColumnType, IColumn } from '../core/components/table/entities/Column';
import { IColumnAction } from '../core/components/table/entities/ColumnAction';
import OETable from '../core/components/table/OETable';
import { Icon } from '../core/entities/Icon';

interface ITableExample {
    textCol: string;
    integerCol: number;
    decimalCol: number;
    currencyCol: number;
}

const Table: React.FunctionComponent = () => {

    const [data, setData] = useState<ITableExample[]>([]);

    useEffect(() => {
        const d: ITableExample[] = [];
        let i: number = 1;
        while (i < 100) {
            d.push({ textCol: `column ${i}`, integerCol: 200 - i, decimalCol: i * 0.45, currencyCol: (200 - i) * (i * .45) });
            i = i + 1;
        }
        setData(d);
    }, []);

    const editItem = (i: ITableExample) => {
        console.log(`${i.textCol} will be edited`);
    };

    const deleteItem = (i: ITableExample) => {
        console.log(`${i.textCol} will be deleted`);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: editItem, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: deleteItem, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', width: '10px', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'textCol', name: 'Text Column', sort: true, type: ColumnType.Link, onClick: editItem, helpText: 'Edit User' },
        { id: 'integerCol', name: 'Integer Column', sort: true, type: ColumnType.Integer, className: 'text-right', subTotal: true },
        { id: 'decimalCol', name: 'Decimal Column', sort: true, type: ColumnType.Decimal, className: 'text-right', subTotal: true },
        { id: 'currencyCol', name: 'Currency Column', sort: true, type: ColumnType.Currency, className: 'text-right', subTotal: true },
    ];

    return (
        <OETable
            data={data}
            columns={columns}
            includeRowNumber={true}
            showPagination={true}
            defaultSort="textCol"
            defaultPageSize={10}
        />

    );
};

export default Table;