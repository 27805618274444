import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { getParameters, OEParameterType } from '../../../core/components/parameters/entities/OEParameter';
import OEParameters from '../../../core/components/parameters/OEParameters';
import { Icon } from '../../../core/entities/Icon';
import { useGetRoutes } from '../../../site/services/RouteService';
import { defaultReportLookupTables, IReportLookupTables } from '../../entities/ReportLookupTables';
import { getReportServerList } from '../../entities/ReportServer';
import { IReportType, ReportType } from '../../entities/ReportType';
import { useGetReportServers } from '../../services/ReportServerService';
import { useCreateReportType, usePutReportType } from '../../services/ReportTypeService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
    lookupTables: IReportLookupTables;
}

const Form: React.FunctionComponent<FormikProps<IReportType> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    return (
        <>
            <OEFormDropdown
                label="Report Server" name="reportServerId" value={values.reportServerId}
                defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables.servers}
                setFieldValue={setFieldValue} required={true}
            />

            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Description" name="description" value={values.description}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />

            <OEFormDropdown
                label="Route" name="routeId" value={values.routeId}
                defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables.routes}
                setFieldValue={setFieldValue} required={true}
            />

            {values.id && values.id !== ReportType.SiteReport && (
                <OEParameters
                    values={getParameters(values.parameters).filter(q => q.type === OEParameterType.URL)}
                    setFieldValue={setFieldValue}
                    type={OEParameterType.URL}
                />
            )}

        </>
    );
};

const ValidationScheme = Yup.object<IReportType>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IReportType;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportTypeFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReportType();
    const { service: createService, setItem: setCreateReportType } = useCreateReportType();
    const { service: serverService } = useGetReportServers();
    const { service: routeService } = useGetRoutes();

    const [lookups, setLookups] = useState<IReportLookupTables>(defaultReportLookupTables);

    useEffect(() => {
        if (routeService.result) {
            setLookups({ ...lookups, routes: routeService.result.items });
        }
        // eslint-disable-next-line
    }, [routeService]);

    useEffect(() => {
        if (serverService.result) {
            setLookups({ ...lookups, servers: getReportServerList(serverService.result) });
        }
        // eslint-disable-next-line
    }, [serverService]);

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            lookupTables={lookups}
            title={`${item.id === '' ? `Add New Report Type` : `Edit Report Type: ${item.name}`}`}
            progressMessage={ProgressMessages.ReportType}
            successMessage={SuccessMessages.ReportType}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReportType : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportTypeFormik;
