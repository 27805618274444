export interface IReportSizing {
    maxHeight: string;
    maxWidth: string;
    minHeight: string;
    minWidth: string;
}

export const defaultReportSizing: IReportSizing = {
    minHeight: '0px', minWidth: '0px', maxHeight: '20000px', maxWidth: '20000px'
};

