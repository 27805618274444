import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';

export const useGetDcFilePreview = () => {
    const [service, setService] = useState(GetRequest.empty());
    const [dcFileToPreview, setDcFileToPreview] = useState<string>('');

    useEffect(() => {
        if (!dcFileToPreview) {
            return;
        }

        processGetAsync(
            setService,
            `api/app/dc-file-requests/${dcFileToPreview}/dc-download/?isPreview=true`
        );
        setDcFileToPreview('');
    }, [dcFileToPreview]);

    return { service, setDcFileToPreview };
};
