import React, { Fragment, useEffect, useState } from 'react';
import OEInput from '../../../core/components/form/OEInput';
import { IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';

interface IComponentInfo {
    filter: IReportFilter;
    onChange: (v: string) => void;
}

const SiteReportFilterInput: React.FunctionComponent<IComponentInfo> = ({ filter, onChange }) => {
    const [value, setValue] = useState<string>(filter.value);

    const onChangeValue = (v: string) => {
        setValue(v);
        onChange(v);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter]);


    return (
        <Fragment>
            <OEInput name={`filter${filter.sortOrder}`} value={value} onChange={onChangeValue} />
        </Fragment>
    );
};

export default SiteReportFilterInput;
