import React from 'react';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { IReportGroup } from '../../../reporting/entities/ReportGroup';

interface IComponentInfo {
    group: IReportGroup;
}

const AboutSection: React.FunctionComponent<IComponentInfo> = ({ group }) => {

    return (
        <div className="report-about">
            <span dangerouslySetInnerHTML={displayHTMLContent(group.about)} />
        </div >
    );
};

export default AboutSection;
