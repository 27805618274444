import React from 'react';


const Styles: React.FunctionComponent = () => {

    return (
        <>
            <iframe title="Style Guide" src="/assets/styles.html" style={{ width: '100%', height: '15400px'} } />
        </>
    );
};

export default Styles;