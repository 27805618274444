import React, { useEffect, useState } from 'react';
import PermissionRestrictions from '../../security/components/PermissionRestrictions';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IModule } from '../entities/Module';
import { getSecurityModuleList } from '../entities/SecurityModule';
import { useGetSecurityModule, usePutModulePermissions } from '../services/SecurityModuleService';

interface IComponentInfo {
    item: IModule;
    onCancel: () => void;
}

const ModulePermissions: React.FunctionComponent<IComponentInfo> = ({ item, onCancel }) => {
    const { service, setModuleId } = useGetSecurityModule();
    const { service: saveService, setSecurity } = usePutModulePermissions();

    const [currentSecurity, setCurrentSecurity] = useState<ISecurityRole[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service.result) {
            setCurrentSecurity(getSecurityModuleList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (saveService.isFinished) {
            if (saveService.isSuccess) {
                onCancel();
            } else {
                setErrors([saveService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [saveService]);

    const refreshSecruity = () => {
        setModuleId(item.id);
    };

    const updateSecruity = (i: ISecurityRole[]) => {
        setSecurity({ id: item.id, permissions: i });
    };

    return (
        <>
            <PermissionRestrictions
                currentSecurity={currentSecurity}
                setSecurity={updateSecruity}
                refreshSecurity={refreshSecruity}
                isInProgress={saveService.isInProgress}
                onCancel={onCancel}
                title={`Edit Restrictions for Module: ${item.name}`}
                info="Select any roles from the Available column to restrict access to this module, if no restrictions are selected all users will have access. Changes are not complete until you hit Submit. "
                errors={errors}
            />
        </>
    );
};

export default ModulePermissions;

