import React, { useEffect, useState } from 'react';
import OEBreadcrumb from '../../core/components/breadcrumbs/OEBreadcrumb';
import { IBreadcrumb } from '../../core/components/breadcrumbs/entities/Breadcrumb';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { IConfirmationMessage, defaultConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import OETable from '../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETabs, { OETab } from '../../core/components/tabs/OETabs';
import { Icon } from '../../core/entities/Icon';
import { parseQueryString, updateURLParameter } from '../../core/utilities/URL';
import { IOrganizationType, defaultOrganizationType, getOrganizationTypeList } from '../entities/OrganizationType';
import { IRole, defaultRole, getRoleList } from '../entities/Role';
import { useDeleteOrganizationType, useGetOrganizationTypes } from '../services/OrganizationTypeService';
import { useDeleteRole, useGetRolesByType } from '../services/RoleService';
import OrganizationTypeFormik from './OrganizationTypeFormik';
import RoleFormik from './RoleFormik';
import RolePermissions from './RolePermissions';

enum ModalTypes {
    None = 1,
    Edit,
    EditRole,
    EditRolePermissions
}

interface IComponentInfo {
    updateTypes?: (i: IOrganizationType[]) => void;
}

const OrganizationTypes: React.FunctionComponent<IComponentInfo> = ({ updateTypes }) => {
    const params: any = parseQueryString();

    const { service, doRefresh } = useGetOrganizationTypes();
    const { service: roleService, setTypeId } = useGetRolesByType();
    const { service: deleteService, setOrganizationTypeId: setDeleteId } = useDeleteOrganizationType();
    const { service: deleteRoleService, setRoleId: setDeleteRoleId } = useDeleteRole();

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [items, setItems] = useState<IOrganizationType[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [item, setItem] = useState<IOrganizationType>(defaultOrganizationType);
    const [role, setRole] = useState<IRole>(defaultRole);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [filtered, setFiltered] = useState<IOrganizationType[]>([]);
    const [parentId, setParentId] = useState<number>(parseInt(params.typeid, 10) || 0);
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Top Level' }];
    const [parentName, setParentName] = useState<string>('Top Level');
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [activeTab, setActiveTab] = useState('types');

    useEffect(() => {
        if (service.result) {
            setItems(getOrganizationTypeList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (roleService.result) {
            setRoles(getRoleList(roleService.result));
        }
    }, [roleService]);

    useEffect(() => {
        if (deleteRoleService.isSuccess) {
            setTypeId(parentId);
        }
        // eslint-disable-next-line
    }, [deleteRoleService]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            doRefresh();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateBreadcrumbs(parentId);
            updateTypes && updateTypes(items);
        }
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateURLParameter('typeid', parentId);
            updateBreadcrumbs(parentId);
        }
        setTypeId(parentId);
        // eslint-disable-next-line
    }, [parentId]);

    useEffect(() => {
        if (breadcrumbs.length > 0) {
            setParentName(breadcrumbs[breadcrumbs.length - 1].name);
        }
    }, [breadcrumbs]);

    const onSave = () => {
        doRefresh();
        setShowModal(ModalTypes.None);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultOrganizationType, parentId });
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IOrganizationType) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onAddRole = () => {
        setRole({ ...defaultRole, typeId: parentId });
        setShowModal(ModalTypes.EditRole);
    };

    const onEditRole = (i: IRole) => {
        setRole(i);
        setShowModal(ModalTypes.EditRole);
    };

    const onEditRolePermissions = (i: IRole) => {
        setRole(i);
        setShowModal(ModalTypes.EditRolePermissions);
    };

    const onSaveRole = () => {
        setShowModal(ModalTypes.None);
        setTypeId(parentId);
    };

    const onSavePermission = () => {
        setShowModal(ModalTypes.None);
        setNotification({ message: 'Permissions Updated Succecssfully', type: 'success' });
    };

    const onDeleteRole = (i: string) => {
        setDeleteRoleId(i);
    };

    const onConfirmDeleteRole = (i: IRole) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Role?",
            message: `Are you sure you want to delete the role <b>${i.name}</b>?`,
            onOk: onDeleteRole, onCancel: onCancel
        });
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IOrganizationType) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Organization Type?",
            message: `Are you sure you want to delete the organization type <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const onFilter = (s: IOrganizationType[]) => {
        setFiltered([...s.filter(q => q.parentId === parentId)]);
    }

    const onNavigate = (i: IOrganizationType) => {
        setParentId(i.id);
    };

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        setParentId(i.id);
    };

    const updateBreadcrumbs = (pid: number, breadcumbs?: IBreadcrumb[]) => {
        let b: IBreadcrumb[] = breadcumbs || [];
        if (pid > 0) {
            let i: IOrganizationType = items.filter(q => q.id === pid)[0];
            b = [{ id: i.id, name: i.name }].concat(b);
            updateBreadcrumbs(i.parentId || 0, b);
        }
        else {
            b = rootBreadcrumbs.concat(b);
            setBreadcrumbs(b);
        }
    }

    const childactions: IColumnAction[] = [
        { icon: Icon.ChildrenNavigate, onClick: onNavigate, helpText: 'View Child Types' }
    ];

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEditRole, helpText: 'Edit Type' },
        { icon: Icon.Delete, onClick: onConfirmDelete, helpText: 'Delete Type', condition: 'childCount', notCondition: true }
    ];

    const columns: IColumn[] = [
        { actions: childactions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'name', name: 'Name', sort: true, type: ColumnType.Link, onClick: onEdit },
        { id: 'code', name: 'Code', sort: true, type: ColumnType.String },
        { id: 'childCount', width: "20px", name: 'Children', sort: true, type: ColumnType.Integer, className: 'text-center' },
        { actions, width: '10px', id: 'Actions', name: '', sort: false, type: ColumnType.Actions },
    ];

    const roleActions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEditRole, helpText: 'Edit Role', condition: 'isStatic', notCondition: true },
        { icon: Icon.Permissions, onClick: onEditRolePermissions, helpText: 'Edit Role Permissions', condition: 'isStatic', notCondition: true },
        { icon: Icon.Delete, onClick: onConfirmDeleteRole, helpText: 'Delete Role', condition: 'isStatic', notCondition: true }
    ];

    const roleColumms: IColumn[] = [
        { actions: roleActions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'name', name: 'Unique Name', sort: true, type: ColumnType.Link, onClick: onEditRole, condition: 'isStatic', notCondition: true },
        { id: 'name', name: 'Unique Name', sort: true, type: ColumnType.String, condition: 'isStatic' },
        { id: 'displayName', name: 'Display Name', sort: true, type: ColumnType.String },
        { id: 'isDefault', name: 'Default', sort: true, type: ColumnType.Boolean, className: 'text-center' },
        { id: 'isPublic', name: 'Public', sort: true, type: ColumnType.Boolean, className: 'text-center' },

    ];

    const setTab = (event: any) => {
        setActiveTab(event);
    };

    return (
        <>
            <OENotification setNotification={setNotification} notification={notification} />
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />
            <OETabs className="fst-italic small" id="orgtypetabs" defaultTab={activeTab} onSelect={setTab}>
                <OETab title={`${parentName} Types`} eventKey="types">
                    <OETable
                        loading={service.isInProgress}
                        loadingMessage="Loading Types"
                        data={filtered}
                        columns={columns}
                        showPagination={filtered.length > 10}
                        defaultSort="name"
                        noDataMessage={`No types exist ${parentId > 0 ? ` for ${parentName}, click <b>Add New Type</b> to add.` : ''}`}
                        defaultPageSize={10}
                        actions={[
                            { icon: Icon.Add, hidden: parentId === 0, text: 'Add New Type', action: onAdd },
                        ]}
                    />
                </OETab>
                <OETab title={`${parentName} Roles`} eventKey="roles">
                    <OETable
                        data={roles.filter(q => q.typeId === parentId)}
                        loading={roleService.isInProgress}
                        loadingMessage="Loading Roles"
                        columns={roleColumms}
                        showPagination={roleColumms.length > 10}
                        defaultSort="textCol"
                        noDataMessage={`No roles exist ${parentId > 0 ? ` for ${parentName}, click <b>Add New Role</b> to add.` : ''}`}
                        defaultPageSize={10}
                        actions={[
                            { icon: Icon.Add, hidden: parentId === 0, text: 'Add New Role', action: onAddRole },
                        ]}
                    />
                </OETab>
            </OETabs>
            {showModal === ModalTypes.Edit && (
                <OrganizationTypeFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}
            {showModal === ModalTypes.EditRole && (
                <RoleFormik types={items} onCancel={onCancel} onSave={onSaveRole} item={role} />
            )}
            {showModal === ModalTypes.EditRolePermissions && (
                <RolePermissions onCancel={onCancel} onSave={onSavePermission} item={role} />
            )}
            <OEConfirmation {...confirmation} />
        </>
    );
};

export default OrganizationTypes;
