export const displayHTMLContent = (data: string): any => {
    return { __html: data };
};


export const canDebug = (): boolean => {
    try {
        return localStorage.getItem('debug-setting') === '1';
    }
    catch {
        return false;
    }
}