import { FormikProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import OEButton from '../../../core/components/form/OEButton';
import OECheckbox from '../../../core/components/form/OECheckbox';
import OEFormik from '../../../core/components/formik/OEFormik';
import OEFormItem from '../../../core/components/formik/OEFormItem';
import OEHeading from '../../../core/components/general/OEHeading';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { IProfile } from '../../entities/Profile';
import { defaultProfileImage, getByteValue, getImageSource, IProfileImage, ProfilePictureType } from '../../entities/ProfileImage';
import { useGetProfileImage, usePostProfileImage } from '../../services/ProfileImageService';

enum ViewTypes {
    Image = 1,
    Form,
}

const labelColumns: number = 0;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IProfileImage> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<any>(undefined);

    const setType = (i: any) => {
        setFieldValue('newType', i);
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (e: any) => {
                setPreview(e.target.result);
                setFieldValue('imageContent', getByteValue(e.target.result));
            };
        }
    };
    return (
        <>
            <OEHeading className="m-t-40" underline={true} size={6} text="Select how you would like to upload new Image" />
            <OECheckbox selectedValue={values.newType} onChange={setType} value={ProfilePictureType.None} name="default" text="Use Default Avatar " />
            <OECheckbox selectedValue={values.newType} onChange={setType} value={ProfilePictureType.Gravatar} name="gravatar" text="Gravatar" />
            <OECheckbox selectedValue={values.newType} onChange={setType} value={ProfilePictureType.Image} name="upload" text="Upload New Image" />

            {values.newType === ProfilePictureType.Image && (
                <>
                    <OEHeading className="m-t-40" underline={true} size={6} text="Upload New Image for your Profile" />
                    <OEFormItem name="uploadImage" columns={labelColumns} label="">
                        <input
                            type="file"
                            ref={fileInput}
                            className="m-t-10"
                            accept="image/png, image/gif, image/jpeg"
                            name="userfile"
                            onChange={handleFileChange}
                        />
                    </OEFormItem>
                    {preview && (
                        <img alt="Preview" style={{ maxHeight: '200px' }} src={preview} className="m-t-10" />
                    )}
                </>
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IProfileImage>().shape({
});

interface IFormikInfo {
    profile: IProfile;
    onSuccess: () => void;
}

const UserImageFormik: React.FunctionComponent<IFormikInfo> = ({ profile, onSuccess }) => {
    const { service, setImage } = usePostProfileImage();
    const { service: imageService } = useGetProfileImage();

    const [profileImage, setProfileImage] = useState<IProfileImage>(defaultProfileImage);
    const [view, setView] = useState<ViewTypes>(ViewTypes.Image);

    useEffect(() => {
        if (imageService.result) {
            setProfileImage(imageService.result);
        }
    }, [imageService]);

    const onSave = () => {
        onSuccess();
    };

    const onCancel = () => {
        setView(ViewTypes.Image);
    };

    const onShowForm = () => {
        setView(ViewTypes.Form);
    };

    return (
        <>
            {view === ViewTypes.Image && (
                <>
                    <OEHeading underline={true} size={6} text="Current Profile Image" />
                    <OEFormItem name="currentImage" columns={labelColumns} label="">
                        <img
                            style={{ maxHeight: '200px' }}
                            src={getImageSource(profileImage)} alt="mdo" className="rounded-circle"
                        />
                    </OEFormItem>
                    <OEButton className="m-t-40" text="Change Image" onClick={onShowForm} />
                </>
            )}

            {view === ViewTypes.Form && (
                <OEFormik
                    item={profileImage}
                    labelColumns={labelColumns}
                    progressMessage={ProgressMessages.ProfileImage}
                    successMessage={SuccessMessages.ProfileImage}
                    onSave={onSave}
                    onCancel={onCancel}
                    validationSchema={ValidationScheme}
                    submitText="Save"
                    component={Form}
                    setItem={setImage}
                    service={service}
                />
            )}
        </>
    );
};

export default UserImageFormik;
