import React, { Fragment, useEffect, useState } from 'react';
import OEDate from '../../../core/components/form/OEDate';
import { getFutureDate } from '../../../core/utilities/Date';
import { IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';

interface IComponentInfo {
    filter: IReportFilter;
    onChange: (v: string) => void;
}

const SiteReportFilterDate: React.FunctionComponent<IComponentInfo> = ({ filter, onChange }) => {
    const [value, setValue] = useState<Date>(getFutureDate(0));

    useEffect(() => {
        try {
            setValue(new Date(filter.defaultValue));
        }
        catch (e: any) {
            console.log(e);
        }
    }, [filter.defaultValue]);

    const setStartDate = (v: Date) => {
        onChange(v.toISOString().slice(0, 10));
    };

    return (
        <Fragment>
            <OEDate name={`filter${filter.sortOrder}`} value={value} onChange={setStartDate} />
        </Fragment>
    );
};

export default SiteReportFilterDate;
