import { useEffect, useRef, useState } from 'react';
import OELink from '../../core/components/general/OELink';
import { Icon } from '../../core/entities/Icon';
import { defaultChatBot, IChatBot } from '../entities/ChatBot';
import { useChatbot, useChatbotSettings } from '../services/ChatbotService';
import '../styles/ChatWidget.css';

declare global {
    interface Window {
        WebChat: any;
        crypto: {
            randomUUID: any;
        };
    }
}

interface Props {
    showChat: boolean;
    setShowChat: (show: boolean) => void;
}

const ChatWidget: React.FunctionComponent<Props> = ({ showChat, setShowChat }) => {
    const [token, setToken] = useState('');
    const [settings, setSettings] = useState<IChatBot>(defaultChatBot);

    const { service } = useChatbot();
    const { service: settingsService } = useChatbotSettings();

    const chatWidget = useRef(null);

    const hideChat = () => {
        setShowChat(false);
    }

    useEffect(() => {
        if (service.result) {
            setToken(service.result.token);
        }
    }, [service]);

    useEffect(() => {
        if (settingsService.result) {
            setSettings(settingsService.result);
        }
    }, [settingsService]);

    useEffect(() => {
        if (!token) {
            return;
        }

        const styleOptions = {
            avatarSize: 50,
            botAvatarImage: 'ask-otis-avatar.png',
            botAvatarInitials: 'Otis',
            userAvatarInitials: 'You',
            hideUploadButton: true,
            bubbleBackground: '#eef4f9',
            bubbleFromUserBackground: '#daeac3',
            suggestedActionLayout: 'stacked',
        };

        try {
            const directLine = window.WebChat.createDirectLine({
                secret: token,
            });

            window.WebChat.renderWebChat(
                {
                    directLine,
                    userID: window.crypto.randomUUID(),
                    userName: window.crypto.randomUUID(),
                    styleOptions,
                    overrideLocalizedStrings: {
                        TEXT_INPUT_PLACEHOLDER:
                            "Type your message or type 'menu' for options",
                    },
                },
                chatWidget.current
            );
        }
        catch { }
    }, [token]);

    return (
        <div id="chat-container" className={showChat ? 'show' : ''}>
            <div className="chat-header">
                <OELink icon={Icon.Close} className="pull-right" style={{ marginRight: '10px', marginTop: '5px' }} onClick={hideChat} />
                <div className="chat-title text-center">{settings.chatbotName}</div>
            </div>

            <div id="chat" ref={chatWidget}></div>
        </div>
    );
};

export default ChatWidget;
