import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultModulePermissionUpdate, IModulePermissionUpdate } from '../entities/Module';
import { ISecurityModuleListItem } from '../entities/SecurityModule';

export const useGetSecurityModule = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<ISecurityModuleListItem>>());
    const [moduleId, setModuleId] = useState<string>('');

    useEffect(() => {
        if (moduleId === '') { return; }
        processGetAsync(setService, `/api/app/security-modules?ModuleId=${moduleId}`);
        setModuleId('');
    }, [moduleId]);

    return { service, setModuleId };
};

export const usePutModulePermissions = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [security, setSecurity] = useState<IModulePermissionUpdate>(defaultModulePermissionUpdate);

    useEffect(() => {
        if (security.id === '') { return; }
        processPutAsync(setService, `/api/app/modules/${security.id}/permissions`, security.permissions);
        setSecurity(defaultModulePermissionUpdate);
    }, [security]);

    return { service, setSecurity };
};
