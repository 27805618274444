import { useEffect, useState } from "react";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { defaultGlossary, defaultGlossaryGroup, defaultGlossaryImport, getGlossaryForSave, IGlosarryImport, IGlossary, IGlossaryGroup } from "../entities/Glossary";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { IBaseList } from "../../core/entities/BaseList";
import { DeleteRequest, processDeleteAsync } from "../../core/services/DeleteRequest";
import { processPutAsync, PutRequest } from "../../core/services/PutRequest";
import { defaultPaginationFilter, IUserPaginationFilter } from "../entities/User";

export const useCreateGlossary = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [glossary, setGlossary] = useState<IGlossary>(defaultGlossary);

    useEffect(() => {
        if (glossary.term === '') { return; }
        processPostAsync(setService, `/api/app/glossaries`, { term: glossary.term, definition: glossary.definition});
        setGlossary(defaultGlossary);
    }, [glossary]);

    return { service, setGlossary };
};

export const usePutGlossary = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [glossary, setGlossary] = useState<IGlossary>(defaultGlossary);

    useEffect(() => {
        if (glossary.id === '') { return; }
        processPutAsync(setService, `/api/app/glossaries/${glossary.id}`, getGlossaryForSave(glossary));
        setGlossary(defaultGlossary);
    }, [glossary]);

    return { service, setGlossary };
};

export const useGetGlossaries = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IGlossary>>());
    const [params, setParams] = useState<IUserPaginationFilter>(defaultPaginationFilter);

    useEffect(() => {
        let url = `/api/app/glossaries?Sorting=term&MaxResultCount=1000`;
        if (params.filter && params.filter.length) {
            url += `&FilterText=${params.filter}`;
        }
        processGetAsync(setService, url);
        setParams(params);
    }, [params]);

    return { service, setParams };
};

export const useGetGlossaryTermGroup = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IGlossaryGroup>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `api/app/glossaries/terms-by-group`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useDeleteGlossary = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() => {
        if (deleteId < 1) { return; }
        processDeleteAsync(setService, `/api/app/glossaries/${deleteId}`);
    }, [deleteId]);

    return { service, setDeleteId };
};

export const useImportGlossary = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [glossaryImport, setGlossaryImport] = useState<IGlosarryImport>(defaultGlossaryImport);

    useEffect(() => {
        if (glossaryImport.webFileInfo.name === '') { return; }
        processPostAsync(setService, `/api/app/glossaries/import`, glossaryImport);
        setGlossaryImport(defaultGlossaryImport);
    }, [glossaryImport]);

    return { service, setGlossaryImport };
};

export const getGlossaryList = (i: IBaseList<IGlossary>): IGlossary[] => {
    const d: IGlossary[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultGlossary,
            ...v
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getGlossaryGroupList = (i: IBaseList<IGlossaryGroup>): IGlossaryGroup[] => {
    const d: IGlossaryGroup[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultGlossaryGroup,
            ...v,
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

