import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { ISiteSettings } from '../entities/SiteSettings';

export const useGetSiteSettings = () => {
    const [service, setService] = useState(GetRequest.empty<ISiteSettings>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/site-settings`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetPublicSiteSettings = () => {
    const [service, setService] = useState(GetRequest.empty<ISiteSettings>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/site-settings/public-settings`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

