import { IRoute } from '../../site/entities/Route';
import { IReport } from './Report';
import { IReportServer } from './ReportServer';
import { IReportType } from './ReportType';


export interface IReportLookupTables {
    types: IReportType[];
    servers: IReportServer[];
    reports: IReport[];
    routes: IRoute[];
}

export const defaultReportLookupTables: IReportLookupTables = {
    types: [], servers: [], reports: [], routes: []
};

