import React from 'react';
import OEHeading from '../../core/components/general/OEHeading';

interface IComponentInfo {
    name: string;
    size?: number;
}

const UnderConstruction: React.FunctionComponent<IComponentInfo> = ({ name, size }) => {

    return (
        <div className="m-t-40 m-l-40 m-b-40">
            <OEHeading size={size || 3} >
                ... <i className="fas fa-truck-pickup"></i>... {name} is currently under construction ... <i className="fas fa-truck-pickup"></i>...
            </OEHeading>
        </div>
    );
};

export default UnderConstruction;