import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { defaultDropdownIntegerSelect, DropdownType } from '../../core/components/form/OEDropdown';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { IOrganizationType } from '../entities/OrganizationType';
import { IOrganizationUnit } from '../entities/OrganizationUnit';
import { useCreateOrganizationUnit, usePutOrganizationUnit } from '../services/OrganizationUnitService';

const labelColumns: number = 2;

interface IFormInfo {
    lookupTables: IOrganizationType[];
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IOrganizationUnit> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    return (
        <>
            <OEFormDropdown
                label="Type" name="typeId" value={values.typeId}
                defaultSelect={defaultDropdownIntegerSelect} alwaysShowDefault={true}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables}
                setFieldValue={setFieldValue} required={true} type={DropdownType.Integer}
            />
            <OEFormInput
                label="Name" name="displayName" value={values.displayName}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Import ID" name="importId" value={values.importId}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IOrganizationUnit>().shape({
    displayName: Yup.string().required('Name is required').min(2).max(250).nullable(),
    typeId: Yup.number().required('Type is required').min(1, 'Type is required'),
});

interface IFormikInfo {
    item: IOrganizationUnit;
    types: IOrganizationType[];
    onCancel: () => void;
    onSave: () => void;
}

const RouteFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave, types }) => {
    const { service, setOrganizationUnit } = usePutOrganizationUnit();
    const { service: createService, setOrganizationUnit: setCreateUnit } = useCreateOrganizationUnit();

    return (
        <OEFormikModal
            item={item}
            labelColumns={labelColumns}
            title={`${item.id !== '' ? `Edit Organization Unit: ${item.displayName}` : `Add New Organization Unit`}`}
            progressMessage={ProgressMessages.OrganizationUnit}
            successMessage={SuccessMessages.OrganizationUnit}
            onSave={onSave}
            validationSchema={ValidationScheme}
            lookupTables={types}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id !== '' ? setOrganizationUnit : setCreateUnit}
            service={item.id !== '' ? service : createService}
        />
    );
};

export default RouteFormik;
