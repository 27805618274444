import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../core/components/form/OEButton';
import OEForm, { OEFormGroup } from '../../core/components/form/OEForm';
import OEInput from '../../core/components/form/OEInput';
import OEHeading from '../../core/components/general/OEHeading';
import OEIcon from '../../core/components/general/OEIcon';
import OELink from '../../core/components/general/OELink';
import OEModal, { ModalSize, OEModalBody } from '../../core/components/general/OEModal';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import OEMessage from '../../core/components/messaging/OEMessage';
import { ICSVSelectItem } from '../../core/entities/CSVSelectItem';
import { Icon } from '../../core/entities/Icon';
import ArtifactTagEdit from './ArtifactTagEdit';

enum ViewTypes {
    Default = 1,
    Add,
}
interface IArtifactTag {
    item: ICSVSelectItem;
    onRemove: (i: string) => void;
}

const ArtifactTag: React.FunctionComponent<IArtifactTag> = ({ item, onRemove }) => {

    const removeItem = () => {
        onRemove(item.value);
    }

    return (
        <div className="csv-selector">
            <span className="label">{item.value}</span>
            <OELink title="Remove" onClick={removeItem} className="select" icon={Icon.CheckNo} />
        </div>
    );
};

interface IArtifiactTagItem {
    item: ICSVSelectItem;
    items: ICSVSelectItem[];
    onSelect: (i: ICSVSelectItem) => void;
    onDelete: (i: ICSVSelectItem) => void;
    updateItem: (e: string, n: string) => void;
}

const ArtifiactTagItem: React.FunctionComponent<IArtifiactTagItem> = ({ item, items, onSelect, onDelete, updateItem }) => {
    const [showEdit, setShowEdit] = useState<boolean>(false);

    const onClick = () => {
        onSelect(item);
    }

    const onEdit = () => {
        setShowEdit(true);
    }

    const onDeleteItem = () => {
        onDelete(item);
    }

    const onSucessEdit = (n: string) => {
        setShowEdit(false);
        updateItem(item.value, n);
    }

    const onCancel = () => {
        setShowEdit(false);
    }

    return (
        <>
            <div className={`${item.isSelected ? 'permissionsDivSelectedStyle' : 'permissionsDivStyle'} m-t-5`}>
                {item.isSelected &&
                    <>
                        <OEIcon onClick={onEdit} icon={Icon.Edit} className="m-t-5 pull-right" />
                        <OEIcon onClick={onDeleteItem} icon={Icon.Delete} className="m-t-5 pull-right" />
                        <OEIcon onClick={onClick} icon={Icon.ArrowRight} className="m-t-5 m-l-5 pull-right" />
                    </>
                }
                {!item.isSelected &&
                    <>
                        <OEIcon onClick={onClick} icon={Icon.ArrowLeft} className="m-t-5 pull-left" />
                        <OEIcon onClick={onEdit} icon={Icon.Edit} className="m-t-5 pull-left" />
                        <OEIcon onClick={onDeleteItem} icon={Icon.Delete} className="m-t-5 m-r-5 pull-left" />
                    </>
                }
                <div onClick={onClick} className="p-t-5">{item.value}</div>
            </div>
            {showEdit && (
                <ArtifactTagEdit items={items.filter(q => q.value !== item.value)} item={item.value} onCancel={onCancel} onSave={onSucessEdit} />
            )}
        </>
    );
};

interface IArtifiactTagFormik {
    items: ICSVSelectItem[];
    onCancel: () => void;
    onSave: (i: ICSVSelectItem[]) => void;
}

const ArtifactTagFormik: React.FunctionComponent<IArtifiactTagFormik> = ({ items, onCancel, onSave }) => {
    const [localValues, setLocalValues] = useState<ICSVSelectItem[]>([]);
    const [value, setValue] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        const i: ICSVSelectItem[] = [];
        for (const v of items) {
            i.push({ value: v.value, isSelected: v.isSelected });
        }
        setLocalValues(i);
        // eslint-disable-next-line
    }, [items]);


    const onUpdateSelection = (i: ICSVSelectItem) => {
        for (const v of localValues) {
            if (i.value === v.value) {
                v.isSelected = !v.isSelected;
            }
        }
        setLocalValues([...localValues]);
    }

    const onUpdateItem = (e: string, n: string) => {
        localValues.filter(q => q.value === e)[0].value = n;
    }

    const onDelete = (i: ICSVSelectItem) => {
        setLocalValues(localValues.filter(q => q.value !== i.value));
    }

    const onSaveSelection = () => {
        onSave(localValues);
    }

    const onChangeValue = (i: string) => {
        setMessage('');
        setValue(i);
    }

    const onAddTag = () => {
        if (value.trim().length === 0) {
            setMessage('Tag can not be blank');
        }
        else {
            let found: boolean = false;
            for (const v of localValues) {
                if (value.toLowerCase() === v.value.toLowerCase()) {
                    found = true;
                    setMessage('Tag already exists');
                }
            }
            if (!found) {
                localValues.push({ value, isSelected: true });
                setLocalValues([...localValues]);
                setValue('');
            }
        }
    }

    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onCancel} title="Add/Edit Tags">
            <OEModalBody>
                <OERow className="m-t-20">
                    <OECol sm={6}>
                        <OEHeading size={5} underline={true} text="Selected Tags:" />
                        <OERow>
                            {localValues.filter(q => q.isSelected).map((item, index) =>
                                <ArtifiactTagItem items={items} updateItem={onUpdateItem} onDelete={onDelete} onSelect={onUpdateSelection} item={item} key={index} />
                            )}
                            {localValues.filter(q => q.isSelected).length === 0 && (
                                <OEMessage type={MessageType.Success} hideDismissable={true} message="No tags are selected" />
                            )}
                        </OERow>
                    </OECol>
                    <OECol sm={6}>
                        <OEHeading underline={true} size={5} text="Available Tags" />
                        <OERow>
                            {localValues.filter(q => !q.isSelected).map((item, index) =>
                                <ArtifiactTagItem items={items} updateItem={onUpdateItem} onDelete={onDelete} onSelect={onUpdateSelection} item={item} key={index} />
                            )}
                            {localValues.filter(q => !q.isSelected).length === 0 && localValues.length > 0 && (
                                <OEMessage type={MessageType.Information} hideDismissable={true} message="All Tags have been selected" />
                            )}
                            {localValues.length === 0 && (
                                <OEMessage type={MessageType.Information} hideDismissable={true} message="No tags have been added." />
                            )}
                        </OERow>
                    </OECol>
                </OERow>
                <OERow className="m-t-20">
                    <OECol sm={6}>
                        <OEForm className={`row align-items-end`}>
                            <OEFormGroup inline={true} className="m-r-0">
                                <OEInput width="250px" onChange={onChangeValue} value={value} placeholder="Add New Tag"></OEInput>
                            </OEFormGroup>
                            <OEFormGroup inline={true} className="m-l-0">
                                <div style={{ marginTop: '-32px' }}>
                                    <OELink title="Add Tag" onClick={onAddTag} icon={Icon.Add} />
                                </div>
                            </OEFormGroup>
                        </OEForm>
                    </OECol>
                    <OECol sm={6} >
                        <OEButton className="pull-right" bStyle={ButtonStyle.Cancel} onClick={onCancel} text="Cancel" />
                        <OEButton className="pull-right" onClick={onSaveSelection} text="Save Changes" />
                    </OECol>
                </OERow>
                <div>
                    <OEMessage className="small" type={MessageType.Error} message={message} icon={Icon.Error} />
                </div>
            </OEModalBody>
        </OEModal >
    );
};

interface IComponentInfo {
    value?: string;
    name: string;
    values: string[];
    onChange?: (data: string) => void;
    setFieldValue?: (id: string, data: any) => void;
}

const ArtifactTags: React.FunctionComponent<IComponentInfo> = ({ value, name, values, onChange, setFieldValue }) => {
    const [items, setItems] = useState<ICSVSelectItem[]>([]);
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);

    useEffect(() => {
        if (value) {
            const i: ICSVSelectItem[] = [];
            for (const v of value.split(',')) {
                i.push({ value: v, isSelected: true });
            }
            onUpdateSelections(i);
        }
        // eslint-disable-next-line
    }, [values, value]);

    useEffect(() => {
        if (items.length > 0) {
            let s: string = '';
            for (const v of items) {
                if (v.isSelected) {
                    s = `${s}${s.length > 0 ? ',' : ''}${v.value}`;
                }
            }
            onChange?.(s);
            setFieldValue?.(name, s);
        }
        // eslint-disable-next-line
    }, [items]);


    const onRemove = (i: string) => {
        items.filter(q => q.value === i)[0].isSelected = false;
        setItems([...items]);
    }

    const onAddItem = () => {
        setShowView(ViewTypes.Add);
    }

    const onCancel = () => {
        setShowView(ViewTypes.Default);
    }

    const onUpdateSelections = (i: ICSVSelectItem[]) => {
        const c: ICSVSelectItem[] = [];
        for (const v of i) {
                c.push({ value: v.value, isSelected: v.isSelected });
        }
        setItems(c);
        setShowView(ViewTypes.Default);
    }

    return (
        <>
            {items.filter(q => q.isSelected).map((item, index) =>
                <ArtifactTag item={item} key={index} onRemove={onRemove} />
            )}
            <OELink title="Add/Edit Tags" onClick={onAddItem} className="Add" icon={Icon.Add} />

            {showView === ViewTypes.Add && (
                <ArtifactTagFormik items={items} onCancel={onCancel} onSave={onUpdateSelections} />
            )}

        </>
    );
};


export default ArtifactTags;