import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';

export const useLogout = () => {
    const [service, setService] = useState(GetRequest.empty());
    const [logoutUser, setLogoutUser] = useState(false);
    const logout = () => setLogoutUser(true);

    useEffect(() => {
        if (!logoutUser) { return; }
        processGetAsync(setService, `/api/account/logout/`);
        setLogoutUser(false);
    }, [logoutUser]);

    return { service, logout };
};
