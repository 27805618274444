import React, { CSSProperties, useEffect, useState } from 'react';
import { displayHTMLContent } from '../../utilities/Miscellaneous';
import OEButton from '../form/OEButton';
import OELink from '../general/OELink';
import { getInformationMessage, IInformationMessage, InformationMessage, MessageType } from './enums/InformationMessages';

interface IComponentInfo {
    className?: string;
    icon?: string;
    informationMessage?: InformationMessage;
    message?: string;
    type?: MessageType;
    actionButton?: string;
    actionClick?: () => void;
    hidden?: boolean;
    hideDismissable?: boolean;
    style?: CSSProperties;
}

const OEMessage: React.FunctionComponent<IComponentInfo> = ({ message, style, hidden, type, actionClick, actionButton, informationMessage, icon, className, hideDismissable }) => {

    const [localMessage, setLocalMessage] = useState<string>('');
    const [localType, setLocalType] = useState<string>(MessageType.Information);
    const [localDismiss, setLocalDismiss] = useState<boolean>(false);

    useEffect(() => {
        hideDismissable && setLocalDismiss(hideDismissable);
    }, [hideDismissable]);

    useEffect(() => {
        setLocalMessage(message || '');
    }, [message]);

    useEffect(() => {
        type && setLocalType(type);
    }, [type]);

    useEffect(() => {
        message && setLocalMessage(message);
        hideDismissable && setLocalDismiss(hideDismissable);
        type && setLocalType(type);
        if (informationMessage) {
            const i: IInformationMessage = getInformationMessage(informationMessage);
            setLocalType(i.type);
            setLocalMessage(i.message);
            setLocalDismiss(i.hideDissmissable || false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {!hidden && localMessage && localMessage.length > 0 && (
                <div style={style} className={className}>
                    <div role="alert" className={`alert ${localDismiss ? '' : 'alert-dismissible'} fade show alert-${localType}`}>
                        {actionClick && (
                            <OELink className=" m-r-5 pull-right" onClick={actionClick} text={actionButton || 'Missing Text'} />
                        )}
                        {icon && (
                            <i className={`${icon} ${className} m-t-0`} aria-hidden="true" />
                        )}
                        <span dangerouslySetInnerHTML={displayHTMLContent(localMessage)} />

                        {!localDismiss && (
                            <OEButton className="btn-close" dataBsDismiss="alert" ariaLabel="Close" />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default OEMessage;