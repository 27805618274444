import React, { useState } from 'react';
import OEButton from '../core/components/form/OEButton';
import OEDisplay from '../core/components/general/OEDisplay';
import OEHeading from '../core/components/general/OEHeading';
import OELink from '../core/components/general/OELink';
import OEModal, { ModalSize, OEModalBody, OEModalFooter, OEModalHeader } from '../core/components/general/OEModal';
import OEWell, { WellStyle } from '../core/components/general/OEWell';
import { Icon } from '../core/entities/Icon';

const General: React.FunctionComponent = () => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Small);

    const onLargeModal = () => {
        setShowModal(true);
        setModalSize(ModalSize.Large);
    }

    const onSmallModal = () => {
        setShowModal(true);
        setModalSize(ModalSize.Small);
    }

    const onMediumModal = () => {
        setShowModal(true);
        setModalSize(ModalSize.Medium);
    }

    const onCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
            <OEWell className="m-t-0" title="OELink Examples" icon={Icon.Link}>
                <div className="p15">
                    <OELink href="#" text="Regular Link" title="This is the Help Text" />
                    <OELink href="#" icon={Icon.CheckYes} className="m-l-40" title="This is the Help Text" text="Regular Link with Icon" />
                </div>
            </OEWell>
            <OEWell oeStyle={WellStyle.Clear} className="m-t-40" title="OEHeading" icon={Icon.File}>
                <div className="p15">
                    <OEHeading text="Heading Size 1" size={1} />
                    <OEHeading text="Heading Size 2" size={2} />
                    <OEHeading text="Heading Size 3" size={3} />
                    <OEHeading text="Heading Size 4" size={4} />
                    <OEHeading text="Heading Size 5" size={5} />
                    <OEHeading text="Heading Size 6" size={6} />
                    <OEHeading text="Heading Size 3 With Icon" size={3} icon={Icon.Error} />
                </div>
            </OEWell>
            <OEWell oeStyle={WellStyle.Clear} className="m-t-40" title="OEDisplay" icon={Icon.File}>
                <div className="p15">
                    <OEDisplay text="Display Size 1" size={1} />
                    <OEDisplay text="Display Size 2" size={2} />
                    <OEDisplay text="Display Size 3" size={3} />
                    <OEDisplay text="Display Size 4" size={4} />
                    <OEDisplay text="Display Size 5" size={5} />
                    <OEDisplay text="Display Size 6" size={6} />
                    <OEDisplay text="Display Size 3 With Icon" size={3} icon={Icon.Error} />
                </div>
            </OEWell>
            <OEWell className="m-t-40" title="OEModal" icon={Icon.Table} >
                <div className="p15">
                    <OEButton text="Large Modal" onClick={onLargeModal} />
                    <OEButton text="Medium Modal" onClick={onMediumModal} />
                    <OEButton text="Small Modal" onClick={onSmallModal} />
                </div>
            </OEWell>

            {showModal && (
                <OEModal show={showModal} oeSize={modalSize} >
                    <OEModalHeader onHide={onCloseModal} >Header</OEModalHeader>
                    <OEModalBody><p>This is the Body of the Modal</p></OEModalBody>
                    <OEModalFooter>
                        <OEButton text="Close" onClick={onCloseModal} />
                    </OEModalFooter>
                </OEModal>
            )}
        </>
    );
};

export default General;