import { ICubeAttribute, getDefaultCubeAttribute } from "./CubeResponse"

interface Result {
    definition: IDefinition
    data: IData
}
interface IDefinition {
    metrics: IMetric[]
    attributes: IAttribute[]
    thresholds: any[]
    sorting: any[]
}

interface IMetric {
    name: string
    id: string
    type: string
    min: number
    max: number
    dataType: string
    numberFormatting: INumberFormatting
}

interface INumberFormatting {
    category: number
    decimalPlaces: number
    formatString: string
}

interface IAttribute {
    name: string
    id: string
    type: string
    forms: IForm[]
}

interface IForm {
    id: string
    name: string
    dataType: string
    baseFormCategory: string
    baseFormType: string
}

interface IData {
    paging: IPaging
    root: IRoot
}

interface IPaging {
    total: number
    current: number
    offset: number
    limit: number
    prev: any
    next: any
}

interface IRoot {
    isPartial: boolean
    children: IChildren[]
}

interface IChildren {
    depth: number
    element: IElement
    metrics: IMetrics
}

interface IElement {
    attributeIndex: number
    formValues: IFormValues
    name: string
    id: string
}

interface IFormValues {
    DESC: string
    CODE: string
    LABEL: string
}

interface IMetrics {
    Measure: IMeasure
}

interface IMeasure {
    rv: number
    fv: string
    mi: number
}
export interface ICubeAttributeResponse {
    id: string
    name: string
    status: number
    instanceId: string
    result?: Result
}

export const defaultCubeAttributeResponse: ICubeAttributeResponse = {
    id: '', name: '', status: -1, instanceId: ''
};

export const getCubeAttributesfromAttributeResponse = (i: IChildren[]): ICubeAttribute[] => {
    const r: ICubeAttribute[] = [];
    for (const c of i) {
        const b: ICubeAttribute = getDefaultCubeAttribute();
        b.id = getKey(c.element.id);
        r.push(b);
    }
    return r.sort((a, b) => {
        if (isGreater(a.id, b.id)) { return 1; }
        if (isGreater(b.id, a.id)) { return -1; }
        return 0;
    });;
}

const getKey = (i: string) => {
    const values = i.split(';');
    let key: string = '';
    let value: string = '';
    for (var j = 0; j < values.length; j++) {
        if (j === 0) {
            key = values[j];
        }
        else {
            value = `${value}${values[j]}`;
        }
    }
    return `${key};${value}`;
}

const isGreater = (a: any, b: any) => {
    try {
        let aid: string = a.split(':')[0].substring(1);
        let bid: string = b.split(':')[0].substring(1);
        if (!isNaN(parseInt(aid, 10)) && !isNaN(parseInt(bid, 10))) {
            return parseInt(aid, 10) > parseInt(bid, 10);
        }
        else {
            aid = a.split(';')[0].substring(1);
            bid = b.split(';')[0].substring(1);
            if (!isNaN(parseInt(aid, 10)) && !isNaN(parseInt(bid, 10))) {
                return parseInt(aid, 10) > parseInt(bid, 10);
            }
            else {
                const a1 = (a && a.toString) ? a.toString() : '';
                const b1 = (b && b.toString) ? b.toString() : '';
                return a1.toLowerCase() > b1.toLowerCase();
            }
        }
    }
    catch {
        const a1 = (a && a.toString) ? a.toString() : '';
        const b1 = (b && b.toString) ? b.toString() : '';
        return a1.toLowerCase() > b1.toLowerCase();
    }
};

