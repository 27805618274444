import React, { useState } from 'react';
import OETabs, { OETab } from '../../../core/components/tabs/OETabs';
import { ISelectedReportAdminViewInfo } from './ReportAdmin';
import MSReportPagesSingleTier from '../admin-microstrategy/MSReportPagesSingleTier';
import MSReportPagesThreeTiered from '../admin-microstrategy/MSReportPagesThreeTiered';
import BIReportPagesSingleTier from '../admin-powerbi/BIReportPagesSingleTier';

const ReportPageTiers: React.FunctionComponent<ISelectedReportAdminViewInfo> = () => {
    const [activeTab, setActiveTab] = useState('mssingleTiered');

    return (
        <OETabs className="fst-italic small" id="reportPages" defaultTab={activeTab} onSelect={setActiveTab}>
            <OETab title={`Single Tiered (Microstrategy)`} eventKey="mssingleTiered">
                <MSReportPagesSingleTier />
            </OETab>
            <OETab title={`Three Tiered (Microstrategy)`} eventKey="msthreeTiered">
                <MSReportPagesThreeTiered />
            </OETab>
            <OETab title={`Single Tiered (Power BI)`} eventKey="bisingleTiered">
                <BIReportPagesSingleTier />
            </OETab>
        </OETabs>
    );
};


export default ReportPageTiers;