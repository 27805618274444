import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../core/components/form/OEDropdown';
import { OEInputType } from '../../core/components/form/OEInput';
import { FormInputType, IFormInfo } from '../../core/components/formik/entities/Form';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import OENotification from '../../core/components/messaging/OENotification';
import { IDatasetConnection } from '../entities/DatasetConnection';
import datasetSourceTypes from '../lookups/dataset-source-types.json';
import { usePostDatasetConnection, usePutDatasetConnection, useTestDatasetConnection } from '../services/DatasetConnectionService';
import DatasetConnectionStringFormik from './DatasetConnectionStringFormik';

const Form: React.FunctionComponent<FormikProps<IDatasetConnection> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    return (<>
        <OENotification
            setNotification={setNotification}
            notification={notification}
        />

        <OEFormInput
            label="Display Name"
            name="displayName"
            value={values.displayName}
            errors={errors}
            touched={touched}
            columns={labelColumns}
            required={true}
            setFieldValue={setFieldValue}
            inputType={FormInputType.String}
        />

        <OEFormDropdown
            label="Dataset Source Type"
            name="datasetSourceType"
            value={values.datasetSourceType}
            defaultSelect={defaultDropdownSelect}
            alwaysShowDefault={true}
            errors={errors}
            touched={touched}
            columns={labelColumns}
            values={[...datasetSourceTypes]}
            setFieldValue={setFieldValue}
            required={true}
        />

        {!values.id && (
            <OEFormInput
                label="Connection String"
                name="connectionString"
                value={values.connectionString}
                errors={errors}
                touched={touched}
                columns={labelColumns}
                required={true}
                setFieldValue={setFieldValue}
                type={OEInputType.LargeText}
                inputType={FormInputType.String}
            />
        )}

        {values.id && (
            <DatasetConnectionStringFormik
                name="connectionString"
                errors={errors}
                touched={touched}
                columns={labelColumns}
                setFieldValue={setFieldValue}
            />
        )}

        <OEFormInput
            label="Database Schema"
            name="databaseSchema"
            value={values.databaseSchema}
            errors={errors}
            touched={touched}
            columns={labelColumns}
            required={false}
            setFieldValue={setFieldValue}
            inputType={FormInputType.String}
        />
    </>);
};

const ValidationScheme = Yup.object<IDatasetConnection>().shape({
    displayName: Yup.string()
        .max(30)
        .required('Display Name is required')
        .nullable(),
    datasetSourceType: Yup.number()
        .required('Dataset Source Type is required')
        .oneOf([1, 2], 'Dataset Source Type is required'),
    connectionString: Yup.string()
        .max(2000)
        .when('id', {
            is: undefined,
            then: Yup.string().required('Connection String is required'),
        })
        .nullable(),
    databaseSchema: Yup.string().max(128),
});

interface IFormikInfo {
    item: IDatasetConnection;
    onCancel: () => void;
    onSave: () => void;
}

const labelColumns: number = 3;

export const DatasetConnectionFormik: React.FunctionComponent<IFormikInfo> = ({
    item,
    onCancel,
    onSave,
}) => {
    const {
        service: createService,
        setDatasetConnection: setCreateDatasetConnection,
    } = usePostDatasetConnection();

    const {
        service: updateService,
        setDatasetConnection: setUpdateDatasetConnection,
    } = usePutDatasetConnection();

    const { testConnection, isValid, setDatasetConnectionTest } =
        useTestDatasetConnection();

    const testConnectionString = (datasetConnection: IDatasetConnection) => {
        setDatasetConnectionTest(datasetConnection);
        testConnection();
    };

    useEffect(() => {
        if (isValid !== null) {
            setNotification({
                message: isValid
                    ? 'Connection is valid'
                    : 'Connection is invalid',
                type: isValid ? 'success' : 'error',
            });
        }
    }, [isValid]);

    const [notification, setNotification] =
        useState<INotification>(defaultNotification);

    return (
        <>
            <OENotification
                setNotification={setNotification}
                notification={notification}
            />

            <OEFormikModal
                item={item}
                labelColumns={labelColumns}
                title={`${item.id
                    ? `Edit Dataset Connection: ${item.displayName}`
                    : `Add New Dataset Connection`
                    }`}
                progressMessage={ProgressMessages.DatasetConnection}
                successMessage={SuccessMessages.DatasetConnection}
                validationSchema={ValidationScheme}
                submitText="Save"
                component={Form}
                onCancel={onCancel}
                onSave={onSave}
                setItem={
                    item.id
                        ? setUpdateDatasetConnection
                        : setCreateDatasetConnection
                }
                service={item.id ? updateService : createService}
                altActionText="Test Connection"
                onAltAction={(item: IDatasetConnection) => {
                    testConnectionString(item);
                }}
            />
        </>
    );
};
