import { WebFileInfo } from '../types/web-file-info';
import Papa from 'papaparse';

export const deEncodeData = (file: WebFileInfo): Blob => {
    const blob = base64ToBlob(file.data, file.contentType);
    return blob;
};

export const base64ToBlob = (b64Data: any, type: string, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type });
};

export const parseCsv = (
    csv: string,
    hasHeader: boolean,
    generateHeader: boolean = false
): { headers: string[] | undefined; rows: any[]; errors: string[] } => {
    if (!hasHeader && generateHeader) {
        const firstLine = csv.substring(0, csv.indexOf('\n'));
        const data = firstLine.split(',');

        data.forEach((d, idx) => {
            data[idx] = `Column${idx + 1}`;
        });

        const newLineCharacter = csv.indexOf('\r\n') > -1 ? '\r\n' : '\n';
        csv = data.join(',') + newLineCharacter + csv;
        hasHeader = true;
    }

    const results = Papa.parse(csv, {
        header: hasHeader,
        skipEmptyLines: true,
    });

    return {
        headers: results.meta.fields,
        rows: results.data,
        errors: results.errors.map((e) => e.message),
    };
};
