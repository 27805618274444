import React, { useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';

export interface IComponentInfo {
    value: boolean;
    name: string;
    yesText?: string;
    noText?: string;
    onChange?: (data: boolean) => void;
    setFieldValue?: (id: string, data: any) => void;
    disabled?: boolean;
}

const OERadioBoolean: React.FunctionComponent<IComponentInfo> = ({
    value, name, yesText, noText, onChange, disabled, setFieldValue
}) => {

    const [v, setV] = useState<boolean>(value);

    useEffect(() => {
        setV(value);
    }, [value]);

    const handleChange = (event: any) => {
        const isChecked: boolean = event.target.value === '1';
        setFieldValue?.(name, isChecked);
        onChange?.(isChecked);
    };

    return (
        <>
            <FormCheck disabled={disabled}
                inline={true}
            >
                <FormCheck.Input
                    id={`${name}-yes`}
                    value={1}
                    checked={v}
                    type="radio"
                    name={name}
                    onChange={handleChange}
                />
                <FormCheck.Label htmlFor={`${name}-yes`}>{yesText || 'Yes'}</FormCheck.Label>
            </FormCheck>
            <FormCheck disabled={disabled}
                onClick={handleChange}
                inline={true}
            >
                <FormCheck.Input
                    id={`${name}-no`}
                    value={0}
                    checked={!v}
                    type="radio"
                    name={name}
                    onChange={handleChange}
                />
                <FormCheck.Label htmlFor={`${name}-no`}>{noText || 'No'}</FormCheck.Label>
            </FormCheck>
        </>
    );
};

export default OERadioBoolean;