
export enum SuccessMessages {
    Dashboard = 'Dashboard Content Saved Successfully',
    DcFileUpload = 'File Upload Saved Successfully',
    DefaultSuccess = "Saved Successfully",
    Logout = 'Logging you Out',
    Menu = 'Menu updated successfully',
    OrganizationType = 'Organization Type updated successfully',
    OrganizationUnit = 'Organization Unit updated successfully',
    Profile = 'Profile updated successfully',
    ProfileImage = 'Profile Image updated successfully',
    Report = 'Report updated successfully',
    ReportGroup = 'Report Group updated successfully',
    ReportPage = 'Report Page updated successfully',
    ReportServer = 'Report Server updated successfully',
    ReportType = 'Report Type updated successfully',
    Role = 'Role updated successfully',
    Route = 'Route updated successfully',
    User = 'User updated successfully',
    Artifact = 'Artifact updated successfully',
    DatasetConnection = 'Dataset Connection updated successfully',
    Glossary ='Glossary updated successfully',
    Reference ='Reference updated successfully'
}
