import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export interface IRoleExtraProperties {
    TypeId: number;
    DisplayName: string;
}

export const defaultRoleExtraProperties: IRoleExtraProperties = {
    TypeId: 0,
    DisplayName: ''
};

export interface IRole extends IBaseAuditedEntity {
    id: string;
    name: string;
    typeId: number;
    type: string;
    displayName: string;
    isDefault: boolean;
    isPublic: boolean;
    isStatic: boolean;
    extraProperties: IRoleExtraProperties;
    childCount: number;
    selected: boolean;
}

export const defaultRole: IRole = {
    id: '', name: '', typeId: 0, extraProperties: defaultRoleExtraProperties,
    isDefault: false, isPublic: true, isStatic: false, displayName: '', childCount: 0,
    type: '', selected: false
};

export const getRoleList = (i: IBaseList<IRole>): IRole[] => {
    const d: IRole[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultRole,
            ...v,
            typeId: v.extraProperties && v.extraProperties.TypeId ? v.extraProperties.TypeId : 0,
            displayName: v.extraProperties && v.extraProperties.DisplayName
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getRoleForSave = (i: IRole): IRole => {
    i.extraProperties.TypeId = i.typeId;
    i.extraProperties.DisplayName = i.displayName;
    return i;
};

