import { FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { OEFormLabel } from '../../../core/components/form/OEForm';
import { OEInputType } from '../../../core/components/form/OEInput';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import { getReportSectionConfigurations, getReportSectionItemConfigurations } from '../../entities/ReportConfiguration';
import { IReportGroup, ReportSectionType } from '../../entities/ReportGroup';
import { useCreateReportGroup, usePutReportGroup } from '../../services/ReportGroupService';
import ReportSectionLabel from '../admin-reports/ReportSectionLabel';
import Configuration from '../report-configruation/Configuration';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IReportGroup> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {

    const [showIcon, setShowIcon] = useState<boolean>(true);

    const setIcon = () => {
        setShowIcon(false);
        window.setTimeout(() => {
            setShowIcon(true);
        }, 0); // this setTimeout is a workaround cause the font awesome icon to display when changed
    }

    return (
        <>
            <OERow>
                <OECol sm={labelColumns}  >
                    <OEFormLabel >Preview</OEFormLabel>
                </OECol>
                {showIcon && (
                    <OECol sm={12 - labelColumns} >
                        <ReportSectionLabel group={values} />
                    </OECol>
                )}
            </OERow>
            <OEFormInput
                label="Label" name="title" value={values.title}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Description" name="description" value={values.description}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />

            {values.sectionType === ReportSectionType.SectionHeader && (
                <Configuration
                    values={values}
                    configurations={getReportSectionConfigurations(values.configuration)}
                    setFieldValue={setFieldValue}
                    onUpdated={setIcon}
                />

            )}

            {values.sectionType === ReportSectionType.SectionItem && (
                <>
                    <Configuration
                        values={values}
                        configurations={getReportSectionItemConfigurations(values.configuration)}
                        setFieldValue={setFieldValue}
                        onUpdated={setIcon}
                    />
                </>
            )}
            <OEFormBoolean
                label="Active" name="isActive" value={values.isActive}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IReportGroup>().shape({
    title: Yup.string().required('Label is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IReportGroup;
    onCancel: () => void;
    onSuccess: () => void;
    type: string;
}

const ReportSectionFormik: React.FunctionComponent<IFormikInfo> = ({ item, type, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReportGroup();
    const { service: createService, setItem: setCreateReportGroup } = useCreateReportGroup();

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            title={`${item.id === '' ? `Add New ${type}` : `Edit ${type}: ${item.title}`}`}
            progressMessage={ProgressMessages.ReportGroup}
            successMessage={SuccessMessages.ReportGroup}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReportGroup : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportSectionFormik;
