import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';

export enum EditorHeight {
    Large = 500,
    Medium = 400,
    Small = 300,
    XtraSmall = 200,
}

interface IComponentInfo {
    name: string;
    data: string;
    height?: EditorHeight;
    onChange?: (content: string, editor?: object) => void;
    autoFocus?: boolean;
    setFieldValue?: (id: string, data: any) => void;
    cssFiles?: string;
}

const OEEditor: React.FunctionComponent<IComponentInfo> = ({ name, data, onChange, height, setFieldValue, cssFiles }) => {
    const [d] = useState<string>(data);
    const handleChange = (changeEvent: any) => {
        if (d !== changeEvent.target.getContent()) {
            onChange?.(changeEvent.target.getContent(), changeEvent.editor);
            setFieldValue?.(name, changeEvent.target.getContent());
        } else {
            onChange?.(d);
            setFieldValue?.(name, d);
        }
    };

    return (
        <>
            <Editor
                apiKey="476n7tnppkpqbzrkfq4urdtjvmjkxf0kmbr9e05t0g0v1ule"
                initialValue={d || ''}
                init={{
                    height: height || EditorHeight.Large,
                    valid_elements: '*[*]',
                    content_css: cssFiles || '/src/css/styles.css,/src/css/custom.css,/src/css/fontawesome.css,/assets/dist/css/bootstrap.min.css',
                    plugins: [
                        'advlist autolink lists fullscreen',
                        'charmap print preview anchor help link',
                        'searchreplace visualblocks code',
                        'insertdatetime table paste wordcount'
                    ],

                }}
                onChange={handleChange}
            />

        </>
    );
};


export default OEEditor;

