import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from "../../core/entities/BaseList";
import { IReportGroup } from './ReportGroup';

export enum ReportPageType {
    MSSingleTiered = 'ST',
    MSThreeTiered = 'TT',
    BISingleTiered = 'BS'
}

export interface IReportPage extends IBaseAuditedEntity {
    id: string;
    name: string;
    path: string;
    description: string;
    groupCount: number;
    configuration: string;
    filters: string;
    pageType: string;
    groups: IReportGroup[];
}

export const defaultReportPage: IReportPage = {
    id: '', name: '', description: '', path: '', groupCount: 0, configuration: '',
    filters: '', pageType: ReportPageType.MSSingleTiered, groups: []
};

export const getReportPageList = (i: IBaseList<IReportPage>): IReportPage[] => {
    const pages: IReportPage[] = [];
    for (const p of i.items) {
        pages.push({ ...p, path: getPagePath(p.id, p.pageType) });
    }
    return pages;
};

const getPagePath = (id: string, pageType: string): string => {
    switch (pageType) {
        case ReportPageType.MSSingleTiered:
            return `/embedded-one?pid=${id}`;
        case ReportPageType.MSThreeTiered:
            return `/embedded-three?pid=${id}`;
        case ReportPageType.BISingleTiered:
            return `/bi-embedded-one?pid=${id}`;
        default:
            return `/reports?id=${id}`;
    }
}

