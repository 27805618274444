import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import OETabs, { OETab } from '../../../core/components/tabs/OETabs';
import { Icon } from '../../../core/entities/Icon';
import { refreshPage } from '../../../core/utilities/URL';
import { IModule } from '../../../site/entities/Module';
import { defaultRoute } from '../../../site/entities/Route';
import { useGetUserModules } from '../../../site/services/RouteService';
import ReportPageTiers from './ReportPageTiers';
import AdminServers from './ReportServers';
import AdminTypes from './ReportTypes';
import AdminReports from './Reports';

export const ModuleComponents = {
    ReportPages: 'ReportPages',
    ReportServers: 'ReportServers',
    ReportTypes: 'ReportTypes',
    Reports: 'Reports',
};

export interface ISelectedReportAdminViewInfo {
    reloadAdmin: () => void;
}

interface ISelectedComponentInfo extends ISelectedReportAdminViewInfo {
    name: string;
}

const SelectedComponent: React.FunctionComponent<ISelectedComponentInfo> = ({ name, reloadAdmin }) => {
    const Viewers: any = {};
    Viewers[ModuleComponents.ReportPages] = ReportPageTiers;
    Viewers[ModuleComponents.ReportServers] = AdminServers;
    Viewers[ModuleComponents.ReportTypes] = AdminTypes;
    Viewers[ModuleComponents.Reports] = AdminReports;

    const SelectedViewer: React.FunctionComponent<ISelectedReportAdminViewInfo> = Viewers[name];
    return (<SelectedViewer reloadAdmin={reloadAdmin} />);
};

const ReportAdmin: React.FunctionComponent = () => {
    const { service, setRoute } = useGetUserModules();

    const [modules, setModules] = useState<IModule[]>([]);

    useEffect(() => {
        setRoute({ ...defaultRoute, path: '/reportadmin' });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (service.result) {
            setModules(service.result.items);
        }
    }, [service]);

    const reloadAdmin = () => {
        refreshPage();
    }

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Report Administration" icon={Icon.ReportAdministration} />
            {modules.length > 0 && (
                <OETabs defaultTab={modules[0].component}>
                    {modules.map((item, index) =>
                        <OETab key={index} title={item.name} eventKey={item.component}>
                            <SelectedComponent name={item.component} reloadAdmin={reloadAdmin} />
                        </OETab>
                    )}
                </OETabs>
            )}
        </div>
    );
};

export default ReportAdmin;