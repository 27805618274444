import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { IGlossary } from '../../entities/Glossary';
import { OEInputType } from '../../../core/components/form/OEInput';
import { useCreateGlossary, usePutGlossary } from '../../services/GlossaryService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IGlossary> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
           {values.id && <OEFormInput
                label="Group" name="group" value={values.group}
                disabled={values.id ? true : false} errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String}
            />}
            <OEFormInput
                label="Term" name="term" value={values.term} maxLength={120}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Definition" name="definition" value={values.definition} maxLength={1000}
                errors={errors} touched={touched} columns={labelColumns} required={true}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IGlossary>().shape({
    term: Yup.string().required('Term is required').min(2).max(120).nullable(),
    definition: Yup.string().required('Definition is required').min(2).max(1000).nullable(),
});

interface IFormikInfo {
    item: IGlossary;
    onCancel: () => void;
    onSave: () => void;
}

const GlossaryFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {

    const { service, setGlossary } = usePutGlossary();
    const { service: createService, setGlossary: setCreateGlossary } = useCreateGlossary();
    const [localValues, setLocalValues] = useState<IGlossary>(item);

    useEffect(() => {
        setLocalValues(item);
    }, [item]);

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={localValues}
            labelColumns={labelColumns}
            title={`${item.id ? `Edit Glossary: ${item.term}` : `Add New Glossary`}`}
            progressMessage={ProgressMessages.Glossary}
            successMessage={SuccessMessages.Glossary}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            onSave={onSave}
            setItem={item.id ? setGlossary : setCreateGlossary}
            service={item.id ? service : createService}
        />
    );
};

export default GlossaryFormik;
