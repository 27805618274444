export interface IDcFileToUpload {
    id: string;
    agencyCode: string;
    agencyAzureDataSource: string;
    agencyAzureFolder: string;
    azureFileUrl: string;
    term: string;
    fileName: string;
    fileBody: string;
}

export const defaultDcFileToUpload: IDcFileToUpload = {
    id: '',
    agencyCode: '',
    agencyAzureDataSource: '',
    agencyAzureFolder: '',
    azureFileUrl: '',
    term: '',
    fileName: '',
    fileBody: '',
};
