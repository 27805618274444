import React, { useEffect, useState } from 'react';
import WidgetContent from '../widgets/components/WidgetContent';
import { getWidgetList, IWidget } from '../widgets/entities/Widget';
import { getWidgetGroupList } from '../widgets/entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../widgets/services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../widgets/services/WidgetService';
import './styles/helppage.css';
 

const HelpPage: React.FunctionComponent = () => {
    const { service: groupService } = useGetWidgetGroupByCode('HELPPAGE');
    const { service, setGroupId } = useGetWidgetsByGroupId();
    const [items, setItems] = useState<IWidget[]>([]);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
        // eslint-disable-next-line
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);

    return (
        <>
            <div className="container m-t-40">
                <WidgetContent items={items} code='CONTENT-HELP' />
            </div>
        </>
    );
};

export default HelpPage;