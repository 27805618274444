import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { defaultConfirmationMessage, getConfirmationMessage, IConfirmationMessage, MessageSubstitions } from '../../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../../core/components/messaging/entities/Notification';
import { ProfileDefaultRoleConfirmation } from '../../../core/components/messaging/enums/ConfirmationMessages';
import { InformationMessage } from '../../../core/components/messaging/enums/InformationMessages';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OENotification from '../../../core/components/messaging/OENotification';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { getOrganizationTypeList, IOrganizationType } from '../../../organization/entities/OrganizationType';
import { getOrganizationUnitList, IOrganizationUnit } from '../../../organization/entities/OrganizationUnit';
import { getRoleList, IRole } from '../../../organization/entities/Role';
import { useGetOrganizationTypes } from '../../../organization/services/OrganizationTypeService';
import { useGetOrganizationUnits } from '../../../organization/services/OrganizationUnitService';
import { useGetRoles } from '../../../organization/services/RoleService';
import { defaultSecurityUser, getSecurityUserList, ISecurityUser } from '../../entities/SecurityUser';
import { IUser } from '../../entities/User';
import { useGetSecurityUser, useSaveSecurityUser } from '../../services/SecurityUserService';
import UserPermission from '../UserPermission';

interface IComponentInfo {
    item: IUser;
}

const UserPermissions: React.FunctionComponent<IComponentInfo> = ({ item }) => {
    const { service, setUserId } = useGetSecurityUser();
    const { service: typeService } = useGetOrganizationTypes();
    const { service: roleService, doRefresh: loadRoles } = useGetRoles();
    const { service: unitService, doRefresh: loadUnits } = useGetOrganizationUnits();
    const { service: saveService, setSecurityUser: setSaveSecurityUser } = useSaveSecurityUser();
    const [errors, setErrors] = useState<string[]>([]);

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [types, setTypes] = useState<IOrganizationType[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [units, setUnits] = useState<IOrganizationUnit[]>([]);
    const [currentSecurity, setCurrentSecurity] = useState<ISecurityUser[]>([]);
    const [defaultPermission, setDefaultPermission] = useState<ISecurityUser>(defaultSecurityUser);
    const [otherPermissions, setOtherPermissions] = useState<ISecurityUser[]>([]);
    const [saveList, setSaveList] = useState<ISecurityUser[]>([]);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (typeService.result) {
            setTypes(getOrganizationTypeList(typeService.result));
        }
        // eslint-disable-next-line
    }, [typeService]);

    useEffect(() => {
        if (roleService.result) {
            setRoles(getRoleList(roleService.result).filter(q => !q.isStatic));
        }
        // eslint-disable-next-line
    }, [roleService]);

    useEffect(() => {
        if (unitService.result) {
            setUnits(getOrganizationUnitList(unitService.result, types));
        }
        // eslint-disable-next-line
    }, [unitService]);

    useEffect(() => {
        if (service.result) {
            const v: ISecurityUser[] = getSecurityUserList(service.result, roles, units);
            setCurrentSecurity([...v]);
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (saveService.isFinished) {
            if (saveService.isSuccess) {
                if (saveList.length === 1) {
                    setNotification({ message: 'Default Role has been updated', type: 'success' });
                    setUserId(item.id);
                }
                updateSaveList();
            } else {
                setErrors([saveService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [saveService]);

    useEffect(() => {
        setDefaultPermission(currentSecurity.filter(q => q.isDefault).length > 0 ? currentSecurity.filter(q => q.isDefault)[0] : defaultPermission);
        setOtherPermissions(currentSecurity.filter(q => !q.isDefault));
        // eslint-disable-next-line
    }, [currentSecurity]);

    useEffect(() => {
        setDefaultPermission(currentSecurity.filter(q => q.isDefault).length > 0 ? currentSecurity.filter(q => q.isDefault)[0] : defaultPermission);
        setOtherPermissions(currentSecurity.filter(q => !q.isDefault));
        // eslint-disable-next-line
    }, [currentSecurity]);

    useEffect(() => {
        if (units.length > 0) {
            setUserId(item.id);
        }
        // eslint-disable-next-line
    }, [units]);

    useEffect(() => {
        if (saveList.length > 0) {
            setSaveSecurityUser(saveList[0]);
        }
        // eslint-disable-next-line
    }, [saveList]);

    useEffect(() => {
        return () => { };
    }, []);

    useEffect(() => {
        if (units.length > 0) {
            setUserId(item.id);
        }
        // eslint-disable-next-line
    }, [units]);

    useEffect(() => {
        if (types.length > 0 && roles.length === 0) {
            loadRoles();
        }
        // eslint-disable-next-line
    }, [types]);

    useEffect(() => {
        if (roles.length > 0 && units.length === 0) {
            loadUnits();
        }
        // eslint-disable-next-line
    }, [roles]);

    const updateSaveList = () => {
        saveList.shift();
        if (saveList.length > 0) {
            setSaveList([...saveList]);
        }
    }

    const onSetDefault = (i: ISecurityUser) => {
        const s: ISecurityUser[] = currentSecurity;
        for (const v of s) {
            if (i.organizationUnitId === v.organizationUnitId && i.roleId === v.roleId) {
                v.dirty = true;
                v.isDefault = true;
            }
            else if (v.isDefault) {
                v.dirty = true;
                v.isDefault = false;
            }
        }
        setSaveList(s);
    }

    const onConfirmDelete = (i: ISecurityUser) => {
        const m: IConfirmationMessage = getConfirmationMessage(ProfileDefaultRoleConfirmation, [{ name: MessageSubstitions.Name, value: `${i.organizationUnit} - ${i.role}` }]);
        setConfirmation({ ...m, setConfirmation, item: i, onOk: onSetDefault });
    };

    return (
        <>
            <OEMessage
                hidden={
                    unitService.isInProgress || roleService.isInProgress
                    || typeService.isInProgress || service.isInProgress || currentSecurity.length > 0}
                informationMessage={InformationMessage.PROFILE_PERMISSIONS_NONE}
            />
            <OESpinner
                hidden={
                    !unitService.isInProgress && !roleService.isInProgress
                    && !typeService.isInProgress && !unitService.isInProgress
                }
                message="Loading Permissions"
            />

            <OEErrorList errors={errors} />
            {currentSecurity.length > 0 && (
                <>
                    <OEHeading underline={true} size={4} text="Default Role" />
                    <OEMessage hidden={defaultPermission.roleId !== ''} informationMessage={InformationMessage.PROFILE_PERMISSIONS_NODEFAULT} />
                    {defaultPermission.roleId !== '' && (
                        <OERow>
                            {currentSecurity.filter(q => q.isDefault).map((item, index) =>
                                <OECol key={index} sm={6}>
                                    <UserPermission item={item} />
                                </OECol>
                            )}
                        </OERow>
                    )}

                    {otherPermissions.length > 0 && (
                        <>
                            <OEHeading className="m-t-20" underline={true} size={4} text="Other Roles" />
                            <OERow>
                                {otherPermissions.map((item, index) =>
                                    <OECol key={index} sm={6}>
                                        <UserPermission onDefault={onConfirmDelete} item={item} />
                                    </OECol>
                                )}
                            </OERow>
                        </>
                    )}
                </>
            )}
            <OENotification setNotification={setNotification} notification={notification} />
            <OEConfirmation {...confirmation} />
        </>
    );
};

export default UserPermissions;

