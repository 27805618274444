import React, { Children } from 'react';
import { displayHTMLContent } from '../../utilities/Miscellaneous';

export interface IQPageTitle {
    text?: string;
    size?: number;
    hidden?: boolean;
    icon?: string;
    className?: string;
    underline?: boolean;
    children?: any;
}

const OEHeading: React.FunctionComponent<IQPageTitle> = ({ text, size, hidden, icon, className, underline, children }) => {
    return (
        <>
            {!hidden && (
                <p className={`h${size || 4} ${className || ''}`}>
                    {icon && (
                        <i className={icon} />
                    )}
                    {text && (
                        <span dangerouslySetInnerHTML={displayHTMLContent(text)} />
                    )}
                    {Children.map(children, i => i)}
                </p>
            )}
            {underline && (
                <hr className="m-t-0" />
            )}
        </>
    );
};

export default OEHeading;
