import React, { CSSProperties, Fragment } from 'react';
import { Flex, Spin } from 'antd';

/// -----------------------------------------------
/// Spinner Component
/// -----------------------------------------------

export enum SpinnerStyle {
    Large = 'fas fa-spinner fa-pulse fa-2x fa-fw',
    Small = 'fas fa-spinner fa-pulse fa-fw',
    ExtraSmall = 'fas fa-spinner fa-pulse fa-xs fa-fw'
}

interface IComponentInfo {
    className?: string;
    classNameMessage?: string;
    message?: string;
    hidden?: boolean;
    oeStyle?: string;
    flexStyle?: boolean;
}

const iconDiv: CSSProperties = {
    verticalAlign: 'middle'
};

const mainDiv: CSSProperties = {
    margin: '5px',
    padding: '5px',
};

const OESpinner: React.FunctionComponent<IComponentInfo> = ({ message, hidden, className, oeStyle, classNameMessage, flexStyle }) => (
    <Fragment>
        {message !== '' && !hidden && (
            <>
                {!flexStyle && (
                    <div style={mainDiv} className={`${className ?? ''} spinner-content`}>
                        <i className={oeStyle || SpinnerStyle.Large} style={iconDiv} />
                        <strong>
                            <p className={classNameMessage} style={{ marginBottom: 0 }}>{message ? message : 'Loading ...'}...</p>
                        </strong>
                    </div>
                )}
                {flexStyle && (
                    <Flex gap="middle" vertical>
                        <Flex gap="middle">
                            <Spin tip="Loading" size="large">
                                <div style={{
                                    padding: 50,
                                    background: 'rgba(0, 0, 0, 0.05)',
                                    borderRadius: 4,
                                }} />
                            </Spin>
                        </Flex>

                    </Flex>
                )}
            </>
        )}
    </Fragment>
);

export default OESpinner;