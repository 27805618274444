import axios from 'axios';
import { defaultRequestResponse, IRequestResponse } from '../entities/RequestResponse';
import { getAuthorizationToken } from './Authentication';
import { INotification } from '../components/messaging/entities/Notification';

const config = {
    headers: {
        'RequestVerificationToken': `${getAuthorizationToken()}`
    }
};

export interface IDeleteResult {
    success: boolean;
    data: any;
    response: IRequestResponse;
}

export class DeleteRequest {
    public static empty(): DeleteRequest {
        return new DeleteRequest(
            false,
            false,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static inProgress(): DeleteRequest {
        return new DeleteRequest(
            true,
            true,
            false,
            undefined,
            defaultRequestResponse
        );
    }

    public static success(data: any): DeleteRequest {
        return new DeleteRequest(
            false,
            true,
            true,
            data,
            defaultRequestResponse
        );
    }

    public static error(error: string): DeleteRequest {
        return new DeleteRequest(
            false,
            false,
            true,
            undefined,
            { ...defaultRequestResponse, message: error }
        );
    }

    constructor(
        public isInProgress: boolean,
        public isSuccess: boolean,
        public isFinished: boolean,
        public data: any,
        public response: IRequestResponse
    ) { }
}

export const processDeleteAsync = (setService: (p: DeleteRequest) => void, url: string) => {
    // tslint:disable-next-line:no-floating-promises
    DeleteAsync(setService, url);
};

const DeleteAsync = async (setService: (p: DeleteRequest) => void, url: string) => {
    try {
        setService(DeleteRequest.inProgress());

        const result = await axios.delete<IDeleteResult>(`${url}`, config);
        setService(DeleteRequest.success(result));
    } catch (e: any) {
        console.error(e);
        if (e.message) {
            setService(DeleteRequest.error(e.message));
        } else {
            setService(DeleteRequest.error(e.response.data.message));
        }
    }
};

interface IProcessDeleteRequest {
    service: any;
    setNotification?: (i: INotification) => void;
    successMessage?: string;
    successAction?: any;
    scroll?: boolean;
    errorMessage?: string;
}

export const processDeleteRequest = (item: IProcessDeleteRequest) => {
    // tslint:disable-next-line:no-floating-promises
    if (item.service.isFinished) {
        if (item.service.isSuccess) {
            item.successMessage && item.setNotification?.({ type: 'success', message: item.successMessage });
            item.successAction && item.successAction();
        }
        else {
            item.errorMessage && item.setNotification?.({ message: item.errorMessage, type: 'error', });
        }
    }
};