import { useEffect } from "react";

export const  useOutsideAlerter = (ref :  React.RefObject<HTMLInputElement>, action: Function) =>{
  useEffect(() => {

    function handleClickOutside(event : any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [action, ref]);
}