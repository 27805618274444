import React from 'react';
import { getReportGroupIcon, IReportGroup } from '../../../../reporting/entities/ReportGroup';
import { ReportIconBaseURL } from '../../../../reporting/entities/ReportIcons';
import OEIcon from '../../../../core/components/general/OEIcon';
import { Icon } from '../../../../core/entities/Icon';
import ReportDownload from './ReportDownload';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    group: IReportGroup;
    pageConfigruation: string;
    openSection: string[];
    setOpenSection: (id: string) => void;

}

const GroupList: React.FunctionComponent<IComponentInfo> = ({ group, pageConfigruation, openSection, setOpenSection }) => {

    const toggleSection = (id: string) => {
        setOpenSection(id);
    };

    return (
        <>
            {group.reportCount > 0 && (
                <div>
                    {group.configuration && group.configuration.includes(".svg") && getReportGroupIcon(group.configuration).length ?
                        getReportGroupIcon(group.configuration) !== ReportIconBaseURL && (
                            <ul className='form-label m-t-20'>
                                <img alt={group.title} className={`report-icon m-r-5`} src={getReportGroupIcon(group.configuration)} ></img>
                                <span className='fw-bolder h5 align-self-center'>{group.title}</span>
                            </ul>
                        ) : group.reports && group.reports.length > 0 && openSection.length > 0 && group.reportGroupId
                            && openSection.includes(group.reportGroupId) ?
                            <ul className='form-label m-t-10' key={group.title}>
                                <span className='fw-bold h6 align-self-center download-icon'> {group.title} </span>
                                {group.reports.map((rl: any) =>
                                    <ul className='form-label' key={group.title}>
                                        <ReportDownload pageConfigruation={pageConfigruation} key={rl.id} report={rl} title={group.title} />
                                    </ul>
                                )}
                            </ul> : null
                    }
                    {group.groups.length > 0 && (
                        <ul className='form-label m-t-15' key={group.title}>
                            {group.configuration && !group.configuration.includes(".svg") &&
                                <div style={{ width: "fit-content" }} onClick={() => toggleSection(group.id)}>
                                    {openSection.length > 0 && openSection.includes(group.id)
                                        && <OEIcon icon={Icon.Minus} />}
                                    {(openSection.length === 0) || (openSection.length > 0 && !openSection.includes(group.id))
                                        ? <OEIcon icon={Icon.Add} /> : null}
                                    <span className='fw-bold h6 align-self-center download-icon'> {group.title} </span>
                                </div>
                            }
                            {group.groups.map((sl) =>
                               <GroupList pageConfigruation={pageConfigruation} key={sl.id} group={sl} openSection={openSection} setOpenSection={setOpenSection} />
                            )}
                        </ul>
                    )}
                </div>
            )}
        </>
    );
};

export default GroupList;
