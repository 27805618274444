import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultMenuPermissionUpdate, IMenuPermissionUpdate } from '../entities/Menu';
import { ISecurityMenuListItem } from '../entities/SecurityMenu';

export const useGetSecurityMenu = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<ISecurityMenuListItem>>());
    const [menuId, setMenuId] = useState<number>(-1);

    useEffect(() => {
        if (menuId < 0) { return; }
        processGetAsync(setService, `/api/app/security-menus?MenuId=${menuId}`);
        setMenuId(-1);
    }, [menuId]);

    return { service, setMenuId };
};

export const usePutMenuPermissions = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [security, setSecurity] = useState<IMenuPermissionUpdate>(defaultMenuPermissionUpdate);

    useEffect(() => {
        if (isNaN(security.id) || security.id <= 0) { return; }
        processPutAsync(setService, `/api/app/menus/${security.id}/permissions`, security.permissions);
        setSecurity(defaultMenuPermissionUpdate);
    }, [security]);

    return { service, setSecurity };
};
