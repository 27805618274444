import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../../core/components/form/OEButton';
import OEModal, { ModalSize, OEModalBody, OEModalFooter, OEModalHeader, OEModalTitle } from '../../../core/components/general/OEModal';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { InformationMessage } from '../../../core/components/messaging/enums/InformationMessages';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { Icon } from '../../../core/entities/Icon';
import { getOrganizationTypeList, IOrganizationType } from '../../../organization/entities/OrganizationType';
import { getOrganizationUnitList, IOrganizationUnit } from '../../../organization/entities/OrganizationUnit';
import { getRoleList, IRole } from '../../../organization/entities/Role';
import { useGetOrganizationTypes } from '../../../organization/services/OrganizationTypeService';
import { useGetOrganizationUnits } from '../../../organization/services/OrganizationUnitService';
import { useGetRoles } from '../../../organization/services/RoleService';
import { getSecurityUserList, getSecurityUserUniqueId, ISecurityUser } from '../../entities/SecurityUser';
import { IUser } from '../../entities/User';
import { useCreateSecurityUser, useDeleteSecurityUser, useGetSecurityUser, useSaveSecurityUser } from '../../services/SecurityUserService';
import UserPermission from '../UserPermission';
import UserPermissionAdd from './UserPermissionAdd';

enum ViewTypes {
    List = 1,
    Permissions,
}

interface IComponentInfo {
    item: IUser;
    onCancel: () => void;
}

const UserPermissions: React.FunctionComponent<IComponentInfo> = ({ item, onCancel }) => {
    const { service, setUserId } = useGetSecurityUser();
    const { service: typeService } = useGetOrganizationTypes();
    const { service: roleService, doRefresh: loadRoles } = useGetRoles();
    const { service: unitService, doRefresh: loadUnits } = useGetOrganizationUnits();
    const { service: createService, setSecurityUser: setCreateSecurityUser } = useCreateSecurityUser();
    const { service: saveService, setSecurityUser: setSaveSecurityUser } = useSaveSecurityUser();
    const { service: deleteService, setSecurtyUserId: setDeleteSecurityUserId } = useDeleteSecurityUser();

    const [types, setTypes] = useState<IOrganizationType[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [units, setUnits] = useState<IOrganizationUnit[]>([]);
    const [currentSecurity, setCurrentSecurity] = useState<ISecurityUser[]>([]);
    const [savedSecurity, setSavedSecurity] = useState<ISecurityUser[]>([]);
    const [saveList, setSaveList] = useState<ISecurityUser[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.List);

    useEffect(() => {
        if (typeService.result) {
            setTypes(getOrganizationTypeList(typeService.result));
        }
    }, [typeService]);

    useEffect(() => {
        if (roleService.result) {
            setRoles(getRoleList(roleService.result).filter(q => !q.isStatic));
        }
    }, [roleService]);

    useEffect(() => {
        if (unitService.result) {
            setUnits(getOrganizationUnitList(unitService.result, types));
        }
        // eslint-disable-next-line
    }, [unitService]);

    useEffect(() => {
        if (service.result) {
            const v: ISecurityUser[] = getSecurityUserList(service.result, roles, units);
            setSavedSecurity([...v]);
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        return () => { };
    }, []);

    useEffect(() => {
        if (units.length > 0) {
            setUserId(item.id);
        }
        // eslint-disable-next-line
    }, [units]);

    useEffect(() => {
        if (types.length > 0 && roles.length === 0) {
            loadRoles();
        }
        // eslint-disable-next-line
    }, [types]);

    useEffect(() => {
        if (roles.length > 0 && units.length === 0) {
            loadUnits();
        }
        // eslint-disable-next-line
    }, [roles]);

    useEffect(() => {
        if (roles.length > 0) {
            const roleList: IRole[] = [...roles];
            for (const v of currentSecurity) {
                roleList.filter(q => q.id === v.roleId)[0].selected = true;
            }
            setRoles(roleList);
        }
        // eslint-disable-next-line
    }, [currentSecurity]);

    useEffect(() => {
        if (createService.isFinished) {
            if (createService.isSuccess) {
                updateSaveList();
            } else {
                setErrors([createService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [createService]);

    useEffect(() => {
        if (saveService.isFinished) {
            if (saveService.isSuccess) {
                updateSaveList();
            } else {
                setErrors([createService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [saveService]);

    useEffect(() => {
        if (deleteService.isFinished) {
            if (deleteService.isSuccess) {
                updateSaveList();
            } else {
                setErrors([deleteService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        setCurrentSecurity([...savedSecurity]);
    }, [savedSecurity]);

    useEffect(() => {
        if (saveList.length > 0) {
            if (saveList[0].delete) {
                setDeleteSecurityUserId(saveList[0].id);
            }
            else if (saveList[0].add) {
                setCreateSecurityUser(saveList[0]);
            }
            else {
                setSaveSecurityUser(saveList[0]);
            }
        }
        // eslint-disable-next-line
    }, [saveList]);

    const updateSaveList = () => {
        saveList.shift();
        if (saveList.length > 0) {
            setSaveList([...saveList]);
        } else {
            onCancel();
        }
    }

    const onSave = (i: ISecurityUser[]) => {
        if (i.length > 0) {
            const s: ISecurityUser[] = currentSecurity.filter(q => q.organizationUnitId !== i[0].organizationUnitId);
            for (const v of i) {
                if (currentSecurity.filter(q => getSecurityUserUniqueId(q) === getSecurityUserUniqueId(v)).length === 0) {
                    s.push({ ...v });
                }
                else {
                    s.push(currentSecurity.filter(q => getSecurityUserUniqueId(q) === getSecurityUserUniqueId(v))[0]);
                }
            }
            setCurrentSecurity([...s]);
        }
        setShowView(ViewTypes.List);
    }

    const onSubmit = () => {
        const s: ISecurityUser[] = [];
        for (const v of currentSecurity) {
            if (savedSecurity.filter(q => getSecurityUserUniqueId(q) === getSecurityUserUniqueId(v)).length === 0) {
                s.push({ ...v, add: true });
            }
            else {
                const t: ISecurityUser = savedSecurity.filter(q => getSecurityUserUniqueId(q) === getSecurityUserUniqueId(v))[0];
                if (t.dirty) {
                    s.push({ ...v, add: false });
                }
            }
        }
        for (const v of savedSecurity) {
            if (currentSecurity.filter(q => getSecurityUserUniqueId(q) === getSecurityUserUniqueId(v)).length === 0) {
                s.push({ ...v, delete: true });
            }
        }

        if (s.length === 0) {
            onCancel();
        }
        else {
            setSaveList(s);
        }
    }

    const onAddCancel = () => {
        setShowView(ViewTypes.List);
    }

    const onAdd = () => {
        setShowView(ViewTypes.Permissions);
    }

    const onDelete = (i: ISecurityUser) => {
        setCurrentSecurity([...currentSecurity.filter(q => q.organizationUnitId + q.roleId !== i.organizationUnitId + i.roleId)]);
    }

    const onDefault = (i: ISecurityUser) => {
        const s: ISecurityUser[] = currentSecurity;
        for (const v of s) {
            v.isDefault = i.organizationUnitId === v.organizationUnitId && i.roleId === v.roleId;
            v.dirty = true;
        }
        setCurrentSecurity([...s]);
    }

    return (
        <OEModal oeSize={ModalSize.Medium} show={true}>
            <OEModalHeader onHide={onCancel}>
                <OEModalTitle>{`Current Roles for ${item.userName}`}</OEModalTitle>
            </OEModalHeader>
            {showView === ViewTypes.List &&
                <>
                    <OEModalBody>
                        <OEMessage
                            hidden={
                                unitService.isInProgress || roleService.isInProgress
                                || typeService.isInProgress || service.isInProgress || currentSecurity.length > 0}
                            informationMessage={InformationMessage.USER_ADMIN_NONE}
                        />
                        <OESpinner
                            hidden={
                                !unitService.isInProgress && !roleService.isInProgress
                                && !typeService.isInProgress && !unitService.isInProgress
                            }
                            message="Loading Security"
                        />
                        {units.length > 0 && (
                            <>
                                <OEErrorList errors={errors} />
                                <OERow >
                                    {currentSecurity.map((item, index) =>
                                        <OECol className="m-t-10" key={index} sm={6}>
                                            <UserPermission onDefault={onDefault} onDelete={onDelete} item={item} />
                                        </OECol>
                                    )}
                                </OERow>
                                <div className="pull-right">
                                    <small><i>* any changes you make are not saved until you hit Save</i></small>
                                </div>
                            </>
                        )}
                    </OEModalBody>
                    <OEModalFooter>
                        <OEMessage message="Saving Perimissions" hidden={!createService.isInProgress && !deleteService.isInProgress} />
                        <OEButton disabled={createService.isInProgress || deleteService.isInProgress} icon={Icon.Add} onClick={onAdd} text={'Add Permission'} />
                        <OEButton disabled={createService.isInProgress || deleteService.isInProgress} onClick={onSubmit} text={'Save'} />
                        {onCancel && (
                            <OEButton disabled={createService.isInProgress || deleteService.isInProgress} bStyle={ButtonStyle.Cancel} onClick={onCancel} text={'Cancel'} />
                        )}
                    </OEModalFooter>
                </>
            }
            {showView === ViewTypes.Permissions &&
                <UserPermissionAdd
                    currentSecurity={currentSecurity}
                    userId={item.id}
                    roles={roles}
                    units={units}
                    onCancel={onAddCancel}
                    onSave={onSave}
                />
            }
        </OEModal>
    );
};

export default UserPermissions;

