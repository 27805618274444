import { AppearanceTypes } from "react-toast-notifications";

export interface INotification {
    message: string;
    type: AppearanceTypes;
    duration?: number;
}

export const defaultNotification: INotification = {
    message: '', type: 'success'
};

