import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { IUserPaginationFilter } from "./User";

export interface IReference extends IBaseAuditedEntity {
    id: string;
    title: string;
    definition: string;
    sortOrder: number;
    url: string;
}

export const defaultReference: IReference = {
    id: '',
    title: '',
    definition: '',
    sortOrder: 0,
    url: '',
};

export const getReferenceForSave = (i: IReference): IReference => {
    return i;
};

export const defaultPaginationFilter: IUserPaginationFilter = {
    sorting: "sortOrder", skipCount: 0, maxResultCount: 10
};