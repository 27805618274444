import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultMenu, getMenuForSave, IMenu, IMenuListItem } from '../entities/Menu';

export const useGetMenus = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IMenuListItem>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/menus?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetMenuByParentId = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IMenuListItem>>());
    const [parentId, setParentId] = useState<number>(-1);

    useEffect(() => {
        if (isNaN(parentId) || parentId <= 0) { return; }
        processGetAsync(setService, `/api/app/menus?MaxResultCount=1000&ParentIdMin=${parentId}&ParentIdMax=${parentId}`);
        setParentId(-1);
    }, [parentId]);

    return { service, setParentId };
};

export const useGetUserMenus = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IMenu>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/menus/usermenus`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutMenu = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [menu, setMenu] = useState<IMenu>(defaultMenu);

    useEffect(() => {
        if (menu.id <= 0) { return; }
        processPutAsync(setService, `/api/app/menus/${menu.id}`, getMenuForSave(menu));
        setMenu(defaultMenu);
    }, [menu]);

    return { service, setMenu };
};

export const useCreateMenu = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [menu, setMenu] = useState<IMenu>(defaultMenu);

    useEffect(() => {
        if (menu.name === '') { return; }
        processPostAsync(setService, `/api/app/menus`, getMenuForSave(menu));
        setMenu(defaultMenu);
    }, [menu]);

    return { service, setMenu };
};

export const useDeleteMenu = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [menuId, setMenuId] = useState<number>(-1);

    useEffect(() => {
        if (menuId < 0) { return; }
        processDeleteAsync(setService, `/api/app/menus/${menuId}`);
        setMenuId(-1);
    }, [menuId]);

    return { service, setMenuId };
};

