import { IDropdownFields } from '../../form/OEDropdown';

export enum FilterType {
    Input = 1,
    Dropdown,
    YesNo,
    MultiSelect,
}

export enum FilterDataType {
    String = 1,
    Number,
    Boolean,
    MultiSelect
}

export interface IFilter {
    name: string;
    filters: IFilterItem[];
    autoSearch?: boolean;
}

export const defaultFilter: IFilter = {
    name: '', filters: []
};

export interface IFilterValue {
    name: string;
    value: any;
    dataType: FilterDataType;
}

export interface IFilterItem {
    name: string;
    label: string;
    width?: number;
    primary?: boolean;
    columns?: string[];
    type?: FilterType;
    dataType?: FilterDataType;
    placeholder?: string;
    defaultValue?: any;
    values?: any[];
    defaultSelect?: any;
    hideDefault?: boolean;
    fields?: IDropdownFields;
    autoSearch?: boolean;
    autoSearchLength?: number;
    hide?: boolean;
}

export interface IBaseFilter {
    performSearch: boolean;
}

export const defaultBaseFilter: IBaseFilter = {
    performSearch: false
};
