import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReportFlag, IReportFlag, } from '../entities/ReportFlag';

export const useGetReportFlag = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportFlag>>());
    const [item, setItem] = useState<IReportFlag>(defaultReportFlag);

    useEffect(() => {
        if (item.reportId === '') { return; }
        processGetAsync(setService, `/api/app/report-flags?MaxResultCount=1000&AcademicYear=${item.academicYear}&ReportId=${item.reportId}&DistrictId=${item.districtId}&SchoolId=${item.schoolId}`);
    }, [item]);

    return { service, setItem };
};

export const usePutReportFlag = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportFlag>(defaultReportFlag);

    useEffect(() => {
        if (item.reportId === '') { return; }
        processPutAsync(setService, `/api/app/report-flags/${item.id}`, item);
        setItem(defaultReportFlag);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportFlag = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportFlag>(defaultReportFlag);

    useEffect(() => {
        if (item.reportId === '') { return; }
        processPostAsync(setService, `/api/app/report-flags`, item);
        setItem(defaultReportFlag);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportFlag = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [reportId, setReportId] = useState<string>('');

    useEffect(() => {
        if (reportId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-flags/${reportId}`);
        setReportId('');
    }, [reportId]);

    return { service, setReportId };
};
