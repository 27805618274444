import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync } from '../../core/services/PutRequest';
import { defaultSecurityUser, ISecurityUser, ISecurityUserListItem } from '../entities/SecurityUser';

export const useGetSecurityUser  = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<ISecurityUserListItem>>());
    const [userId, setUserId] = useState<string>('');

    useEffect(() => {
        if (userId === '') { return; }
        processGetAsync(setService, `/api/app/security-users?UserId=${userId}`);
        setUserId('');
    }, [userId]);

    return { service, setUserId };
};

export const useSaveSecurityUser = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [securityUser, setSecurityUser] = useState<ISecurityUser>(defaultSecurityUser);

    useEffect(() => {
        if (securityUser.userId === '') { return; }
        processPutAsync(setService, `/api/app/security-users/${securityUser.id}`, securityUser);
        setSecurityUser(defaultSecurityUser);
    }, [securityUser]);

    return { service, setSecurityUser };
};

export const useCreateSecurityUser = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [securityUser, setSecurityUser] = useState<ISecurityUser>(defaultSecurityUser);

    useEffect(() => {
        if (securityUser.userId === '') { return; }
        processPostAsync(setService, `/api/app/security-users`, securityUser);
        setSecurityUser(defaultSecurityUser);
    }, [securityUser]);

    return { service, setSecurityUser };
};

export const useDeleteSecurityUser = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [securityUserId, setSecurtyUserId] = useState<number>(-1);

    useEffect(() => {
        if (securityUserId < 0) { return; }
        processDeleteAsync(setService, `/api/app/security-users/${securityUserId}`);
        setSecurtyUserId(-1);
    }, [securityUserId]);

    return { service, setSecurtyUserId };
};

