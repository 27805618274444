import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { IArtifactDatasetBase } from "./ArtifactDatasetBase";
import { IArtifactMicroStrategyBase } from "./ArtifactMicroStrategyBase";
import { IArtifactSsrsBase } from "./ArtifactSsrsBase";
import { ArtifactType } from "./ArtifactType";
import { IArtifactUrlBase } from "./ArtifactUrlBase";

export interface IArtifact extends IBaseAuditedEntity {
    displayName?: string;
    description?: string;
    application?: string;
    domainTagsCsv?: string;
    subjectTagsCsv?: string;
    artifactType: ArtifactType;
    isPublished: boolean;
    thumbnail?: string;
    tags?: string;
    extraProperties: Record<string, object>;
    dataset: IArtifactDatasetBase;
    microStrategy: IArtifactMicroStrategyBase;
    ssrs: IArtifactSsrsBase;
    url: IArtifactUrlBase;
}

export const defaultArtifact: IArtifact = {
    displayName: undefined,
    description: undefined,
    application: 'NimblePortal',
    domainTagsCsv: undefined,
    subjectTagsCsv: undefined,
    artifactType: ArtifactType.AllArtifacts,
    isPublished: false,
    thumbnail: undefined,
    tags: undefined,
    extraProperties: {},
    dataset: {
        databaseConnectionId: undefined,
        datasetView: undefined,
        filters: [],
        categories: []
    },
    microStrategy: {
        reportId: '',
        reportName: ''
    },
    ssrs: {
        reportPath: undefined,
        reportName: undefined,
        reportQueryString: undefined
    },
    url: {
        reportUrl: undefined
    },
};

export const getMicroStrategyArtifactForSave = (artifact: IArtifact): any => {
    return {
        ...getCommonArtifactForSave(artifact),
        application: 'MicroStrategy',
        reportId: artifact.microStrategy.reportId,
        reportName: artifact.microStrategy.reportName,
    };
};

export const getSsrsArtifactForSave = (artifact: IArtifact): any => {
    return {
        ...getCommonArtifactForSave(artifact),
        reportPath: artifact.ssrs.reportPath,
        reportName: artifact.ssrs.reportName,
        reportQueryString: artifact.ssrs.reportQueryString,
    };
};

export const getUrlArtifactForSave = (artifact: IArtifact): any => {
    return {
        ...getCommonArtifactForSave(artifact),
        reportUrl: artifact.url.reportUrl,
    };
};

export const getCommonArtifactForSave = (artifact: IArtifact): any => {
    return {
        displayName: artifact.displayName,
        description: artifact.description,
        application: artifact.application || 'NimblePortal',
        domainTagsCsv: artifact.domainTagsCsv,
        isPublished: artifact.isPublished,
        subjectTagsCsv: artifact.subjectTagsCsv,
        thumbnail: artifact.thumbnail,
    }
};