import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReportList, IReportList, IReportListItem } from '../entities/ReportList';

export const useGetReportLists = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportListItem>>());
    const [groupId, setGroupId] = useState<string>('');

    useEffect(() => {
        if (groupId === '') { return; }
        processGetAsync(setService, `/api/app/report-lists?MaxResultCount=1000&ReportGroupId=${groupId}`);
        setGroupId('');
    }, [groupId]);

    return { service, setGroupId };
};

export const useGetReportList = () => {
    const [service, setService] = useState(GetRequest.empty<IReportListItem>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-lists/${itemId}/with-navigation-properties`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReportList = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportList>(defaultReportList);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/report-lists/${item.id}`, item);
        setItem(defaultReportList);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportList = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportList>(defaultReportList);

    useEffect(() => {
        if (item.title === '') { return; }
        processPostAsync(setService, `/api/app/report-lists`, item);
        setItem(defaultReportList);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportList = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [reportId, setReportId] = useState<string>('');

    useEffect(() => {
        if (reportId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-lists/${reportId}`);
        setReportId('');
    }, [reportId]);

    return { service, setReportId };
};


