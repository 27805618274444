import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { OEFormLabel } from '../../core/components/form/OEForm';
import OEFormSubmit from '../../core/components/formik/OEFormSubmit';
import OEModal, {
    ModalSize,
    OEModalBody,
} from '../../core/components/general/OEModal';
import { OERow } from '../../core/components/grid/OERow';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import OEMessage from '../../core/components/messaging/OEMessage';
import { Icon } from '../../core/entities/Icon';
import OEButton from '../../core/components/form/OEButton';
import { OECol } from '../../core/components/grid/OECol';
import { usePostDcFileUpload } from '../services/DcFileUploadService';
import {
    IDcFileRequestUploadResultDto,
    defaultDcFileRequestUploadResultDto,
} from '../entities/DcFileRequestUploadResultDto';
import {
    defaultDcFileToUpload,
    IDcFileToUpload,
} from '../entities/IDcFileToUpload';
import OESpinner, { SpinnerStyle } from '../../core/components/messaging/OESpinner';

interface IComponentInfo {
    id: string;
    agencyName: string;
    agencyCode: string;
    agencyAzureDataSource: string;
    agencyAzureFolder: string;
    azureFileUrl: string;
    fileTypeName: string;
    fileTypeCategoryName?: string;
    fileTypeGroupName?: string;
    term: string;
    accepts: string;
    onCancel: () => void;
    onSave: (i: string) => void;
}

const DcFileUpload: React.FunctionComponent<IComponentInfo> = ({
    id,
    agencyName,
    agencyCode,
    agencyAzureDataSource,
    agencyAzureFolder,
    azureFileUrl,
    fileTypeName,
    fileTypeCategoryName,
    fileTypeGroupName,
    term,
    accepts,
    onCancel,
    onSave,
}) => {
    const [file, setFile] = useState<IDcFileToUpload>(defaultDcFileToUpload);
    const [fileUploadResults, setFileUploadResults] =
        useState<IDcFileRequestUploadResultDto>(
            defaultDcFileRequestUploadResultDto
        );
    const [message, setMessage] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<string>('None');
    const [submitText, setSubmitText] = useState<string>('Upload File');
    const [uploading, setUploading] = useState<boolean>(false);
    const { service: dcFileToUploadService, setDcFileToUpload } =
        usePostDcFileUpload();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleCancel = (e: any) => {
        e.preventDefault();
        onCancel();
    };

    const handleSubmit = () => {
        const { id, fileName, fileBody } = file;

        if (submitText === 'Upload File') {
            if (!file.fileBody) {
                setMessage('Please select a file to upload');
                return;
            }

            setUploading(true);
            setDcFileToUpload({
                id: id,
                agencyCode: agencyCode,
                agencyAzureDataSource: agencyAzureDataSource,
                agencyAzureFolder: agencyAzureFolder,
                azureFileUrl: azureFileUrl,
                term: term,
                fileName: fileName,
                fileBody: fileBody,
            });
        } else {
            onSave(fileName);
        }
    };

    const readFile = (id: string, file: File) => {
        const reader = new FileReader();

        reader.onload = (e: any) => {
            setFile({
                id: id,
                agencyCode: agencyCode,
                agencyAzureDataSource: agencyAzureDataSource,
                agencyAzureFolder: agencyAzureFolder,
                azureFileUrl: azureFileUrl,
                term: term,
                fileName: file.name,
                fileBody: e.target.result,
            });
        };

        const selectedFile = file;
        setSelectedFile(
            `Selected file: ${selectedFile.name}, size: ${selectedFile.size} bytes`
        );

        reader.readAsText(selectedFile);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            readFile(id, event.target.files[0]);
        }
    };

    const handleDrop = (event: DragEvent) => {
        event.preventDefault();

        if (event.dataTransfer.items) {
            const item = event.dataTransfer.items[0];

            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    readFile(id, file);
                }
            }
        } else {
            if (event.dataTransfer.files.length > 0) {
                const file = event.dataTransfer.files[0];
                readFile(id, file);
            }
        }
    };

    useEffect(() => {
        if (dcFileToUploadService.data) {
            setSubmitText('Close');
            setFileUploadResults(dcFileToUploadService.data.data);
            setUploading(false);
        }
    }, [dcFileToUploadService]);

    return (
        <OEModal
            oeSize={ModalSize.Medium}
            show={true}
            onHide={onCancel}
            title="File Upload"
        >
            <OEModalBody>
                <OERow>
                    <OECol sm={3}>
                        <OEFormLabel>Organization</OEFormLabel>
                    </OECol>
                    <OECol>{agencyName}</OECol>
                </OERow>

                <OERow>
                    <OECol sm={3}>
                        <OEFormLabel>File Type</OEFormLabel>
                    </OECol>
                    <OECol>{fileTypeName}</OECol>
                </OERow>

                {fileTypeCategoryName && (
                    <OERow>
                        <OECol sm={3}>
                            <OEFormLabel>File Type Category</OEFormLabel>
                        </OECol>
                        <OECol>{fileTypeCategoryName}</OECol>
                    </OERow>
                )}

                {fileTypeGroupName && (
                    <OERow>
                        <OECol sm={3}>
                            <OEFormLabel>File Type Group</OEFormLabel>
                        </OECol>
                        <OECol>{fileTypeGroupName}</OECol>
                    </OERow>
                )}

                <OERow>
                    {fileUploadResults.fileName === '' && (
                        <>
                            <div
                                className="text-center rounded p-5"
                                style={{ border: '2px dashed' }}
                                onDrop={(e) => handleDrop(e)}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <OEButton
                                    text="Choose file to upload"
                                    onClick={() =>
                                        fileInputRef.current?.click()
                                    }
                                />

                                <p className="mt-3">
                                    or drag and drop the file
                                </p>

                                <p>Selected file: {selectedFile}</p>
                            </div>

                            <input
                                type="file"
                                hidden
                                ref={fileInputRef}
                                accept={accepts}
                                onChange={handleFileChange}
                            />

                            <div className="text-center pt-2">
                                <OEFormSubmit
                                    sm={0}
                                    smOffset={0}
                                    submitText={submitText}
                                    onCancel={handleCancel}
                                    onSubmit={handleSubmit}
                                    disabled={uploading}
                                />
                                <OESpinner message='Uploading' oeStyle={SpinnerStyle.Small} hidden={!uploading} />
                            </div>

                            <div>
                                <OEMessage
                                    className="small"
                                    type={MessageType.Error}
                                    message={message}
                                    icon={Icon.Error}
                                />
                            </div>
                        </>
                    )}

                    {fileUploadResults.fileName !== '' && (
                        <>
                            <div
                                className="text-center rounded p-5"
                                style={{ border: '2px solid' }}
                            >
                                <h4>
                                    {fileUploadResults.isSuccess && (
                                        <OEFormLabel>Success</OEFormLabel>
                                    )}

                                    {fileUploadResults.isError && (
                                        <OEFormLabel className="text-danger">
                                            Error
                                        </OEFormLabel>
                                    )}
                                </h4>

                                <OERow className="text-left">
                                    <OECol sm={4}>
                                        <OEFormLabel>File Name</OEFormLabel>
                                    </OECol>
                                    <OECol>{fileUploadResults.fileName}</OECol>
                                </OERow>

                                <OERow className="text-left">
                                    <OECol sm={4}>
                                        <OEFormLabel>Row Count</OEFormLabel>
                                    </OECol>
                                    <OECol>{fileUploadResults.fileRows}</OECol>
                                </OERow>

                                {fileUploadResults.isError && (
                                    <OERow className="text-left text-danger">
                                        <OECol sm={4}>
                                            <OEFormLabel>Error</OEFormLabel>
                                        </OECol>
                                        <OECol>
                                            {fileUploadResults.errorMessage}
                                        </OECol>
                                    </OERow>
                                )}

                                <OERow className="text-left">
                                    <OECol sm={4}>
                                        <OEFormLabel>Upload Date</OEFormLabel>
                                    </OECol>
                                    <OECol>
                                        {new Date(
                                            fileUploadResults.uploadedOn
                                        ).toLocaleString('en-US')}
                                    </OECol>
                                </OERow>

                                <OERow className="text-left">
                                    <OECol sm={4}>
                                        <OEFormLabel>Upload By</OEFormLabel>
                                    </OECol>
                                    <OECol>
                                        {fileUploadResults.uploadedBy}
                                    </OECol>
                                </OERow>
                            </div>

                            <div className="text-center pt-2">
                                <OEFormSubmit
                                    sm={0}
                                    smOffset={0}
                                    submitText={submitText}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </>
                    )}
                </OERow>
            </OEModalBody>
        </OEModal>
    );
};

export default DcFileUpload;
