import React, { useEffect, useState } from 'react';
import { IFilter } from '../../../core/components/filter/entities/Filter';
import OEFilter from '../../../core/components/filter/OEFilter';
import OEButton, { ButtonStyle } from '../../../core/components/form/OEButton';
import OEHeading from '../../../core/components/general/OEHeading';
import { OEModalBody, OEModalFooter } from '../../../core/components/general/OEModal';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import RolePermission from '../../../organization/components/RolePermission';
import { defaultOrganizationUnit, IOrganizationUnit } from '../../../organization/entities/OrganizationUnit';
import { IRole } from '../../../organization/entities/Role';
import { defaultSecurityUser, ISecurityUser } from '../../entities/SecurityUser';

interface IComponentInfo {
    userId: string;
    roles: IRole[];
    units: IOrganizationUnit[];
    currentSecurity: ISecurityUser[];
    onCancel: () => void;
    onSave: (s: ISecurityUser[]) => void;
}

const UserPermissionAdd: React.FunctionComponent<IComponentInfo> = ({ userId, onSave, roles, units, onCancel, currentSecurity }) => {

    const [step, setStep] = useState<number>(1);
    const [unit, setUnit] = useState<IOrganizationUnit>(defaultOrganizationUnit);
    const [filterList, setFilterList] = useState<IOrganizationUnit[]>([]);
    const [localRoles, setLocalRoles] = useState<IRole[]>([...roles]);
    const [security, setSecurity] = useState<ISecurityUser[]>([]);

    useEffect(() => {
        if (unit.id !== '') {
            const s: ISecurityUser[] = [];
            for (const v of localRoles.filter(q => q.selected)) {
                s.push({
                    ...defaultSecurityUser,
                    userId,
                    organizationUnit: unit.displayName,
                    organizationUnitId: unit.id,
                    role: v.name,
                    roleId: v.id,
                    importID: unit.importId
                });
            }
            setSecurity([...s]);
        }
        // eslint-disable-next-line
    }, [localRoles]);

    const onBack = () => {
        setStep(1);
    }

    const onSubmit = () => {
        onSave(security);
    }

    const onSelectOrganization = (i: IOrganizationUnit) => {
        setUnit(i);
        const l: IRole[] = roles.filter(q => q.typeId === i.typeId);
        for (const r of l) {
            r.selected = currentSecurity.filter(q => q.organizationUnitId === i.id && q.roleId === r.id).length > 0;
        }
        setLocalRoles(l);
        setStep(2);
    }
    const actions: IColumnAction[] = [
        { icon: Icon.Select, onClick: onSelectOrganization, helpText: 'Select' },
    ];

    const columns: IColumn[] = [
        { actions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'displayName', name: 'Name', sort: true, type: ColumnType.String },
        { id: 'type', name: 'Type', sort: true, type: ColumnType.String },
        { id: 'importId', name: 'Import Id', sort: true, type: ColumnType.String, className: 'text-right' },
    ];

    const onSelectRole = (i: IRole) => {
        const roleList: IRole[] = [...localRoles];
        roleList.filter(q => q.id === i.id)[0].selected = !roleList.filter(q => q.id === i.id)[0].selected;
        setLocalRoles([...roleList]);
    }

    const filter: IFilter = {
        name: 'userPermissions',
        autoSearch: true,
        filters: [
            { name: 'name', columns: ['displayName', 'importId'], autoSearchLength: 3, autoSearch: true, label: 'Name', width: 250, placeholder: 'Search by Name, Import Id' },
            { name: 'type', columns: ['type'], autoSearchLength: 3, autoSearch: true, label: 'Type', width: 150, placeholder: 'Search by type' },
        ],
    };

    return (
        <>
            <OEModalBody>
                {step === 1 &&
                    <>
                        <OEHeading text={`Step One: Select Organization`} size={5} />
                        <OEFilter singleLine={true} className="m-t-0" items={units} filter={filter} setFilterList={setFilterList} />
                        <OETable
                            data={filterList}
                            columns={columns}
                            showPagination={units.length > 10}
                            defaultSort="name"
                            defaultPageSize={10}
                        />
                    </>
                }
                {step === 2 &&
                    <>
                        <OEHeading text={`Step Two: Select <i>${unit?.displayName} - ${unit?.type}</i> Roles to add`} size={5} />
                        <OERow className="m-t-20">
                            <OECol sm={6}>
                                <OEHeading size={6} underline={true} text="Selected" />
                                <OERow>
                                    {localRoles.filter(q => q.selected).map((item, index) =>
                                        <RolePermission onSelect={onSelectRole} role={item} key={index} />
                                    )}
                                </OERow>
                            </OECol>
                            <OECol sm={6}>
                                <OEHeading underline={true} size={6} text="Available" />
                                <OERow>
                                    {localRoles.filter(q => !q.selected).map((item, index) =>
                                        <RolePermission onSelect={onSelectRole} role={item} key={index} />
                                    )}
                                </OERow>
                            </OECol>
                        </OERow>
                    </>
                }
            </OEModalBody>
            <OEModalFooter>
                <div style={{ width: "100%" }}>
                    <div className="pull-left">
                        <OEButton icon={Icon.Back} hidden={step === 1} bStyle={ButtonStyle.Cancel} onClick={onBack} text={'Back to Organization List'} />
                    </div>
                    <div className="pull-right">
                        <OEButton hidden={step === 1 || localRoles.filter(q => q.selected).length === 0} icon={Icon.Add} onClick={onSubmit} text={'Save'} />
                        <OEButton bStyle={ButtonStyle.Cancel} onClick={onCancel} text={'Cancel'} />
                    </div>
                </div>
            </OEModalFooter>
        </>
    );
};

export default UserPermissionAdd;

