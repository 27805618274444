import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { getParameters, IOEParameter, OEParameterType } from '../../../core/components/parameters/entities/OEParameter';
import OEParameters from '../../../core/components/parameters/OEParameters';
import { Icon } from '../../../core/entities/Icon';
import { IReportServer } from '../../entities/ReportServer';
import { useCreateReportServer, usePutReportServer } from '../../services/ReportServerService';

const labelColumns: number = 2;

const Form: React.FunctionComponent<FormikProps<IReportServer>> = ({ values, errors, touched, setFieldValue }) => {
    const [urlParameters, setURLParameters] = useState<IOEParameter[]>([]);
    const [globalParameters, setGlobalParameters] = useState<IOEParameter[]>([]);

    useEffect(() => {
        setGlobalParameters(getParameters(values.parameters).filter(q => q.type === OEParameterType.Global));
        setURLParameters(getParameters(values.parameters).filter(q => q.type === OEParameterType.URL));
        // eslint-disable-next-line
    }, []);

    const onUpdateURLParameters = (i: IOEParameter[]) => {
        setURLParameters(i);
        setFieldValue('parameters', JSON.stringify([...globalParameters, ...i]));
    }

    const onUpdateGlobalParameters = (i: IOEParameter[]) => {
        setGlobalParameters(i);
        setFieldValue('parameters', JSON.stringify([...i, ...urlParameters]));
    }

    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Path" name="path" value={values.path}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
                type={OEInputType.LargeText} rows={3}
            />

            <OEParameters
                values={urlParameters}
                onChange={onUpdateURLParameters}
                type={OEParameterType.URL}
                label="URL Parameters"
            />

            <OEParameters
                values={globalParameters}
                onChange={onUpdateGlobalParameters}
                type={OEParameterType.Global}
                label="Global Parameters"
            />

        </>
    );
};

const ValidationScheme = Yup.object<IReportServer>().shape({
    name: Yup.string().required('Name is required').min(2).max(500).nullable(),
    path: Yup.string().required('Path is required').min(2).max(1000).nullable(),
});

interface IFormikInfo {
    item: IReportServer;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportServerFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReportServer();
    const { service: createService, setItem: setCreateItem } = useCreateReportServer();

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            title={`${item.id === '' ? `Add New Report Server` : `Edit Report Server: ${item.name}`}`}
            progressMessage={ProgressMessages.ReportServer}
            successMessage={SuccessMessages.ReportServer}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateItem : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportServerFormik;
