
export interface IDatasetFilter extends Record<any, any> {
    filterName: string;
    filterSelector: number;
}


export const defaultDatasetFilter: IDatasetFilter = {
    filterName: '', filterSelector: -1
};
