import React from 'react';
import OEModal, { OEModalBody } from '../general/OEModal';
import { IFormikModalInfo } from './entities/Form';
import OEFormik from './OEFormik';

const OEFormikModal: React.FunctionComponent<IFormikModalInfo> = ({
    service,
    setItem,
    title,
    icon,
    item,
    component,
    progressMessage,
    onSave,
    onCancel,
    validationSchema,
    errorList,
    lookupTables,
    labelColumns,
    successMessage,
    altActionText,
    onAltAction,
    oeSize
}) => {
    return (
        <OEModal show={true} onHide={onCancel} title={title} icon={icon} oeSize={oeSize} >
            <OEModalBody>
                <OEFormik
                    item={item}
                    component={component}
                    progressMessage={progressMessage}
                    onSave={onSave}
                    onCancel={onCancel}
                    service={service}
                    labelColumns={labelColumns}
                    successMessage={successMessage}
                    setItem={setItem}
                    validationSchema={validationSchema}
                    errorList={errorList}
                    lookupTables={lookupTables}
                    altActionText={altActionText}
                    onAltAction={onAltAction}
                />
            </OEModalBody>
        </OEModal>
    );
};

export default OEFormikModal;
