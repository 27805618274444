import React, { useState } from 'react';
import { IReportList } from '../../../../reporting/entities/ReportList';
import { getReportConfigurationValue, ReportConfigurationType } from '../../../../reporting/entities/ReportConfiguration';
import OEIcon from '../../../../core/components/general/OEIcon';
import { Icon } from '../../../../core/entities/Icon';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    report: IReportList;
    pageConfigruation: string;
    title: string;
}

export function replaceDoubleSlash(str: string) {
    str = str.replace(/[- ]/g, '_').replace(/[,#!$%^&*;:{}=`~()]/g, '').replace(/ /g, '').replace(/_{2,}/g,'_');
    return str.replace(/(\/\/)/g, (match, _, offset) => {
      if (str.indexOf('//') === offset) {
        return match;
      }
      return '/'; 
    });
  }

const ReportDownload: React.FunctionComponent<IComponentInfo> = ({ report, pageConfigruation, title }) => {
    const [url] = useState<string>(getReportConfigurationValue(ReportConfigurationType.DataDownloadDirectory, pageConfigruation));
    const [prefix] = useState<string>(getReportConfigurationValue(ReportConfigurationType.DataDownloadPrefix, pageConfigruation));

    const getFilename = () => {
        return `${prefix}${report.title}.csv`;
    }

    const getFileUrl = () => {
        let fileURL = `${url}/${getFilename()}`;
        return replaceDoubleSlash(fileURL);
    }

    return (
        <div style={{ width: "fit-content" }} className='form-label m-t-10'>
            <a href={getFileUrl()} className='form-label m-t-10'>
                <OEIcon className={"download-icon report-icon"} icon={Icon.Download} />
                <span className='fw-normal h6 m-l-5'>{`${report.title}`}</span>
                < br />
            </a>
        </div>
    );
};

export default ReportDownload;
