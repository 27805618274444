import React, { Children, CSSProperties } from 'react';
import { Button } from 'react-bootstrap';

export enum ButtonStyle {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark',
    Cancel = 'light',
    Save = 'primary',
    Menu = 'menu'
}

export enum ButtonSize {
    Small = 'btn-sm',
    Standard = '',
    Large = 'btn-lg',
}

interface IComponentInfo {
    className?: string;
    icon?: string;
    text?: string;
    bStyle?: ButtonStyle;
    bSize?: ButtonSize;
    bOutline?: boolean;
    onClick?: (item: any) => void;
    disabled?: boolean;
    hidden?: boolean;
    iconRight?: boolean;
    removeMargin?: boolean;
    id?: string;
    dataBsDismiss?: string;
    ariaLabel?: string;
    style?: CSSProperties;
    children?: any;
}

const OEButton: React.FunctionComponent<IComponentInfo> = ({
    className, icon, text, bStyle, bSize, bOutline, onClick,
    disabled, hidden, iconRight, id, removeMargin,
    dataBsDismiss, ariaLabel, style, children
}: IComponentInfo) => {

    const onLinkClicked = (e: any) => {
        onClick?.(e);
    };

    const getClass = () => {
        return `btn ${className || ''} ${removeMargin ? '' : 'm-r-5'} btn${bOutline ? '-outline' : ''}-${bStyle || ButtonStyle.Dark} ${bSize || ButtonSize.Standard}`;
    }

    return (
        <>
            {!hidden && (
                <Button
                    className={getClass()}
                    id={id}
                    onClick={onLinkClicked}
                    disabled={disabled}
                    data-bs-dismiss={dataBsDismiss}
                    aria-label={ariaLabel}
                    style={style}
                >
                    {icon && !iconRight && (
                        <i className={`${icon} m-r-5`} />
                    )}
                    {text && (
                        <>
                            {text}
                        </>
                    )}
                    {Children.map(children, i => i)}

                    {icon && iconRight && (
                        <i className={`${icon} m-l-5`} />
                    )}
                </Button>
            )}
        </>
    );
};

export default OEButton;
