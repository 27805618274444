import React from 'react';
import { getFutureDate } from '../../../core/utilities/Date';

import '../../styles/NavMenu.css';

const SiteFooter: React.FunctionComponent = () => {

    return (
        <div className="site-footer">
            <div className="pull-right m-r-10">
                &copy; {getFutureDate(0).getFullYear()} Otis Educational
                Systems, Inc.&nbsp;
            </div>
        </div>

    );

};

export default SiteFooter;
