import React, { ChangeEvent, CSSProperties, useRef } from 'react';
import { getByteValue } from '../../../user/entities/ProfileImage';
import { Icon } from '../../entities/Icon';
import OEButton, { ButtonStyle } from '../form/OEButton';
import { INotification } from '../messaging/entities/Notification';
import OEFormItem, { IOEFormItemInfo } from './OEFormItem';

interface IComponentInfo {
    type?: any;
    maxLength?: number;
    placeholder?: string;
    value: any;
    rows?: number;
    setNotification?: (v: INotification) => void;
    maxSize: number;
    accept: string;
    style?: CSSProperties;

}

const OEFormImage: React.FunctionComponent<IComponentInfo & IOEFormItemInfo
> = ({
    columns,
    name,
    label,
    errors,
    touched,
    setFieldValue,
    maxSize,
    required,
    hidden,
    value,
    accept,
    onChange,
    action,
    actionIcon,
    labelInstructions,
    setNotification,
    style
}) => {

        const fileInputRef = useRef<HTMLInputElement>(null);

        const getImageSource = (src: string | undefined): string | undefined => {
            return src ? `data:image/png;base64,${src}` : src;
        };

        const setThumbnail = (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files.length > 0) {
                if (maxSize && event.target.files[0].size / 1024 / 1024 > maxSize) {
                    setNotification && setNotification({
                        message: `File size must be less than ${maxSize}MB`,
                        type: 'error',
                    });

                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e: any) => {
                    onChange?.(name, getByteValue(e.target.result));
                    setFieldValue?.(name, getByteValue(e.target.result));
                };
            }
        };

        const resetThumbnail = () => {
            onChange?.(name, undefined);
            setFieldValue?.(name, undefined);
            if (fileInputRef && fileInputRef.current) { fileInputRef.current.value = ''; }

        };

        return (
            <OEFormItem
                columns={columns}
                name={name}
                label={label}
                labelInstructions={labelInstructions}
                errors={errors}
                touched={touched}
                required={required}
                hidden={hidden}
                action={action}
                actionIcon={actionIcon}
            >
                <img hidden={!value} alt="Preview" style={style} src={getImageSource(value)} className="m-b-10" />
                <div className="cleardiv" />
                <OEButton className="btn-dark btn-small mb-0" icon={Icon.Add} onClick={() => fileInputRef.current?.click()}
                    text={` ${value && value.length > 0 ? 'Update' : 'Add'} ${label}`} />
                <OEButton text={`Remove  ${label}`} bStyle={ButtonStyle.Cancel} hidden={!value} className="btn-small small mb-0"
                    icon={Icon.Delete} onClick={resetThumbnail} />
                <input hidden type="file" ref={fileInputRef} className="m-t-10 m-b-0" accept={accept} onChange={setThumbnail} />
            </OEFormItem>
        );
    };

export default OEFormImage;
