import React from 'react';
import OEWell from '../core/components/general/OEWell';
import { MessageType } from '../core/components/messaging/enums/InformationMessages';
import OEErrorList from '../core/components/messaging/OEErrorList';
import OEMessage from '../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../core/components/messaging/OESpinner';
import { Icon } from '../core/entities/Icon';

const Messaging: React.FunctionComponent = () => {
    const onClick = () => {
        console.log("clicked");
    }

    return (
        <>
            <OEWell className="m-t-0" title="OESpinner" icon={Icon.SpinnerSmall}>
                <div className="p15">
                    <OESpinner message="Large Spinner" />
                    <OESpinner oeStyle={SpinnerStyle.Small} message="Small Spinner" />
                    <OESpinner oeStyle={SpinnerStyle.ExtraSmall} message="Xtra Small Spinner" />
                </div>
            </OEWell>

            <OEWell className="m-t-40" title="OEMessage" icon={Icon.Message}>
                <div className="p15">
                    <OEMessage message="A simple primary alert" type={MessageType.Primary} />
                    <OEMessage message="A simple secondary alert" type={MessageType.Secondary} />
                    <OEMessage message="A simple success alert" type={MessageType.Success} />
                    <OEMessage message="A simple danger alert" type={MessageType.Danger} />
                    <OEMessage message="A simple warning alert" type={MessageType.Warning} />
                    <OEMessage message="A simple informative alert" type={MessageType.Information} />
                    <OEMessage message="A simple light alert" type={MessageType.Light} />
                    <OEMessage message="A simple dark alert" type={MessageType.Dark} />
                    <OEMessage message="Error Message" type={MessageType.Error} />
                    <OEMessage message="Message With Action Link" type={MessageType.Warning} actionButton="Click" actionClick={onClick} />
                </div>
            </OEWell>

            <OEWell className="m-t-40" title="OEErrorList" icon={Icon.Error} >
                <div className="p15">
                    <OEErrorList errors={['Error One', 'Error Two']} />
                </div>
            </OEWell>

        </>
    );
};

export default Messaging;