import { INotification } from "../../core/components/messaging/entities/Notification";
import { formatDateTime } from "../../core/utilities/Date";

export enum ProcessingToken {
    Idle = 'Idle',
    TokenCheck = 'TokenCheck',
    TokenValidating = 'TokenValidating',
    TokenValid = 'TokenValid',
}
export const ProcessingTokenLabel = new Map<string, string>([
    [ProcessingToken.TokenCheck, 'Check Token'],
    [ProcessingToken.TokenValidating, 'Validating Token'],
    [ProcessingToken.TokenValid, 'Token Valid'],
]);

export const logProcessingToken = (i: ProcessingToken, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingTokenLabel(i));
    }
}

export const getProcessingTokenLabel = (i: ProcessingToken): string => {
    try {
        return ProcessingTokenLabel.get(i) || i;
    }
    catch {
        return i;
    }
}

export enum ProcessingPageFilters {
    Idle = 'Idle',
    Start = 'Start',
    SiteReportLoad = 'SiteReportLoad',
    CreatePageFilters = 'CreatePageFilters',
    SiteReportFiltersLoad = 'SiteReportFiltersLoad',
    SiteReportFiltersComplete = 'SiteReportFiltersComplete',
    CubeLoad = 'CubeLoad',
    AttributesLoadStart = 'AttributesLoadStart',
    AttributesLoadNext = 'AttributesLoadNext',
    AttributesLoading = 'AttributesLoading',
    AttributesLoaded = 'AttributesLoaded',
    UpdateFilters = 'UpdateFilters',
    FiltersUpdated = 'FiltersUpdated',
    Complete = 'Complete',
    FatalError = 'FatalError',
}

export const ProcessingPageFiltersLabel = new Map<string, string>([
    [ProcessingPageFilters.Idle, 'Idle'],
    [ProcessingPageFilters.Start, 'Loading Page Filters'],
    [ProcessingPageFilters.SiteReportLoad, 'Loading Embedded Core Site Report'],
    [ProcessingPageFilters.CreatePageFilters, 'Creating Page Filters'],
    [ProcessingPageFilters.SiteReportFiltersLoad, 'Loading Core Site Filters'],
    [ProcessingPageFilters.SiteReportFiltersComplete, 'Core Site Filters Loaded'],
    [ProcessingPageFilters.CubeLoad, 'Cube Loaded'],
    [ProcessingPageFilters.FatalError, 'Fatal Error'],
    [ProcessingPageFilters.AttributesLoadStart, 'Load Next Attribute from Cube'],
    [ProcessingPageFilters.AttributesLoadNext, 'Load Next Attribute from Cube'],
    [ProcessingPageFilters.AttributesLoading, 'Loading Attributes from Cube'],
    [ProcessingPageFilters.AttributesLoaded, 'All Attributes Loaded'],
    [ProcessingPageFilters.UpdateFilters, 'Updating Page Filters'],
    [ProcessingPageFilters.Complete, 'Page Filter Processing Complete'],
]);

export const logProcessingPageFilters = (i: ProcessingPageFilters, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingPageFiltersLabel(i));
    }
}

export const getProcessingPageFiltersLabel = (i: ProcessingPageFilters): string => {
    try {
        return ProcessingPageFiltersLabel.get(i) || i;
    }
    catch {
        return i;
    }
}

export enum ProcessingCube {
    Idle = 'Idle',
    AttributesLoadNext = 'AttributesLoadNext',
    AttributesLoad = 'AttributesLoading',
    AttributesLoaded = 'AttributesLoaded',
    CubeLoadStart = 'CubeLoadStart',
    CubeLoading = 'CubeLoading',
    CubeLoaded = 'CubeLoaded',
    CubeLoadNextCube = 'CubeLoadNextCube',
    FatalError = 'FatalError',
    FiltersStartLoad = 'FiltersStartLoad',
    FilterLoadNext = 'FilterLoadNext',
    FilterLoading = 'FilterLoading',
    FiltersLoaded = 'FiltersLoaded',
    Complete = 'Complete',
}

export const ProcessingCubeLabel = new Map<string, string>([
    [ProcessingCube.Idle, 'Idle'],
    [ProcessingCube.AttributesLoadNext, 'Load Next Attribute from Cube'],
    [ProcessingCube.AttributesLoad, 'Loading Attribute from Cube'],
    [ProcessingCube.AttributesLoaded, 'All Attributes Loaded'],
    [ProcessingCube.CubeLoadStart, 'Starting Cube Load(s)'],
    [ProcessingCube.CubeLoading, 'Loading Cube'],
    [ProcessingCube.CubeLoaded, 'Cube Loaded'],
    [ProcessingCube.CubeLoadNextCube, 'Loading Next Cube'],
    [ProcessingCube.FiltersStartLoad, 'Load Next Filter from Cube'],
    [ProcessingCube.FiltersStartLoad, 'Load Next Filter from Cube'],
    [ProcessingCube.FilterLoadNext, 'Load Next Filter from Cube'],
    [ProcessingCube.FilterLoading, 'Loading Filter from Cube'],
    [ProcessingCube.FiltersLoaded, 'All Filters Loaded'],
]);

export const logProcessingCube = (i: ProcessingCube, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingCubeLabel(i));
    }
}

export const getProcessingCubeLabel = (i: ProcessingCube): string => {
    try {
        return ProcessingCubeLabel.get(i) || i;
    }
    catch {
        return i;
    }
}

export enum ProcessingDossierFilters {
    Idle = 'Idle',
    Start = 'Start',
    FiltersLoaded = 'FiltersLoaded',
    CubeLoadStart = 'CubeLoadStart',
    CubeLoading = 'CubeLoading',
    CubeLoadNextCube = 'CubeLoadNextCube',
    CubeFilterStart = 'CubeFilterStart',
    CubeFilterLoadNext = 'CubeFilterLoadNext',
    CubeFilterLoading = 'CubeFilterLoading',
    CubeFilterComplete = 'CubeFilterUpdateComplete',
    CubeFilterUpdateStart = 'CubeFilterUpdateStart',
    CubeFilterUpdateLoadNext = 'CubeFilterUpdateLoadNext',
    CubeFilterUpdateLoading = 'CubeFilterUpdateLoading',
    DossierDefinitionLoad = 'DossierDefinitionLoad',
    DosserDefinitionLoaded = 'DosserDefinitionLoaded',
    PageChanged = 'PageChanged',
    FilterLoadNext = 'FilterLoadNext',
    FilterLoading = 'FilterLoading',
    UpdateFilters = 'UpdateFilters',
    Complete = 'Complete',
    FatalError = 'FatalError',
}

export const ProcessingDossierFiltersLabel = new Map<string, string>([
    [ProcessingDossierFilters.Idle, 'Idle'],
    [ProcessingDossierFilters.Start, 'Dosser Filters Start'],
    [ProcessingDossierFilters.CubeLoadStart, 'Starting Cube Load(s)'],
    [ProcessingDossierFilters.CubeLoading, 'Loading Cube'],
    [ProcessingDossierFilters.CubeLoadNextCube, 'Loading Next Cube'],
    [ProcessingDossierFilters.CubeFilterStart, 'Cube Filter Start'],
    [ProcessingDossierFilters.CubeFilterLoadNext, 'Loading Next Cube Filter'],
    [ProcessingDossierFilters.CubeFilterLoading, 'Loading Cube Filter'],
    [ProcessingDossierFilters.CubeFilterComplete, 'Cube Filter Complete'],
    [ProcessingDossierFilters.CubeFilterUpdateStart, 'Cube Filter Update Start'],
    [ProcessingDossierFilters.CubeFilterUpdateLoadNext, 'Loading Next Cube Filter for Update'],
    [ProcessingDossierFilters.CubeFilterUpdateLoading, 'Loading Cube Filter for Update'],
    [ProcessingDossierFilters.DossierDefinitionLoad, 'Load Dossier Definition'],
    [ProcessingDossierFilters.DosserDefinitionLoaded, 'Dossier Definition Loaded'],
    [ProcessingDossierFilters.PageChanged, 'Page Changed'],
    [ProcessingDossierFilters.FatalError, 'Fatal Error'],
    [ProcessingDossierFilters.FilterLoadNext, 'Load Next Filter from Cube'],
    [ProcessingDossierFilters.FilterLoading, 'Loading Filter from Cube'],
    [ProcessingDossierFilters.FiltersLoaded, 'All Filters Loaded'],
    [ProcessingDossierFilters.UpdateFilters, 'Updating Dossier Filters'],
    [ProcessingDossierFilters.Complete, 'Dossier Filter Loading Complete'],
]);

export const logProcessingDossierFilters = (i: ProcessingDossierFilters, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingDossierFiltersLabel(i));
    }
}

export const getProcessingDossierFiltersLabel = (i: ProcessingDossierFilters): string => {
    try {
        return ProcessingCubeLabel.get(i) || i;
    }
    catch {
        return i;
    }
}
export enum ProcessingReport {
    Idle = 'Idle',
    CubeLoad = 'CubeLoad',
    CubeLoaded = 'CubeLoaded',
    ReportLoad = 'ReportLoad',
    ReportLoading = 'ReportLoading',
    ReportLoaded = 'ReportLoaded',
    PageChanged = 'PageChanged',
    PageChangedFiltersLoaded = 'PageChangedFiltersLoaded',
    DossierCreate = 'DossierCreate',
    DossierFiltersLoad = 'DossierFiltersLoad',
    DossierFiltersLoaded = 'DossierFiltersLoaded',
    DossierFiltersInitialize = 'DossierFiltersInitialize',
    DossierFiltersApply = 'DossierFiltersApply',
    ReloadFiltersFromDossier = 'ReloadFiltersFromDossier',
    NoDataFound = 'NoDataFound',
    FatalError = 'FatalError',
    ChapterListEmpty = 'ChapterListEmpty',
    Complete = 'Complete'
}

export const ProcessingReportLabel = new Map<string, string>([
    [ProcessingReport.Idle, 'Idle'],
    [ProcessingReport.ReportLoad, 'Loading Report'],
    [ProcessingReport.ChapterListEmpty, 'Chapter List Is Empty'],
    [ProcessingReport.FatalError, 'Fatal Error'],
    [ProcessingReport.CubeLoad, 'Load Cube'],
    [ProcessingReport.CubeLoaded, 'Cube Loaded'],
    [ProcessingReport.ReportLoad, 'Load Report'],
    [ProcessingReport.ReportLoaded, 'Report Loaded'],
    [ProcessingReport.ReportLoading, 'Loading Report'],
    [ProcessingReport.PageChanged, 'Page Changed'],
    [ProcessingReport.PageChangedFiltersLoaded, 'Page Changed Filters Loaded'],
    [ProcessingReport.DossierCreate, 'Create Dossier'],
    [ProcessingReport.DossierFiltersLoad, 'Loading Filters from Dossier'],
    [ProcessingReport.DossierFiltersLoaded, 'Dossier Filters Loaded'],
    [ProcessingReport.DossierFiltersInitialize, 'Intiializing Dossier Filters'],
    [ProcessingReport.DossierFiltersApply, 'Applying Filter Updates to Dossier'],
    [ProcessingReport.ReloadFiltersFromDossier, 'Reloading Filters From Dossier'],
    [ProcessingReport.NoDataFound, 'No Data Found'],
    [ProcessingReport.Complete, 'Dossier Processing Complete'],
]);

export const logProcessingReport = (i: ProcessingReport, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingReportLabel(i));
    }
}

export const getProcessingReportLabel = (i: ProcessingReport): string => {
    try {
        return ProcessingCubeLabel.get(i) || i;
    }
    catch {
        return i;
    }
}

export enum ProcessingPage {
    Idle = 'Idle',
    PageStart = 'PageStart',
    PageLoad = 'PageLoad',
    PageLoaded = 'PageLoaded',
    ReportLoad = 'ReportLoad',
    ReportLoading = 'ReportLoading',
    ReportLoaded = 'ReportLoaded',
    SectionLoad = 'SectionLoad',
    SectionLoaded = 'SectionLoaded',
    GroupLoadInitial = 'GroupLoadInitial',
    GroupLoad = 'GroupLoad',
    GroupLoaded = 'GroupLoaded',
    PageFiltersLoad = 'PageFiltersLoad',
    PageFiltersLoaded = 'PageFiltersLoaded',
    DossierFiltersLoaded = 'DossierFiltersLoaded',
    PageFiltersUpdated = 'PageFiltersUpdated',
    UpdateDossierFilters = 'UpdateDossierFilters',
    Complete = 'Complete',
    FatalError = 'FatalError',
    SectionGroupsInitialize = 'SectionGroupsInitialize',
    SectionGroupsInitialized = 'SectionGroupsInitialized'
}

export const ProcessingStepLabel = new Map<string, string>([
    [ProcessingPage.Idle, 'Idle'],
    [ProcessingPage.PageStart, 'Page Processing Starting'],
    [ProcessingPage.PageLoad, 'Load Page'],
    [ProcessingPage.PageLoaded, 'Page Loaded'],
    [ProcessingPage.SectionLoad, 'Load Section'],
    [ProcessingPage.SectionLoaded, 'Section Loaded'],
    [ProcessingPage.GroupLoadInitial, 'Load Initial Group'],
    [ProcessingPage.GroupLoad, 'Load Group'],
    [ProcessingPage.GroupLoaded, 'Group Loaded'],
    [ProcessingPage.ReportLoad, 'Load Report'],
    [ProcessingPage.ReportLoading, 'Loading Report'],
    [ProcessingPage.ReportLoaded, 'Report Loaded'],
    [ProcessingPage.PageFiltersLoad, 'Loading Page Filters'],
    [ProcessingPage.PageFiltersLoaded, 'Page Filters Loaded'],
    [ProcessingPage.DossierFiltersLoaded, 'Dossier Filters Loaded'],
    [ProcessingPage.PageFiltersUpdated, 'Page Filters Updated'],
    [ProcessingPage.UpdateDossierFilters, 'Update Dossier Filters'],
    [ProcessingPage.Complete, 'Processing Complete'],
    [ProcessingPage.FatalError, 'Fatal Error - Report Not Displayed'],
    [ProcessingPage.SectionGroupsInitialize, 'Section Groups Initialize'],
    [ProcessingPage.SectionGroupsInitialized, 'Section Groups Initialized'],
]);

export const logProcessingPage = (i: ProcessingPage, debug: boolean) => {
    if (debug) {
        reportLogToConsole(debug, getProcessingPageLabel(i));
    }
}

export const getProcessingPageLabel = (i: ProcessingPage): string => {
    try {
        return ProcessingCubeLabel.get(i) || i;
    }
    catch {
        return i;
    }
}

export const updateProcessingStep = (i: ProcessingPage, setStep: any) => {
    window.setTimeout(() => {
        setStep(i);
    }, 5);
}

export const reportLogToConsole = (debug: boolean, ...args: any) => {
    if (debug) {
        const date = new Date();
        date.setDate(date.getDate());
        console.log(`${formatDateTime(date.toString(), 'mm/dd/yyyy ss')}`, ...args);
    }
}

export const reportLogToConsoleNotification = (debug: boolean, setNotification?: any, i?: INotification) => {
    if (debug) {
        const date = new Date();
        date.setDate(date.getDate());
        setNotification?.(i);
        console.log(`${formatDateTime(date.toString(), 'mm/dd/yyyy ss')}`, i?.message);
    }
}