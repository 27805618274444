export enum PageNavigation {
    Forward = -1,
    ForwardStep = -2,
    Back = -3,
    BackStep = -4
}

export interface IPaginationLink {
    page: number;
    currentpage: number;
    onSelected: (page: number) => void;
}

export interface IPagination {
    records: number;
    start: number;
    end: number;
    currentpage: number;
    pages?: number;
    pagesize: number;
    showPageSize: boolean;
    showPageTotals: boolean;
    pagesizeoptions?: number[];
    pagelistsize: number;
    pagelist?: number[];
    onChange?: (start: number, end: number, currentpage: number, pagesize: number) => void;
}

export const defaultPagination: IPagination = {
    records: 0, start: 0, end: 0, currentpage: 0, showPageSize: false, showPageTotals: false, pagelistsize: 0, pagesize: 1
}

export class Pagination implements IPagination {

    public static getPages(records: number, pagesize: number) {
        return Math.ceil(records / pagesize);
    }

    public static setCurrentPage(pagination: IPagination, page: number) {
        const returnValue: IPagination = { ...defaultPagination, start: 1, end: 1, currentpage: 1, pagelist: [] };
        if (pagination.records <= pagination.pagesize) {
            returnValue.currentpage = 1;
            returnValue.start = 1;
            returnValue.end = pagination.records;
        }
        else {
            switch (page) {
                case PageNavigation.ForwardStep:
                    returnValue.currentpage = pagination.currentpage + 1;
                    break;
                case PageNavigation.BackStep:
                    returnValue.currentpage = pagination.currentpage - 1;
                    break;
                case PageNavigation.Forward:
                    if (pagination.pagelist) {
                        returnValue.currentpage = pagination.pagelist[pagination.pagelist.length - 4] + 1;
                    }
                    break;
                case PageNavigation.Back:
                    if (pagination.pagelist) {
                        returnValue.currentpage = pagination.pagelist[3] - pagination.pagelistsize;
                    }
                    break;
                default:
                    returnValue.currentpage = page;
                    break;
            }
            returnValue.start = (returnValue.currentpage - 1) * pagination.pagesize + 1;
            returnValue.end = (returnValue.currentpage - 1) * pagination.pagesize + pagination.pagesize;
            returnValue.end = returnValue.end > pagination.records ? pagination.records : returnValue.end;
        }
        returnValue.pagelist = Pagination.generatePageList(pagination.pages || 1, pagination.pagelistsize, returnValue.currentpage);
        return returnValue;
    }

    public static setPageSize(pagination: IPagination, size: number) {
        const returnValue: IPagination = { ...defaultPagination, start: 1, end: 1, currentpage: 1, pagelist: [], pages: 1, pagesize: size };
        if (pagination.records <= size) {
            returnValue.currentpage = 1;
            returnValue.start = 1;
            returnValue.end = pagination.records;
        }
        else {
            returnValue.currentpage = pagination.currentpage;
            returnValue.start = (returnValue.currentpage - 1) * size + 1;
            returnValue.end = (returnValue.currentpage - 1) * size + size;
        }
        returnValue.pages = Pagination.getPages(pagination.records, size);
        if (returnValue.currentpage > returnValue.pages) returnValue.currentpage = returnValue.pages;
        returnValue.pagelist = Pagination.generatePageList(returnValue.pages, pagination.pagelistsize, returnValue.currentpage);
        return returnValue;
    }

    private static generatePageList(pages: number, size: number, currentpage: number) {
        let list;
        list = Array(size + 4);
        let pagegroup;
        const maxpagegroup = pagegroup = Math.floor(pages / size);
        if (currentpage <= size + 2) pagegroup = 0;
        else if (currentpage > pages - size - 2) pagegroup = maxpagegroup;
        else pagegroup = Math.floor(currentpage / size) - 1;

        if (pages > 1) {
            if (pages <= size + 3) {
                for (let i = 0; i < pages; i = i + 1) {
                    list[i] = i + 1;
                }
            }
            else {
                list = Array(size + 4);
                for (let i = 0; i < list.length; i = i + 1) {
                    switch (i) {
                        case 0:
                            list[i] = 1;
                            break;

                        case list.length - 1:
                            list[i] = pages;
                            break;

                        case 1:
                            list[i] = (pagegroup === 0) ? i + 1 : PageNavigation.Back;
                            break;

                        case list.length - 2:
                            list[i] = (pagegroup === maxpagegroup) ? pages - 1 : PageNavigation.Forward;
                            break;

                        default:
                            list[i] = (pagegroup === maxpagegroup) ? pages - (size + 2) + (i - 1) : pagegroup === 0 ? i + 1 : i + size * pagegroup + 1;
                            break;
                    }
                }
                if (pages > 1 && currentpage > 1) {
                    list.unshift(PageNavigation.BackStep);
                }
                if (currentpage < pages) {
                    list.push(PageNavigation.ForwardStep);
                }

            }
        }
        return list;
    }

    public records: number;
    public pagesize: number;
    public start: number;
    public end: number;
    public currentpage: number;
    public pages?: number;
    public showPageSize: boolean;
    public showPageTotals: boolean;
    public pagesizeoptions: number[];
    public pagelistsize: number;
    public pagelist?: number[];

    constructor
        (records: number, pagesize: number = 10, currentpage: number = 1
            , pagesizeoptions: number[] = [10, 25, 50, 100]
            , showpagesize: boolean = true, showpagetotals: boolean = true
            , pagelistsize: number = 3
        ) {
        this.records = records;
        this.showPageSize = showpagesize;
        this.showPageTotals = showpagetotals;
        this.pagelistsize = pagelistsize;
        this.pagesize = pagesize > 200 ? 200 : pagesize;
        this.pagesizeoptions = pagesizeoptions;
        this.currentpage = currentpage;
        const m = Pagination.setPageSize(this, pagesize);
        this.start = m.start;
        this.end = m.end > records ? records : m.end;
        this.pagelist = m.pagelist;
        this.currentpage = m.currentpage;
        this.pages = m.pages;
    }

}
