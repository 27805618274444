import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { defaultProfileImage, IProfileImage, ProfilePictureType } from '../entities/ProfileImage';

export const usePostProfileImage = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [image, setImage] = useState<IProfileImage>(defaultProfileImage);

    useEffect(() => {
        if (image.newType === -1) { return; }
        const i: IProfileImage = {
            ...image,
            type: image.newType,
            imageContent: image.newType === ProfilePictureType.Image ? image.imageContent : ''
        }
        processPostAsync(setService, `/api/account/profile-picture/`, i);
        setImage(image);
    }, [image]);

    return { service, setImage };
};

export const useGetProfileImage = () => {
    const [service, setService] = useState(GetRequest.empty<IProfileImage>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/site-settings/my-profile-picture`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};