
export interface IFilterOperand {
    type?: string;
    id?: string;
    elements?: IFilterOperand[];
}

interface IFilterAttribute {
    operator: string;
    operands: IFilterOperand[];

}

interface IViewFilter {
    operator: string;
    operands: IFilterAttribute[];
}

export const getDefaultViewFilter = (): IViewFilter => JSON.parse(JSON.stringify({
    operator: 'And', operands: []
}));

interface IRequestedObjects {
    attributes: IFilterOperand[];
}

const getDefaultRequestedObjects = (): IRequestedObjects => JSON.parse(JSON.stringify({
    attributes: []
}));
export interface ICubeAttributeRequest {
    viewFilter: IViewFilter;
    requestedObjects: IRequestedObjects;
}

export const getDefaultCubeAttributeRequest = (): ICubeAttributeRequest => JSON.parse(JSON.stringify({
    viewFilter: getDefaultViewFilter(), requestedObjects: getDefaultRequestedObjects()
}));

