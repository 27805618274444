import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import { Icon } from '../../../core/entities/Icon';
import { getReferenceList, useGetReference } from '../../services/ReferenceService';
import { IReference } from '../../entities/Reference';
import OELink from '../../../core/components/general/OELink';

const Reference: React.FunctionComponent = () => {

    const { service } = useGetReference();
    
    const [items, setItems] = useState<IReference[]>([]);

    useEffect(() => {
        if (service && service.result) {
            setItems(getReferenceList(service.result));
        }
    }, [service]);

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Other Education Data" icon={Icon.UserManagement} />
            <br></br>
            {items.length > 0 ? items.map((item) => {
                return <div>
                   <OELink href={item.url} text={item.title} target={'_blank'} />
                    <p>{item.definition}</p>
                    <hr></hr>
                </div>
            }): <span>{"No Data Found"}</span>}
        </div>
    );
};

export default Reference;