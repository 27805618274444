interface Props {
    showChat: boolean;
    setShowChat: (show: boolean) => void;
}

const ChatBtn: React.FunctionComponent<Props> = ({ showChat, setShowChat }) => {
    return (

        <img
            className={showChat ? 'on' : ''}
            alt="Chat"
            src="/images/ask-otis-avatar.png"
            id="chat-btn"
            onClick={() => setShowChat(!showChat)}
        />
    );
};

export default ChatBtn;
