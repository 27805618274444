import React, { Children, CSSProperties } from 'react';
import Row from 'react-bootstrap/Row';

interface IComponentInfo {
    className?: string;
    id?: string;
    onClick?: () => void;
    style?: CSSProperties;
    children?: any;
}

export const OERow: React.FunctionComponent<IComponentInfo> = ({ className, id, style, children }) => {

    return (
        <Row
            style={style}
            className={className}
            id={id}
        >
            {Children.map(children, i => i)}
        </Row>
    );
};
