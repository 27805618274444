import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEForm from '../../../core/components/formik/OEForm';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEHeading from '../../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import { IOEParameter } from './entities/OEParameter';

const labelColumns: number = 2;

interface IFormInfo {
    lookupTables: boolean;
}

const Form: React.FunctionComponent<FormikProps<IOEParameter> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {

    return (
        <>
            <OEFormInput
                label="Key" name="key" value={values.key} disabled={lookupTables}
                errors={errors} touched={touched} columns={labelColumns}
                autoFocus={!lookupTables}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Value" name="value" value={values.value} required={true}
                errors={errors} touched={touched} columns={labelColumns}
                autoFocus={lookupTables}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IOEParameter>().shape({
    key: Yup.string().required('Key is required').nullable(),
    value: Yup.string().required('Value is required').nullable(),
});

interface IFormikInfo {
    item: IOEParameter;
    isEditing: boolean;
    onCancel: () => void;
    onSubmit: (i: IOEParameter) => void;
}

const OEParameterFormik: React.FunctionComponent<IFormikInfo> = ({ item, isEditing, onCancel, onSubmit }) => {

    const [localValues] = useState(item);

    const getForm = (props: FormikProps<any>, p: boolean) => (
        <OEForm {...props}
            onCancel={onCancel}
            component={Form}
            submitText="Submit"
            cancelText="Cancel"
            lookupTables={isEditing}
            labelColumns={2}
        />
    );

    return (
        <OEModal show={true} oeSize={ModalSize.Medium} onHide={onCancel}>
            <OEModalBody className="alert-light">
                <OEHeading size={3} text="Add Parameter" />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={localValues}
                    enableReinitialize={true}
                    validationSchema={ValidationScheme}
                >
                    {(props) => getForm(props, isEditing)}
                </Formik>

            </OEModalBody>
        </OEModal >
    );
};

export default OEParameterFormik;
