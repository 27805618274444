import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { defaultWidgetGroup, IWidgetGroup } from './WidgetGroup';
import { defaultWidgetType, IWidgetType } from './WidgetType';

export interface IWidget extends IBaseAuditedEntity {
    id: string;
    name: string;
    code: string;
    content: string;
    widgetTypeId: number;
    widgetGroupId: number;
    widgetType: IWidgetType;
    widgetGroup: IWidgetGroup;
    path: string;
}

export interface IWidgetSave extends IBaseAuditedEntity {
    id: string;
    name: string;
    code: string;
    content: string;
    widgetTypeId: number;
    widgetGroupId: number;
}

export const defaultWidget: IWidget = {
    id: '', name: '', content: '', typeId: -1,
    code: '', widgetTypeId: -1, widgetGroupId: -1,
    widgetGroup: defaultWidgetGroup, widgetType: defaultWidgetType,
    widgetGroupCode: '', widgetTypeCode: '', path: ''
};

export interface IWidgetListItem extends IBaseAuditedEntity {
    widget: IWidget;
    widgetType: IWidgetType;
    widgetGroup: IWidgetGroup;
}


export const getWidgetList = (i: IBaseList<IWidgetListItem>): IWidget[] => {
    const d: IWidget[] = [];
    for (const v of i.items) {
        d.push(getWidget(v));
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getWidget = (i: IWidgetListItem): IWidget => {
    return {
        ...defaultWidget,
        ...i.widget,
        widgetType: i.widgetType,
        widgetGroup: i.widgetGroup,
        path: `/widget?id=${i.widget.code}`
    };
};

export const getWidgetForSave = (i: IWidget): IWidgetSave => {
    return {
        id: i.id,
        name: i.name,
        code: i.code,
        content: i.content,
        widgetTypeId: i.widgetTypeId,
        widgetGroupId: i.widgetGroupId
    };
};

