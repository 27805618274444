import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { DatasetSourceType } from "./DatasetSourceType";

export interface IDatasetConnection extends IBaseAuditedEntity {
    id?: string;
    displayName?: string;
    datasetSourceType?: DatasetSourceType;
    connectionString?: string;
    databaseSchema?: string;
}

export const defaultDatasetConnection: IDatasetConnection = {
    id: undefined,
    displayName: undefined,
    datasetSourceType: undefined,
    connectionString: undefined,
    databaseSchema: undefined,
}

export const getDatasetConnectionForSave = (datasetConnection: IDatasetConnection): any => {
    return {
        displayName: datasetConnection.displayName,
        datasetSourceType: datasetConnection.datasetSourceType,
        connectionString: datasetConnection.connectionString,
        databaseSchema: datasetConnection.databaseSchema,
    };
  };