import React from 'react';
import { displayHTMLContent } from '../../utilities/Miscellaneous';
import OEButton, { ButtonStyle } from '../form/OEButton';
import OEHeading from '../general/OEHeading';
import OEModal, { ModalSize, OEModalBody, OEModalFooter } from '../general/OEModal';
import { defaultConfirmationMessage, IConfirmationMessage } from './entities/ConfirmationMessage';


const OEConfirmation: React.FunctionComponent<IConfirmationMessage> = ({ item, message, title, onOk, onCancel, setConfirmation, show, icon, okText, cancelText }) => {

    const onConfirmOK = () => {
        onOk && onOk(item);
        setConfirmation?.({ ...defaultConfirmationMessage, show: false });
    }
    const onConfirmCancel = () => {
        onCancel && onCancel();
        setConfirmation?.({ ...defaultConfirmationMessage, show: false });
    }

    return (
        <>
            {show && (
                <OEModal oeSize={ModalSize.Medium} show={true} onHide={onConfirmCancel}>
                    <OEModalBody>
                        {title && (
                            <OEHeading size-={6} text={title} icon={icon} />
                        )}
                        <span dangerouslySetInnerHTML={displayHTMLContent(message)} />
                    </OEModalBody>
                    <OEModalFooter>
                        <OEButton  onClick={onConfirmOK} text={okText || "Ok"} />
                        {onCancel && (
                            <OEButton bStyle={ButtonStyle.Cancel} onClick={onConfirmCancel} text={cancelText || "Cancel"} />
                        )}
                    </OEModalFooter>
                </OEModal >
            )}
        </>
    );
};

export default OEConfirmation;