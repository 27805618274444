import React from 'react';
import { IReportGroup } from '../../../../reporting/entities/ReportGroup';

interface IComponentInfo {
    index: number;
    group: IReportGroup;
    onClick: (i: IReportGroup) => void;
    selected: boolean;
}

const SectionGroup: React.FunctionComponent<IComponentInfo> = ({ index, group, onClick, selected }) => {
    const onSelect = () => {
        onClick(group);
    }

    return (
        <button onClick={onSelect} id={`reports-tab-group${index}`} role="tab" data-rb-event-key={`group${index}`}
            aria-controls={`reports-tabpane-group${index}`} aria-selected="true"
            className={`nav-item nav-link ${selected ? 'active' : ''}`}>
            {group.title}
        </button>
    );
};

export default SectionGroup;
