import { useCallback, useEffect, useState } from "react";
import { IBaseList } from "../../core/entities/BaseList";
import { DeleteRequest, processDeleteAsync } from "../../core/services/DeleteRequest";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { getEmptyGUID } from "../../core/utilities/String";
import { getUrl } from "../../core/utilities/URL";
import { defaultAlertSubscription, defaultAlertSubscriptions, IAlertSubscription } from "../entities/AlertSubscription";

export const useGetAlertSubscriptions = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IAlertSubscription>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, getUrl('/api/app/alert-subscriptions', {}));
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePostSubscribe = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [subscriptionAlert, setAlertSubscription] = useState<IAlertSubscription>(defaultAlertSubscription);
    const [subscriptionAlerts, setAlertSubscriptions] = useState<IAlertSubscription[]>(defaultAlertSubscriptions);

    const updateAlertSubscription = useCallback((alert: IAlertSubscription) => {
        subscriptionAlerts.map((item) => {
            if (item.eventName === alert.eventName) {
                item.isSubscribed = true;
                return item;
            }
            else
                return item;
        });

        return subscriptionAlerts;
    }, [subscriptionAlerts]);

    useEffect(() => {
        if (subscriptionAlert.id === getEmptyGUID()) { return; }
        processPostAsync(setService, `/api/app/alert-subscriptions`, { eventName: subscriptionAlert.eventName });
        updateAlertSubscription(subscriptionAlert);
        setAlertSubscription(defaultAlertSubscription);
        setAlertSubscriptions(defaultAlertSubscriptions);
    }, [updateAlertSubscription, setAlertSubscription, subscriptionAlert, subscriptionAlerts]);

    return { service, subscriptionAlert, updateAlertSubscription, setAlertSubscription, setAlertSubscriptions };
};

export const useDeleteForUnsubscribe = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [subscriptionAlert, setAlertSubscription] = useState<IAlertSubscription>(defaultAlertSubscription);
    const [subscriptionAlerts, setAlertSubscriptions] = useState<IAlertSubscription[]>(defaultAlertSubscriptions);

    const updateAlertSubscription = useCallback((alert: IAlertSubscription) => {
        subscriptionAlerts.map((item) => {
            if (item.eventName === alert.eventName) {
                item.isSubscribed = false;
                return item;
            }
            else
                return item;
        });

        return subscriptionAlerts;
    }, [subscriptionAlerts]);

    useEffect(() => {
        if (subscriptionAlert.eventName === "") { return; }
        processDeleteAsync(setService, `/api/app/alert-subscriptions?eventName=${subscriptionAlert.eventName}`);
        updateAlertSubscription(subscriptionAlert);
        setAlertSubscription(defaultAlertSubscription);
        setAlertSubscriptions(defaultAlertSubscriptions);
    }, [setAlertSubscription, updateAlertSubscription, subscriptionAlert, subscriptionAlerts]);

    return { service, updateAlertSubscription, setAlertSubscription, setAlertSubscriptions };
};