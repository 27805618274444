import { localStoragePersistentKey } from '../../../services/Authentication';
import { FilterDataType, FilterType, IFilter, IFilterItem, IFilterValue } from './Filter';
const localStorageFilter = `${localStoragePersistentKey}numbleFilters`;

interface IFilterSave {
    name: string;
    values: IFilterValue[];
}

const getLocalStorageFilter = (): IFilterSave[] => {
    const savedFilter = localStorage.getItem(localStorageFilter);
    let filter: IFilterSave[];
    filter = savedFilter ? JSON.parse(savedFilter) : [];
    return filter;
};

export const saveFilterValues = (name: string, values: IFilterValue[]) => {
    const filter: IFilterSave[] = getLocalStorageFilter();
    if (filter.filter((q) => q.name === name).length > 0) {
        filter.filter((q) => q.name === name)[0].values = values;
    } else {
        filter.push({ name, values });
    }
    localStorage.setItem(localStorageFilter, JSON.stringify(filter));
};

export const getFilterList = (
    filter: IFilter,
    values: IFilterValue[],
    items: any[]
): any[] => {
    let i = items;
    let found: boolean = false;
    try {
        // primary filters override regular filters
        if (filter.filters.filter((q) => q.primary).length > 0) {
            const item: IFilterItem = filter.filters.filter(
                (q) => q.primary
            )[0];
            const f: any = values.filter((q) => q.name === item.name)[0];
            if (item.dataType === FilterDataType.String) {
                if (f.value && f.value !== '') {
                    i = i.filter(
                        (q) =>
                            q[f.name] &&
                            q[f.name]
                                .toString()
                                .toLowerCase()
                                .includes(f.value.toString().toLowerCase())
                    );
                    found = true;
                }
            }
            if (item.dataType === FilterDataType.Number) {
                if (f.value && f.value > 0) {
                    i = i.filter(
                        (q) =>
                            q[f.name] &&
                            q[f.name]
                                .toString()
                                .toLowerCase()
                                .includes(f.value.toString().toLowerCase())
                    );
                    found = true;
                }
            }
        }
        if (!found) {
            for (const f of values) {
                if (f.value) {
                    const item: IFilterItem = filter.filters.filter(
                        (q) => q.name === f.name
                    )[0];
                    if (item.type === FilterType.Dropdown) {
                        if (
                            item.dataType === FilterDataType.Number &&
                            f.value &&
                            f.value > 0
                        ) {
                            i = i.filter((q) => q[f.name] === f.value);
                        } else if (f.value.length > 0) {
                            i = i.filter((q) => q[f.name] === f.value);
                        }
                    } else {
                        // tslint:disable
                        switch (item.dataType) {
                            case FilterDataType.Number:
                                if (f.value && f.value > 0) {
                                    i = i.filter((q) => q[f.name] === f.value);
                                }
                                break;
                            case FilterDataType.MultiSelect:
                                if (f.value.length > 0) {
                                    i = i.filter(
                                        (q) => f.value.indexOf(q[f.name]) > -1
                                    );
                                }
                                break;
                            default:
                                if (f.value && f.value !== '') {
                                    if (
                                        item.columns &&
                                        item.columns?.length > 0
                                    ) {
                                        i = i.filter((q) => {
                                            for (const i2 of item.columns ||
                                                []) {
                                                if (
                                                    q[i2] &&
                                                    q[i2]
                                                        .toString()
                                                        .toLowerCase()
                                                        .includes(
                                                            f.value
                                                                .toString()
                                                                .toLowerCase()
                                                        )
                                                ) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        });
                                    } else {
                                        i = i.filter(
                                            (q) =>
                                                q[f.name] &&
                                                q[f.name]
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(
                                                        f.value
                                                            .toString()
                                                            .toLowerCase()
                                                    )
                                        );
                                    }
                                }
                                break;
                        }
                        // tslint:enable
                    }
                }
            }
        }
    } catch {
        saveFilterValues(filter.name, getDefaultValues(filter));
        i = items;
    }

    return i;
};

export const getSavedFilterValue = (f: IFilter, v: string): any => {
    try {
        const filter: IFilterSave[] = getLocalStorageFilter();
        if (filter.length > 0 && filter.filter(q => q.name === f.name).length > 0) {
            const iv: IFilterValue[] = filter.filter(q => q.name === f.name)[0].values;
            if (iv.filter(q => q.name === v).length > 0) {
                const val = iv.filter(q => q.name === v)[0].value;
                if (val.toString() === '') return undefined;
                switch (f.filters.filter(q => q.name === v)[0].dataType) {
                    case FilterDataType.Boolean:
                        return val === 'true';
                    default:
                        return val;
                }
            }
        }
    }
    catch (e) {
        console.log(e);
    }
    return undefined;
};

export const getSavedFilterValues = (f: IFilter): IFilterValue[] => {
    const d = getDefaultValues(f);
    try {
        const filter: IFilterSave[] = getLocalStorageFilter();
        if (
            filter.length > 0 &&
            filter.filter((q) => q.name === f.name).length > 0
        ) {
            const iv: IFilterValue[] = filter.filter(
                (q) => q.name === f.name
            )[0].values;
            for (const r of iv) {
                if (d.filter((q) => q.name === r.name).length > 0) {
                    d.filter((q) => q.name === r.name)[0].value = r.value;
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
    return d;
};

export const getDefaultValues = (f: IFilter): IFilterValue[] => {
    const d: IFilterValue[] = [];
    try {
        for (const i of f.filters) {
            if (i.defaultValue) {
                d.push({
                    name: i.name,
                    value: i.defaultValue,
                    dataType: i.dataType || FilterDataType.String,
                });
            } else {
                const v: any =
                    i.type === FilterType.Dropdown
                        ? i.values && i.fields
                            ? i.values[0][i.fields?.id]
                            : ''
                        : i.dataType === FilterDataType.Number
                        ? -1
                        : '';
                d.push({
                    name: i.name,
                    value: v,
                    dataType: i.dataType || FilterDataType.String,
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
    return d;
};

export const getFilter = (f: IFilterValue[], d: any): any => {
    const s: any = {
        ...d,
        performSearch: true,
    };

    for (const v of f) {
        s[v.name] =
            v.dataType === FilterDataType.Number && v.value === ''
                ? -1
                : v.value;
    }
    return s;
};

export const getFilterValue = (f: IFilter, iv: IFilterValue[], v: string): any => {
    try {
        const filter: IFilterSave[] = getLocalStorageFilter();
        if (filter.length > 0 && filter.filter(q => q.name === f.name).length > 0) {
            if (iv.filter(q => q.name === v).length > 0) {
                const val = iv.filter(q => q.name === v)[0].value;
                if (val.toString() === '') return undefined;
                const d: FilterDataType = f.filters.filter(q => q.name === v)[0].dataType || FilterDataType.String;
                switch (d) {
                    case FilterDataType.Boolean:
                        return val === 'true';
                    default:
                        return val;
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
    return undefined;
};
