
export interface IColumnAction {
    icon?: string;
    text?: string;
    helpText?: string;
    onClick?: (item: any) => void;
    condition?: string;
    conditionValue?: any;
    notCondition?: boolean;
    className?: string;
    target?: string;
    download?: string;
    href?: string;
}

export const showColumnAction = (i: IColumnAction, c: any): boolean => {
    if (i.condition) {
        if (i.conditionValue) {
            return i.notCondition ? c[i.condition] !== i.conditionValue : c[i.condition] === i.conditionValue;
        }
        else {
            return !i.condition
                || (!i.notCondition && c[i.condition])
                || (i.notCondition && !c[i.condition])
        }
    }
    else {
        return true;
    }
};