import React, { useEffect, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import { ISelectedReportAdminViewInfo } from './ReportAdmin';
import { defaultReportServer, getReportServerList, IReportServer } from '../../entities/ReportServer';
import { useDeleteReportServer, useGetReportServers } from '../../services/ReportServerService';
import ReportServerFormik from './ReportServerFormik';

enum ModalTypes {
    None = 1,
    Edit,
}

const AdminServers: React.FunctionComponent<ISelectedReportAdminViewInfo> = ({ reloadAdmin }) => {
    const { service, doRefresh } = useGetReportServers();
    const { service: deleteService, setItemId: setDeleteId } = useDeleteReportServer();

    const [items, setItems] = useState<IReportServer[]>([]);
    const [item, setItem] = useState<IReportServer>(defaultReportServer);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [initalLoad, setInitaload] = useState<boolean>(true);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);

    useEffect(() => {
        if (service.result) {
            setItems(getReportServerList(service.result));
            setInitaload(false);
            !initalLoad && reloadAdmin();
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            doRefresh();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultReportServer });
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IReportServer) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onSuccess = () => {
        setShowModal(ModalTypes.None);
        doRefresh();
    };

    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IReportServer) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Report Server?",
            message: `Are you sure you want to delete the report group <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "canDelete", notCondition: true, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { id: 'name', name: 'Name', idNewLine: 'id', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Server' },
        { id: 'path', name: 'Path', sort: true, type: ColumnType.NavigationLink, target: '_blank' },
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
    ];

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OETable
                loading={service.isInProgress}
                loadingMessage="Loading Report Servers"
                data={items}
                noDataMessage="There are no report servers"
                columns={columns}
                showPagination={true}
                defaultSort="name"
                defaultPageSize={10}
                actions={[
                    { icon: Icon.Add, text: 'Add New Server', action: onAdd },
                ]}
            />
            {showModal === ModalTypes.Edit && (
                <ReportServerFormik onCancel={onCancel} onSuccess={onSuccess} item={item} />
            )}
        </>
    );
};

export default AdminServers;