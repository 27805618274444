import { defaultReport, IReport } from '../../reporting/entities/Report';

export interface IPowerBIReportEmbeddedContent {
    embedToken: string;
    embedUrl: string
    reportId: string;
    groupId: string;
    accessToken: string;
}

export const defaultPowerBIReportEmbeddedContent: IPowerBIReportEmbeddedContent = {
    embedToken: '', embedUrl: '', reportId: '', groupId: '', accessToken: ''
};

export interface IPowerBIReport extends IReport {
    embedContent: IPowerBIReportEmbeddedContent;
}

export const defaultPowerBIReport: IPowerBIReport = {
    ...defaultReport, embedContent: defaultPowerBIReportEmbeddedContent
};
