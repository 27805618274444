import React, { useEffect, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import { ISelectedReportAdminViewInfo } from './ReportAdmin';
import { defaultReportType, getReportTypeList, IReportType } from '../../entities/ReportType';
import { useDeleteReportType, useGetReportTypes } from '../../services/ReportTypeService';
import ReportTypeFormik from './ReportTypeFormik';

enum ModalTypes {
    None = 1,
    Edit,
}

const AdminTypes: React.FunctionComponent<ISelectedReportAdminViewInfo> = ({ reloadAdmin }) => {

    const { service, doRefresh } = useGetReportTypes();
    const { service: deleteService, setItemId: setDeleteId } = useDeleteReportType();

    const [items, setItems] = useState<IReportType[]>([]);
    const [item, setItem] = useState<IReportType>(defaultReportType);
    const [initalLoad, setInitaload] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);

    useEffect(() => {
        if (service.result) {
            setItems(getReportTypeList(service.result));
            setInitaload(false);
            !initalLoad && reloadAdmin();
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            doRefresh();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultReportType });
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IReportType) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onSuccess = () => {
        setShowModal(ModalTypes.None);
        doRefresh();
    };

    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IReportType) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Report Server?",
            message: `Are you sure you want to delete the report group <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "canDelete", notCondition: true, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { id: 'name', name: 'Name/Id', idNewLine: 'id', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Type' },
        { id: 'reportServer', name: 'Server/Route', idNewLine: 'route', sort: true, type: ColumnType.String },
        { id: 'description', name: 'Description', sort: true, type: ColumnType.String },
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
    ];

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OETable
                loading={service.isInProgress}
                loadingMessage="Loading Report Types"
                data={items}
                columns={columns}
                showPagination={true}
                defaultSort="name"
                defaultPageSize={10}
                actions={[
                    { icon: Icon.Add, text: 'Add New Type', action: onAdd },
                ]}
            />
            {showModal === ModalTypes.Edit && (
                <ReportTypeFormik onCancel={onCancel} onSuccess={onSuccess} item={item} />
            )}
        </>
    );
};

export default AdminTypes;