import { ICubeAttributeRequest } from "./CubeAttributeRequest";

export interface ICubeRequest {
    id: string;
    projectId: string;
    attributeId: string;
    run: boolean;
    tokenId: string;
    instanceId: string;
    body?: ICubeAttributeRequest;
    reportId: string;
    dossierId: string;
}

export const defaultCubeRequest: ICubeRequest = {
    id: '',
    projectId: '',
    attributeId: '',
    tokenId: '',
    instanceId: '',
    run: false,
    reportId: '',
    dossierId: ''
};

