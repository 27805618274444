import React, { Fragment } from 'react';
import { Icon } from '../../entities/Icon';
import { formatInteger } from '../../utilities/String';
import OELink from '../general/OELink';
import { IPagination, IPaginationLink, PageNavigation, Pagination } from './entities/Pagination';

const PageLink: React.FunctionComponent<IPaginationLink> = (props) => {
    const onSelected = () => {
        props.onSelected(props.page);
    };

    const className = `page-item ${props.currentpage === props.page ? ' active' : ''}`;

    const getIcon = (page: number): string => {
        if (page === PageNavigation.BackStep) { return Icon.PageBackwardStep };
        if (page === PageNavigation.ForwardStep) { return Icon.PageForwardStep };
        if (page === PageNavigation.Back) { return Icon.PageBackward };
        if (page === PageNavigation.Forward) { return Icon.PageForward };
        return '';
    }

    const getText = (page: number): string => {
        if (page === PageNavigation.BackStep || page === PageNavigation.ForwardStep || page === PageNavigation.Back || page === PageNavigation.Forward) {
            return ''
        };
        return props.page.toString();
    }

    return (
        <Fragment>
            <li className={className}>
                <OELink className="page-link" onClick={onSelected} icon={getIcon(props.page)} text={getText(props.page)} />
            </li>
        </Fragment>
    );
};

interface IQPaginationComponent {
    setPagination: (QPagination: IPagination) => void;
}

const OEPagination: React.FunctionComponent<IPagination & IQPaginationComponent> = (properties) => {

    const onPageSizeChange = (event: any) => {
        const m = Pagination.setPageSize(properties, parseInt(event.target.value, 10));
        m.currentpage = properties.currentpage;
        properties.setPagination({ ...properties, start: m.start, end: m.end, currentpage: m.currentpage, pagelist: m.pagelist, pages: m.pages, pagesize: m.pagesize });
        properties.onChange && properties.onChange(m.start, m.end, m.currentpage, m.pagesize);
    };

    const onPageChange = (incr: number) => {
        if (incr === properties.currentpage) return;
        const m = Pagination.setCurrentPage(properties, incr);
        properties.setPagination({ ...properties, start: m.start, end: m.end, currentpage: m.currentpage, pagelist: m.pagelist });
        properties.onChange && properties.onChange(m.start, m.end, m.currentpage, m.pagesize);
    };


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12">
                    <ul className="pagination ipad m-t-0 pull-left">
                        {properties && properties.pagelist && properties.pagelist.map(item =>
                            <PageLink key={item} currentpage={properties.currentpage} onSelected={onPageChange} page={item} />
                        )}
                    </ul>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 m-t-10">
                    {properties.showPageTotals && (
                        <i className="pull-right m-b-5">{`${formatInteger(properties.start)} - ${formatInteger(properties.end)} of ${formatInteger(properties.records)}`}</i>
                    )}
                    {properties.showPageSize && properties.records > (properties.pagesizeoptions ? properties.pagesizeoptions[0] : 10) && (
                        <div className="pull-right m-r-10 ">
                            <i> <small className="m-b-5">Page Size:&nbsp;</small></i>
                            <select className="pagination-Dropdown" value={properties.pagesize} onChange={onPageSizeChange} >
                                {properties && properties.pagesizeoptions && properties.pagesizeoptions.map(item =>
                                    <option key={item} value={item}>{item}</option>
                                )}
                            </select>
                        </div>
                    )}
                </div>
            </div>
        </Fragment >
    );
};


export default OEPagination;

