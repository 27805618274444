import React from 'react';
import OEEditor, { EditorHeight } from '../form/OEEditor';
import OEFormItem, { IOEFormItemInfo } from './OEFormItem';

interface IComponentInfo {
    columns: number;
    value: any;
    label: any;
    name: string;
    height?: EditorHeight;
    hidden?: boolean;
    setFieldValue?: any;
    required?: boolean;
    cssFiles?: string;
    children?: any;
}

const OEFormEditor: React.FunctionComponent<IComponentInfo & IOEFormItemInfo> =
    ({
        columns, name, label, errors, touched, setFieldValue, required, hidden, value, height, cssFiles
    }) => {

        return (
            <OEFormItem columns={columns} name={name} label={label} errors={errors} touched={touched} required={required} hidden={hidden}>
                <OEEditor cssFiles={cssFiles} setFieldValue={setFieldValue} height={height} name={name} data={value} />
            </OEFormItem>
        );
    };

export default OEFormEditor;
