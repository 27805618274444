import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export interface IWidgetGroup extends IBaseAuditedEntity {
    id: number;
    name: string;
    code: string;
}

export const defaultWidgetGroup: IWidgetGroup = {
    id: -1, name: '', code: ''
};

export const getWidgetGroupList = (i: IBaseList<IWidgetGroup>): IWidgetGroup[] => {
    const d: IWidgetGroup[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultWidgetGroup,
            ...v,
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getWidgetTypeForSave = (i: IWidgetGroup): IWidgetGroup => {
    return i;
};

