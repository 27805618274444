import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IRoute } from './Route';

export interface ISecurityMenu extends ISecurityRole {
    menuId: number;
}

export interface ISecurityMenuListItem extends IBaseAuditedEntity {
    securityMenu: ISecurityMenu;
}

export interface IMenuLookupTables {
    routes: IRoute[];
}

export const defaultMenuLookupTables: IMenuLookupTables = {
    routes: []
};

export const getSecurityMenuList = (i: IBaseList<ISecurityMenuListItem>): ISecurityRole[] => {
    const d: ISecurityRole[] = [];
    for (const v of i.items) {
        d.push({ roleId: v.securityMenu.roleId });
    }
    return d;
};

