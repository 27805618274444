import React, { useEffect, useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { Icon } from '../../../core/entities/Icon';
import { emptyGUID } from '../../../core/utilities/String';
import { IMicroStrategyFilter } from '../../../report-microstrategy/entities/MicroStrategyFilters';
import { defaultReportFlag, getReportFlagList, IReportFlag } from '../../../reporting/entities/ReportFlag';
import { useGetReportFlag } from '../../../reporting/services/ReportFlagService';
import { IReport } from '../../entities/Report';
import ReportFlagFormik from './ReportFlagFormik';

interface IReportFlagInfo {
    report: IReport;
    pageFilters: IMicroStrategyFilter[];
}

const ReportFlagging: React.FunctionComponent<IReportFlagInfo> = ({ report, pageFilters }) => {

    const { service: reportFlagService, setItem: setReportFlagSearch } = useGetReportFlag();
    const [flag, setFlag] = useState<IReportFlag>(defaultReportFlag);
    const [flagSearch, setFlagSearch] = useState<IReportFlag>(defaultReportFlag);

    const [show, setShow] = useState<boolean>(false);
    const [flags, setFlags] = useState<IReportFlag[]>([]);

    useEffect(() => {
        if (!emptyGUID(report.id) && pageFilters.length > 2) {
            try {
                setFlagSearch({
                    ...defaultReportFlag,
                    reportId: report.id,
                    report: report.title,
                    academicYear: pageFilters[0].values.filter(q => q.parentId === pageFilters[0].selection)[0].id,
                    academicYearLabel: pageFilters[0].values.filter(q => q.parentId === pageFilters[0].selection)[0].name,
                    districtId: pageFilters[1].values.filter(q => q.parentId === pageFilters[1].selection)[0].id,
                    district: pageFilters[1].values.filter(q => q.parentId === pageFilters[1].selection)[0].name,
                    schoolId: pageFilters[2].values.filter(q => q.parentId === pageFilters[2].selection)[0].id,
                    school: pageFilters[2].values.filter(q => q.parentId === pageFilters[2].selection)[0].name,
                });
            }
            catch { }
            // eslint-disable-next-line
        }
    }, [pageFilters, report]);

    useEffect(() => {
        refreshFlag();
        // eslint-disable-next-line
    }, [flagSearch]);

    useEffect(() => {
        if (reportFlagService.result) {
            setFlags(getReportFlagList(reportFlagService.result));
        }
        // eslint-disable-next-line
    }, [reportFlagService]);

    useEffect(() => {
        const i: IReportFlag = getItemFlag();
        if (flags.length > 0) {
            setFlag({ ...flags[0], report: i.report, school: i.school, district: i.district });
        } else {
            setFlag({ ...i, comments: '' });
        }
        // eslint-disable-next-line
    }, [flags]);

    const getItemFlag = (): IReportFlag => {
        return {
            report: flagSearch.report,
            reportId: flagSearch.reportId,
            academicYear: flagSearch.academicYear,
            academicYearLabel: flagSearch.academicYearLabel,
            schoolId: flagSearch.schoolId && flagSearch.schoolId.length > 0 ? flagSearch.schoolId : 'All',
            districtId: flagSearch.districtId && flagSearch.districtId.length > 0 ? flagSearch.districtId : 'All',
            school: flagSearch.school || 'All Schools',
            district: flagSearch.district || 'All Districts',
            comments: ''
        }
    }

    const refreshFlag = () => {
        const i: IReportFlag = getItemFlag();
        setFlag({ ...i });
        setReportFlagSearch({
            ...defaultReportFlag,
            reportId: i.reportId,
            academicYear: i.academicYear,
            schoolId: i.schoolId,
            districtId: i.districtId
        });
    }

    const onCancel = () => {
        setShow(false);
    };

    const onSuccess = () => {
        setShow(false);
        refreshFlag();
    };

    const onShow = () => {
        setShow(true);
    };

    return (
        <span className="m-l-5">
            {flag.comments === '' && (
                <OEIcon hidden={flag.reportId === ''} icon={Icon.FlagOff} onClick={onShow} />
            )}
            {flag.comments !== '' && (
                <span style={{ color: "#CE2D27" }}>
                    <OEIcon hidden={flag.reportId === ''} icon={Icon.FlagOn} onClick={onShow} />
                </span>
            )}
            {show && (
                <ReportFlagFormik item={flag} onCancel={onCancel} onSuccess={onSuccess} />
            )}
        </span>
    );
};

export default ReportFlagging;