import React, { useState } from 'react';
import OEHeading from '../core/components/general/OEHeading';
import OETabs, { OETab } from '../core/components/tabs/OETabs';
import { Icon } from '../core/entities/Icon';
import OrganizationTypes from './components/OrganizationTypes';
import OrganizationUnits from './components/OrganizationUnits';
import { IOrganizationType } from './entities/OrganizationType';

const OrganizationAdmin: React.FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState('units');
    const [types, setTypes] = useState<IOrganizationType[]>([]);

    const setTab = (event: any) => {
        setActiveTab(event);
    };

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Organization Administration" icon={Icon.SiteAdministration} />
            <OETabs className="m-t-40" defaultTab={activeTab} onSelect={setTab}>
                <OETab title="Organization Units" eventKey="units">
                    <OrganizationUnits types={types} />
                </OETab>
                <OETab title="Organization Types/Roles" eventKey="types">
                    <OrganizationTypes updateTypes={setTypes} />
                </OETab>
            </OETabs>
        </div>
    );
};

export default OrganizationAdmin;