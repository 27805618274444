import { IDropdownValues } from "../../core/components/form/OEDropdown";

export enum MicroStrategyDossierFilterType {
    SingleSelect = 'S',
    MultipleSelect = 'M',
    Range = 'R',
    SingleSelectWithAll = 'X'
}

export const MicroStrategyDossierFilterTypeDropdownValues: IDropdownValues[] =
    [
        { id: MicroStrategyDossierFilterType.MultipleSelect, name: 'Multiple Select' },
        { id: MicroStrategyDossierFilterType.Range, name: 'Slider/Range' },
        { id: MicroStrategyDossierFilterType.SingleSelect, name: 'Single Select' },
        { id: MicroStrategyDossierFilterType.SingleSelectWithAll, name: 'Single Select or All Select ' },
    ];

export const MicroStrategySingleSelectAllValue = 'MicroStrategySingleSelectAllValue-7234968723459'

export interface IMicroStrategyDossierFilterSelection {
    name?: string;
    value?: string;
}

export const defaultMicroStrategyDossierFilterSelection: IMicroStrategyDossierFilterSelection = {
    value: ''
};

export interface IMicroStrategyDossierFilterInfo {
    key: string;
}

export const defaultMicroStrategyDossierFilterInfo: IMicroStrategyDossierFilterInfo = {
    key: ''
};

export interface IMicroStrategyDossierFilterSingle {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selection: IMicroStrategyDossierFilterSelection;
}

export const defaultMicroStrategyDossierFilterSingle: IMicroStrategyDossierFilterSingle = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selection: defaultMicroStrategyDossierFilterSelection
};

export interface IMicroStrategyDossierFilterMultiple {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selections: IMicroStrategyDossierFilterSelection[];
}

export const defaultMicroStrategyDossierFilterMultiple: IMicroStrategyDossierFilterMultiple = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selections: []
};


export const isSuppressionFilter = (n: string): boolean => {
    try {
        return n.toLowerCase() === 'suppressed' || n.toLowerCase() === 'suppression';
    }
    catch {
        return false;
    }
}

export interface IMicroStrategyDossierFilter {
    singleSelect?: IMicroStrategyDossierFilterSingle;
    multipleSelect?: IMicroStrategyDossierFilterMultiple;
    type: MicroStrategyDossierFilterType;
    start?: number;
    end?: number;
    name: string;
}
