import { Formik, FormikProps } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import {
    defaultNotification,
    INotification,
} from '../messaging/entities/Notification';
import OENotification from '../messaging/OENotification';
import { IFormikInfo } from './entities/Form';
import OEForm from './OEForm';

const OEFormik: React.FunctionComponent<IFormikInfo> = ({
    service,
    setItem,
    item,
    component,
    progressMessage,
    onSave,
    onCancel,
    validationSchema,
    lookupTables,
    labelColumns,
    submitText,
    cancelText,
    successMessage,
    altActionText,
    onAltAction,
}) => {
    const [localValues, setLocalValues] = useState(item);
    const [errors, setErrors] = useState<string[]>([]);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [notification, setNotification] =
        useState<INotification>(defaultNotification);

    useEffect(() => {
        if (service && service.isFinished) {
            if (service.isSuccess) {
                setNotification({
                    message: successMessage || '',
                    type: 'success',
                });
                setSaveDone(true);
            } else {
                setErrors(
                    [service.response.message] || [
                        'An error occurred generating request',
                    ]
                );
            }
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        setLocalValues(item);
    }, [item]);

    useEffect(() => {
        saveDone && onSave && onSave();
        // eslint-disable-next-line
    }, [saveDone]);

    const onSubmit = (values: any) => {
        setErrors([]);
        setItem(values);
    };

    const getForm = (props: FormikProps<any>) => (
        <OEForm
            {...props}
            isSubmitting={service && service.isInProgress}
            errorList={errors}
            onCancel={onCancel}
            component={component}
            submitText={submitText}
            cancelText={cancelText}
            labelColumns={labelColumns}
            lookupTables={lookupTables}
            progressMessage={progressMessage}
            altActionText={altActionText}
            onAltAction={onAltAction}
        />
    );

    return (
        <Fragment>
            {successMessage && (
                <OENotification
                    setNotification={setNotification}
                    notification={notification}
                />
            )}
            <Formik
                onSubmit={onSubmit}
                initialValues={localValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {(props) => getForm(props)}
            </Formik>
        </Fragment>
    );
};

export default OEFormik;
