import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { IOrganizationType } from '../entities/OrganizationType';
import { useCreateOrganizationType, usePutOrganizationType } from '../services/OrganizationTypeService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IOrganizationType> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Code" name="code" value={values.code}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IOrganizationType>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
    code: Yup.string().required('Code is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IOrganizationType;
    onCancel: () => void;
    onSave: () => void;
}

const OrganizationTypeFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSave }) => {
    const { service, setOrganizationType } = usePutOrganizationType();
    const { service: createService, setOrganizationType: setCreateOrganizationType } = useCreateOrganizationType();

    return (
        <OEFormikModal
            item={item}
            labelColumns={labelColumns}
            title={`${item.id > 0 ? `Edit Organization Type: ${item.name}` : `Add New Organization Type`}`}
            progressMessage={ProgressMessages.OrganizationType}
            successMessage={SuccessMessages.OrganizationType}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id > 0 ? setOrganizationType : setCreateOrganizationType}
            service={item.id > 0 ? service : createService}
        />
    );
};

export default OrganizationTypeFormik;
