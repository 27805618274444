import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { IBaseList } from '../../core/entities/BaseList';
import { getUrl } from '../../core/utilities/URL';
import { IDcFileRequestWithNavigationProperties } from '../entities/DcFileRequestWithNavigationProperties';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import {
    defaultSiteReport,
    ISiteReport,
} from '../../reporting/entities/SiteReport';

export type IDcFileUploadFilter = { term: string; orgUnitId: string } | null;

export const useGetDcFileUploadList = () => {
    const [service, setService] = useState(
        GetRequest.empty<IBaseList<IDcFileRequestWithNavigationProperties>>()
    );
    const [dcFileUploadFilter, setDcFileUploadFilter] =
        useState<IDcFileUploadFilter>(null);

    useEffect(() => {
        if (!dcFileUploadFilter) {
            return;
        }
        processGetAsync(
            setService,
            getUrl(`api/app/dc-file-requests`, {
                term: dcFileUploadFilter.term,
                agencyIds: dcFileUploadFilter.orgUnitId,
            })
        );
        setDcFileUploadFilter(null);
    }, [dcFileUploadFilter]);

    return { service, setDcFileUploadFilter };
};

export const useGetFileUploadListFilters = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<ISiteReport>(defaultSiteReport);

    useEffect(() => {
        if (item.reportFilters.length <= 0) {
            return;
        }
        processPostAsync(
            setService,
            `/api/app/site-reports/file-filters`,
            item
        );
        setItem(defaultSiteReport);
    }, [item]);

    return { service, setItem };
};
