import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { formatDateTime } from '../../utilities/Date';
import { IColumn, ColumnType } from './entities/Column';

/// -----------------------------------------------
/// Export CSV Component for Table
/// -----------------------------------------------

interface IHeaders {
    label: string;
    key: string;
}

interface IComponentInfo {
    columns: IColumn[];
    data: any[];
    onCancel: () => void;
    showExport?: boolean;
    filename?: string;
}

export const OETableExport: React.FunctionComponent<IComponentInfo> = ({ columns, data, onCancel, showExport, filename }) => {
    const [headers, setHeaders] = useState<IHeaders[]>([]);
    const [csvData, setCSVData] = useState<any[]>([]);

    useEffect(() => {
        const h: IHeaders[] = [];
        for (const c of columns) {
            c.type !== ColumnType.Actions && h.push({ label: c.name || c.id, key: c.id });
        }
        setHeaders(h);
    }, [columns]);

    useEffect(() => {
        const d: any[] = [...data];
        for (const v of d) {
            for (const c of columns) {
                if (c.type === ColumnType.Date) {
                    v[c.id] = formatDateTime(v[c.id], c.format);
                }
            }
        }
        setCSVData(d);
        // eslint-disable-next-line
    }, [data]);

    const exportCsvRef = useRef<any>();

    useEffect(() => {
        if (showExport && exportCsvRef.current) {
            window.setTimeout(() => {
                exportCsvRef.current.link.click();
                onCancel();
            }, 0); // this setTimeout is a workaround to the need to export without using the link provided by CSVLink the library
        }
        // eslint-disable-next-line
    }, [showExport]);

    return (
        <CSVLink
            ref={exportCsvRef}
            data={csvData}
            headers={headers}
            filename={`${filename || 'Table_export'}.csv`}
        />
    );
};

export default OETableExport;


