import { defaultConfirmationMessage, IConfirmationMessage } from '../entities/ConfirmationMessage';


// Organization Unit Page
export const OrganizationUnitDeleteError: IConfirmationMessage = {
    ...defaultConfirmationMessage, show: true, title: 'Error Occurred deleting Organization Unit'
}

export const OrganizationUnitDeleteConfirm: IConfirmationMessage = {
    ...defaultConfirmationMessage, show: true, title: 'Delete Organization Unit?',
    message: `Are you sure you want to delete the organization unit <b>{NAME}</b>?`
}

// Profile Page
export const ProfileDefaultRoleConfirmation: IConfirmationMessage = {
    ...defaultConfirmationMessage, show: true, title: 'Update yout Default Role?',
    message: `Are you sure you want to make your default role <b>{NAME}</b>?`
}

