import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultRoutePermissionUpdate, IRoutePermissionUpdate } from '../entities/Route';
import { ISecurityRouteListItem } from '../entities/SecurityRoute';

export const useGetSecurityRoute = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<ISecurityRouteListItem>>());
    const [routeId, setRouteId] = useState<number>(-1);

    useEffect(() => {
        if (routeId < 0) { return; }
        processGetAsync(setService, `/api/app/security-routes?RouteId=${routeId}`);
        setRouteId(-1);
    }, [routeId]);

    return { service, setRouteId };
};

export const usePutRolePermissions = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [security, setSecurity] = useState<IRoutePermissionUpdate>(defaultRoutePermissionUpdate);

    useEffect(() => {
        if (isNaN(security.id) || security.id <= 0) { return; }
        processPutAsync(setService, `/api/app/routes/${security.id}/permissions`, security.permissions);
        setSecurity(defaultRoutePermissionUpdate);
    }, [security]);

    return { service, setSecurity };
};
