import { FormikProps } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { OEInputType } from '../../core/components/form/OEInput';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { Icon } from '../../core/entities/Icon';
import { defaultDatasetConnection, IDatasetConnection } from '../entities/DatasetConnection';


enum ViewTypes {
    Default = 1,
    Edit,
}

const Form: React.FunctionComponent<FormikProps<IDatasetConnection>> = ({ values, errors, touched, setFieldValue }) => {

    return (
        <OEFormInput
            label="Connection String"
            name="connectionString"
            value={values.connectionString}
            errors={errors}
            touched={touched}
            columns={12}
            rows={5}
            required={true}
            setFieldValue={setFieldValue}
            type={OEInputType.LargeText}
            inputType={FormInputType.String}
        />
    );
};

interface IDatasetConnectionStringInfo {
    columns: number;
    errors?: any;
    name: string;
    touched?: any;
    setFieldValue: any;
}

const DatasetConnectionStringFormik: React.FunctionComponent<IDatasetConnectionStringInfo> = ({ columns, errors, name, touched, setFieldValue }) => {
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);
    const [item, setItem] = useState<IDatasetConnection>(defaultDatasetConnection);
    const onCancel = () => {
        setShowView(ViewTypes.Default);
    }

    const onEdit = () => {
        setItem(defaultDatasetConnection);
        setShowView(ViewTypes.Edit);
    }

    const onSave = (i: IDatasetConnection) => {
        setShowView(ViewTypes.Default);
        setFieldValue(name, i.connectionString);
    }

    return (
        <>
            <OEFormInput
                label="Connection String"
                name="connectionString"
                value="**************************************"
                errors={errors}
                touched={touched}
                columns={columns}
                required={true}
                disabled={true}
                type={OEInputType.Password}
                inputType={FormInputType.String}
                instructions="For security purposes, this field is not shown. It can only be changed"
                action={onEdit}
                actionIcon={Icon.Edit}
            />

            {showView === ViewTypes.Edit && (
                <OEFormikModal
                    item={item}
                    labelColumns={12}
                    title="Update Connection String"
                    validationSchema={Yup.object<IDatasetConnection>().shape({
                        connectionString: Yup.string().max(2000).required('Connection String is required').nullable()
                    })}
                    submitText="Update"
                    setItem={onSave}
                    component={Form}
                    onCancel={onCancel}
                />

            )}

        </>
    );
};

export default DatasetConnectionStringFormik;