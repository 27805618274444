
export interface IChatBot {
    projectName: string;
    directLineId: string;
    chatbotName: string;
    excludeWords: string;
}

export const defaultChatBot: IChatBot = {
    projectName: '', directLineId: '', chatbotName: '', excludeWords: ''
};
