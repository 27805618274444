import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { getEmptyGUID } from '../../core/utilities/String';
import { IReportFilter, defaultReportFilter } from '../../report-microstrategy/entities/ReportFilter';
import { IReportColumn } from './ReportColumm';

export interface ISiteReport extends IBaseAuditedEntity {
    id: string;
    title: string;
    description: string;
    externalId: string;
    parameters: string;
    width: number;
    height: number;
    storedProcedure: string;
    filterProcedure: string;
    reportTypeId: string;
    reportTypeName?: string;
    isActive: boolean;
    sortOrder: number;
    configuration: string;
    path: string;
    reportFilters: IReportFilter[];
    columns: IReportColumn[];
    visible: boolean;
}

export const defaultSiteReport: ISiteReport = {
    id: getEmptyGUID(), title: '', description: '', externalId: '', parameters: '', width: 0, height: 0,
    storedProcedure: '', filterProcedure: '', reportTypeId: getEmptyGUID(), isActive: true, sortOrder: 0,
    configuration: '', path: '', reportFilters: [], columns: [], visible: false, pageId: getEmptyGUID()
};

export const defaultSiteReportFormFilter: ISiteReport = {
    id: getEmptyGUID(), title: '', description: '', externalId: '', parameters: '', width: 0, height: 0,
    storedProcedure: '', filterProcedure: '', reportTypeId: getEmptyGUID(), isActive: true, sortOrder: 0,
    configuration: '', path: '',
    reportFilters: [
        {
            ...defaultReportFilter, reportId: getEmptyGUID(), name: "AcademicYear", value: ""
        },
        {
            ...defaultReportFilter, reportId: getEmptyGUID(), name: "District", value: ""
        },
        {
            ...defaultReportFilter, reportId: getEmptyGUID(), name: "School", value: ""
        },
    ],
    columns: [], visible: false, pageId: getEmptyGUID()
};
