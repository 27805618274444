import React from 'react';
import UnderConstruction from '../../misc/components/UnderConstruction';
import { ISelectedSiteAdminInfo } from '../SiteAdmin';


const Settings: React.FunctionComponent<ISelectedSiteAdminInfo> = () => {

    return (
        <UnderConstruction name="Settings" size={4} />
    );
};

export default Settings;