import React, { Children } from 'react';
import OEWell, { WellStyle } from '../../core/components/general/OEWell';
import { Icon } from '../../core/entities/Icon';
import { IWidget } from '../entities/Widget';
import { navigateToPage } from '../../core/utilities/URL';

interface IComponentInfo {
    item: IWidget;
    onEdit: (i: IWidget) => void;
    children?: any;
    showView?: boolean
}

const PageContent: React.FunctionComponent<IComponentInfo> = ({ item, children, onEdit, showView }) => {

    const onEditClick = () => {
        onEdit(item);
    }

    const onView = () => {
        navigateToPage(`/widget?id=${item.code}`);
    }

    return (
        <>
            {item && (
                <OEWell headingSize={6} icon={Icon.EditHeader} title={item.name} oeStyle={WellStyle.Bordered}
                    actions={[
                        { onClick: onView, icon: Icon.View, hidden: !showView },
                        { onClick: onEditClick, icon: Icon.Edit },
                    ]}
                >
                    {Children.map(children, i => i)}
                </OEWell>
            )}
        </>
    );
};


export default PageContent;