/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { emptyGUID } from '../../../core/utilities/String';
import { IReport, canDebugReport, getReportCubeRequest } from '../../../reporting/entities/Report';
import { ProcessingCube, getProcessingCubeLabel, logProcessingCube } from '../../../reporting/entities/ReportProcessing';
import { initCubeAttributesElements } from '../../entities/api/CubeAttributeElementResponse';
import { ICubeRequest, defaultCubeRequest } from '../../entities/api/CubeRequest';
import { ICubeAttribute, ICubeResponse, defaultCubeResponse } from '../../entities/api/CubeResponse';
import { getMSAuthorizationToken, useGetCube, useGetCubeAttributeElements } from '../../services/MicrostrategyService';

interface ICubeInfo {
    report: IReport;
    cubeId: string;
    validToken: boolean;
    setError: (i: any) => void;
    setResult?: (i: string) => void;
    setNotification: (n: INotification) => void;
    setDebugMessage?: (i: string) => void;
    setCube?: (c: ICubeAttribute[]) => void;
}

const Cube: React.FunctionComponent<ICubeInfo> = ({
    report, cubeId, validToken, setError, setResult, setDebugMessage,
    setCube
}) => {
    const { service: cubeService, setCube: setGetCube } = useGetCube();
    const { service: cubeGetAttributeService, setCube: setGetCubeAttributeElements } = useGetCubeAttributeElements();

    const [debug] = useState<boolean>(canDebugReport());
    const [cubeRequest, setCubeRequest] = useState<ICubeRequest>(defaultCubeRequest);
    const [cubeResponse, setCubeResponse] = useState<ICubeResponse>(defaultCubeResponse);
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [attributeLoadIndex, setAttributeLoadIndex] = useState<number>(-1);
    const [processingStep, setProcessingStep] = useState<ProcessingCube>(ProcessingCube.Idle);

    useEffect(() => {
        logProcessingCube(processingStep, debug);
        setDebugMessage?.(getProcessingCubeLabel(processingStep));
        switch (processingStep) {

            case ProcessingCube.CubeLoadStart:
                setProcessingStep(ProcessingCube.CubeLoading);
                break;

            case ProcessingCube.CubeLoading:
                if (!emptyGUID(report.id)) {
                    setCubeRequest({ ...getReportCubeRequest(report), id: cubeId, tokenId: getMSAuthorizationToken(), run: true });
                } else {
                    setProcessingStep(ProcessingCube.Idle);
                }
                break;

            case ProcessingCube.CubeLoaded:
                if (cubeAttributes.length > 0) {
                    setProcessingStep(ProcessingCube.AttributesLoadNext);
                }
                else {
                    setProcessingStep(ProcessingCube.Complete);
                }
                break;

            case ProcessingCube.AttributesLoadNext:
                setAttributeLoadIndex(attributeLoadIndex + 1);
                break;

            case ProcessingCube.AttributesLoad:
                setDebugMessage?.(`Cube - ${processingStep} - ${cubeAttributes[attributeLoadIndex].name}`);
                setGetCubeAttributeElements({ ...cubeRequest, attributeId: cubeAttributes[attributeLoadIndex].id, run: true });
                break;

            case ProcessingCube.AttributesLoaded:
                setAttributeLoadIndex(-1);
                setCube?.(cubeAttributes);
                setProcessingStep(ProcessingCube.Idle)
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);


    useEffect(() => {
        if (validToken && processingStep === ProcessingCube.Idle && !emptyGUID(cubeId) && !emptyGUID(report.id)) {
            setProcessingStep(ProcessingCube.CubeLoadStart);
        }
        // eslint-disable-next-line
    }, [report, cubeId, validToken]);

    useEffect(() => {
        if (!cubeService.isInProgress) {
            if (cubeService.body) {
                setResult?.(JSON.stringify(cubeService.body));
                setCubeResponse(cubeService.body);
            }
            if (cubeService.error) {
                setError?.(cubeService.error);
                setProcessingStep(ProcessingCube.Idle);
            }
        }
        // eslint-disable-next-line
    }, [cubeService]);

    useEffect(() => {
        if (cubeResponse.id) {
            const i: ICubeAttribute[] = cubeResponse.result.definition.availableObjects.attributes;
            i.sort((a, b) => {
                if (a.name > b.name) { return 1; }
                if (b.name > a.name) { return -1; }
                return 0;
            });;
            for (const j of i) {
                j.cubeId = cubeId;
            }
            setCubeAttributes(i);
        }
        // eslint-disable-next-line
    }, [cubeResponse]);

    useEffect(() => {
        if (processingStep === ProcessingCube.CubeLoading) {
            setProcessingStep(ProcessingCube.CubeLoaded);
        }
        // eslint-disable-next-line
    }, [cubeAttributes]);

    useEffect(() => {
        if (attributeLoadIndex >= 0) {
            setProcessingStep(attributeLoadIndex < cubeAttributes.length ? ProcessingCube.AttributesLoad : ProcessingCube.AttributesLoaded);
        }
        // eslint-disable-next-line
    }, [attributeLoadIndex]);

    useEffect(() => {
        if (!cubeGetAttributeService.isInProgress) {
            if (cubeGetAttributeService.body) {
                setResult?.(JSON.stringify(cubeGetAttributeService.body));
                cubeAttributes[attributeLoadIndex].elements = initCubeAttributesElements(cubeGetAttributeService.body);
                setProcessingStep(ProcessingCube.AttributesLoadNext);
            }
            if (cubeGetAttributeService.error) {
                setError?.(cubeGetAttributeService.error);
                setProcessingStep(ProcessingCube.Idle);
            }
        }
        // eslint-disable-next-line
    }, [cubeGetAttributeService]);

    useEffect(() => {
        setGetCube(cubeRequest);
        // eslint-disable-next-line
    }, [cubeRequest]);

    return (<></>);
};

export default Cube;
