import { useEffect, useState } from 'react';
import OETable from '../../core/components/table/OETable';
import {
    ColumnType,
    IColumn,
} from '../../core/components/table/entities/Column';
import { Icon } from '../../core/entities/Icon';
import {
    IDatasetConnection,
    defaultDatasetConnection,
} from '../entities/DatasetConnection';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import { IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import {
    useDeleteDatasetConnection,
    useGetDatasetConnections,
} from '../services/DatasetConnectionService';
import datasetSourceTypes from '../lookups/dataset-source-types.json';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import { GetDatasetConnectionsInput } from '../entities/GetDatasetConnectionsInput';
import OEHeading from '../../core/components/general/OEHeading';
import { DatasetConnectionFilters } from './DatasetConnectionFilters';
import { DatasetConnectionFormik } from './DatasetConnectionFormik';

enum ModalTypes {
    None = 1,
    Edit,
}

export const DatasetConnectionList: React.FunctionComponent = () => {
    const {
        service: getDatasetConnectionService,
        doRefresh,
        setFilters,
    } = useGetDatasetConnections();

    const { service: deleteService, setDeleteId } =
        useDeleteDatasetConnection();

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>({
        show: false,
        message: '',
        title: '',
    });

    const [items, setItems] = useState<IDatasetConnection[]>([]);
    const [item, setItem] = useState<IDatasetConnection>(
        {} as IDatasetConnection
    );
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (getDatasetConnectionService.result) {
            const datasetConnection =
                getDatasetConnectionService.result.items.map((a) => {
                    return {
                        ...a,
                        datasetConnectionName:
                            datasetSourceTypes.find(
                                (x) => x.id === a.datasetSourceType
                            )?.name || '',
                    };
                });

            setItems(datasetConnection);
        }
    }, [getDatasetConnectionService]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            doRefresh();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    const onAdd = () => {
        setItem(defaultDatasetConnection);
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IDatasetConnection) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onSave = () => {
        doRefresh();
        setShowModal(ModalTypes.None);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IDatasetConnection) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Delete Dataset Connection?',
            message: `Are you sure you want to delete the dataset connection?`,
            onOk: onDelete,
            onCancel: onCancel,
        });
    };

    const onFilter = (filters?: GetDatasetConnectionsInput) => {
        const { displayName, datasetSourceType, databaseSchema } =
            filters || {};

        const filter: GetDatasetConnectionsInput = {
            displayName,
            datasetSourceType,
            databaseSchema,
        };

        setFilters(filter);
        doRefresh();
    };

    const actions: IColumnAction[] = [
        {
            icon: Icon.Edit,
            onClick: onEdit,
            helpText: 'Edit Dataset Connection',
        },
        {
            icon: Icon.Delete,
            onClick: onConfirmDelete,
            helpText: 'Delete Dataset Connection',
        },
    ];

    const columns: IColumn[] = [
        {
            id: 'displayName',
            name: 'Display Name',
            sort: true,
            type: ColumnType.String,
            onClick: onEdit,
        },
        {
            id: 'datasetConnectionName',
            name: 'Dataset Source Type',
            sort: false,
            type: ColumnType.String,
        },
        {
            id: 'databaseSchema',
            name: 'Database Schema',
            sort: false,
            type: ColumnType.String,
        },
        {
            actions,
            id: 'Actions',
            name: '',
            sort: false,
            type: ColumnType.Actions,
        },
    ];

    return (
        <div className="container">
            <OEHeading
                className="m-b-20 m-t-10"
                text="Dataset Connections"
                icon={Icon.DatasetConnections}
            />

            <DatasetConnectionFilters onFilter={onFilter} onAdd={onAdd} />

            <OETable
                loading={getDatasetConnectionService.isInProgress}
                loadingMessage="Loading Dataset Connection"
                data={items}
                columns={columns}
                showPagination={(items?.length || 0) > 10}
                defaultSort="name"
                noDataMessage="No Dataset Connections exist"
                defaultPageSize={10}
            />

            {showModal === ModalTypes.Edit && (
                <DatasetConnectionFormik
                    item={item}
                    onCancel={onCancel}
                    onSave={onSave}
                />
            )}

            <OEConfirmation {...confirmation} />
        </div>
    );
};
