import React from 'react';
import OELink from '../general/OELink';
import { IColumnAction } from './entities/ColumnAction';

interface IComponentInfo {
    item: any;
}

const OEColumnActions: React.FunctionComponent<IColumnAction & IComponentInfo> = ({
    item, icon, text, className, onClick, helpText, target, href, download }) => {

    const onClicked = () => {
        onClick && onClick(item);
    };

    return (
        <>
            {(onClick || href) && (
                <OELink
                    text={text}
                    target={target}
                    onClick={onClicked}
                    title={helpText || ''}
                    icon={icon}
                    href={href && item[href]}
                    download={download && item[download]}
                    className={className}
                />
            )}

            {!onClick && !href && (
                <i className={`${icon} ${className || ''}`} />
            )}

        </>
    );
};

export default OEColumnActions;