import React, { useEffect, useState } from 'react';
import { defaultReportFilter, IReportFilter, IReportFilterSelectionValues, ReportFilterType } from '../../../report-microstrategy/entities/ReportFilter';
import SiteReportFilterDate from './SiteReportFilterDate';
import SiteReportFilterDropdown from './SiteReportFilterDropdown';
import SiteReportFilterInput from './SiteReportFilterInput';

interface IFilterInfo {
    filter: IReportFilter;
    values: IReportFilterSelectionValues[];
    onChange: (i: number, v: string) => void;
}

const SiteReportFilter: React.FunctionComponent<IFilterInfo> = ({ filter, onChange, values }) => {

    const [localValue, setLocalValue] = useState<IReportFilter>(defaultReportFilter);

    const setValue = (v: string) => {
        onChange(filter.sortOrder, v);
    };


    useEffect(() => {
        setLocalValue({ ...filter });
        // eslint-disable-next-line
    }, [filter]);

    return (
        <>
            <div className="label">
                <b className="ng-binding">{localValue.label}</b></div>
            <div className={`standard`}>
                {localValue.reportFilterTypeId === ReportFilterType.Text && (
                    <SiteReportFilterInput filter={localValue} onChange={setValue} />
                )}
                {localValue.reportFilterTypeId === ReportFilterType.Date && (
                    <SiteReportFilterDate filter={localValue} onChange={setValue} />
                )}
                {localValue.reportFilterTypeId === ReportFilterType.Dropdown && (
                    <SiteReportFilterDropdown filter={localValue} values={values.length > 0 ? values[0].values : []} onChange={setValue} />
                )}
            </div>
        </>
    );
};

export default SiteReportFilter;
