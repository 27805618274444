import { useEffect, useState } from "react";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { IChatBot } from "../entities/ChatBot";
import { IDirectLine } from "../entities/DirectLine";


export const useChatbot = () => {
    const [service, setService] = useState(GetRequest.empty<IDirectLine>());

    useEffect(() => {
        processGetAsync(setService, `/api/app/chatbots/directline`);
    }, []);

    return { service };
};

export const useChatbotSettings = () => {
    const [service, setService] = useState(GetRequest.empty<IChatBot>());

    useEffect(() => {
        processGetAsync(setService, `/api/app/chatbots`);
    }, []);

    return { service };
};