import React from 'react';
import OELink from '../../../core/components/general/OELink';
import { IMicroStrategyExportOptions } from '../../entities/MicroStrategyExportOptions';

interface IComponentInfo {
    exportOption: IMicroStrategyExportOptions;
    exportReport: (i: IMicroStrategyExportOptions) => void;
}

export const ReportExport: React.FunctionComponent<IComponentInfo> = ({ exportOption, exportReport }) => {

    const onExportReport = (p: any) => {
        exportReport(exportOption);
    }

    return (
        <>
            <OELink className="pull-right p-r-20" text={exportOption.text} icon={exportOption.icon} onClick={onExportReport} />
        </>
    );
};


export default ReportExport;
