import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest } from '../../core/services/PostRequest';
import { processPutAsync } from '../../core/services/PutRequest';
import { defaultPermissionRequest, defaultPermissionSave, IPermissionRequest, IPermissionResult, IPermissionSave } from '../entities/Permissions';

export const useGetPermissions = () => {
    const [service, setService] = useState(GetRequest.empty<IPermissionResult>());
    const [request, setRequest] = useState<IPermissionRequest>(defaultPermissionRequest);

    useEffect(() => {
        if (request.key === '') { return; }
        processGetAsync(setService, `/api/permission-management/permissions?providerName=${request.name}&providerKey=${request.key}`);
    }, [request]);

    return { service, setRequest };
};

export const useSavePermissions = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [permissions, setPermissions] = useState<IPermissionSave>(defaultPermissionSave);

    useEffect(() => {
        if (permissions.key === '') { return; }
        processPutAsync(setService, `/api/permission-management/permissions/?providerName=${permissions.name}&providerKey=${permissions.key}`, permissions);
        setPermissions(defaultPermissionSave);
    }, [permissions]);

    return { service, setPermissions };
};