import { useEffect, useState } from 'react';
import OEFormSubmit from '../../core/components/formik/OEFormSubmit';
import OEModal, {
    ModalSize,
    OEModalBody,
} from '../../core/components/general/OEModal';
import {
    ColumnType,
    IColumn,
} from '../../core/components/table/entities/Column';
import OETable from '../../core/components/table/OETable';
import { WebFileInfo } from '../../core/types/web-file-info';
import { deEncodeData, parseCsv } from '../../core/utilities/FileHelper';

interface IComponentInfo {
    webFileInfo?: WebFileInfo;
    hasHeader: boolean;
    onPreviewCancel: () => void;
}

const DcFilePreview: React.FunctionComponent<IComponentInfo> = ({
    webFileInfo,
    hasHeader,
    onPreviewCancel,
}) => {
    const [rows, setRows] = useState<any[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const MAX_PREVIEW_ROWS = 50;

    const handleCancel = (e: any) => {
        e.preventDefault();
        onPreviewCancel();
    };

    useEffect(() => {
        const getData = async () => {
            if (!webFileInfo || !webFileInfo.data) {
                return;
            }

            const blob = deEncodeData(webFileInfo);
            const data = await blob.text();

            const csv = parseCsv(data, hasHeader, true);
            setRows(csv.rows);
            setErrors(csv.errors);

            const cols: IColumn[] = [];
            csv.headers?.forEach((h) => {
                cols.push({
                    id: h,
                    name: h,
                    type: ColumnType.String,
                });
            });

            setColumns(cols);
        };

        getData();
    }, [webFileInfo, hasHeader, setRows, setErrors]);

    return (
        <OEModal
            oeSize={ModalSize.Large}
            show={true}
            onHide={onPreviewCancel}
            title="File Preview"
        >
            <OEModalBody>
                <>
                    {errors.length > 0 && (
                        <div className="alert alert-danger">
                            <strong>Errors:</strong>
                            <ul>
                                {errors.map((e, i) => (
                                    <li key={i}>{e}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {errors.length === 0 && (
                        <>
                            {(webFileInfo?.totalRecords ?? 0) >
                                MAX_PREVIEW_ROWS && (
                                <p>
                                    <strong>
                                        Previewing the first {MAX_PREVIEW_ROWS}
                                        &nbsp;rows of :&nbsp;
                                        {webFileInfo?.totalRecords} rows
                                    </strong>
                                </p>
                            )}

                            <OETable
                                data={rows}
                                columns={columns}
                                showPagination={(rows?.length || 0) > 10}
                                defaultSort="name"
                                defaultPageSize={10}
                            ></OETable>
                        </>
                    )}

                    <div className="text-center pt-2">
                        <OEFormSubmit
                            sm={0}
                            smOffset={0}
                            submitText={'Cancel'}
                            onSubmit={handleCancel}
                        />
                    </div>
                </>
            </OEModalBody>
        </OEModal>
    );
};

export default DcFilePreview;
