import React, { Fragment, useEffect, useState } from 'react';
import OEDropdown, { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';

interface IComponentInfo {
    filter: IReportFilter;
    values: IDropdownValues[];
    onChange: (v: string) => void;
}

const SiteReportFilterDropdown: React.FunctionComponent<IComponentInfo> = ({ filter, onChange, values }) => {
    const [value, setValue] = useState<string>(filter.value);

    const onChangeValue = (v: string) => {
        setValue(v);
        onChange(v);
    };


    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <Fragment>
            <OEDropdown values={values} name={`filter${filter.sortOrder}`} value={value} onChange={onChangeValue} />
        </Fragment>
    );
};

export default SiteReportFilterDropdown;
