
export interface IConfirmationMessage {
    item?: any;
    message: string;
    title?: string;
    onOk?: (id?: any) => void;
    onCancel?: () => void;
    show: boolean;
    icon?: string;
    okText?: string;
    cancelText?: string;
    setConfirmation?: (i: IConfirmationMessage) => void;
}

export const defaultConfirmationMessage: IConfirmationMessage = {
    show: false, message: '', title: ''
};

export enum MessageSubstitions {
    Name = '{NAME}'
}

export interface IMessageSubstitions {
    name: MessageSubstitions;
    value: string;
}

export const getConfirmationMessage = (m: IConfirmationMessage, s: IMessageSubstitions[]) => {
    let message: string = m.message;
    for (const v of s) {
        message = message.replace(v.name, v.value);
    }
    return { ...m, message };
};

