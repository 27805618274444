import OEHeading from '../../core/components/general/OEHeading';
import { Icon } from '../../core/entities/Icon';

interface Props {
    title: string;
    url: string;
}

export const DictionaryIframe: React.FunctionComponent<Props> = ({
    title,
    url,
}: Props) => {
    return (
        <div className="container">
            {title && <OEHeading icon={Icon.Dictionary} className="m-b-20 m-t-10" text={title} />}
            <iframe
                style={{
                    boxSizing: 'border-box',
                    display: 'block !important',
                    width: '100%',
                    height: `calc(100vh - ${title ? '240' : '200'}px`,
                }}
                title={title}
                src={url}
            />
        </div>
    );
};
