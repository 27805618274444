import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { OEInputType } from '../../../core/components/form/OEInput';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import { getReportPageConfigurations } from '../../entities/ReportConfiguration';
import { IReportPage, ReportPageType } from '../../entities/ReportPage';
import { useCreateReportPage, usePutReportPage } from '../../services/ReportPageService';
import Configuration from '../report-configruation/Configuration';
import Filters from '../report-configruation/Filters';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IReportPage> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Description" name="description" value={values.description}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
            />

            {values.pageType !== ReportPageType.BISingleTiered && (
                <>
                    <Configuration
                        values={values}
                        configurations={getReportPageConfigurations(values.configuration)}
                        setFieldValue={setFieldValue}
                    />

                    <Filters isReport={false} values={values} filters={[]} setFieldValue={setFieldValue} />
                </>
            )}

        </>
    );
};

const ValidationScheme = Yup.object<IReportPage>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IReportPage;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportPageFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReportPage();
    const { service: createService, setItem: setCreateReportPage } = useCreateReportPage();

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            title={`${item.id === '' ? `Add New Report Page` : `Edit Report Page: ${item.name}`}`}
            progressMessage={ProgressMessages.ReportPage}
            successMessage={SuccessMessages.ReportPage}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReportPage : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportPageFormik;
