import { useEffect, useState } from 'react';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import {
    defaultDcFileToUpload,
    IDcFileToUpload,
} from '../entities/IDcFileToUpload';

export type IDcFileUploadFilter = { term: string; orgUnitId: string } | null;

export const usePostDcFileUpload = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [dcFileToUpload, setDcFileToUpload] = useState<IDcFileToUpload>(
        defaultDcFileToUpload
    );

    useEffect(() => {
        if (!dcFileToUpload.fileBody) {
            return;
        }

        processPostAsync(setService, `api/app/dc-file-requests/dc-upload`, {
            ...dcFileToUpload,
        });
        setDcFileToUpload(defaultDcFileToUpload);
    }, [dcFileToUpload]);

    return { service, setDcFileToUpload };
};
