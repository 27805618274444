import { useEffect, useState } from "react";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";

export const useGetDcFileDownload = () => {
    const [service, setService] = useState(GetRequest.empty());
    const [dcFileToDownload, setDcFileToDownload] = useState<string>('');

    useEffect(() => {
        if (!dcFileToDownload) { return; }

        processGetAsync(setService, `api/app/dc-file-requests/${dcFileToDownload}/dc-download`);
        setDcFileToDownload('');
    }, [dcFileToDownload]);

    return { service, setDcFileToDownload };
};
