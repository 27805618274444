export enum MessageType {
    Information = 'info',
    Primary = 'primary',
    Light = 'light',
    Dark = 'dark',
    Success = 'success',
    Error = 'danger',
    Warning = 'warning',
    Secondary = 'secondary',
    Danger = 'danger'
}

export interface IInformationMessage {
    code: InformationMessage,
    message: string;
    type: MessageType;
    hideDissmissable?: boolean;
}

export enum InformationMessage {
    CORE_TABLE_NORECORDS,
    PROFILE_PERMISSIONS_NONE,
    PROFILE_PERMISSIONS_NODEFAULT,
    USER_ADMIN_NONE
}

const InformationMessages: IInformationMessage[] =
    [
        {
            code: InformationMessage.CORE_TABLE_NORECORDS,
            message: 'No data found.',
            type: MessageType.Secondary,
            hideDissmissable: true
        },
        {
            code: InformationMessage.PROFILE_PERMISSIONS_NONE,
            message: 'No permissions have been set up for your account, please contact your system administrator.',
            type: MessageType.Secondary,
            hideDissmissable: true
        },
        {
            code: InformationMessage.PROFILE_PERMISSIONS_NODEFAULT,
            message: 'No default permission is set up please select one of the roles below to update your default permission',
            type: MessageType.Secondary,
            hideDissmissable: true
        },
        {
            code: InformationMessage.USER_ADMIN_NONE,
            message: '"No permissions have been set up for this user, click <b><i>Add Permission</i></b> to add restirct user access.',
            type: MessageType.Secondary,
            hideDissmissable: true
        },
    ]
    ;

export const getInformationMessage = (i: InformationMessage): IInformationMessage => {
    return InformationMessages.filter(q => q.code ===i)[0];
}