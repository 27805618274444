import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultOrganizationType, getOrganizationTypeForSave, IOrganizationType } from '../entities/OrganizationType';

export const useGetOrganizationTypes = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IOrganizationType>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/organization-types?MaxResultCount=100`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutOrganizationType = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [organizationType, setOrganizationType] = useState<IOrganizationType>(defaultOrganizationType);

    useEffect(() => {
        if (organizationType.id < 0) { return; }
        processPutAsync(setService, `/api/app/organization-types/${organizationType.id}`, getOrganizationTypeForSave(organizationType));
        setOrganizationType(defaultOrganizationType);
    }, [organizationType]);

    return { service, setOrganizationType };
};

export const useCreateOrganizationType = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [organizationType, setOrganizationType] = useState<IOrganizationType>(defaultOrganizationType);

    useEffect(() => {
        if (organizationType.name === '') { return; }
        processPostAsync(setService, `/api/app/organization-types`, getOrganizationTypeForSave(organizationType));
        setOrganizationType(defaultOrganizationType);
    }, [organizationType]);

    return { service, setOrganizationType };
};

export const useDeleteOrganizationType = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [organizationTypeId, setOrganizationTypeId] = useState<number>(-1);

    useEffect(() => {
        if (organizationTypeId < 0) { return; }
        processDeleteAsync(setService, `/api/app/organization-types/${organizationTypeId}`);
        setOrganizationTypeId(-1);
    }, [organizationTypeId]);

    return { service, setOrganizationTypeId };
};

