import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Routes } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { redirectLogin } from './core/services/Authentication';
import './css/controls.css';
import './css/reporting.css';
import './css/styles.css';
import MicrostrategyCubeDefinition from './misc/debugging/MicrostrategyCubeDefinition';
import MicrostrategyCubeFilters from './misc/debugging/MicrostrategyCubeFilters';
import PublicPage from './public/PublicPage';
import PageNotFound from './site/PageNotFound';
import Layout from './site/custom/Layout';
import { OECustomComponent } from './site/custom/components/OECustomComponent';
import Dashboard from './site/custom/pages/Dashboard';
import { IRoute, getRouteList } from './site/entities/Route';
import { ISiteSettings, defaultSiteSettings } from './site/entities/SiteSettings';
import { useGetUserRoutes } from './site/services/RouteService';
import { useGetSiteSettings } from './site/services/SiteSettingsService';

const getComponent = (name: string): any => {
    return (<OECustomComponent name={name} />);
};

const App: React.FunctionComponent = () => {
    const { service, doRefresh } = useGetUserRoutes();

    const [routes, setRoutes] = useState<IRoute[]>([]);
    const { service: settingService } = useGetSiteSettings();
    const [settings, setSettings] = useState<ISiteSettings>(defaultSiteSettings);

    useEffect(() => {
        if (settingService.result !== undefined) {
            setSettings(settingService.result);
        }
    }, [settingService]);

    useEffect(() => {
        // for public page we ignore not getting settings, all other pages should redirect to login if not authorized
        if (!window.location.pathname.includes('public-')) {
            if (typeof settings === 'number') {
                redirectLogin();
            } else {
                if (settings.logoutRedirect !== '') {
                    doRefresh();
                }
                else {

                }
            }
        }
        // eslint-disable-next-line
    }, [settings]);

    useEffect(() => {
        if (service.result) {
            const r: IRoute[] = getRouteList(service.result).filter(q => q.isActive);
            setRoutes(r);
        }
        // eslint-disable-next-line
    }, [service]);

    const CheckAuthorization = () => {
        return <ToastProvider>
            {window.location.pathname.includes('public-') && <PublicPage />}
            {!window.location.pathname.includes('public-') && routes.length > 0 && (
                <Layout routes={routes} settings={settings} >
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        {routes.map((item: IRoute) => (
                            <Route
                                key={item.id}
                                path={item.path}
                                element={getComponent(item.component)}
                            />
                        ))}
                        <Route path="debug-cubedefinition" element={<MicrostrategyCubeDefinition />} />
                        <Route path="debug-cubefilters" element={<MicrostrategyCubeFilters />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Layout>
            )}
        </ToastProvider>
    }


    return CheckAuthorization();
};
export default App;
