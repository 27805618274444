import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { IReportTypeListItem, IReportType, defaultReportType } from '../entities/ReportType';

export const useGetReportTypes = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportTypeListItem>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/report-types?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetReportType = () => {
    const [service, setService] = useState(GetRequest.empty<IReportTypeListItem>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-types/${itemId}/with-navigation-properties`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReportType = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportType>(defaultReportType);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/report-types/${item.id}`, item);
        setItem(defaultReportType);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportType = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportType>(defaultReportType);

    useEffect(() => {
        if (item.name === '') { return; }
        processPostAsync(setService, `/api/app/report-types`, item);
        setItem(defaultReportType);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportType = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-types/${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};
