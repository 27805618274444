import { getFutureDate } from "../../core/utilities/Date";
import { getEmptyGUID } from "../../core/utilities/String";

export interface IReportAccessLog {
    reportId: string;
    userId: string;
    accessDate: Date;
    parameters: string;
}

export const defaultReportAccessLog: IReportAccessLog = {
    reportId: '', parameters: '', userId: getEmptyGUID(), accessDate: getFutureDate(0)
};

