import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../core/components/form/OEButton';
import OEHeading from '../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody, OEModalFooter, OEModalHeader, OEModalTitle } from '../../core/components/general/OEModal';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { MessageType } from '../../core/components/messaging/enums/InformationMessages';
import OEErrorList from '../../core/components/messaging/OEErrorList';
import OEInformation from '../../core/components/messaging/OEInformation';
import OEMessage from '../../core/components/messaging/OEMessage';
import OESpinner from '../../core/components/messaging/OESpinner';
import RolePermission from '../../organization/components/RolePermission';
import { getRoleList, IRole } from '../../organization/entities/Role';
import { useGetRoles } from '../../organization/services/RoleService';
import { ISecurityRole } from '../entities/SecurityRole';

interface IComponentInfo {
    currentSecurity: ISecurityRole[];
    setSecurity: (i: ISecurityRole[]) => void;
    refreshSecurity: () => void;
    isInProgress: boolean;
    onCancel: () => void;
    title: string;
    info: string;
    errors: string[];
}

const PermissionRestrictions: React.FunctionComponent<IComponentInfo> = ({
    currentSecurity, setSecurity, refreshSecurity, onCancel, isInProgress,
    title, info, errors
}) => {
    const { service: roleService, doRefresh: refreshRoles } = useGetRoles();

    const [roles, setRoles] = useState<IRole[]>([]);
    const [available, setAvailable] = useState<IRole[]>([]);
    const [selected, setSelected] = useState<IRole[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (roles.length === 0) {
            refreshRoles();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (roles.length > 0) {
            const roleList: IRole[] = [...roles];
            for (const v of currentSecurity) {
                roleList.filter(q => q.id === v.roleId)[0].selected = true;
            }
            setRoles(roleList);
        }
        // eslint-disable-next-line
    }, [currentSecurity]);

    useEffect(() => {
        if (roleService.result) {
            setRoles(getRoleList(roleService.result).filter(q => !q.isStatic));
            refreshSecurity();
        }
        // eslint-disable-next-line
    }, [roleService]);

    useEffect(() => {
        setSelected(roles.filter(q => q.selected));
        setAvailable(roles.filter(q => !q.selected));
        setLoading(false);
    }, [roles]);


    const onSelectRole = (i: IRole) => {
        const roleList: IRole[] = [...roles];
        roleList.filter(q => q.id === i.id)[0].selected = !roleList.filter(q => q.id === i.id)[0].selected;
        setRoles(roleList);
    }

    const onRemoveAll = () => {
        const roleList: IRole[] = [...roles];
        for (const v of roleList) {
            v.selected = false;
        }
        setRoles(roleList);
    }

    const onSubmit = () => {
        const s: ISecurityRole[] = [];
        for (const v of selected) {
            s.push({ roleId: v.id });
        }
        setSecurity(s);
    }

    return (
        <OEModal oeSize={ModalSize.Medium} show={true}>
            <OEModalHeader onHide={onCancel}>
                <OEModalTitle>{title}</OEModalTitle>
            </OEModalHeader>
            <OEModalBody>
                <OEInformation message={info} />
                {!loading && (
                    <OERow className="m-t-20">
                        <OECol sm={6}>
                            <OEHeading size={5} underline={true} text="Restrict Access To:" />
                            <OERow>
                                {selected.map((item, index) =>
                                    <RolePermission onSelect={onSelectRole} role={item} key={index} />
                                )}
                                {selected.length === 0 && (
                                    <OEMessage type={MessageType.Success} hideDismissable={true} message="No restrictions selected, all users will have access" />
                                )}
                            </OERow>
                        </OECol>
                        <OECol sm={6}>
                            <OEHeading underline={true} size={5} text="Available" />
                            <OERow>
                                {available.map((item, index) =>
                                    <RolePermission onSelect={onSelectRole} role={item} key={index} />
                                )}
                                {available.length === 0 && (
                                    <OEMessage type={MessageType.Information} hideDismissable={true} message="All Roles have been selected for restrictions" />
                                )}
                            </OERow>
                        </OECol>
                    </OERow>
                )}
                <OESpinner message="Saving Restrictions" hidden={!isInProgress} />
            </OEModalBody>
            <OEModalFooter>
                <OERow className="m-b-10">
                    <OECol sm={12}>
                        <OEButton disabled={isInProgress} onClick={onSubmit} text={'Submit'} />
                        <OEButton hidden={selected.length === 0} bStyle={ButtonStyle.Secondary} disabled={isInProgress} onClick={onRemoveAll} text={'Remove All Restrictions'} />
                        {onCancel && (
                            <OEButton disabled={isInProgress} bStyle={ButtonStyle.Cancel} onClick={onCancel} text={'Cancel'} />
                        )}
                    </OECol>
                </OERow>
                <OEErrorList errors={errors} />
            </OEModalFooter>
        </OEModal>
    );
};

export default PermissionRestrictions;

