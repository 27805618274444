import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { emptyGUID } from '../../core/utilities/String';
import { defaultWidget, getWidgetForSave, IWidget, IWidgetListItem } from '../entities/Widget';

export const useGetWidgets = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetListItem>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/widgets?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetWidget = () => {
    const [service, setService] = useState(GetRequest.empty<IWidgetListItem>());
    const [widgetId, setWidgetId] = useState<string>('');

    useEffect(() => {
        if (emptyGUID(widgetId)) { return; }
        processGetAsync(setService, `/api/app/widgets/${widgetId}/with-navigation-properties`);
        setWidgetId('')
    }, [widgetId]);

    return { service, setWidgetId };
};

export const useCreateWidget = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IWidget>(defaultWidget);

    useEffect(() => {
        if (item.code === '') { return; }
        processPostAsync(setService, `/api/app/widgets`, getWidgetForSave(item));
        setItem(defaultWidget);
    }, [item]);

    return { service, setItem };
};

export const usePutWidget = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IWidget>(defaultWidget);

    useEffect(() => {
        if (emptyGUID(item.id)) { return; }
        processPutAsync(setService, `/api/app/widgets/${item.id}`, getWidgetForSave(item));
        setItem(defaultWidget);
    }, [item]);

    return { service, setItem };
};

export const useGetWidgetsByGroupId = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetListItem>>());
    const [groupId, setGroupId] = useState<number>(-1);

    useEffect(() => {
        if (groupId < 0) { return; }
        processGetAsync(setService, `api/app/widgets?WidgetGroupId=${groupId}`);
        setGroupId(-1)
    }, [groupId]);

    return { service, setGroupId };
};

export const useDeleteWidget = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [widgetId, setWidgetId] = useState<string>('');

    useEffect(() => {
        if (emptyGUID(widgetId)) { return; }
        processDeleteAsync(setService, `/api/app/widgets/${widgetId}`);
        setWidgetId('');
    }, [widgetId]);

    return { service, setWidgetId };
};