import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export enum WidgetTypeCode {
    Text = 'TXT',
    HTMLContent = 'HTM',
    File = 'FIL',
}

export interface IWidgetType extends IBaseAuditedEntity {
    id: number;
    name: string;
    code: string;
}

export const defaultWidgetType: IWidgetType = {
    id: -1, name: '', code: ''
};

export const getWidgetTypeList = (i: IBaseList<IWidgetType>): IWidgetType[] => {
    const d: IWidgetType[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultWidgetType,
            ...v,
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getWidgetTypeForSave = (i: IWidgetType): IWidgetType => {
    return i;
};

