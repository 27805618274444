export const getPropertyValue = (
    json: string,
    propertyName: string,
    defaultReturn: string
): string => {
    if (!json) {
        return defaultReturn;
    }

    try {
        const obj = JSON.parse(json);

        return obj[propertyName];
    } catch {
        return defaultReturn;
    }
};
