import React, { useEffect, useState } from 'react';
import PermissionRestrictions from '../../security/components/PermissionRestrictions';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IMenu } from '../entities/Menu';
import { getSecurityMenuList } from '../entities/SecurityMenu';
import { useGetSecurityMenu, usePutMenuPermissions } from '../services/SecurityMenuService';

interface IComponentInfo {
    item: IMenu;
    onCancel: () => void;
}

const MenuPermissions: React.FunctionComponent<IComponentInfo> = ({ item, onCancel }) => {
    const { service, setMenuId } = useGetSecurityMenu();
    const { service: saveService, setSecurity } = usePutMenuPermissions();

    const [currentSecurity, setCurrentSecurity] = useState<ISecurityRole[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service.result) {
            setCurrentSecurity(getSecurityMenuList(service.result));
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (saveService.isFinished) {
            if (saveService.isSuccess) {
                onCancel();
            } else {
                setErrors([saveService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [saveService]);

    const refreshSecruity = () => {
        setMenuId(item.id);
    };

    const updateSecruity = (i: ISecurityRole[]) => {
        setSecurity({ id: item.id, permissions: i });
    };

    return (
        <>
            <PermissionRestrictions
                currentSecurity={currentSecurity}
                setSecurity={updateSecruity}
                refreshSecurity={refreshSecruity}
                isInProgress={saveService.isInProgress}
                onCancel={onCancel}
                title={`Edit Restrictions for Menu: ${item.name}`}
                info="Select any roles from the Available column to restrict access to this menu, if no restrictions are selected all users will have access. Changes are not complete until you hit Submit. "
                errors={errors}
            />
        </>
    );
};

export default MenuPermissions;

