import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReportGroup, IReportGroup, IReportGroupListItem } from '../entities/ReportGroup';

export const useGetReportGroupsByPage = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportGroupListItem>>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-groups?MaxResultCount=1000&ReportPageId=${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const useGetReportGroupsByGroup = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportGroupListItem>>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-groups?MaxResultCount=1000&ReportGroupId=${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const useGetReportGroup = () => {
    const [service, setService] = useState(GetRequest.empty<IReportGroupListItem>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-groups/${itemId}/with-navigation-properties`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReportGroup = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportGroup>(defaultReportGroup);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/report-groups/${item.id}`, item);
        setItem(defaultReportGroup);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportGroup = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportGroup>(defaultReportGroup);

    useEffect(() => {
        if (item.title === '') { return; }
        processPostAsync(setService, `/api/app/report-groups`, item);
        setItem(defaultReportGroup);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportGroup = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [groupId, setGroupId] = useState<string>('');

    useEffect(() => {
        if (groupId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-groups/${groupId}`);
        setGroupId('');
    }, [groupId]);

    return { service, setGroupId };
};



