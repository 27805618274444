import React, { useEffect, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { INotification } from '../../core/components/messaging/entities/Notification';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OESpinner from '../../core/components/messaging/OESpinner';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { defaultWidget, getWidgetList, IWidget } from '../entities/Widget';
import { getWidgetGroupList } from '../entities/WidgetGroup';
import { WidgetTypeCode } from '../entities/WidgetType';
import { useGetWidgetGroupByCode } from '../services/WidgetGroupService';
import { useDeleteWidget, useGetWidgetsByGroupId } from '../services/WidgetService';
import WidgetFormik from './WidgetFormik';

enum ModalTypes {
    None = 1,
    Edit = 2,
}

interface IComponentInfo {
    setNotification: (i: INotification) => void;
}

const ContentAdmin: React.FunctionComponent<IComponentInfo> = ({ setNotification }) => {
    const { service: groupService } = useGetWidgetGroupByCode('WIDGETPAGE');
    const { service: deleteService, setWidgetId: setDeleteId } = useDeleteWidget();
    const { service, setGroupId: setServiceGroupId } = useGetWidgetsByGroupId();

    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [groupId, setGroupId] = useState<number>(-1);
    const [item, setItem] = useState<IWidget>({ ...defaultWidget });
    const [items, setItems] = useState<IWidget[]>([]);
    const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);


    useEffect(() => {
        if (deleteService.isSuccess) {
            doRefresh();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        setServiceGroupId(groupId);
        // eslint-disable-next-line
    }, [groupId]);

    const doRefresh = () => {
        setServiceGroupId(groupId);
    }

    const onEdit = (i: IWidget) => {
        setItem(i);
        setModalType(ModalTypes.Edit);
    };

    const onAdd = () => {
        const i: IWidget = { ...defaultWidget, widgetTypeId: items[0].widgetTypeId, widgetGroupId: items[0].widgetGroupId };
        i.widgetType.code = WidgetTypeCode.HTMLContent;
        setItem(i);
        setModalType(ModalTypes.Edit);
    };

    const onSave = () => {
        setModalType(ModalTypes.None);
        doRefresh();
    };

    const onCancel = () => {
        setModalType(ModalTypes.None);
    };

    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onConfirmDelete = (i: IWidget) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Content Page?",
            message: `Are you sure you want to delete the content page <b>${i.name}</b> with code ${i.code}?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, helpText: 'Delete', notCondition: true }
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'name', name: 'Name', sort: true, type: ColumnType.String },
        { id: 'code', width: '200px', name: 'Code', sort: true, type: ColumnType.String },
        { id: 'path', name: 'Path', sort: true, type: ColumnType.NavigationLink, target: '_blank' },
    ];

    return (
        <div className="container">
            <OEConfirmation {...confirmation} />
            <OESpinner message="Loading Content" hidden={!service.isInProgress} />

            {modalType === ModalTypes.Edit && (
                <WidgetFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}

            <OETable
                columns={columns}
                data={items}
                className="table-bordered"
                noDataMessage="No Parameters have been added"
                hideTable={modalType === ModalTypes.Edit}
                actions={[
                    { icon: Icon.Add, text: 'Add New Content Page', action: onAdd },
                ]}
            />
        </div>
    );
};

export default ContentAdmin;