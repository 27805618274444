import React from 'react';
import OEButton, { ButtonStyle } from '../core/components/form/OEButton';
import OEWell from '../core/components/general/OEWell';
import { Icon } from '../core/entities/Icon';


const Form: React.FunctionComponent = () => {

    return (
        <>
            <OEWell className="m-t-0" title="OEButtons" icon={Icon.Click}>
                <div className="p15">
                    <h5>Regular</h5>
                    <p>
                        <OEButton text="Primary" bStyle={ButtonStyle.Primary} />
                        <OEButton text="Secondary" bStyle={ButtonStyle.Secondary} />
                        <OEButton text="Success" bStyle={ButtonStyle.Success} />
                        <OEButton text="Danger" bStyle={ButtonStyle.Danger} />
                        <OEButton text="Warning" bStyle={ButtonStyle.Warning} />
                        <OEButton text="Info" bStyle={ButtonStyle.Info} />
                        <OEButton text="Light" bStyle={ButtonStyle.Light} />
                        <OEButton text="Dark" bStyle={ButtonStyle.Dark} />
                    </p>
                    <h5>Outlined</h5>
                    <p>
                        <OEButton text="Primary" bOutline={true} bStyle={ButtonStyle.Primary} />
                        <OEButton text="Secondary" bOutline={true} bStyle={ButtonStyle.Secondary} />
                        <OEButton text="Success" bOutline={true} bStyle={ButtonStyle.Success} />
                        <OEButton text="Danger" bOutline={true} bStyle={ButtonStyle.Danger} />
                        <OEButton text="Warning" bOutline={true} bStyle={ButtonStyle.Warning} />
                        <OEButton text="Info" bOutline={true} bStyle={ButtonStyle.Info} />
                        <OEButton text="Light" bOutline={true} bStyle={ButtonStyle.Light} />
                        <OEButton text="Dark" bOutline={true} bStyle={ButtonStyle.Dark} />
                    </p>
                    <h5>Various Styles</h5>
                    <p>
                        <OEButton text="Save" bStyle={ButtonStyle.Save} />
                        <OEButton text="Cancel" bStyle={ButtonStyle.Cancel} />
                        <OEButton icon={Icon.Edit} text="With Icon Left" />
                        <OEButton icon={Icon.Edit} text="With Icon Right" iconRight={true} />
                    </p>
                </div>
            </OEWell>
        </>
    );
};

export default Form;