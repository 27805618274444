import React from 'react';
import OEButton, { ButtonStyle } from '../../../core/components/form/OEButton';
import OEModal, { ModalSize, OEModalBody, OEModalFooter } from '../../../core/components/general/OEModal';
import { IReportIcon, ReportIconBaseURL, ReportIcons } from '../../entities/ReportIcons';

interface IReportIconInfo {
    item: IReportIcon;
    onSelect: (i: string) => void;
}

const ReportIcon: React.FunctionComponent<IReportIconInfo> = ({ item, onSelect }) => {

    const onClick = () => {
        onSelect(item.filename);
    }
    return (
        <span style={{ padding: '5px' }}>
            <img alt="" style={{ cursor: 'pointer' }} onClick={onClick} className="report-icon m-r-5" src={`${ReportIconBaseURL}${item.filename}`} ></img>
        </span>
    );
};

interface IIconSelectorInfo {
    onSelect: (i: string) => void;
    onCancel: () => void;
}

const IconSelector: React.FunctionComponent<IIconSelectorInfo> = ({ onSelect, onCancel }) => {
    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onCancel} title="Select Icon">
            <OEModalBody>
                {ReportIcons.map((i, index) =>
                    <ReportIcon item={i} key={index} onSelect={onSelect} />
                )}
            </OEModalBody>
            <OEModalFooter>
                <OEButton className="pull-right" bStyle={ButtonStyle.Cancel} onClick={onCancel} text="Cancel" />
            </OEModalFooter>
        </OEModal >
    );
};

export default IconSelector;
