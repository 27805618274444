

export interface IPermissionProvider {
    providerName: string;
    providerKey: string;
}

export interface IPermission {
    name: string;
    displayName: string;
    parentName: string;
    isGranted: boolean;
    allowedProviders: IPermissionProvider[];
    grantedProviders: IPermissionProvider[];
}

export const defaultPermission: IPermission = {
    name: '', displayName: '', parentName: '', isGranted: false, allowedProviders: [], grantedProviders: []
};

export interface IPermissionGroup {
    name: string;
    displayName: string;
    permissions: IPermission[];
}

export interface IPermissionResult {
    name: string;
    groups: IPermissionGroup[];
}

export const defaultPermissionResult: IPermissionResult = {
    name: '', groups: []
};

export interface IPermissionRequest {
    key: string;
    name: string;
}

export const defaultPermissionRequest: IPermissionRequest = {
    key: '', name: ''
};

export interface IPermissionUpdate {
    name: string;
    isGranted: boolean;
}

export interface IPermissionSave {
    key: string;
    name: string;
    permissions: IPermissionUpdate[];
}

export const defaultPermissionSave: IPermissionSave = {
    permissions: [], key: '', name: ''
};

export interface IPermissionGrouping {
    name: string;
    displayName: string;
    view: boolean;
    admin: boolean;
};