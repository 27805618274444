import React, { useEffect, useState } from 'react';
import { IBreadcrumb } from '../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../../core/components/breadcrumbs/OEBreadcrumb';
import { updateURLParameter } from '../../../core/utilities/URL';
import { defaultReportGroup, IReportGroup } from '../../entities/ReportGroup';
import { defaultReportPage, IReportPage, ReportPageType } from '../../entities/ReportPage';
import ReportGroups from './ReportGroups';
import ReportLists from './ReportLists';
import ReportPages from '../admin-reports/ReportPages';

enum ViewTypes {
    Pages = 1,
    Groups,
    Reports
}

const BIReportPagesSingleTier: React.FunctionComponent = () => {
    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Home' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Pages);

    useEffect(() => {
        if (page.id !== '') {
            setBreadcrumbs([...rootBreadcrumbs, { id: 1, name: page.name }]);
            setShowView(ViewTypes.Groups);
            updateURLParameter('pageid', page.id);
        }
        else {
            setShowView(ViewTypes.Pages);
            updateURLParameter('pageid', '');
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (group.id !== '') {
            setShowView(ViewTypes.Reports);
            setBreadcrumbs([...breadcrumbs, { id: 2, name: group.title }]);
            updateURLParameter('groupid', group.id);
            setGroup(group);
        }
        else {
            updateURLParameter('groupid', '');
        }
        // eslint-disable-next-line
    }, [group]);

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        if (i.id === 0) {
            setPage(defaultReportPage);
            setGroup(defaultReportGroup);
        }
        if (i.id === 1) {
            setShowView(ViewTypes.Groups);
            setGroup(defaultReportGroup);
        }
    };

    return (
        <>
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />

            {showView === ViewTypes.Pages && (
                <ReportPages defaultConfiguration='' pageType={ReportPageType.BISingleTiered} setPage={setPage} />
            )}

            {showView === ViewTypes.Groups && (
                <ReportGroups parentId={page.id} pageType={ReportPageType.BISingleTiered} setGroup={setGroup} />
            )}

            {showView === ViewTypes.Reports && (
                <ReportLists group={group} multipleReports={true} />
            )}

        </>
    );
};


export default BIReportPagesSingleTier;