import React, { useEffect, useState } from 'react';
import { FilterDataType, IFilter } from '../../../core/components/filter/entities/Filter';
import { saveFilterValues } from '../../../core/components/filter/entities/FilterSave';
import OEDropdown, { DropdownType } from '../../../core/components/form/OEDropdown';
import OEForm, { OEFormGroup, OEFormLabel } from '../../../core/components/form/OEForm';
import OEInput from '../../../core/components/form/OEInput';
import OEFormItem, { IOEFormItemInfo } from '../../../core/components/formik/OEFormItem';
import OEIcon from '../../../core/components/general/OEIcon';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import OEWell, { WellStyle } from '../../../core/components/general/OEWell';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { FilterStorage } from '../../../core/entities/FilterStorage';
import { Icon } from '../../../core/entities/Icon';
import { IReport, getReportList } from '../../entities/Report';
import { IReportType, ReportType, getReportTypeList } from '../../entities/ReportType';
import { useGetReports } from '../../services/ReportService';
import { useGetReportTypes } from '../../services/ReportTypeService';

interface IReportSelectionInfo {
    onSelect: (i: IReport) => void;
    onCancel: () => void;
}

const ReportSelection: React.FunctionComponent<IReportSelectionInfo> = ({ onSelect, onCancel }) => {

    const { service: typeService } = useGetReportTypes();
    const { service, setReportTypeId: refreshReports } = useGetReports();

    const [reportTypeId, setReportTypeId] = useState<string>(localStorage.getItem(FilterStorage.ReportAdminID) || '');
    const [reportName, setReportName] = useState<string>(localStorage.getItem(FilterStorage.ReportAdminName) || '');
    const [types, setTypes] = useState<IReportType[]>([]);
    const [items, setItems] = useState<IReport[]>([]);
    const [filteredItems, setFilteredItems] = useState<IReport[]>([]);

    const filter: IFilter = {
        name: 'reportSelector',
        autoSearch: true,
        filters: [
            { name: 'reportTypeID', label: 'Report Type', defaultValue: ReportType.SiteReport },
        ],
    };

    useEffect(() => {
        if (typeService.result) {
            setTypes(getReportTypeList(typeService.result));
        }
    }, [typeService]);

    useEffect(() => {
        if (service.result) {
            setItems(getReportList(service.result).filter(q => q.id !== ReportType.EmbeddedFilters));
        }
    }, [service]);

    useEffect(() => {
        setFilteredItems(items.filter(q => q.title.toLowerCase().includes(reportName.toLowerCase().trim())));
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        doRefresh();
        localStorage.setItem(FilterStorage.ReportAdminID, reportTypeId);
        saveFilterValues(filter.name, [{ name: filter.filters[0].name, value: reportTypeId, dataType: FilterDataType.String }]);
        // eslint-disable-next-line
    }, [reportTypeId]);

    useEffect(() => {
        localStorage.setItem(FilterStorage.ReportAdminName, reportName);
        setFilteredItems(items.filter(q => q.title.toLowerCase().includes(reportName.toLowerCase().trim())));
        // eslint-disable-next-line
    }, [reportName]);

    const doRefresh = () => {
        reportTypeId && refreshReports(reportTypeId);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Select, onClick: onSelect, helpText: 'Select' },
    ];

    const columns: IColumn[] = [
        { actions, id: '', width: '10px', name: '', sort: false, type: ColumnType.Actions },
        { id: 'title', name: 'Title / Id', sort: true, type: ColumnType.Link, onClick: onSelect, helpText: 'Select Report' },
        { id: 'reportTypeName', name: 'Type', sort: true, type: ColumnType.String },
    ];

    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onCancel} title="Report Selector">
            <OEModalBody>
                <OEWell oeStyle={WellStyle.Bordered}  >
                    <OEForm className={`row align-items-end`}>
                        <OEFormGroup inline={true} className="m-r-10">
                            <OEFormLabel>Type</OEFormLabel>
                            <OEDropdown
                                onChange={setReportTypeId}
                                values={types}
                                type={DropdownType.String}
                                name="type"
                                value={reportTypeId} />
                        </OEFormGroup>
                        <OEFormGroup inline={true} className="m-r-10">
                            <OEFormLabel>Name</OEFormLabel>
                            <OEInput
                                onChange={setReportName}
                                name="type"
                                value={reportName} />
                        </OEFormGroup>
                    </OEForm>
                </OEWell>

                <OETable
                    loading={service.isInProgress}
                    loadingMessage="Loading Report List"
                    data={filteredItems}
                    noDataMessage="There are no reports"
                    columns={columns}
                    showPagination={true}
                    defaultSort="name"
                    defaultPageSize={10}
                />
            </OEModalBody>
        </OEModal >
    );
};

enum ViewTypes {
    Default = 1,
    Select
}

interface IComponentInfo {
    reportName: string;
    onChange?: (i: IReport) => void;
}


const ReportSelector: React.FunctionComponent<IComponentInfo & IOEFormItemInfo> = ({ name, reportName, columns, required, hidden, errors, touched, onChange, label }) => {
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);

    const onSelect = (i: IReport) => {
        onChange(i);
        setShowView(ViewTypes.Default);
    };

    const onCancel = () => {
        setShowView(ViewTypes.Default);
    };

    const onShowSelect = () => {
        setShowView(ViewTypes.Select);
    };

    return (
        <>
            <div onClick={onShowSelect} >
                <OEFormItem columns={columns} name={name} label={label} errors={errors} touched={touched} required={required} hidden={hidden}>
                    <OEIcon icon={Icon.Search} className="p-t-10 pull-right" />
                    <OEWell className="m-t-0">
                        {reportName || 'No Report Selected, Click to select report.'}
                    </OEWell>
                </OEFormItem>

            </div>

            {showView === ViewTypes.Select && (
                <ReportSelection
                    onCancel={onCancel}
                    onSelect={onSelect}
                />
            )}
        </>
    );
};

export default ReportSelector;