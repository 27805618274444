export const CubeAttributeElementResponseSeparator = ' ^ ';

export interface ICubeFilterAttribute {
    id: string;
}

export const defaultCubeFilterAttribute: ICubeFilterAttribute = {
    id: ''
};

export interface ICubeAttributeElementResponse {
    id: string;
    formValues: string[];
    key: string;
    value: string;
    rawValue: string;
}

export const defaultCubeAttributeElementResponse: ICubeAttributeElementResponse = {
    id: '', formValues: [], value: '', key: '', rawValue: ''
}

export const initCubeAttributesElements = (c: ICubeAttributeElementResponse[]): ICubeAttributeElementResponse[] => {
    for (const i of c) {
        if (i.formValues.length > 0) {
            // C39ED9A241913577DAA1AABCABC5761C: 2018
            i.value = i.formValues[0];
        }
        else {
            i.value = '---- UNDEFINED ----';
        }
        let rawValue: string = '';
        for (var k = 0; k < i.formValues.length; k++) {
            rawValue = `${rawValue}${rawValue.length > 0 ? CubeAttributeElementResponseSeparator : ''}${i.formValues[k]}`;
        }
        i.rawValue = rawValue;
        i.key = getCubeAttributeKeyFromRawValue(i.id);
    }
    return c;
}

export const getCubeAttributeKeyFromRawValue = (i: string) => {
    const values = i.split(':');
    let key: string = '';
    let value: string = '';
    for (var j = 0; j < values.length; j++) {
        if (j === 0) {
            key = values[j];
        }
        else {
            value = `${value}${values[j]}`;
        }
    }
    return `h${value};${key}`;
}

export const getCubeAttributeRawValueFromKey = (i: string) => {
    const values = i.split(';');
    let key: string = '';
    let value: string = '';
    for (var j = 0; j < values.length; j++) {
        if (j === 0) {
            key = values[j].substring(1);
        }
        else {
            value = `${value}${values[j]}`;
        }
    }
    return `${value}:${key}`;
}