import React from 'react';

interface IComponentInfo {
    name: string;
    className?: string;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
    value: any;
    text?: string;
}

const OEToggleSwitch: React.FunctionComponent<IComponentInfo> = ({
    name, onChange, disabled, value, text, className
}) => {


    const handleChange = (event: any) => {
        onChange?.(!value);
    };

    return (
        <>
            <span className={className} >
                <span className="toggle-switch-text">
                    {text}
                </span>
                <input
                    disabled={disabled}
                    className="toggle-switch-checkbox"
                    id={name}
                    type="checkbox"
                    checked={value}
                    onChange={handleChange}
                />
                <label
                    style={{ backgroundColor: `${value ? '#06D6A0' : 'grey'}` }}
                    className="toggle-switch-label"
                    htmlFor={name}
                >
                    <span className={`toggle-switch-button`} />
                </label>
            </span>

        </>
    );
};

export default OEToggleSwitch;