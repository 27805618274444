import React, { useEffect, useState } from 'react';
import OEMessage from '../core/components/messaging/OEMessage';
import WidgetContent from '../widgets/components/WidgetContent';
import { getWidgetList, IWidget } from '../widgets/entities/Widget';
import { getWidgetGroupList } from '../widgets/entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../widgets/services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../widgets/services/WidgetService';
import { useSearchParams } from 'react-router-dom';
import './styles/widget.css';

const Widget: React.FunctionComponent = () => {
    const { service: groupService } = useGetWidgetGroupByCode('WIDGETPAGE');
    const { service, setGroupId } = useGetWidgetsByGroupId();
    const [items, setItems] = useState<IWidget[]>([]);
    const [searchParams] = useSearchParams();
    const [widgetId, setWidgetId] = useState<string>(searchParams.get("id") || 'NOT-FOUND');

    useEffect(() => {
        setWidgetId(searchParams.get("id") || 'NOT-FOUND')
    }, [searchParams]);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
        // eslint-disable-next-line
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);

    return (
        <>
            <div className="container m-t-40">
                {items.filter(q => q.code === widgetId).length > 0 && (
                    <WidgetContent items={items} code={widgetId} />
                )}
                {items.filter(q => q.code === widgetId).length === 0 && (
                    <OEMessage message="Widget Not Found" />
                )}
            </div>
        </>
    );
};

export default Widget;