import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../core/components/form/OEButton';
import OEModal, { OEModalBody, OEModalFooter, OEModalHeader, OEModalTitle } from '../../core/components/general/OEModal';
import OEErrorList from '../../core/components/messaging/OEErrorList';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { IPermissionGroup, IPermissionGrouping, IPermissionSave } from '../../security/entities/Permissions';
import { useGetPermissions, useSavePermissions } from '../../security/services/PermissionService';
import { IRole } from '../entities/Role';

interface ICompnentInfo {
    item: IRole;
    onCancel: () => void;
    onSave: () => void;
}

const RolePermissions: React.FunctionComponent<ICompnentInfo> = ({ item, onCancel, onSave }) => {
    const { service, setRequest } = useGetPermissions();
    const { service: saveService, setPermissions } = useSavePermissions();

    const [items, setItems] = useState<IPermissionGroup[]>([]);
    const [groupings, setGroupings] = useState<IPermissionGrouping[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (service.result) {
            initGroupings(service.result.groups.filter(q => q.name === 'Nimble'));
        }
    }, [service]);

    useEffect(() => {
        if (saveService.isFinished) {
            saveService.isSuccess ? onSave() : setErrors([saveService.response.message]);
        }
        // eslint-disable-next-line
    }, [saveService]);


    useEffect(() => {
        setRequest({ name: 'R', key: item.name });
        // eslint-disable-next-line
    }, [item]);

    const initGroupings = (j: IPermissionGroup[]) => {
        const g: IPermissionGrouping[] = [];
        // load groups
        for (const i of j) {
            for (const p of i.permissions) {
                if (p.parentName === null) {
                    g.push({ displayName: p.displayName, name: p.name, view: p.isGranted, admin: false })
                }
            }
        }
        // load permissions
        for (const i of j) {
            for (const p of i.permissions) {
                if (p.parentName !== null) {
                    g.filter(q => q.name === p.parentName)[0].admin =
                        g.filter(q => q.name === p.parentName)[0].admin || p.isGranted;
                }
            }
        }
        setGroupings(g);
        setItems(j);
    };

    const onOK = () => {
        setErrors([]);
        const i: IPermissionSave = { name: 'R', key: item.name, permissions: [] };
        for (const g of items) {
            for (const p of g.permissions) {
                i.permissions.push({ name: p.name, isGranted: p.isGranted })
            }
        }
        setPermissions(i);
    }

    const onGrant = (i: IPermissionGrouping) => {
        i.view = true;
        setGroupings([...groupings]);
        onUpdateItems(i.name, groupings);
    }

    const onRemove = (i: IPermissionGrouping) => {
        i.view = false;
        i.admin = false;
        setGroupings([...groupings]);
        onUpdateItems(i.name, groupings);
    }

    const onGrantAdmin = (i: IPermissionGrouping) => {
        i.view = true;
        i.admin = true;
        setGroupings([...groupings]);
        onUpdateItems(i.name, groupings);
        onUpdateItems(i.name, groupings);
    }

    const onRemoveAdmin = (i: IPermissionGrouping) => {
        i.admin = false;
        setGroupings([...groupings]);
        onUpdateItems(i.name, groupings);
    }

    const onUpdateItems = (n: string, g: IPermissionGrouping[]) => {
        for (const i of items) {
            for (const p of i.permissions) {
                if (p.name === n) {
                    p.isGranted = g.filter(q => q.name === n)[0].view;
                }
                if (p.parentName === n) {
                    p.isGranted = g.filter(q => q.name === n)[0].admin;
                }
            }
        }
        setItems([...items]);
    }

    const columns2: IColumn[] = [
        { id: 'displayName', name: 'Permission', sort: true, type: ColumnType.String },
        { id: 'view', name: 'View', icon: Icon.CheckNo, sort: true, type: ColumnType.Link, className: 'text-center', condition: 'view', notCondition: true, onClick: onGrant },
        { id: 'view', name: 'View', icon: Icon.CheckYes, sort: true, type: ColumnType.Link, className: 'text-center', condition: 'view', onClick: onRemove },
        { id: 'admin', name: 'Admin', icon: Icon.CheckNo, sort: true, type: ColumnType.Link, className: 'text-center', condition: 'admin', notCondition: true, onClick: onGrantAdmin },
        { id: 'admin', name: 'Admin', icon: Icon.CheckYes, sort: true, type: ColumnType.Link, className: 'text-center', condition: 'admin', onClick: onRemoveAdmin },
    ];

    return (
        <>
            <OEModal show={true} onHide={onCancel}>
                <OEModalHeader onHide={onCancel}>
                    <OEModalTitle icon={Icon.Permissions}>
                        {`Permissions for ${item.name}`}
                    </OEModalTitle>
                </OEModalHeader>
                <OEModalBody>
                    <OETable
                        loading={service.isInProgress}
                        loadingMessage="Loading Permissions Minimized"
                        data={groupings}
                        columns={columns2}
                        showPagination={true}
                        defaultSort="name"
                        defaultPageSize={10}
                        noDataMessage={`No permissions found`}
                    />
                    <OEErrorList errors={errors} />
                </OEModalBody>
                <OEModalFooter>

                    <OEButton onClick={onOK} text="Save" />
                    {onCancel && (
                        <OEButton bStyle={ButtonStyle.Cancel} onClick={onCancel} text="Cancel"
                        />
                    )}
                </OEModalFooter>
            </OEModal>
        </>
    );
};

export default RolePermissions;
