import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultRole, getRoleForSave, IRole } from '../entities/Role';

export const useGetRolesByType = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IRole>>());
    const [typeId, setTypeId] = useState<number>(-1);

    useEffect(() => {
        if (typeId < 0) { return; }
        processGetAsync(setService, `/api/identity/roles?MaxResultCount=100&TypeId=${typeId}`);
        setTypeId(-1);
    }, [typeId]);

    return { service, setTypeId };
};

export const useGetRoles = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IRole>>());
    const [refresh, setRefresh] = useState(false);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/identity/roles?MaxResultCount=100`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutRole = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [role, setRole] = useState<IRole>(defaultRole);

    useEffect(() => {
        if (role.name === '') { return; }
        processPutAsync(setService, `/api/identity/roles/${role.id}`, getRoleForSave(role));
        setRole(defaultRole);
    }, [role]);

    return { service, setRole };
};

export const useCreateRole = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [role, setRole] = useState<IRole>(defaultRole);

    useEffect(() => {
        if (role.name === '') { return; }
        processPostAsync(setService, `/api/identity/roles`, getRoleForSave(role));
        setRole(defaultRole);
    }, [role]);

    return { service, setRole };
};

export const useDeleteRole = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [roleId, setRoleId] = useState<string>('');

    useEffect(() => {
        if (roleId === '') { return; }
        processDeleteAsync(setService, `/api/identity/roles/${roleId}`);
        setRoleId('');
    }, [roleId]);

    return { service, setRoleId };
};

