import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { ISecurityRole } from '../../security/entities/SecurityRole';
import { IRoute } from './Route';

export interface IModule extends Record<any, any> {
    name: string;
    component: string;
    key: string;
}

export const defaultModule: IModule = {
    name: '', key: '', component: ''
};

export interface IModuleListItem extends IBaseAuditedEntity {
    menu: IModule;
    route: IRoute;
}

export const getModuleList = (i: IBaseList<IModule>): IModule[] => {
    const d: IModule[] = [];
    for (const v of i.items) {
        d.push({ ...v.module });
    }
    return d;
};

export interface IModulePermissionUpdate extends Record<any, any> {
    id: string;
    permissions: ISecurityRole[];
}

export const defaultModulePermissionUpdate: IModulePermissionUpdate = {
    id: '', permissions: []
};
