import React, { Children, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { parseQueryString, updateURLParameter } from '../../utilities/URL';

interface IOETabsInfo {
    id?: string;
    defaultTab?: string;
    show?: boolean;
    onSelect?: (event: any) => void;
    className?: string;
    onTabChange?: (s: any) => void;
    children?: any;
}

const OETabs: React.FunctionComponent<IOETabsInfo> = ({ className, id, defaultTab, children, onTabChange }) => {
    const params: any = parseQueryString();

    const [activeTab, setActiveTab] = useState(params[id || 'tab'] || defaultTab);
    const [tab] = useState(id || 'tab');

    useEffect(() => {
        onTabChange?.(activeTab);
        // eslint-disable-next-line
    }, [activeTab]);

    const onSelect = (event: any) => {
        updateURLParameter(tab, event);
        setActiveTab(event);
    };

    return (
        <>
            <Tabs
                defaultActiveKey={activeTab}
                onSelect={onSelect}
                id={id}
                className={`${className} nav nav-tabs mb-3`}
            >
                {Children.map(children, i => i)}
            </Tabs>
        </>
    );
};

interface IOETabInfo {
    id?: string;
    eventKey: any;
    className?: string;
    title: string;
    hidden?: boolean;
    children?: any;
}

export const OETab: React.FunctionComponent<IOETabInfo> = ({ className, id, eventKey, title, children, hidden }) => {

    return (
        <Tab
            id={id}
            eventKey={eventKey}
            className={`${className} nav nav-tabs mb-3`}
            hidden={hidden}
            title={title}
        >
            <div className="tablayout m-b-10">
                {Children.map(children, i => i)}
            </div>
        </Tab>
    );
};


export default OETabs;


