import React, { useEffect, useState } from 'react';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import { defaultOEParameter, IOEParameter, OEParameterType } from './entities/OEParameter';
import OEParameterFormik from './OEParameterFormik';

enum ModalTypes {
    None = 1,
    Edit,
    Add,
}

interface IComponentInfo {
    label?: string;
    name?: string;
    values: IOEParameter[];
    type: OEParameterType;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
    onChange?: (parameters: IOEParameter[]) => void;
}

const OEParameters: React.FunctionComponent<IComponentInfo> = ({ label, name, values, setFieldValue, onChange, type }) => {
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [parameters, setParameters] = useState<IOEParameter[]>(values);
    const [parameter, setParameter] = useState<IOEParameter>(defaultOEParameter);
    const [initalized, setInitalized] = useState<boolean>(false);

    useEffect(() => {
        if (initalized) {
            setFieldValue?.(name || "parameters", JSON.stringify(parameters));
            onChange?.(parameters);
            setInitalized(false);
        }
        // eslint-disable-next-line
    }, [parameters]);

    useEffect(() => {
        setParameters(values);
        window.setTimeout(() => {
            setInitalized(true);
        }, 500); // this setTimeout is a workaround to prevent race condition on setting values
    }, [values]);

    const onDeleteParameter = (i: IOEParameter) => {
        setParameters(parameters.filter(q => q.key !== i.key));
    }

    const onAdd = () => {
        setShowModal(ModalTypes.Add);
        setParameter({ ...defaultOEParameter, type });
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSubmit = (i: IOEParameter) => {
        if (showModal === ModalTypes.Add) {
            setParameters([...parameters, i]);
        }
        else {
            for (const p of parameters) {
                if (p.key === i.key) {
                    p.value = i.value;
                }
            }
            setParameters([...parameters]);
        }
        setShowModal(ModalTypes.None);
    };

    const onEditParameter = (i: IOEParameter) => {
        setShowModal(ModalTypes.Edit);
        setParameter(i);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEditParameter, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onDeleteParameter, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'key', name: 'Key', sort: true, type: ColumnType.String },
        { id: 'value', name: 'Value', sort: true, type: ColumnType.String },
    ];

    return (
        <>
            <OERow>
                <OECol sm={2}>{label || 'Parameters'}</OECol>
                <OECol sm={10}>
                    <OETable
                        columns={columns}
                        data={parameters}
                        className="table-bordered"
                        noDataMessage="No Parameters have been added"
                        actions={[
                            { icon: Icon.Add, text: 'Add New Parameter', action: onAdd },
                        ]}
                    />
                </OECol>
            </OERow>
            {showModal !== ModalTypes.None && (
                <OEParameterFormik onCancel={onCancel} isEditing={showModal === ModalTypes.Edit} onSubmit={onSubmit} item={parameter} />
            )}
        </>
    );
};

export default OEParameters;
