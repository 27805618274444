import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";
import { OECol } from "../../core/components/grid/OECol";
import { OERow } from "../../core/components/grid/OERow";
import OESpinner from "../../core/components/messaging/OESpinner";
import { IReport } from "../../reporting/entities/Report";
import { defaultReportAccessLog } from "../../reporting/entities/ReportAccessLog";
import { useLogAccess } from "../../reporting/services/ReportService";
import { IPowerBIReport, defaultPowerBIReport } from "../entities/PowerBIReport";
import { useGetPowerBIReport } from "../services/PowerBIService";
import { ReportConfigurationType, getReportConfigurationValue } from "../../reporting/entities/ReportConfiguration";
import { getBlankGUID } from "../../core/utilities/String";

interface IComponentInfo {
    report: IReport;
    configuration: string;
}

const BIEmbeddedReport: React.FunctionComponent<IComponentInfo> = ({ report, configuration }) => {
    const { service: reportService, setItemId: setReportId } = useGetPowerBIReport();
    const { setItem: logReport } = useLogAccess();

    const [item, setItem] = useState<IPowerBIReport>(defaultPowerBIReport);
    const [loading, setLoading] = useState<boolean>(false);
    const [showTitle, setShowTitle] = useState<boolean>(true);

    useEffect(() => {
        if (reportService.result) {
            logReport({ ...defaultReportAccessLog, reportId: report.id });
            setItem(reportService.result);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (report.id !== '') {
            setLoading(true);
            setReportId(report.id);
            setShowTitle(getReportConfigurationValue(ReportConfigurationType.ShowTitle, configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    return (
        <>
            {item.embedContent.reportId !== '' && (
                <>
                    <div style={{ display: `${getBlankGUID(report.id) || loading ? 'none' : 'block'}` }}>
                        {showTitle && (
                            <>
                                <div style={{ marginLeft: '15px', marginRight: '15px', marginTop: '15px' }} >
                                    <OERow>
                                        <OECol sm={6}>
                                            <h4 >{report.title}</h4>
                                        </OECol>
                                        <OECol sm={6}>
                                            <div className="pull-right" >{report.description}</div>
                                        </OECol>
                                    </OERow>
                                </div>
                                <hr style={{ marginTop: '0px' }} />
                            </>
                        )}
                        <OESpinner message="Loading Report" hidden={!loading} />
                        <table width="100%" >
                            <tbody>
                                <tr>
                                    <td>
                                        <h2>{item.title}</h2>
                                        <PowerBIEmbed
                                            cssClassName="myFrame"
                                            embedConfig={{
                                                type: 'report',   // Since we are reporting a BI report, set the type to report
                                                id: item.embedContent.reportId, // Add the report Id here
                                                embedUrl: item.embedContent.embedUrl,
                                                // embedUrl: `${baseUrl}?reportId=21c4b44c-4487-474e-ab8d-123656340bb7&groupId=0c79c1c4-227b-4c50-a289-bf922a0555ae&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVBBQVMtMS1TQ1VTLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
                                                accessToken: item.embedContent.embedToken,
                                                tokenType: models.TokenType.Embed,
                                                permissions: models.Permissions.Read

                                                , // Since we are using an Azure Active Directory access token, set the token type to Aad
                                                settings: {
                                                    panes: {
                                                        filters: {
                                                            expanded: false,
                                                            visible: false,
                                                        },
                                                        pageNavigation: {
                                                            visible: true,
                                                        },
                                                    },
                                                    background: models.BackgroundType.Transparent,
                                                },
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )
            }
        </>
    );
};

export default BIEmbeddedReport;