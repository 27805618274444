import { useEffect, useState } from "react";
import { IBaseList } from "../../core/entities/BaseList";
import { GetRequest, processGetAsync } from "../../core/services/GetRequest";
import { PostRequest, processPostAsync } from "../../core/services/PostRequest";
import { processPutAsync, PutRequest } from "../../core/services/PutRequest";
import { emptyGUID } from "../../core/utilities/String";
import { defaultArtifact, getCommonArtifactForSave, IArtifact } from "../entities/Artifact";
import { IArtifactDatasetView } from "../entities/ArtifactDatasetView";
import { defaultArtifactDatasetViewColumnSearch, IArtifactDatasetViewColumns, IArtifactDatasetViewColumnSearch } from "../entities/ArtifactDatasetViewColumns";
import { ArtifactType } from "../entities/ArtifactType";

export const useGetArtifactDatasetViews = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IArtifactDatasetView>>());
    const [datasetConnectionId, setDatasetConnectionId] = useState<string>('');

    useEffect(() => {
        if (emptyGUID(datasetConnectionId)) { return; }
        processGetAsync(setService, `api/app/artifacts/dataset/${datasetConnectionId}/views`);
        setDatasetConnectionId('');
    }, [datasetConnectionId]);

    return { service, setDatasetConnectionId };
};

export const useGetArtifactDatasetViewColumns = () => {
    const [service, setService] = useState(GetRequest.empty<IArtifactDatasetViewColumns[]>());
    const [search, setSearch] = useState<IArtifactDatasetViewColumnSearch>(defaultArtifactDatasetViewColumnSearch);

    useEffect(() => {
        if (emptyGUID(search.datasetConnectionId)) { return; }
        processGetAsync(setService, `api/app/artifacts/dataset/${search.datasetConnectionId}/${search.view}/view-columns`);
        setSearch(search);
    }, [search]);

    return { service, setSearch };
};


export const usePostDatasetArtifact = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Dataset) { return; }
        processPostAsync(setService, `/api/app/artifacts/dataset`, getDatasetArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};

export const usePutDatasetArtifact = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [artifact, setArtifact] = useState<IArtifact>(defaultArtifact);

    useEffect(() => {
        if (artifact.artifactType !== ArtifactType.Dataset) { return; }
        processPutAsync(setService, `/api/app/artifacts/dataset/${artifact.id}`, getDatasetArtifactForSave(artifact));
        setArtifact(defaultArtifact);
    }, [artifact]);

    return { service, setArtifact };
};


const getDatasetArtifactForSave = (artifact: IArtifact): any => {
    return {
        ...getCommonArtifactForSave(artifact),
        databaseConnectionId: artifact.dataset.databaseConnectionId,
        datasetView: artifact.dataset.datasetView,
        categories: artifact.dataset.categories.length > 0 ? artifact.dataset.categories : undefined,
        filters: artifact.dataset.filters.length > 0 ? artifact.dataset.filters : undefined
    };
};

