import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from "../../core/entities/BaseList";
import { getReportConfigurationValue, ReportConfigurationType } from './ReportConfiguration';
import { ReportIconBaseURL } from './ReportIcons';
import { IReportList } from './ReportList';
import { IReportPage, ReportPageType } from './ReportPage';

export enum ReportSectionType {
    SectionHeader = 'SG',
    SectionItem = 'SH',
    SectionGroup = 'SG',
    ReportGroup = 'RG',
}

export enum ReportGroupType {
    Report = 'DR',
    AboutPage = 'AP'
}

export interface IReportGroup extends IBaseAuditedEntity {
    id: string;
    title: string;
    description: string;
    sortOrder: number;
    reportPageId?: string;
    reportGroupId?: string;
    reportCount: number;
    isActive: boolean;
    configuration: string;
    filters: string;
    about: string;
    groupType: string;
    icon: string;
    iconPath: string;
    sectionType: string;
    isPublic: boolean;
    groups: IReportGroup[];
    reports: IReportList[];
    pageType?: ReportPageType;
    selected?: boolean;
    defaultSection?: boolean;
}

export const defaultReportGroup: IReportGroup = {
    id: '', title: '', description: '', sortOrder: 0, isActive: true,
    configuration: '', filters: '', about: '', groupType: ReportGroupType.Report, icon: '', iconPath: '',
    reports: [], groups: [], sectionType: '', isPublic: false, selected: false, reportCount: 0
};

export interface IReportGroupListItem extends IBaseAuditedEntity {
    reportGroup: IReportGroup;
    reportPage: IReportPage;
}

export const getReportGroupList = (i: IBaseList<IReportGroupListItem>): IReportGroup[] => {
    const d: IReportGroup[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultReportGroup,
            ...v.reportGroup,
            icon: getReportConfigurationValue(ReportConfigurationType.Icon, v.reportGroup.configuration),
            iconPath: getReportGroupIcon(v.reportGroup.configuration)
        });
    }
    return d;
};

export const getReportGroupIcon = (config: string): string => {
    return `${ReportIconBaseURL}${getReportConfigurationValue(ReportConfigurationType.Icon, config).trim()}`;
};

export const getReportGroupIconOff = (config: string): string => {
    const s: string = getReportConfigurationValue(ReportConfigurationType.IconOff, config);
    if (s) {
        return `${ReportIconBaseURL}${s}`;
    }
    return getReportGroupIcon(config);
};

export const getReportSectionName = (type: ReportSectionType): string => {
    // tslint:disable
    switch (type) {
        case ReportSectionType.ReportGroup:
            return 'Report Group';

        case ReportSectionType.SectionHeader:
            return 'Section Header';

        case ReportSectionType.SectionItem:
            return 'Section Item';

        case ReportSectionType.SectionGroup:
            return 'Report Group';

        default:
            return '';
    }
    // tslint:enable

};
