import React, { Children } from 'react';
import { getFutureDate } from '../core/utilities/Date';

interface IComponentInfo {
    children?: any;
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children }) => {

    return (
        <>
            <main>
                <div className="public-body">
                    {Children.map(children, i => i)}
                </div>
                <div className="site-footer" >
                    <div className="pull-right m-t-5 m-r-10">
                        &copy; {getFutureDate(0).getFullYear()} Otis Educational Systems, Inc.&nbsp;
                    </div>
                </div>
            </main>
        </>
    );

};

export default Layout;